import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useAuth from '../../../../hooks/useAuth';
import translate from '../../../../utils/translate';
import styles from '../../styles.module.scss';
import Button from '../../../../components/Button';

const SignUpSending = ({ email }) => {
	const { onResendEmail } = useAuth();

	const handleSend = useCallback(() => {
		onResendEmail(email);
	}, [onResendEmail, email]);

	return (
		<>

			<h2
				className={cn(
					'h2',
					styles.title
				)}
			>

				{translate('Confirmation email')}

			</h2>

			<p className={styles.description}>

				{translate('Wrong address?')}

				&nbsp;

				{/*<Link to={SIGN_UP_ROUTE}>

					{translate('Sign up')}

				</Link>*/}

			</p>

			<p className={cn(
				styles.description,
				styles.success,
			)}>

				{translate('An e-mail with a confirmation link was send to the mailbox:')}

				&nbsp;

				<span>

					{email}

				</span>

			</p>

			<p
				className={cn(
					styles.description,
					styles.noEmail,
				)}
			>

				{translate('Didn\'t get the email? Check the address is correct and try again')}

			</p>

			<Button
				variant="contained"
				color="primary"
				size="big"
				onClick={handleSend}
			>

				{translate('Resend Email')}

			</Button>

		</>
	);
};

SignUpSending.propTypes = {
	email: PropTypes.string.isRequired,
}

export default SignUpSending;