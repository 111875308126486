import React from 'react';
import PropTypes from 'prop-types';
import cn from  'classnames';
import styles from './styles.module.scss';

const Diagram = ({ title, diagrams }) => {
	return (
		<section
			className={styles.diagram}
		>

			<div className={styles.container}>

				<div
					className={cn(
						styles.circle,
					)}
				>

					{title}

				</div>

				<div>

					{diagrams.map(({ id, title, description }) => (
						<div
							key={id}
							className={styles.item}
						>

							<div className={styles.content}>

								<h3 className={styles.title}>

									{title}

								</h3>

								<p className={styles.description}>

									{description}

								</p>

							</div>

							<div className={styles.line}/>

						</div>
					))}

				</div>

			</div>

		</section>
	);
};

Diagram.defaultProps = {
	title: '',
	diagrams: [],
};

Diagram.propTypes = {
	title: PropTypes.string,
	diagrams: PropTypes.array,
};

export default Diagram;