import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styles from '../styles.module.scss';
import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { AngleDown } from '../../../assets/icons';

const NavigationSubItem = ({tab}) => {

  const [ open, setOpen ] = useState(true);

  const toggle = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen((prev) => !prev);
  }, []);

  const hasSubTabs = useMemo(() => !!tab.subTabs, [tab]);

  return (
    <>

      <div
        onClick={toggle}
        className={cn(styles.subTabWrapper, hasSubTabs && styles.withSubItems)}
      >

        {tab.location ? (

            <NavLink
              key={tab.location}
              to={tab.location}
              exact={tab.exact}
              className={styles.subItem}
              activeClassName={styles.active}
            >

              {tab.title}

            </NavLink>

          ) : (

            <div className={styles.subItem}>

              {tab.title}

            </div>
          )
        }

        {hasSubTabs && (

          <div className={cn(styles.arrowIcon, open && styles.rotate)}>

            <AngleDown />

          </div>

        )}



      </div>

      {hasSubTabs && open && (

        <div className={styles.subTabsInner}>

          {

            tab.subTabs.map((subTab) => (
              <>

                {subTab.location ? (

                  <NavLink
                    key={subTab.location}
                    to={subTab.location}
                    exact={subTab.exact}
                    className={cn(styles.subItem, styles.ml)}
                    activeClassName={styles.active}
                  >

                    {subTab.title}

                  </NavLink>

                ) : (

                  <div className={cn(styles.subItem, styles.ml)}>

                    {subTab.title}

                  </div>
                )

                }

              </>

            ))

          }

        </div>

      )}


    </>

  );
}

NavigationSubItem.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.shape({
    location: PropTypes.string,
    title: PropTypes.string,
    subTabs: PropTypes.array,
  }).isRequired,
};

export default NavigationSubItem;
