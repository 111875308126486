import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings-dark.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/plus2.svg';
import { PAYMENT_SETTINGS_ROUTE, ADD_PAYMENT_METHOD_ROUTE, TRANSACTIONS_ROUTE } from '../../../../constants/routes';
import PrivateLayout from '../../../../layouts/PrivateLayout';
import Sidebar from '../../../../components/Sidebar';
import Button from '../../../../components/Button';
import useAuth from '../../../../hooks/useAuth';
import translate from '../../../../utils/translate';
import links from '../links';
import styles from '../styles.module.scss';
import st_pay from './styles.module.scss';
import { deletePaymentMethod, getPaymentMethods, updatePaymentMethod } from '../../../../services/actions/auth';
import { ReactComponent as LeftIcon } from '../../../../assets/icons/angle-left.svg';
import { FavoriteEmpty, FavoriteFilled } from '../../../../assets/icons';
import { toast } from 'react-toastify';

export const PAYMENT_TYPES = {
  OTHER: 'other',
  LIPA_NA_MPESA: 'mpesa',
}

export const PAYMENT_METHODS_OPTIONS = [
  {
    type: PAYMENT_TYPES.OTHER,
    title: 'Bank Account / MPESA Mobile Phone',
  },
  {
    type: PAYMENT_TYPES.LIPA_NA_MPESA,
    title: 'Lipa na MPESA',
  },
];

const PaymentInfo = () => {
  const { profile, paymentMethods } = useAuth();

  const paymentMethodsOptions = useMemo(() => {
    return PAYMENT_METHODS_OPTIONS.map((option) => {
      const method = paymentMethods?.find((method) => method.payment_type === option.type);
      return {
        type: option.type,
        key: option.type,
        title: translate(option.title),
        paymentData: method?.data || null,
        id: method?.id || null,
        primary: method?.primary || false,
      }
    }, [])
  }, [paymentMethods]);

  const onDeletePaymentMethod = useCallback((methodId) => {
    deletePaymentMethod({
      user: profile.id,
      id: methodId,
    }).then(() => {
      getPaymentMethods();
    });
  }, []);

  const onChangePrimary = useCallback((methodId, primary) => {
    const onFailure = () => {
      toast.error(translate('Error while updating payment method'));
    }

    updatePaymentMethod({
      id: methodId,
      primary,
    }, { onFailure }).then(() => {
      getPaymentMethods();
    });
  }, []);

  return (
    <PrivateLayout>

      <div className={styles.profile_container}>

        <Sidebar
          className={styles.sidebar}
          links={links(profile.is_landlord)}
        />

        <div className={st_pay.container}>

          <div className={st_pay.head}>

            <h2 className={cn(styles.title, st_pay.title)}>

              {translate('Payment Info')}

            </h2>

            <div className={st_pay.btns}>

              {profile.is_tenant && (
                <Button
                  tag={Link}
                  color="secondary"
                  variant="shadow"
                  to={PAYMENT_SETTINGS_ROUTE}
                  svg={<SettingsIcon />}
                >
                  {translate('Settings')}
                </Button>
              )}

              {/*{profile.is_landlord && paymentMethods?.length === 0 && (*/}
              {/*  <Button*/}
              {/*    tag={Link}*/}
              {/*    color="secondary"*/}
              {/*    variant="shadow"*/}
              {/*    to={ADD_PAYMENT_METHOD_ROUTE}*/}
              {/*    svg={<Plus />}*/}
              {/*    className={st_pay.addPayment}*/}
              {/*  >*/}

              {/*  <span>*/}

              {/*    {translate('Add payment method')}*/}

              {/*  </span>*/}

              {/*  </Button>*/}
              {/*)}*/}

            </div>

          </div>

          <div className={st_pay.paymentMethodsWrapper}>

            {
              paymentMethodsOptions.map((paymentMethod) => {
                return paymentMethod.paymentData ? (
                  <div
                    key={paymentMethod.key}
                    className={st_pay.paymentMethod}
                  >

                    {
                      paymentMethod.primary ? (
                        <Button
                          isIcon
                          svg={<FavoriteFilled />}
                          onClick={() => onChangePrimary(paymentMethod.id, false)}
                        />
                      ) : (
                        <Button
                          isIcon
                          svg={<FavoriteEmpty />}
                          onClick={() => onChangePrimary(paymentMethod.id, true)}
                        />
                      )
                    }

                    <Link
                      to={`${ADD_PAYMENT_METHOD_ROUTE}/${paymentMethod.id}?type=${paymentMethod.type}`}
                    >

                      {
                        paymentMethod.type === PAYMENT_TYPES.OTHER ? (

                          <>

                            <h3>{paymentMethod.paymentData.bank_name}</h3>

                            <p>{paymentMethod.paymentData._type === 'mpesa' && '+'}{paymentMethod.paymentData.bank_account.slice(-4).padStart(paymentMethod.paymentData.bank_account.length, '*')}</p>

                          </>

                        ) : (

                          <>

                            <h3>{translate(paymentMethod.title)}</h3>

                            <p>{paymentMethod.paymentData.shortcode.slice(-4).padStart(paymentMethod.paymentData.shortcode.length, '*')}</p>

                          </>

                        )
                      }

                    </Link>

                    <Button
                      color="danger"
                      isIcon
                      size="small"
                      onClick={() => onDeletePaymentMethod(paymentMethod.id)}
                    >

                      <TrashIcon />

                    </Button>

                  </div>
                ) : (
                  <Button
                    key={paymentMethod.key}
                    tag={Link}
                    to={`${ADD_PAYMENT_METHOD_ROUTE}?type=${paymentMethod.type}`}
                    svg={<Plus />}
                    className={st_pay.addPayment}
                  >

                    <span>

                      {translate(paymentMethod.title)}

                    </span>

                  </Button>
                )
              })
            }

          </div>


          {/*{paymentMethods?.length === 1 && (*/}
          {/*  <div className={st_pay.paymentMethods}>*/}

          {/*    {paymentMethods.map((method) => {*/}
          {/*      const handleDelete = () => {*/}
          {/*        deletePaymentMethod({*/}
          {/*          user: profile.id,*/}
          {/*          id: method.id,*/}
          {/*        })*/}
          {/*      };*/}

          {/*      return (*/}
          {/*        <div*/}
          {/*          key={method.id}*/}
          {/*          className={st_pay.paymentMethod}*/}
          {/*        >*/}

          {/*          <Link*/}
          {/*            to={`${ADD_PAYMENT_METHOD_ROUTE}/${method.id}`}*/}
          {/*          >*/}

          {/*            <h3>{method.bank_name}</h3>*/}

          {/*            <p>{method._type === 'mpesa' && '+'}{method.bank_account.slice(-4).padStart(method.bank_account.length, '*')}</p>*/}

          {/*          </Link>*/}

          {/*          <Button*/}
          {/*            color="danger"*/}
          {/*            isIcon*/}
          {/*            size="small"*/}
          {/*            onClick={handleDelete}*/}
          {/*          >*/}

          {/*            <TrashIcon />*/}

          {/*          </Button>*/}

          {/*        </div>*/}
          {/*      );*/}
          {/*    })}*/}

          {/*  </div>*/}
          {/*)}*/}

        </div>

      </div>

    </PrivateLayout>
  );
};

export default PaymentInfo;
