import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Line = ({ className, children }) => {

	return (
		<div
			className={cn(
				styles.line,
				className
			)}
		>

			<span>

				{children}

			</span>

		</div>
	);
};

Line.defaultProps = {
	className: '',
	children: '',
};

Line.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default Line;
