import React, { useCallback, useRef } from 'react';
import SwiperCore, { Navigation, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import NavigationButton from '../../../../components/NavigationButton';
import Review from './Review';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import styles from './styles.module.scss';

SwiperCore.use([Navigation, Scrollbar, A11y]);

const Reviews = ({ reviews }) => {
	const swiperRef = useRef(null);

	const handleNext = useCallback((e) => {
		swiperRef.current.navigation.onNextClick(e);
	}, [swiperRef]);

	const handlePrev = useCallback((e) => {
		swiperRef.current.navigation.onPrevClick(e);
	}, [swiperRef]);

	return (
		<section
			className={styles.reviews}
		>

			<div className={styles.wrap}>

				<Swiper
					slidesPerView={1}
					loop
					onSwiper={(swiper) => {
						swiperRef.current = swiper;
					}}
				>

					{reviews.map(({ id, title, description, icon, position }) => (
						<SwiperSlide
							key={id}
						>

							<Review
								content={description}
								profile={{
									avatar: icon,
									name: title,
									position: position,
								}}
							/>

						</SwiperSlide>
					))}

				</Swiper>

				<div className={styles.navigation}>

					<NavigationButton
						className={styles.navigationNext}
						onClick={handleNext}
					/>

					<NavigationButton
						className={styles.navigationPrev}
						onClick={handlePrev}
						direction="prev"
					/>

				</div>

			</div>

		</section>
	);
};

export default Reviews;