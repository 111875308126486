import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import styles from './styles.module.scss';

export const InputNotice = ({ className, name }) => {

	return (
		<ErrorMessage
			name={name}
			component="div"
			className={cn(
				styles.inputNotice,
				className
			)}
		/>
	);
};

InputNotice.defaultProps = {
	className: '',
}

InputNotice.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
}

export const InputNoticeUC = ({ className, error }) => {

	return typeof error === 'boolean' ? null : (
		<div
			className={cn(styles.inputNotice, className)}
		>

			{error}

		</div>
	);
};

InputNoticeUC.defaultProps = {
	className: '',
	error: false,
}

InputNoticeUC.propTypes = {
	className: PropTypes.string,
	error: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
}