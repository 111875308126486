import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import { useParams } from 'react-router';
import { ReactComponent as LeftIcon } from '../../../assets/icons/angle-left.svg';
import { PrintSvg } from '../../../assets/icons';
import { TRANSACTIONS_ROUTE } from '../../../constants/routes';
import PrivateLayout from '../../../layouts/PrivateLayout';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import useConfigurations from '../../../hooks/useConfigurations';
import useTransaction from '../../../hooks/useTransaction';
import useProperty from '../../../hooks/useProperty';
import useTenant from '../../../hooks/useTenant';
import useLease from '../../../hooks/useLease';
import useUnit from '../../../hooks/useUnit';
import translate from '../../../utils/translate';
import styles from './styles.module.scss';

export default function TransactionDetail() {
  const { id } = useParams();

  const { status_transaction, categories, payment_modes, subcategories } = useConfigurations()

  // const { transaction } = useTransaction(Number(id));
  const { transaction } = useTransaction(id);

  const { lease } = useLease(transaction?.lease_id);

  const { property } = useProperty(lease?.property_id);

  const { unit } = useUnit(lease?.unit_id);

  const { tenant } = useTenant(transaction?.tenant_id);

  if(!transaction) {
    return (
      <Loader />
    );
  }

  return (
    <PrivateLayout>

      <div className={styles.transactionDetail}>

        <div className={styles.heading}>

          <div className={styles.control}>

            <Button
              tag={Link}
              variant="goBack"
              to={TRANSACTIONS_ROUTE}
              isIcon
              svg={<LeftIcon />}
            />

            <div className={styles.title}>

              {transaction.parent_number ? translate('Receipt') : transaction.invoice_id ? translate('Invoice') : translate('Transaction')}

              &nbsp;

              <span className={styles.number}>

                {`${id}`}

              </span>

            </div>

          </div>

          <Button
            color="secondary"
            variant="shadow"
            size="small"
            svg={<PrintSvg />}
          >

            {translate('Print')}

          </Button>

        </div>

        <Card>

          <CardHeader styles={{ padding: 0}}>

            <div
              className={cn(
                styles.header,
                {
                  [styles[transaction.status]]: transaction.status,
                },
              )}
            >

              {`${transaction.parent_number ? translate('Receipt') : transaction.invoice_id ? translate('Invoice') : translate('Transaction')} ${status_transaction.find((status) => status.id === transaction.status)?.name}`}

            </div>

          </CardHeader>

          <CardBody>

            <div className={styles.tr}>

              <div className={styles.label}>

                {transaction.parent_number ? translate('Receipt ID') : transaction.invoice_id ? translate('Invoice ID') : translate('Transaction ID')}

              </div>

              <div className={styles.value}>

                {`${id}`}

              </div>

            </div>

            {

              transaction.parent_number && transaction.reference && (

                <div className={styles.tr}>

                  <div className={styles.label}>

                    {translate('Reference')}

                  </div>

                  <div className={styles.value}>

                    {`${transaction.reference}`}

                  </div>

                </div>

              )

            }

            <div className={styles.tr}>

              <div className={styles.label}>

                {translate('Automatic payment')}

              </div>

              <div className={styles.value}>

                {transaction.automatic_payment ? translate('Yes') : translate('No')}

              </div>

            </div>

            <div className={styles.tr}>

              <div className={styles.label}>

                {transaction.parent_number ? translate('Transaction Type') : transaction.invoice_id ? translate('Invoice Type') : translate('Transaction Type')}

              </div>

              <div className={styles.value}>

                {categories.find((category) => category.id === transaction.category_id)?.name}

              </div>

            </div>

            {transaction.sub_category_id && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {translate('Sub category')}

                </div>

                <div className={styles.value}>

                  {subcategories.find((category) => category.id === transaction.sub_category_id)?.name}

                </div>

              </div>
            )}

            {property && unit && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {translate('Property')}

                </div>

                <div className={styles.value}>

                  {`${property.name} ${unit.name}`}

                </div>

              </div>
            )}

            {transaction.lease_id && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {translate('Lease')}

                </div>

                <div className={styles.value}>

                  {`Lease ${transaction.lease_id}`}

                </div>

              </div>
            )}

            {transaction.settlement_ref && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {translate('Settlement reference')}

                </div>

                <div className={styles.value}>

                  {`Lease ${transaction.settlement_ref}`}

                </div>

              </div>
            )}

            {transaction.invoice_date && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {translate('Invoice date')}

                </div>

                <div className={styles.value}>

                  {moment(transaction.invoice_date).format('MM.DD.YYYY')}

                </div>

              </div>
            )}

            {transaction.invoice_date_due && (
              <div className={styles.tr}>

              <div className={styles.label}>

              {translate('Invoice due date')}

              </div>

              <div className={styles.value}>

              {moment(transaction.invoice_date_due).format('MM.DD.YYYY')}

              </div>

              </div>
            )}

            {transaction.transaction_date && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {translate('Transaction date')}

                </div>

                <div className={styles.value}>

                  {moment(transaction.transaction_date).format('MM.DD.YYYY')}

                </div>

              </div>
            )}

            {tenant && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {transaction.parent_number ? translate('Tenant') : translate('Payer') }

                </div>

                <div className={styles.value}>

                  {tenant.first_name ? `${tenant.first_name} ${tenant.last_name}` : tenant.email}

                </div>

              </div>
            )}

            <div className={styles.tr}>

              <div className={styles.label}>

                {translate('Mode')}

              </div>

              <div className={styles.value}>

                {payment_modes.find((mode) => mode.id === transaction.mode_id)?.name}

              </div>

            </div>

            <div className={styles.tr}>

              <div className={styles.label}>

                {translate('Amount')}

              </div>

              <div className={styles.value}>

                {`${transaction.amount}KSh`}

              </div>

            </div>

            {transaction.transaction_code && (
              <div className={styles.tr}>

                <div className={styles.label}>

                  {translate('Receipt number')}

                </div>

                <div className={styles.value}>

                  {transaction.transaction_code}

                </div>

              </div>
            )}

            <div className={styles.tr}>

              <div className={styles.label}>

                {translate('Description')}

              </div>

              <div className={styles.value}>

                {transaction.description}

              </div>

            </div>

          </CardBody>

        </Card>

      </div>

    </PrivateLayout>
  );
}
