import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import PublicLayout from '../PublicLayout';
import { HOME_ROUTE } from '../../constants/routes';
import translate from '../../utils/translate';
import styles from './styles.module.scss';

const AuthLayout = ({ children }) => {

	return (
		<PublicLayout>

			<section className={styles.authLayout}>

				<div className={cn(styles.row, styles.form)}>

					{children}

				</div>

				<div className={cn(styles.row, styles.info)}>

					<Link
						className={styles.logo}
						to={HOME_ROUTE}
					>

						{process.env.REACT_APP_WEBSITE_NAME}

					</Link>

					<div>

						<h2 className={styles.title}>

							{translate('A few clicks away from creating your account')}

						</h2>

						<p className={styles.description}>

							{translate('Create you aacount in minutes')}

							<br/>

							{translate('Save your money and time')}

						</p>

					</div>

				</div>

			</section>

		</PublicLayout>
	);
};

AuthLayout.dafaultProps = {
	children: '',
};

AuthLayout.propTypes = {
	children: PropTypes.node,
};

export default AuthLayout;