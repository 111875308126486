import React from 'react';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus2.svg';
import Tenant from './Tenant';
import ModalUser from './ModalUser';
import Button from '../../../../components/Button';
import useModal from '../../../../hooks/useModal';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

const Tenants = ({ users }) => {
	const [modalUser, openModalUser, closeModalUser] = useModal(false);

	return (
		<div className={styles.tenants}>

			<ModalUser
				isOpen={modalUser}
				onClose={closeModalUser}
			/>

			<div className={styles.header}>

				<h2>

					{translate('Direct message')}

				</h2>

				<Button
					color="primary"
					isIcon
					onClick={openModalUser}
				>

					<PlusIcon />

				</Button>

			</div>

			<div className={styles.list}>

				{users.map(({ id, avatar, title, content, time, count }) => (
					<Tenant
						key={id}
						id={id}
						avatar={avatar}
						title={title}
						content={content}
						time={time}
						count={count}
					/>
				))}

			</div>

		</div>
	);
};

export default Tenants;