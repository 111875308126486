import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import PrivateLayout from '../../../layouts/PrivateLayout';
import { InputText } from '../../../components/Inputs/InputText';
import InputCheckbox from '../../../components/Inputs/InputCheckbox';
import InputPhone from '../../../components/Inputs/InputPhone';
import InputImage from '../../../components/Inputs/InputImage';
import InputDate from '../../../components/Inputs/InputDate';
import HalfBlock from '../../../components/Inputs/HalfBlock';
import Sidebar from '../../../components/Sidebar';
import Button from '../../../components/Button';
import Label from '../../../components/Label';
import useModal from '../../../hooks/useModal';
import useAuth from '../../../hooks/useAuth';
import links from './links';
import translate from '../../../utils/translate';
import styles from './styles.module.scss';

const Profile = () => {
	const [readonly, setPositive, setNegative] = useModal(true);

	const { profile, onUpdateProfile } = useAuth();

	const { is_landlord } = profile;

	const initValues = {
		avatar: profile.avatar || '',
		first_name: profile.first_name || '',
		last_name: profile.last_name || '',
		company: profile.company || '',
		as_company: !!profile.as_company,
		birthday: profile.birthday ? new Date(profile.birthday) : new Date(),
		phone: profile.phone || '',
		city: profile.city || '',
		address: profile.address || '',
		state: profile.state || '',
	};

	const shape = useMemo(() => {
		const object = {
			avatar: Yup.mixed()
				.imageTypes()
				.fileMaxSize(),
			first_name: Yup.string()
				.required(),
			last_name: Yup.string()
				.required(),
			birthday: Yup.date()
				.required(),
			phone: Yup.string(),
			city: Yup.string(),
			address: Yup.string(),
			state: Yup.string(),
		}

		if(is_landlord) {
			object.company = Yup.string();
			object.as_company = Yup.bool();
		}

		return object;
	}, [is_landlord]);

	const schema = Yup.object().shape(shape);

	const handleSubmit = useCallback((values, action) => {
		onUpdateProfile(values, { ...action, onSuccess: setPositive });
	}, [setPositive, onUpdateProfile]);

	return (
		<PrivateLayout>

			<div className={styles.profile_container}>

				<Sidebar
					className={styles.sidebar}
					links={links(profile.is_landlord)}
				/>

				<div className={styles.container}>

					<Formik
						initialValues={initValues}
						validationSchema={schema}
						onSubmit={handleSubmit}
						enableReinitialize
					>

						{({ isSubmitting, handleReset }) => (
							<Form>

								<div className={styles.wrapTitle}>

									<h2 className={styles.title}>

										{translate('General Info')}

									</h2>

									{readonly && (
										<Button
											color="secondary"
											variant="shadow"
											svg={<EditIcon />}
											onClick={setNegative}
											className={styles.editButton}
										>

											{translate('Edit Profile')}

										</Button>
									)}

								</div>

								<InputImage
									name="avatar"
									id="avatar"
									variant="profile"
									disabled={readonly}
								/>

								<HalfBlock className={styles.halfBlock}>

									<Label>

										{translate('First Name')}

									</Label>

									<Label>

										{translate('Last Name')}

									</Label>

									<InputText
										id="first_name"
										name="first_name"
										disabled={readonly}
									/>

									<InputText
										id="last_name"
										name="last_name"
										disabled={readonly}
									/>

								</HalfBlock>

								<Label>

									{translate('Company')}

								</Label>

								<InputText
									id="company"
									name="company"
									disabled={readonly}
								/>

								<InputCheckbox
									id="as_company"
									name="as_company"
									variant="switch"
									title={translate('Display as a company')}
									disabled={readonly}
									value={true}
								/>

								<Label>

									{translate('Date of birth')}

								</Label>

								<InputDate
									id="birthday"
									name="birthday"
									disabled={readonly}
								/>

								<Label>

									{translate('Phone')}

								</Label>

								<InputPhone
									id="phone"
									name="phone"
									disabled={readonly}
								/>

								<Label>

									{translate('Neighbourhood')}

								</Label>

								<InputText
									id="address"
									name="address"
									disabled={readonly}
								/>

								<Label>

									{translate('City')}

								</Label>

								<InputText
									id="city"
									name="city"
									disabled={readonly}
								/>

								<Label>

									{translate('Country')}

								</Label>

								<InputText
									id="state"
									name="state"
									disabled={readonly}
								/>

								{!readonly && (
									<div className={styles.buttons}>

										<Button
											color="primary"
											onClick={() => {
												handleReset();
												setPositive();
											}}
										>

											{translate('Cancel')}

										</Button>

										<Button
											color="primary"
											variant="contained"
											type="submit"
											loading={isSubmitting}
										>

											{translate('Confirm')}

										</Button>

									</div>
								)}

							</Form>
						)}

					</Formik>

				</div>

			</div>

		</PrivateLayout>
	);
};

export default Profile;