import React from 'react';
import PublicLayout from '../../../layouts/PublicLayout';
import styles from './styles.module.scss';
import cn from 'classnames';
import RequestDemoForm from './RequestDemoForm';
import usePublic from '../../../hooks/usePublic';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const RequestDemoPage = () => {

  const {
    REQUEST_DEMO_PAGE_TITLE,
    REQUEST_DEMO_PAGE_1_TITLE,
    REQUEST_DEMO_PAGE_2_TITLE,
    HOME_DEVICES_2_IMAGE_URL,
    REQUEST_DEMO_PAGE_3_IMAGE_URL,
  } = usePublic();

  return (
    <PublicLayout>

      <section className={styles.requestDemoLayout}>

        <div className={cn(styles.row, styles.info)}>

          <h2 className={styles.title}>

            {REQUEST_DEMO_PAGE_TITLE}

          </h2>

          <h4 className={styles.subtitle}>

            {REQUEST_DEMO_PAGE_1_TITLE}
            <br/>
            {REQUEST_DEMO_PAGE_2_TITLE}

          </h4>

          <figure className={styles.imageMain}>

            <img src={REQUEST_DEMO_PAGE_3_IMAGE_URL} alt="request demo" />

          </figure>

          <figure className={styles.imageDevice}>

            <img src={HOME_DEVICES_2_IMAGE_URL} alt="request demo" />

          </figure>

          <h3 className={styles.logoText}>

            {process.env.REACT_APP_WEBSITE_NAME}

          </h3>

        </div>

        <div className={cn(styles.row, styles.form)}>

          <div className={styles.formWrapper}>

            <GoogleReCaptchaProvider
              size="invisible"
              reCaptchaKey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY}`}
            >
              <RequestDemoForm />
            </GoogleReCaptchaProvider>

          </div>

        </div>

      </section>

    </PublicLayout>
  );
};

export default RequestDemoPage;
