import schemas from '../schemas';
import requestWrapper from '../../utils/requestWrapper';
import { PROPERTIES } from '../../constants/type';
import { API_PROPERTIES_URL, API_PROPERTIES_IMAGES_URL } from '../../constants/api';
import defaultErrorHandler from '../../utils/errorHandlers';
import { getUnit } from './units';

export const getProperties = async ({
	actions,
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: PROPERTIES.GET_LIST,
		url: API_PROPERTIES_URL,
		method: 'get',
		normalizeSchema: schemas.PROPERTIES_ARRAY,
		onSuccess,
	});
};

export const getProperty = async (id, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: PROPERTIES.GET_PROPERTY,
		url: `${API_PROPERTIES_URL}${id}/`,
		method: 'get',
		normalizeSchema: schemas.PROPERTIES,
		onSuccess,
		onFailure,
	});
};

const getRequestData = (data) => {
	const requestData = new FormData();

	requestData.append('name', data.name);
	requestData.append('type', data.type);
	requestData.append('address', data.address);
	requestData.append('city', data.city);
	requestData.append('state', data.state);
	requestData.append('unit_type', data.unit_type ? 'multi' : 'single');
	requestData.append('amenities', JSON.stringify(data.amenities));

	if (!(typeof data.cover === 'string' && data.cover.length > 0)) {
		requestData.append('cover', data.cover);
	}

	return requestData;
};

const updateUnits = (property) => {
	property.units.forEach((unit) => {
		getUnit(unit.id);
	});
};

export const createProperty = async (data, actions) => {
	const requestData = getRequestData(data);

	requestData.append('units', JSON.stringify((data.unit_type ? data.multi : data.single).map((unit) => ({
		...unit,
		features: Object.keys(unit.features).map((featureId) => ({
			feature: featureId,
			quantity: unit.features[featureId].quantity,
		}))
	}))));

	const func = async (index, property, unitId) => {
		if (index < data.gallery.length) {
			const imageFormData = new FormData();

			imageFormData.append('image', data.gallery[index]);
			imageFormData.append('property', property);

			await requestWrapper({
				action: PROPERTIES.ADD_PROPERTY_IMAGE,
				url: API_PROPERTIES_IMAGES_URL,
				method: 'post',
				normalizeSchema: schemas.PROPERTIES,
				requestData: imageFormData,
				onSuccess: () => func(index + 1, property, unitId),
			});
		} else if (actions?.onSuccess) {
			await getProperty(property);

			actions.onSuccess(unitId);
		}
	};

	const onSuccess = (response) => {
		func(0, response.id, response.units[0]?.id);

		updateUnits(response);
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: PROPERTIES.CREATE_PROPERTY,
		url: API_PROPERTIES_URL,
		method: 'post',
		normalizeSchema: schemas.PROPERTIES,
		requestData,
		onSuccess,
		onFailure,
	});
};

export const updateProperty = async (data, actions) => {
	const requestData = getRequestData(data);

	requestData.append('id', data.id);

	if (data.multi) {
		const unitsIdsArr = data.multi.map(x => x.id);

		console.log(unitsIdsArr)
	}

	requestData.append('units', JSON.stringify((data.unit_type ? data.multi : data.single)
		.map((unit) => ({
			...unit,
			features: Object.keys(unit.features).map((featureId) => ({
				id: unit.features[featureId].id,
				quantity: unit.features[featureId].quantity,
				feature: featureId,
				unit: unit.id,
			}))
		}))));

	const imageIds = data.gallery.filter((obj) => obj.id).map((image) => image.id)

	const list = [
		...data.gallery.filter((obj) => !obj.id),
		...data.images.filter((image) => !imageIds.includes(image.id)),
	];

	const func = async (index, unitId) => {
		if (index < list.length) {
			if (list[index].id) {
				await requestWrapper({
					action: PROPERTIES.REMOVE_PROPERTY_IMAGE,
					url: `${API_PROPERTIES_IMAGES_URL}${list[index].id}/`,
					method: 'delete',
					requestData: list[index],
					addDataToResponse: true,
					normalizeSchema: schemas.PROPERTIES,
					onSuccess: () => func(index + 1, unitId),
				});
			} else {
				const imageFormData = new FormData();

				imageFormData.append('image', list[index]);
				imageFormData.append('property', data.id);

				await requestWrapper({
					action: PROPERTIES.ADD_PROPERTY_IMAGE,
					url: API_PROPERTIES_IMAGES_URL,
					method: 'post',
					normalizeSchema: schemas.PROPERTIES,
					requestData: imageFormData,
					onSuccess: () => func(index + 1, unitId),
				});
			}
		} else if (actions?.onSuccess) {
			await getProperty(data.id);

			actions.onSuccess(unitId);
		}
	};

	const onSuccess = (response) => {
		func(0, response.units[0]?.id);

		updateUnits(response);
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: PROPERTIES.UPDATE_PROPERTY,
		url: `${API_PROPERTIES_URL}${data.id}/`,
		method: 'patch',
		normalizeSchema: schemas.PROPERTIES,
		requestData,
		onSuccess,
		onFailure,
	});
};
