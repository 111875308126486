import { TENANTS_ROUTE } from '../../../constants/routes';

const getLinks = (id) => {
	return [
		{
			title: 'General Info',
			location: `${TENANTS_ROUTE}/${id}`,
			exact: true,
		},
		{
			title: 'Accounting',
			location: `${TENANTS_ROUTE}/${id}/accounting`,
		},
		{
			title: 'Leases',
			location: `${TENANTS_ROUTE}/${id}/leases`,
		},
	];
}

export default getLinks;