import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const HalfBlock = ({ className, children }) => {
	return (
		<div
			className={cn(
				styles.halfBlock,
				className,
			)}
		>

			{React.Children.toArray(children).map(({ key, ...tt }) => {
				return (
					<div key={key}>
						{tt}
					</div>
				);
			})}

		</div>
	);
};

HalfBlock.defaultProps = {
	className: '',
	children: '',
};

HalfBlock.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default HalfBlock;