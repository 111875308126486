import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

const Hero = ({ title, image }) => {
	return (
		<section
			className={styles.hero}
		>

			<div className={styles.wrap}>

				<h2
					className={styles.title}
				>

					{title}

				</h2>

				<Button
					className={styles.button}
					tag={Link}
					to={SIGN_IN_ROUTE}
					color="primary"
					variant="contained"
					size="big"
				>

					{translate('Get Started')}

				</Button>

				<div className={styles.promo}>

					<figure className={styles.promoHalf}>

						<img src={image} alt="hero" />

					</figure>

				</div>

			</div>

			<div className={styles.promoWrap}>

				<div className={styles.promo}>

					<div className={styles.promoSpace} />

				</div>

			</div>

		</section>
	);
};

Hero.defautProps = {
	title: '',
	image: '',
};

Hero.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
};

export default Hero;