import { AUTH, PROPERTIES } from '../../constants/type';

export const defaultState = {
	ids: [],
	select: [],
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case PROPERTIES.GET_LIST.SUCCESS:
			return {
				...state,
				ids: [...new Set([
					...state.ids,
					...payload.result,
				])],
				select: [...new Set([
					...state.select,
					...payload.result.map(id => payload.entities.properties[id]),
				])]
			};

		case PROPERTIES.CREATE_PROPERTY.SUCCESS:
			return {
				...state,
				ids: [...new Set([
					...state.ids,
					payload.result,
				])],
			};

		case AUTH.SIGN_OUT.SUCCESS:
		case AUTH.GET_PROFILE.FAILURE: {
			return defaultState;
		}

		default:
			return state;
	}
};
