import React, { useEffect, useState } from 'react';
import AuthLayout from '../../../layouts/AuthLayout';
import SignUpForm from './SignUpForm';
import SignUpSending from './SignUpSending';
import history from '../../../utils/history';

const SignUpPage = () => {
	const [email, setEmail] = useState('');

	useEffect(() => {
		const unlisten = history.listen(() => {
			setEmail('');
		});

		return () => {
			unlisten();
		}
	}, []);

	return (
		<AuthLayout>

			{
				email
					? (
						<SignUpSending
							email={email}
						/>
					)
					: (
						<SignUpForm
							setEmail={setEmail}
						/>
					)
			}

		</AuthLayout>
	);
};

export default SignUpPage;