import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as EditInvoice } from '../../../../assets/icons/invoice.svg';
import { ReactComponent as LeftIcon } from '../../../../assets/icons/angle-left.svg';
import { ReactComponent as FileIcon } from '../../../../assets/icons/file.svg';
import Button from '../../../../components/Button';
import translate from '../../../../utils/translate';
import useLease from '../../../../hooks/useLease';
import Card from '../../../../components/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardTitle from '../../../../components/Card/CardTitle';
import CardBody from '../../../../components/Card/CardBody';
import Loader from '../../../../components/Loader';
import Avatar from '../../../../components/Avatar';
import useProperty from '../../../../hooks/useProperty';
import useModal from '../../../../hooks/useModal';
import history from '../../../../utils/history';
import styles from '../styles.module.scss';
import ModalTermination from './ModalTermination';
import Lease from '../../../../components/Lease';

const LeaseDetail = () => {
	const { leaseId } = useParams();

	const [modalTermination, openModalTermination, closeModalTermination] = useModal(false);

	const { lease } = useLease(Number(leaseId));

	const { property } = useProperty(lease?.property_id);

	const onEdit = useCallback(() => {
		history.push(`${history.location.pathname}/edit`);
	}, []);

	if(!lease || !property) {
		return (<Loader/>);
	}

	return (
		<>

			<ModalTermination
				isOpen={modalTermination}
				onClose={closeModalTermination}
			/>

			<div className={styles.wrapTitle}>

				<h2 className={styles.title}>

					<Button
						className={styles.buttonBack}
						color="secondary"
						variant="shadow"
						isIcon
						svg={<LeftIcon />}
					/>

					{translate('Lease Details')}

				</h2>

				<div className={styles.controls}>

					<Button
						color="secondary"
						variant="shadow"
						svg={(
							<EditIcon />
						)}
						onClick={onEdit}
						className={styles.control}
					>

						{translate('Edit')}

					</Button>

					<Button
						color="secondary"
						variant="shadow"
						svg={(
							<EditInvoice/>
						)}
						onClick={openModalTermination}
						className={styles.control}
					>

						{translate('Lease Termination')}

					</Button>

					<Button
						color="secondary"
						variant="shadow"
						svg={(
							<EditIcon />
						)}
						onClick={onEdit}
						className={styles.controlMobile}
					/>

					<Button
						color="secondary"
						variant="shadow"
						svg={(
							<EditInvoice/>
						)}
						onClick={openModalTermination}
						className={styles.controlMobile}
					/>

				</div>

			</div>

			<Card className={styles.card}>

				<CardHeader>

					<CardTitle>

						{translate('General Info')}

					</CardTitle>

				</CardHeader>

				<CardBody>

					<div className={styles.leasesWrap}>

						<div className={styles.leasesItem}>

							<h4>

								{translate('Property')}

							</h4>

							<div
								className={styles.leasesTenant}
							>

								<Avatar
									variant="circle"
									size="32"
									src={property.cover}
								/>

								<p>

									{[property.city, property.address].join(', ')}

								</p>

							</div>

						</div>


						<div className={styles.leasesItem}>

							<h4>

								{translate('Landlord')}

							</h4>

							<div
								className={styles.leasesTenant}
							>

								<Avatar
									variant="circle"
									size="32"
								/>

								<p>

									{['Name', 'Landlord'].join(' ')}

								</p>

							</div>

						</div>

					</div>

				</CardBody>

			</Card>

			<Card className={styles.card}>

				<CardHeader>

					<CardTitle>

						{translate('Tenant Info')}

					</CardTitle>

				</CardHeader>

				<CardBody>

					<Lease
						leaseId={Number(leaseId)}
						noTitle
					/>

				</CardBody>

			</Card>

			<Card>

				<CardHeader className={styles.cardHeader}>

					<CardTitle>

						{translate('Agreements')}

					</CardTitle>

					{lease.document && (
						<Button
							className={styles.buttonFile}
							tag="a"
							color="primary"
							isIcon
							href={lease.document}
							target="_blank"
						>

							<FileIcon />

						</Button>
					)}

				</CardHeader>

				<CardBody>

					{lease.agreement}

				</CardBody>

			</Card>

		</>
	);
};

export default LeaseDetail;