import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import {
	CHANGE_EMAIL_ROUTE,
	CHANGE_PASSWORD_ROUTE,
	HOME_ROUTE,
	MESSAGES_ROUTE,
	PAYMENT_ROUTE,
	PAYMENT_SETTINGS_ROUTE,
	PROFILE_ROUTE,
	PROPERTIES_ROUTE,
	RECOVER_PASSWORD_ROUTE,
	SECURITY_ROUTE,
	TRANSACTION_PAY_INVOICE_ROUTE,
	TRANSACTIONS_ROUTE
} from '../../../constants/routes';
import TransactionPayInvoice from '../../../modules/Private/TransactionPayInvoice';
import TransactionDetail from '../../../modules/Private/TransactionDetail';
import RecoverPassword from '../../../modules/Private/Profile/Security/RecoverPassword';
import ChangePassword from '../../../modules/Private/Profile/Security/ChangePassword';
import OverviewTenant from '../../../modules/Private/OverviewTenant';
import Transactions from '../../../modules/Private/Transactions';
import ChangeEmail from '../../../modules/Private/Profile/Security/ChangeEmail';
import UnitDetail from '../../../modules/Private/UnitDetail';
import Messages from '../../../modules/Private/Messages';
import Security from '../../../modules/Private/Profile/Security';
import Profile from '../../../modules/Private/Profile';
import Units from '../../../modules/Private/Units';
import PaymentPage from '../../../modules/Auth/PaymentPage';
import PaymentSettings from '../../../modules/Private/Profile/PaymentInfo/PaymentSettings';

const TenantRouter = () => (
	<Switch>

		<Route exact path={PAYMENT_ROUTE}><PaymentPage /></Route>

		<Route exact path={HOME_ROUTE}><OverviewTenant /></Route>

		{/* PROPERTIES */}
		<Route exact path={PROPERTIES_ROUTE}><Units /></Route>
		<Route path={`${PROPERTIES_ROUTE}/:unit_id`}><UnitDetail /></Route>

		{/* TRANSACTIONS */}
		<Route exact path={TRANSACTIONS_ROUTE}><Transactions /></Route>
		<Route path={`${TRANSACTION_PAY_INVOICE_ROUTE}/:id?`}><TransactionPayInvoice /></Route>
		<Route path={`${TRANSACTIONS_ROUTE}/:id`}><TransactionDetail /></Route>

		{/* MESSAGES */}
		<Route path={MESSAGES_ROUTE}><Messages /></Route>

		{/* PROFILE */}
		<Route path={PROFILE_ROUTE}><Profile /></Route>
		<Route exact path={SECURITY_ROUTE}><Security /></Route>
		<Route path={CHANGE_EMAIL_ROUTE}><ChangeEmail /></Route>
		<Route path={CHANGE_PASSWORD_ROUTE}><ChangePassword /></Route>
		<Route path={RECOVER_PASSWORD_ROUTE}><RecoverPassword /></Route>
		<Route path={PAYMENT_SETTINGS_ROUTE}><PaymentSettings /></Route>

		{/* NOT FOUND */}
		<Route path="*"><Redirect to={HOME_ROUTE} /></Route>

	</Switch>
);

export default TenantRouter;
