import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DropdownContext from '../../../contexts/DropdownContext';
import styles from './styles.module.scss';

const DropDownItem = ({
  className, toggle, disabled, onClick, children,
}) => {
  const dropdownContext = useContext(DropdownContext);

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      if (onClick) {
        onClick(e);
      }

      if (toggle) {
        dropdownContext.toggle(e);
      }
    }
  };

  return (
    <button
      className={cn(styles.dropDownItem, className)}
      type="button"
      onClick={handleClick}
    >

      {children}

    </button>
  );
};

DropDownItem.defaultProps = {
  className: '',
  onClick: undefined,
  disabled: false,
  toggle: false,
  children: '',
};

DropDownItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  toggle: PropTypes.bool,
  children: PropTypes.node,
};

export default DropDownItem;
