import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TenantsFilter  from './TenantsFilter';
import ModalSendInvite from './ModalSendInvite';
import { CellSvg, ImportSvg, PlusSvg, SendSvg, SettingsSvg } from '../../../assets/icons';
import {
  MOVE_IN_ROUTE,
  TENANTS_CREATE_ROUTE,
  TENANTS_IMPORT_ROUTE,
  TENANTS_ROUTE
} from '../../../constants/routes';
import PrivateLayout from '../../../layouts/PrivateLayout/index';
import UncontrolledDropdown from '../../../components/DropDown/UncontrolledDropdown';
import DropdownToggle from '../../../components/DropDown/DropdownToggle';
import DropdownMenu from '../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../components/DropDown/DropDownItem';
import TableSimple from '../../../components/TableSimple';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/Button';
import Tenant from '../../../components/Tenant';
import Status from '../../../components/Status';
import { changeTenantsPage, getTenants } from '../../../services/actions/tenants';
import useConfigurations from '../../../hooks/useConfigurations';
import useTenantsFilter from '../../../hooks/useTenantsFilter';
import useProperty from '../../../hooks/useProperty';
import useTenants from '../../../hooks/useTenants';
import useTenant from '../../../hooks/useTenant';
import useLease from '../../../hooks/useLease';
import useModal from '../../../hooks/useModal';
import useUnit from '../../../hooks/useUnit';
import translate from '../../../utils/translate';
import history from '../../../utils/history';
import st from './styles.module.scss';
import MobileRow from '../../../components/MobileRow';

const TenantRow = ({ tenantId }) => {
  const { types_property_unit, status_tenant } = useConfigurations();

  const { tenant } = useTenant(tenantId);

  const { lease } = useLease(tenant?.lease_id);

  const { unit } = useUnit(lease?.unit_id);

  const { property } = useProperty(lease?.property_id);

  const handleEdit = () => {
    history.push(`${TENANTS_CREATE_ROUTE}/${tenantId}`)
  };

  const moveIn = () => {
    history.push(`${MOVE_IN_ROUTE}?tenantId=${tenantId}`);
  }

  return (
    <tr>
      <td>

        <Tenant
          title={tenant.first_name && tenant.last_name ? `${tenant.first_name} ${tenant.last_name}` : tenant.email}
          src={tenant.avatar || ''}
          isCircleLogo={false}
          link={`${TENANTS_ROUTE}/${tenantId}`}
        />

      </td>
      <td>

        {tenant?.lease_id && `${property?.name} ${unit?.name}`}

      </td>
      <td>
        {tenant?.lease_id && types_property_unit.find((type) => type.id === property?.unit_type)?.name}
      </td>
      <td>
        {`${tenant?.balance} KSh`}
      </td>
      <td>
        {tenant.due_date && moment(tenant.due_date).format('DD MMMM, YYYY')}
      </td>
      <td>

        <Status
          status={tenant.status}
        >

          {status_tenant.find((status) => status.id === tenant.status)?.name}

        </Status>

      </td>
      <td>

        {tenant.status !== 'pending' && (
          <Button
            color="info"
            variant="shadow"
            size="small"
            onClick={moveIn}
          >

            {translate('Move in')}

          </Button>
        )}

      </td>
      <td>
        <UncontrolledDropdown>

          <DropdownToggle>

            <Button
              isIcon
              className={st.buttonDrop}
            >

              <CellSvg />

            </Button>

          </DropdownToggle>

          <DropdownMenu
            position="right"
          >

            <DropDownItem
              onClick={handleEdit}
            >

              {translate('Edit')}

            </DropDownItem>

          </DropdownMenu>

        </UncontrolledDropdown>
      </td>
    </tr>
  )
};

export const MobileTenantRow = ({ tenantId }) => {
  const { types_property_unit, status_tenant } = useConfigurations();

  const { tenant } = useTenant(tenantId);

  const { lease } = useLease(tenant?.lease_id);

  const { unit } = useUnit(lease?.unit_id);

  const { property } = useProperty(lease?.property_id);

  const handleEdit = () => {
    history.push(`${TENANTS_CREATE_ROUTE}/${tenantId}`)
  };

  const moveIn = () => {
    history.push(`${MOVE_IN_ROUTE}?tenantId=${tenantId}`);
  }

  return (
    <MobileRow
      src={tenant.avatar || ''}
      status={tenant.status}
      statusName={status_tenant.find((status) => status.id === tenant.status)?.name}
      title={tenant.first_name && tenant.last_name ? `${tenant.first_name} ${tenant.last_name}` : tenant.email}
      content={tenant?.lease_id && `${property?.name} ${unit?.name}`}
      link={`${TENANTS_ROUTE}/${tenantId}`}
      buttons={[
        {
          title: translate('Edit'),
          toggle: true,
          onClick: handleEdit,
        }
      ]}
      list={[
        {
          title: translate('Type'),
          value: tenant?.lease_id && types_property_unit.find((type) => type.id === property?.unit_type)?.name,
        },
        {
          title: translate('Outstanding'),
          value: `${tenant?.balance} KSh`,
        },
        {
          title: translate('Due Date'),
          value: tenant.due_date && moment(tenant.due_date).format('DD MMMM, YYYY'),
        },
      ]}
      buttonBottom={tenant.status !== 'pending' && {
        title: translate('Move in'),
        onClick: moveIn,
      }}
    />
  );
};

const Tenants = () => {
  const [modalSendInvite, openModalSendInvite, closeModalSendInvite] = useModal(false);

  const filter = useTenantsFilter();

  const { type, status, search } = filter;

  const { ids, page, count, limit } = useTenants();

  const onChangePage = useCallback((newPage) => {
    changeTenantsPage(newPage);
  }, []);

  useEffect(() => {
    changeTenantsPage(1);
  }, []);

  useEffect(() => {
    getTenants({
      params: {
        page,
        type,
        status,
        search,
      }
    });
  }, [type, status, search, page]);

  return (
    <PrivateLayout>

      <ModalSendInvite
        isOpen={modalSendInvite}
        onClose={closeModalSendInvite}
      />

      <div className={st.content}>

        <div className={st.wrapper}>

          {translate('Tenants')}

          <div className={st.controls}>

            <Button
              tag={Link}
              to={TENANTS_IMPORT_ROUTE}
              color="secondary"
              variant="shadow"
              svg={<ImportSvg />}
              className={st.control}
            >

              {translate('Import')}

            </Button>

            <Button
              onClick={openModalSendInvite}
              color="secondary"
              variant="shadow"
              svg={<SendSvg />}
              className={st.control}
            >

              {translate('Send invite')}

            </Button>

            <Button
              tag={Link}
              to={TENANTS_CREATE_ROUTE}
              color="primary"
              variant="contained"
              svg={<PlusSvg />}
              className={st.control}
            >

              {translate('Create tenant')}

            </Button>

            <Button
              tag={Link}
              to={TENANTS_IMPORT_ROUTE}
              color="secondary"
              variant="shadow"
              svg={<ImportSvg />}
              className={st.controlMobile}
            />

            <Button
              onClick={openModalSendInvite}
              color="secondary"
              variant="shadow"
              svg={<SendSvg />}
              className={st.controlMobile}
            />

            <Button
              tag={Link}
              to={TENANTS_CREATE_ROUTE}
              color="primary"
              variant="contained"
              svg={<PlusSvg />}
              className={st.controlMobile}
            />

          </div>

        </div>

        <TenantsFilter
          filter={filter}
        />

        <TableSimple
          className={st.table}
          settings
        >

          <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Property')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Outstanding')}</th>
            <th>{translate('Due Date')}</th>
            <th>{translate('Status')}</th>
            <th/>
            <th>
              <div className={st.buttonDrop}>
                <SettingsSvg />
              </div>
            </th>
          </tr>
          </thead>

          <tbody>

          {ids.map((id) => (
            <TenantRow
              key={id}
              tenantId={id}
            />
          ))}

          </tbody>

        </TableSimple>

        <div className={st.list}>

          {ids.map((id) => (
            <MobileTenantRow
              key={id}
              tenantId={id}
            />
          ))}

        </div>

        <Pagination
          current={page}
          total={count}
          limit={limit}
          onChangePage={onChangePage}
        />

      </div>

    </PrivateLayout>
  );
};

export default Tenants;
