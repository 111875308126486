import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const Modal = ({ isOpen, onClose, children }) => {
	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="modal"
			overlayClassName="overlay"
			ariaHideApp={false}
		>

			{children}

		</ReactModal>
	);
};

Modal.defaultProps = {
	isOpen: false,
	children: '',
}

Modal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node,
}

export default Modal;