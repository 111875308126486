import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Avatar from '../../../../../components/Avatar';
import styles from './styles.module.scss';

const Tenant = ({ id, avatar, title, time, content, count }) => {
	return (
		<div className={styles.tenant}>

			<Avatar
				className={styles.avatar}
				src={avatar}
				size="40"
			/>

			<div className={styles.wrap}>

				<div className={styles.header}>

					<h3>

						{title}

					</h3>

					<time>

						{moment(time * 1000).fromNow()}

					</time>

				</div>

				<div className={styles.content}>

					<p>{content}</p>

					<div
						className={cn(
							{[styles.hidden]: count === 0}
						)}
					>

						{count}

					</div>

				</div>

			</div>

		</div>
	);
};

Tenant.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	avatar: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	time: PropTypes.number.isRequired,
	content: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired
}

export default Tenant;