import { AUTH } from '../../constants/type';

export const defaultState = {
	isAuthorized: false,
	profile: null,
	paymentMethods: null,
	banks: null,
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case AUTH.SIGN_IN.base:
		case AUTH.SIGN_IN.SUCCESS:
			return {
				...state,
				isAuthorized: true,
			};

		case AUTH.CONFIRM_EMAIL.SUCCESS: {
			return {
				...state,
			};
		}

		case AUTH.UPDATE_PROFILE.SUCCESS:
		case AUTH.GET_PROFILE.SUCCESS: {
			return {
				...state,
				isAuthorized: true,
				profile: payload,
			};
		}

		case AUTH.GET_PAYMENT_METHODS.REQUEST: {
			return {
				...state,
				paymentMethods: [],
			};
		}

		case AUTH.DELETE_PAYMENT_METHOD.SUCCESS: {
			return {
				...state,
			};
		}

		case AUTH.GET_PAYMENT_METHODS.SUCCESS: {
			return {
				...state,
				paymentMethods: payload,
			};
		}

		case AUTH.GET_BANKS.REQUEST: {
			return {
				...state,
				banks: [],
			};
		}

		case AUTH.GET_BANKS.SUCCESS: {
			return {
				...state,
				banks: payload,
			};
		}

		case AUTH.CREATE_PAYMENT_METHOD.SUCCESS: {
			return {
				...state,
				paymentMethods: [
					...state.paymentMethods,
					payload,
				],
			};
		}

		case AUTH.UPDATE_PAYMENT_METHOD.SUCCESS: {
			return {
				...state,
				paymentMethods: state.paymentMethods.map(pm => {
					return pm.id === payload.id ? payload : pm;
				}),
			};
		}

		case AUTH.SIGN_OUT.SUCCESS:
		case AUTH.GET_PROFILE.FAILURE: {
			return defaultState;
		}

		default:
			return state;
	}
};
