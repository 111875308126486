import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar';
import st from './styles.module.scss';

export default function Tenant({
  src, title, content, price, date, className, isCircleLogo, link, svg,
}) {
  const tenantContent = useMemo(() => (
    <div className={cx(st.wrapper, className)}>

      <div className={st.left}>

        <Avatar
          src={src}
          variant={isCircleLogo ? 'circle' : ''}
          size="40"
        />

        <div className={st.info}>

          <div className={st.username}>{title}</div>

          {content && (
            <div className={st.card}>

              {svg && <span className={st.svg}>{svg}</span>}

              {content}

            </div>
          )}

        </div>

      </div>

      <div className={st.right}>

        <div className={st.price}>{price}</div>

        <div className={st.date}>{date}</div>

      </div>

    </div>
  ), [src, title, content, price, date, className, isCircleLogo, svg]);

  return link ? <Link className={st.item} to={link}>{tenantContent}</Link> : tenantContent;
}

Tenant.defaultProps = {
  src: null,
  title: '',
  price: '',
  date: '',
  content: '',
  className: '',
  isCircleLogo: true,
  link: null,
  svg: null,
};

Tenant.propTypes = {
  link: PropTypes.string,
  isCircleLogo: PropTypes.bool,
  src: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  price: PropTypes.string,
  date: PropTypes.string,
  svg: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.node,
	]),
};
