import React, { useCallback } from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD_ROUTE } from '../../../constants/routes';
import Label from '../../../components/Label';
import useAuth from '../../../hooks/useAuth';
import { InputNotice } from '../../../components/Inputs/InputNotice';
import InputCheckbox from '../../../components/Inputs/InputCheckbox';
import { InputText } from '../../../components/Inputs/InputText';
import AuthLayout from '../../../layouts/AuthLayout';
import translate from '../../../utils/translate';
import Button from '../../../components/Button';
import Line from '../../../components/Line';
import styles from '../styles.module.scss';
import styles2 from './styles.module.scss';

const SignInPage = () => {
	const {
		onSignIn,
	} = useAuth();

	const initValues = {
		email: '',
		password: '',
		remember: false,
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.email()
			.required(),
		password: Yup.string()
			.min(8)
			.max(24)
			.required(),
		remember: Yup.bool(),
	});

	const handleSubmit = useCallback((values, action) => {
		onSignIn(values, {...action});
	}, [onSignIn]);

	return (
		<AuthLayout>

			<h2
				className={cn(
					'h2',
					styles.title
				)}
			>

				{translate('Sign in')}

			</h2>

			<p className={styles.description}>

				{translate('Don\'t have an account?')}

				&nbsp;

				{/*<Link to={SIGN_UP_ROUTE}>

					{translate('Sign up')}

				</Link>*/}

			</p>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
			>

				{({ isSubmitting }) => (
					<Form>

						<Label htmlFor="email">

							{translate('Email')}

						</Label>

						<InputText
							id="email"
							name="email"
							type="email"
							autoComplete="username email"
						/>

						<Label htmlFor="password">

							{translate('Password')}

						</Label>

						<InputText
							id="password"
							name="password"
							type="password"
							autoComplete="current-password"
						/>

						<div className={styles2.remember}>

							<InputCheckbox
								id="remember"
								name="remember"
								title={translate('Remember me')}
								value={true}
							/>

							<Link
								to={FORGOT_PASSWORD_ROUTE}
							>

								{translate('Forgot password?')}

							</Link>

						</div>

						<InputNotice name="remember" />

						<div className={styles2.buttons}>

							<Button
								className={styles2.button}
								color="primary"
								variant="contained"
								type="submit"
								size="big"
								loading={isSubmitting}
							>

								{translate('Sign in')}

							</Button>

							<Button
								className={styles2.button}
								tag={Link}
								color="primary"
								size="big"
								to={FORGOT_PASSWORD_ROUTE}
							>

								{translate('Forgot password?')}

							</Button>

						</div>

					</Form>
				)}

			</Formik>

			<Line>

			  {translate('Or')}

			</Line>

			<Button
				variant="outlined"
				size="big"
				className={styles2.google}
			>

				{translate('Google')}

			</Button>

		</AuthLayout>
	);
};

export default SignInPage;
