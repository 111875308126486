import React, { useCallback } from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { InputText } from '../../../../components/Inputs/InputText';
import InputCheckbox from '../../../../components/Inputs/InputCheckbox';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import Line from '../../../../components/Line';
import { SIGN_IN_ROUTE, TERMS_ROUTE } from '../../../../constants/routes';
import useAuth from '../../../../hooks/useAuth';
import translate from '../../../../utils/translate';
import styles from '../../styles.module.scss';
import styles2 from '../../SignInPage/styles.module.scss';

const SignUpForm = ({ setEmail }) => {
	const {
		onSignUp,
	} = useAuth();

	const initValues = {
		email: '',
		password: '',
		password_confirm: '',
		first_name: '',
		last_name: '',
		company: '',
		as_company: false,
		terms: false,
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.email()
			.required(),
		password: Yup.string()
			.min(8)
			.max(24)
			.required(),
		password_confirm: Yup.string()
			.oneOf([Yup.ref('password')], translate('Both passwords are not matching'))
			.required(),
		first_name: Yup.string()
			.required(),
		last_name: Yup.string()
			.required(),
		company: Yup.string()
			.required(),
		terms: Yup.bool()
			.oneOf([true])
			.required(),
		as_company: Yup.bool()
	});

	const handleSubmit = useCallback((values, action) => {
		onSignUp(values, {...action, setEmail});
	}, [onSignUp, setEmail]);

	return (
		<>

			<h2
				className={cn(
					'h2',
					styles.title
				)}
			>

				{translate('Sign up')}

			</h2>

			<p className={styles.description}>

				{translate('Have an account?')}

				&nbsp;

				<Link to={SIGN_IN_ROUTE}>

					{translate('Sign in')}

				</Link>

			</p>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
			>

				{({ isSubmitting }) => (
					<Form>

						<Label>

							{translate('Email')}

						</Label>

						<InputText
							id="email"
							name="email"
							type="email"
							autoComplete="username email"
						/>

						<Label>

							{translate('Password')}

						</Label>

						<InputText
							id="password"
							name="password"
							type="password"
							autoComplete="new-password"
						/>

						<Label>

							{translate('Confirm password')}

						</Label>

						<InputText
							id="password_confirm"
							name="password_confirm"
							type="password"
							autoComplete="new-password"
						/>

						<Label>

							{translate('First name')}

						</Label>

						<InputText
							id="first_name"
							name="first_name"
							autoComplete="username"
						/>

						<Label>

							{translate('Last name')}

						</Label>

						<InputText
							id="last_name"
							name="last_name"
						/>

						<Label>

							{translate('Company')}

						</Label>

						<InputText
							id="company"
							name="company"
							autoComplete="company"
						/>

						<InputCheckbox
							id="as_company"
							name="as_company"
							variant="switch"
							title={translate('Display as a company')}
							value={true}
						/>

						<InputCheckbox
							id="terms"
							name="terms"
							noLabelClick
							title={(
								<div className={styles2.agree}>

									{translate('Creating an account means you’re okay with our')}

									<br />

									<Link
										to={TERMS_ROUTE}
									>

										{translate('Terms of Service,')}

									</Link>

									&nbsp;

									<Link
										to={TERMS_ROUTE}
									>

										{translate('Privacy Policy')}

									</Link>

								</div>
							)}
							value={true}
						/>

						<Button
							color="primary"
							variant="contained"
							type="submit"
							loading={isSubmitting}
							size="big"
							style={{
								width: '100%',
								marginBottom: '1.6rem',
							}}
						>

							{translate('Sign up')}

						</Button>

					</Form>
				)}

			</Formik>

			<Line>

				{translate('Or')}

			</Line>

			<Button
				variant="outlined"
				size="big"
				className={styles2.google}
			>

				{translate('Google')}

			</Button>

		</>
	);
};

export default SignUpForm;