import { toast } from 'react-toastify';

const defaultErrorHandler = (e, actions = {}) => {
	if (e.non_field_errors) {
		toast.error(e.non_field_errors[0]);
	} else if (e.detail) {
		toast.error(e.detail);
	} else {
		Object.keys(e).forEach((key) => {
			const message = e[key][0];

			if (actions?.setFieldError) {
				actions.setFieldError(key, message);
			}
		});
	}

	if (actions?.setSubmitting) {
		actions.setSubmitting(false);
	}
};

export default defaultErrorHandler;
