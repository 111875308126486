import React, { forwardRef, useCallback, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import ReactSelect from 'react-select';
import { InputNotice } from '../InputNotice';
import styles from './styles.module.scss';

const option = PropTypes.shape({
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]).isRequired,
});

const InputSelect = forwardRef(({
 className, id, name, options, selectedOption, onChange, isMulti, disabled, allowUserInput, ...rest
}, ref) => {
	const [field, meta, helpers] = useField(name);

	const { error, touched } = meta;

	const handleChange = useCallback((currentOption) => {
		let value = null;

		if (isMulti) {
			value = (currentOption || []).map((option) => option.value);
		} else if (currentOption) {
			value = currentOption ? currentOption.value : null;
		}

		helpers.setValue(value);

		if (onChange) {
			onChange(value, name);
		}
	}, [isMulti, allowUserInput, helpers, onChange]);

	const handleInputChange = (val) => {
		if(allowUserInput) {
			if (val) {
				handleChange({
					value: val
				});
			}
		}
	}

	const handleBlur = () => {
		helpers.setTouched(true);
	};

	let value;

	if (field.value === undefined) {
		value = selectedOption;
	} else if (isMulti) {
		value = field.value.map((optionValue) => options.find((currentOption) => currentOption.value === optionValue));
	} else {
		value = options.find((currentOption) => currentOption.value === field.value) || null;
	}

	return (
		<>

			<ReactSelect
				id={id}
				ref={ref}
				className={cn(
					styles.select,
					{ [styles.error]: error && touched },
					className,
				)}
				classNamePrefix="react-select"
				name={name}
				options={options}
				isMulti={isMulti}
				{...rest}
				onChange={handleChange}
				onInputChange={handleInputChange}
				onBlur={handleBlur}
				value={value}
				isDisabled={disabled}
			/>

			{ error && touched && (
				<InputNotice
					name={name}
				/>
			)}

		</>
	);
});

InputSelect.defaultProps = {
	className: '',
	id: '',
	selectedOption: null,
	onChange: null,
	isMulti: false,
	disabled: false,
};

InputSelect.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(option).isRequired,
	selectedOption: option,
	onChange: PropTypes.func,
	isMulti: PropTypes.bool,
	disabled: PropTypes.bool,
	allowUserInput: PropTypes.bool,
};

const InputSelectUC = forwardRef(({
	className, id, name, selectedOption, onChange, options, value, error, ...rest
}, ref) => {
	const valueOption = value !== undefined
		? options.find((currentOption) => currentOption.value === value)
		: selectedOption || null;

	return (
		<ReactSelect
			ref={ref}
			className={cn(
				styles.select,
				className,
				{ [styles.error]: error },
			)}
			classNamePrefix="react-select"
			name={name}
			value={valueOption || selectedOption}
			onChange={onChange}
			options={options}
			{...rest}
			inputId={id}
		/>
	);
});

InputSelectUC.defaultProps = {
	className: '',
	id: '',
	name: '',
	selectedOption: null,
	value: null,
	error: false,
};

InputSelectUC.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.arrayOf(option).isRequired,
	selectedOption: option,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	error: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export {
	InputSelect,
	InputSelectUC
}
