import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import translate from '../../../../utils/translate';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import Label from '../../../../components/Label';
import { InputText } from '../../../../components/Inputs/InputText';
import InputCheckbox from '../../../../components/Inputs/InputCheckbox';
import styles2 from '../../../Auth/SignInPage/styles.module.scss';
import { Link } from 'react-router-dom';
import { HOME_ROUTE, PRIVACY_ROUTE, TERMS_ROUTE } from '../../../../constants/routes';
import Button from '../../../../components/Button';
import InputPhone from '../../../../components/Inputs/InputPhone';
import InputSwitch  from '../../../../components/Inputs/InputSwitch';
import styles from '../styles.module.scss';
import ModalRequestDemoSubmitted from '../RequestDemoModal';
import history from '../../../../utils/history';
import { sendDemoRequest } from '../../../../services/actions/public';
import { InputLocation } from '../../../../components/Inputs/InputLocation';
import InputRole from './InputRole';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const REDIRECT_TIMEOUT = 10000;

const DEFAULT_COUNTRY = 'ke';
const RECAPTCHA_ACTION = 'demo_request';

const RequestDemoForm = () => {

  const [submitted, setSubmitted] = useState(false);
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const initValues = {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    location: '',
    company: '',
    property_type: '',
    role: '',
    number_of_units: '',
    other_role: '',
    terms: true,
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(translate('Email format is invalid'))
      .min(1, translate('Value must be greater than or equal to 1'))
      .max(254)
      .required(translate('Email is required')),
    first_name: Yup.string()
      .min(1, translate('Value must be greater than or equal to 1'))
      .max(150)
      .required(translate('First name is required')),
    last_name: Yup.string()
      .min(1, translate('Value must be greater than or equal to 1'))
      .max(150)
      .required(translate('Last name is required')),
    phone: Yup.string()
      .min(10, translate('Phone number must include at least 10 symbols'))
      .max(36)
      .required(translate('Phone is required')),
    location: Yup.string()
      .max(255),
    company: Yup.string()
      .max(255),
    property_type: Yup.string()
      .oneOf(['R', 'C']),
    role: Yup.string()
      .oneOf(['L', 'P', 'O'])
      .required(translate('Role is required')),
    other_role: Yup.string()
      .when('role', {
        is: 'O',
        then: Yup.string().required('This field is required'),
      }),
    number_of_units: Yup.number()
      .min(1, translate('Value must greater than or equal to 1'))
      .max(32767)
      .required(translate('Number of units is required')),
    terms: Yup.bool()
      .oneOf([true])
      .required(translate('This field is required')),
  });

  const propertyTypeOptions = useMemo(() => {
    return [
      {
        label: translate('Residential'),
        value: 'R',
      },
      {
        label: translate('Commercial'),
        value: 'C',
      },
    ]
  }, []);

  useEffect(() => {
    if(submitted) {
      const timer = setTimeout(() => {
        history.push(HOME_ROUTE);
      }, REDIRECT_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [submitted]);

  const handleSubmit = useCallback(async (values, actions) => {
    const onSuccess = () => {
      setIsSubmittingRequest(false);
      actions.resetForm();

      toast.success(translate('Your request has been submitted'));

      setSubmitted(true);
    };

    const onFailure = () => {
      setIsSubmittingRequest(false);
    }

    const token = await executeRecaptcha(RECAPTCHA_ACTION);

    if(token && token.length > 0) {
      setIsSubmittingRequest(true);
      sendDemoRequest({
        ...values,
        recaptcha: token,
      }, { ...actions, onSuccess, onFailure });
    }

  }, [executeRecaptcha]);

  return (
    <>
        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>

              <Label htmlFor="first_name">

                {translate('First name')}*

              </Label>

              <InputText
                id="first_name"
                name="first_name"
              />

              <Label htmlFor="last_name">

                {translate('Last name')}*

              </Label>

              <InputText
                id="last_name"
                name="last_name"
              />

              <Label htmlFor="company">

                {translate('Company')}

              </Label>

              <InputText
                id="company"
                name="company"
              />

              <Label htmlFor="phone">

                {translate('Telephone Number')}*

              </Label>

              <InputPhone
                id="phone"
                name="phone"
                country={DEFAULT_COUNTRY}
              />

              <Label htmlFor="email">

                {translate('Email address')}*

              </Label>

              <InputText
                id="email"
                name="email"
                type="email"
              />

              <Label htmlFor="location">

                {translate('Location')}

              </Label>

              <InputLocation
                id="location"
                name="location"
              />

              <Label htmlFor="property_type">

                {translate('Property Type')}

              </Label>

              <InputSwitch
                id="property_type"
                name="property_type"
                tabs={propertyTypeOptions}
                className={styles.switch}
              />

              <Label htmlFor="number_of_units">

                {translate('Number of units')}*

              </Label>

              <InputText
                id="number_of_units"
                name={"number_of_units"}
                type={"number"}
              />

              <Label htmlFor="role">

                {translate('Role')}*

              </Label>

              <InputRole
                nameForRole="role"
                nameForInput="other_role"
              />

              <div className={styles.agree}>

                {translate("Clicking the Submit Request button means you’re okay with our ")}

                <Link
                  to={TERMS_ROUTE}
                >

                  {translate('Terms of Service')}

                </Link>

                {translate(" and ")}

                <Link
                  to={PRIVACY_ROUTE}
                >

                  {translate('Privacy Policy')}.

                </Link>

              </div>

              {/*<InputCheckbox*/}
              {/*  id="terms"*/}
              {/*  name="terms"*/}
              {/*  noLabelClick*/}
              {/*  title={(*/}
              {/*    <div className={styles2.agree}>*/}

              {/*      {translate("Submit Request means you're okay with our")}*/}

              {/*      <br />*/}

              {/*      <Link*/}
              {/*        to={TERMS_ROUTE}*/}
              {/*      >*/}

              {/*        {translate('Terms of Service,')}*/}

              {/*      </Link>*/}

              {/*      &nbsp;*/}

              {/*      <Link*/}
              {/*        to={TERMS_ROUTE}*/}
              {/*      >*/}

              {/*        {translate('Privacy Policy')}*/}

              {/*      </Link>*/}

              {/*    </div>*/}
              {/*  )}*/}
              {/*  value={true}*/}
              {/*/>*/}

              <Button
                color="primary"
                variant="contained"
                type="submit"
                loading={isSubmitting || isSubmittingRequest}
                size="big"
                style={{
                  width: '100%',
                  marginBottom: '1.6rem',
                }}
              >

                {translate('Submit Request')}

              </Button>

            </Form>
          )}
        </Formik>

      <ModalRequestDemoSubmitted isOpen={submitted} onClose={() => {}} />
    </>

  );
}

export default RequestDemoForm;
