import PropTypes from 'prop-types';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import styles from '../styles.module.scss';
import React, { useState, useCallback, useMemo } from 'react';
import NavigationSubItem from '../NavigationSubItem';
import cn from 'classnames';

const isMatch = (tabLocation, currentPathname) => {
  return tabLocation && !!matchPath(
    currentPathname,
    tabLocation
  );
}

const NavigationItem = ({ tab }) => {

  const [ open, setOpen ] = useState(false);
  const { pathname } = useLocation();

  const onMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setOpen(false);
  }, []);

  const toggleOpen = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen((prev) => !prev);
  }, []);

  const hasSubTabs = useMemo(() => !!tab.subTabs, [tab]);

  const isActive = useMemo(() => {
    if(!tab.subTabs) {
      return false;
    }

    return tab.subTabs?.some((subTab) => {

      if(subTab.location) {
        if(isMatch(subTab.location, pathname)) {
          return true;
        }
      }

      return subTab.subTabs?.some((subSubTab) => {
        return isMatch(subSubTab.location, pathname);
      });
    });
  }, [tab, pathname]);

  return (

    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >

      {

        tab.location ? (

          <NavLink
            key={tab.location}
            to={tab.location}
            exact={tab.exact}
            className={cn(styles.tab, hasSubTabs && open && styles.hovered, hasSubTabs && isActive && styles.active)}
            activeClassName={styles.active}
          >

            {tab.title}

          </NavLink>

        ) : (

          <div
            onTouchEnd={toggleOpen}
            className={cn(styles.tab, hasSubTabs && open && styles.hovered, hasSubTabs && isActive && styles.active)}
          >

            {tab.title}

          </div>

        )

      }

      {hasSubTabs && open && (

        <div className={styles.subTabsBox}>

          {

            tab.subTabs.map((item) => (
              <NavigationSubItem tab={item} key={item.location || ''} />
            ))

          }

        </div>

      )}

    </div>

  );
}

NavigationItem.defaultProps = {
  className: '',
};

NavigationItem.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.shape({
    location: PropTypes.string,
    title: PropTypes.string,
    subTabs: PropTypes.array,
  }).isRequired,
};

export default NavigationItem;
