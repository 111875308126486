import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Video from '../../../../components/Video';
import styles from './styles.module.scss';

const Hero = ({ title, videoUrl }) => {

	return (
		<section
			className={styles.hero}
		>

			<h2
				className={cn(
					styles.title,
				)}
			>

				{title}

			</h2>


			<Video
				src={videoUrl}
			/>

		</section>
	);
};

Hero.defaultProps = {
	title: '',
	videoUrl: '',
};

Hero.propTypes = {
	title: PropTypes.string,
	videoUrl: PropTypes.string,
};

export default Hero;