import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const TableSimple = ({ className, settings, children }) => {
	return (
		<table
			className={cn(
				styles.table,
				{
					[styles.settings]: settings,
				},
				className,
			)}
		>

			{children}

		</table>
	);
};

TableSimple.defaultProps = {
	className: '',
	settings: false,
	children: '',
};

TableSimple.propTypes = {
	className: PropTypes.string,
	settings: PropTypes.bool,
	children: PropTypes.node,
};

export default TableSimple;