import React from 'react';
import PropTypes from 'prop-types';
import LeaseComponent from '../../../../components/Lease';
import CardBody from '../../../../components/Card/CardBody';
import Tabs from '../../../../components/Tabs';
import Card from '../../../../components/Card';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function Lease({ leaseIds }) {
  return (
    <Card className={styles.card}>

      <CardBody className={styles.cardBody}>

        <Tabs>

          {leaseIds.map((leaseId) => (
            <Tabs.Pane
              key={leaseId}
              name={leaseId}
              className={styles.pane}
              label={`${translate('Lease')} #${leaseId}`}
            >

              <LeaseComponent
                leaseId={leaseId}
              />

            </Tabs.Pane>
          ))}

        </Tabs>

      </CardBody>

    </Card>
  );
}

Lease.propTypes = {
  leaseIds: PropTypes.array
}
