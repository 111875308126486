import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import {
	CONFIRM_EMAIL_ROUTE,
	CONTACTS_ROUTE,
	FEATURES_ROUTE,
	FORGOT_PASSWORD_ROUTE,
	HOME_ROUTE,
	INVITE_REGISTER_ROUTE,
	PAYMENT_ROUTE,
	PRICING_ROUTE,
	PRIVACY_ROUTE,
	REQUEST_DEMO_ROUTE,
	RESET_PASSWORD_ROUTE,
	SIGN_IN_ROUTE,
	SIGN_UP_ROUTE,
	SOLUTION_ROUTE,
	TERMS_ROUTE,
} from '../../constants/routes';
import ResetPasswordPage from '../../modules/Auth/ResetPasswordPage';
import ConfirmEmailPage from '../../modules/Auth/ConfirmEmailPage';
import InviteRegister from '../../modules/Auth/InviteRegister';
import FeaturesPage from '../../modules/Public/FeaturesPage';
import SolutionPage from '../../modules/Public/SolutionPage';
import ContactsPage from '../../modules/Public/ContactsPage';
import PricingPage from '../../modules/Public/PricingPage';
import SignInPage from '../../modules/Auth/SignInPage';
import SignUpPage from '../../modules/Auth/SignUpPage';
import ForgotPage from '../../modules/Auth/ForgotPage';
import HomePage from '../../modules/Public/HomePage';
import Privacy from '../../modules/Public/Privacy';
import Terms from '../../modules/Public/Terms';
import RequestDemoPage from '../../modules/Public/RequestDemoPage';
import { getTexts } from '../../services/actions/public';
import usePublic from '../../hooks/usePublic';
import Loader from '../../components/Loader';
import PaymentPage from '../../modules/Auth/PaymentPage';

const PublicRouter = () => {
	useEffect(() => {
		getTexts();
	}, []);

	const keys = usePublic();

	if(Object.keys(keys).length === 0 ) {
		return (
			<Loader />
		);
	}

	return (
		<Switch>

			<Route exact path={HOME_ROUTE}><HomePage /></Route>

			<Route path={FEATURES_ROUTE}><FeaturesPage /></Route>

			<Route path={SOLUTION_ROUTE}><SolutionPage /></Route>

			<Route path={PRICING_ROUTE}><PricingPage /></Route>

			<Route path={CONTACTS_ROUTE}><ContactsPage /></Route>

			<Route path={TERMS_ROUTE}><Terms /></Route>

			<Route path={PRIVACY_ROUTE}><Privacy /></Route>

			<Route path={REQUEST_DEMO_ROUTE}><RequestDemoPage /></Route>

			{/* AUTH */}
			<Route path={SIGN_IN_ROUTE}><SignInPage /></Route>

			<Route path={SIGN_UP_ROUTE}><SignUpPage /></Route>

			<Route path={FORGOT_PASSWORD_ROUTE}><ForgotPage /></Route>

			<Route path={RESET_PASSWORD_ROUTE}><ResetPasswordPage /></Route>

			<Route path={CONFIRM_EMAIL_ROUTE}><ConfirmEmailPage /></Route>

			<Route path={INVITE_REGISTER_ROUTE}><InviteRegister /></Route>

			<Route exact path={PAYMENT_ROUTE}><PaymentPage /></Route>

			{/* NOT FOUND */}

			<Route path="*"><Redirect to={SIGN_IN_ROUTE} /></Route>

		</Switch>
	);
};

export default PublicRouter;
