import { createAction } from 'redux-actions';
import schemas from '../schemas';
import requestWrapper from '../../utils/requestWrapper';
import { UNITS } from '../../constants/type';
import { API_PROPERTIES_URL, API_UNITS_URL, API_PROPERTIES_UNITS_STMT_REPORT } from '../../constants/api';
import defaultErrorHandler from '../../utils/errorHandlers';
import store from '../../utils/store';
import { toast } from 'react-toastify';
import translate from '../../utils/translate';

export const getUnits = async ({
	params = {},
	actions = {},
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: UNITS.GET_LIST,
		url: API_PROPERTIES_URL + API_UNITS_URL,
		method: 'get',
		normalizeSchema: schemas.UNITS_ARRAY,
		addHeadersToResponse: true,
		options: {
			params,
		},
		onSuccess,
	});
};

export const getUnit = async (id, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: UNITS.GET_UNIT,
		url: `${API_PROPERTIES_URL}unit/${id}/`,
		method: 'get',
		normalizeSchema: schemas.UNITS,
		onSuccess,
		onFailure,
	});
};

export const clearSelectUnit = () => {
	store.dispatch(createAction(UNITS.CLEAR_SELECT.base)());
};

export const getSelectUnits = async ({
	params = {},
	actions = {},
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: UNITS.GET_UNITS_OPTIONS,
		url: `${API_UNITS_URL}listing/all/`,
		method: 'get',
		options: {
			params,
		},
		onSuccess,
	});
};

export const changeUnitsPage = (page) => {
	store.dispatch(createAction(UNITS.CHANGE_PAGE.base)({
		page,
	}));
};

export const changeUnitsFilter = (value, type) => {
	store.dispatch(createAction(UNITS.CHANGE_FILTER.base)({
		value,
		type
	}));
};

export const getStatementReport = async ({
		 params = {},
		 actions = {},
	 }) => {

	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		if(e) {
			defaultErrorHandler(e, actions);
		}
	};

	await requestWrapper({
		url: `${API_PROPERTIES_UNITS_STMT_REPORT}`,
		action: UNITS.GET_STATEMENT_REPORT,
		method: 'get',
		options: {
			params,
		},
		onSuccess,
		onFailure,
	});
}
