import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../../assets/icons/angle-left.svg';
import { TENANTS_ROUTE, TENANTS_UPLOAD_ROUTE } from '../../../constants/routes';
import PrivateLayout from '../../../layouts/PrivateLayout';
import InputFile from '../../../components/Inputs/InputFile';
import Button from '../../../components/Button';
import translate from '../../../utils/translate';
import st from './styles.module.scss';

export default function TenantsImport() {
  const initValues = {
    file: '',
  };

  const handleSubmit = useCallback((values, actions) => {
    console.log({...values}, {...actions});
  }, []);

  return (
    <PrivateLayout>

      <div className={st.wrapper}>

        <div className={st.heading}>

          <Button
            tag={Link}
            variant="goBack"
            to={TENANTS_ROUTE}
            isIcon
            svg={<LeftIcon />}
          />

          <div className={st.title}>

            {translate('Bulk tenants upload')}

          </div>

        </div>

        <div className={st.subHeading}>

          {translate('Please note! You can imports up to 1000 rows per one import.')}

        </div>

        <Formik
          initialValues={initValues}
          onSubmit={handleSubmit}
        >

          <Form className={st.wrapper}>

            <InputFile
              id="file"
              name="file"
              heading={translate('Upload File')}
              description={''}
            />

            <div className={st.footer}>

              <Button
                color="secondary"
                variant="shadow"
              >

                {translate('Download example')}

              </Button>

              <Button
                tag={Link}
                to={TENANTS_UPLOAD_ROUTE}
                color="primary"
                variant="contained"
                type="submit"
              >

                {translate('Upload')}

              </Button>

            </div>

          </Form>

        </Formik>

      </div>

    </PrivateLayout>
  );
}
