import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { clearSelectLease, getSelectLeases } from '../services/actions/leases';
import { leasesSelectSelector } from '../services/selectors/leases';
import { entitiesInvoicesSelector } from '../services/selectors/entities';
import { getInvoice } from '../services/actions/invoices';

const selector = createSelector(
	leasesSelectSelector,
	entitiesInvoicesSelector,
	(select, invoices) => {
		const leaseOptions = select.map((option) => ({
			label: option.full_name,
			value: option.id,
		}));

		const invoicesByLease = select.reduce((acc, item) => {
			acc[item.id] = item.invoices.map((invoiceId) => ({
				id: invoiceId,
				name: invoices[invoiceId]?.reference,
				amount: invoices[invoiceId]?.amount,
				isLoaded: Object.keys(invoices[invoiceId] || {}).length > 1,
				isLoading: Object.keys(invoices[invoiceId] || {}).length === 1,
			}));

			return acc;
		} , {});

		return {
			leaseOptions,
			invoicesByLease,
		};
	},
);

const useSelectLeases = (params = {}) => {
	const { leaseOptions, invoicesByLease } = useSelector(selector);

	useEffect(() => {
		clearSelectLease();

		getSelectLeases({
			params,
		});
	}, []);

	useEffect(() => {
		leaseOptions.forEach(({ value }) => {
			invoicesByLease[value].forEach((invoice) => {
				if(!invoice.isLoaded && !invoice.isLoading) {
					getInvoice(invoice.id);
				}
			});
		});
	}, [leaseOptions, invoicesByLease]);

	return {
		leaseOptions,
		invoicesByLease,
	};
};

export default useSelectLeases;