import React, { useMemo } from 'react';
import PublicLayout from '../../../layouts/PublicLayout';
import FormStart from '../HomePage/FormStart';
import Hero from './Hero';
import Questions from './Questions';
import Diagram from './Diagram';
import usePublic from '../../../hooks/usePublic';

const PricingPage = () => {
	const {
		PRICING_HERO_TITLE, PRICING_HERO_DESCRIPTION, PRICING_DIAGRAM_TITLE, PRICING_QUESTIONS_TITLE,
		PRICING_FORMSTART_TITLE, ...rest
	} = usePublic();

	const diagrams = useMemo(() => {
		const array = [];

		Array(6).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`PRICING_DIAGRAM_${id}_TITLE`]
				|| rest[`PRICING_DIAGRAM_${id}_DESCRIPTION`]
			) {
				array.push({
					id,
					title: rest[`PRICING_DIAGRAM_${id}_TITLE`],
					description: rest[`PRICING_DIAGRAM_${id}_DESCRIPTION`],
				});
			}
		});

		return array;
	}, [rest]);

	const questions = useMemo(() => {
		const array = [];

		Array(8).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`PRICING_QUESTIONS_${id}_TITLE`]
				|| rest[`PRICING_QUESTIONS_${id}_DESCRIPTION`]
			) {
				array.push({
					id,
					title: rest[`PRICING_QUESTIONS_${id}_TITLE`],
					description: rest[`PRICING_QUESTIONS_${id}_DESCRIPTION`],
				});
			}
		});

		return array;
	}, [rest]);

	return (
		<PublicLayout
			withFooter
		>

			<Hero
				title={PRICING_HERO_TITLE}
				description={PRICING_HERO_DESCRIPTION}
			/>

			<Diagram
				title={PRICING_DIAGRAM_TITLE}
				diagrams={diagrams}
			/>

			<Questions
				title={PRICING_QUESTIONS_TITLE}
				questions={questions}
			/>

			<FormStart
				title={PRICING_FORMSTART_TITLE}
			/>

		</PublicLayout>
	);
};

export default PricingPage;