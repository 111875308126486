import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../../assets/icons/angle-left.svg';
import PrivateLayout from '../../../layouts/PrivateLayout';
import GeneralInfo from './GeneralInfo';
import PersonalInfo from './PersonalInfo';
import Verification from './Verification';
import Button from '../../../components/Button';
import { TENANTS_ROUTE } from '../../../constants/routes';
import { createTenant, updateTenant } from '../../../services/actions/tenants';
import useTenant from '../../../hooks/useTenant';
import translate from '../../../utils/translate';
import styles from './styles.module.scss';

export default function TenantCreate() {
  const params = useParams();

  const { tenant } = useTenant(Number(params.id))

  const history = useHistory();

  const initValues = {
    email: '',
    address: '',
    city: '',
    avatar: '',
    first_name: '',
    last_name: '',
    phone: '',
    birthday: new Date(),
    identity_type: 'passport',
    identity_number: '',
    documents: [],
    identity_verify: false,
  };

  if (params.id && tenant) {
    /*if(tenant.email) {
      initValues.email = tenant.email;
    }*/

    if (tenant.address) {
      initValues.address = tenant.address;
    }

    if (tenant.city) {
      initValues.city = tenant.city;
    }

    if (tenant.avatar) {
      initValues.avatar = tenant.avatar;
    }

    if (tenant.first_name) {
      initValues.first_name = tenant.first_name;
    }

    if (tenant.last_name) {
      initValues.last_name = tenant.last_name;
    }

    if (tenant.phone) {
      initValues.phone = tenant.phone;
    }

    if (tenant.birthday) {
      initValues.birthday = new Date(tenant.birthday);
    }

    if (tenant.identity_type) {
      initValues.identity_type = tenant.identity_type;
    }

    if (tenant.identity_number) {
      initValues.identity_number = tenant.identity_number;
    }

    if (tenant.identity_files) {
      initValues.documents = tenant.identity_files.map((document) => ({
        id: document.id,
        image: document.file,
      }));
    }

    if (tenant.identity_verify) {
      initValues.identity_verify = tenant.identity_verify;
    }
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(),
    address: Yup.string(),
    city: Yup.string(),
    avatar: Yup.mixed()
      .imageTypes()
      .fileMaxSize(),
    first_name: Yup.string(),
    last_name: Yup.string(),
    phone: Yup.string(),
    birthday: Yup.date(),
    identity_type: Yup.string(),
    identity_number: Yup.string()
      .min(5)
      .required(),
    documents: Yup.array().of(
      Yup.mixed()
        .imageTypes(['png', 'jpg', 'jpeg', 'pdf'])
        .fileMaxSize()
    ),
    identity_verify: Yup.bool()
      .oneOf([true])
      .required(),
  });

  const toTenantsPage = useCallback((id) => {
    history.push(typeof id !== 'object' ? `${TENANTS_ROUTE}/${id}` : TENANTS_ROUTE);
  }, [history]);

  const handleSubmit = useCallback((values, actions) => {
    const action = params.id
      ? updateTenant
      : createTenant;

    const requestData = params.id
      ? {
        id: tenant.id,
        identity_id: tenant.identity_id,
        identity_files: tenant.identity_files,
        ...values
      }
      : values;

    action(requestData, { ...actions, onSuccess: toTenantsPage });
  }, [toTenantsPage, params, tenant]);

  return (
    <PrivateLayout>

      <div className={styles.tenantCreate}>

        <div className={styles.heading}>

          <Button
            variant="goBack"
            onClick={toTenantsPage}
            isIcon
            svg={<LeftIcon />}
          />

          <div className={styles.title}>

            {params.id ? translate('Edit tenant') : translate('Create new tenant')}

          </div>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>

              <PersonalInfo />

              <GeneralInfo isEdit={params.id} />

              <Verification />

              <div className={styles.footer}>

                <Button
                  color="primary"
                  onClick={toTenantsPage}
                  className={styles.button}
                >

                  {translate('Cancel')}

                </Button>

                <Button
                  loading={isSubmitting}
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={styles.button}
                >

                  {params.id ? translate('Save') : translate('Create')}

                </Button>

              </div>

            </Form>
          )}

        </Formik>

      </div>

    </PrivateLayout>
  );
};
