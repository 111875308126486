import React, { useCallback, useEffect, useMemo } from 'react';
import { InputSelectUC } from '../../../../../components/Inputs/InputSelect';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Label from '../../../../../components/Label';
import translate from '../../../../../utils/translate';
import { components } from "react-select";
import { AngleDown } from '../../../../../assets/icons';
import styles from './styles.module.scss';
import InputCheckbox from '../../../../../components/Inputs/InputCheckbox';
import Avatar from '../../../../../components/Avatar';
import { useField } from 'formik';
import useUnits from '../../../../../hooks/useUnits';
import { getUnits } from '../../../../../services/actions/units';
import useUnit from '../../../../../hooks/useUnit';
import useProperty from '../../../../../hooks/useProperty';

const IndicatorSeparator = () => {
  return <span/>;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <span className={styles.dropdownIndicator}>
        <AngleDown />
      </span>
    </components.DropdownIndicator>
  );
};

export const Option = (props) => {
  const { value: { unitId, fieldName, cbClickAll }, label } = props;

  const { unit } = useUnit(unitId);
  const { property } = useProperty(unit?.property);

  const [field, meta, helpers] = useField(fieldName);

  const onClickAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    cbClickAll();
  }

  const onClickLabel = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const checked = field.value.includes(unitId);
    const formatValue = [...field.value, unitId];
    const newValue = checked ? field.value.filter((item) => item !== unitId) : formatValue;
    helpers.setValue(newValue);
  }

  return (
    <components.Option {...props}>
      <div className={styles.inputPropertyItem}>
        {
          unitId === 'All' ? (
            <div
              onClick={onClickAll}
              className={styles.selectAll}
            >

              <span>{label}</span>

            </div>
          ) : (
            <div className={styles.checkboxWrapper}>

              <InputCheckbox
                id={`${fieldName}-${unit?.id}`}
                name={fieldName}
                multiValue
                value={unit?.id}
                className={styles.checkbox}
              />

              <div className={styles.optionWrapper} onClick={onClickLabel}>

                <Avatar
                  src={property?.cover}
                  size="40"
                  variant={"rounded"}
                />

                <div>

                  <div className={styles.title}>

                    {`${property?.name} ${unit?.name}`}

                  </div>

                  <div className={styles.description}>

                    {`${property?.state}, ${property?.city}, ${property?.address}`}

                  </div>

                </div>

              </div>

            </div>
          )
        }
      </div>
    </components.Option>
  )
};

const InputProperty = ({ name, className = '', ...rest }) => {
  const [field, meta, helpers] = useField(name);

  const { ids: unitIds } = useUnits();

  useEffect(() => {
    getUnits({
      params: { page_size: 1000}
    });
  }, []);

  const onClickAll = useCallback(() => {
    helpers.setTouched(true);
    helpers.setValue([]);
  }, [field, unitIds, helpers]);

  const unitsFilterLabel = useMemo(() => {
    if(field.value.length === 0) {
      return translate('All');
    }
    const unitsLabel = field.value.length === 1 ? translate('unit') : translate('units');
    return `${translate('Filtered by')} ${field.value.length} ${unitsLabel}`;
  }, [field]);

  const unitOptionsWithAll = useMemo(() => {
    return [
      {
        value: {
          unitId: 'All',
          cbClickAll: onClickAll,
        },
        label: translate('All')
      },
      ...unitIds.map(id => ({
        value: {
          unitId: id,
          fieldName: name,
        },
        label: id,
      }))
    ]
  }, [unitIds, onClickAll, name]);

  return (
    <div className={cn(styles.inputPropertyWrapper, className)}>

      <Label htmlFor={name}>

        {translate('Property')}

      </Label>

      <InputSelectUC
        id={name}
        options={unitOptionsWithAll}
        placeholder={unitsFilterLabel}
        components={{IndicatorSeparator, DropdownIndicator, Option }}
        closeMenuOnSelect={false}
        isSearchable={false}
        onChange={() => {}}
        {...rest}
      />

    </div>
  )
};

export default InputProperty;

InputProperty.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}
