import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import cn from 'classnames';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import PrivateLayout from '../../../layouts/PrivateLayout';
import { ReactComponent as LeftIcon } from '../../../assets/icons/angle-left.svg';
import { InputSelect } from '../../../components/Inputs/InputSelect';
import InputCheckbox from '../../../components/Inputs/InputCheckbox';
import { InputText } from '../../../components/Inputs/InputText';
import InputDate from '../../../components/Inputs/InputDate';
import InputFile from '../../../components/Inputs/InputFile';
import Button from '../../../components/Button';
import Label from '../../../components/Label';
import { TRANSACTIONS_ROUTE } from '../../../constants/routes';
import { createTransaction, updateTransaction } from '../../../services/actions/transactions';
import useConfigurations from '../../../hooks/useConfigurations';
import useSelectTenants from '../../../hooks/useSelectTenants';
import useSelectLeases from '../../../hooks/useSelectLeases';
import useTransaction from '../../../hooks/useTransaction';
import translate from '../../../utils/translate';
import history from '../../../utils/history';
import styles from './styles.module.scss';

export default function TransactionCreate() {
  const params = useParams();

  // const { transaction } = useTransaction(Number(params.id));
  const { transaction } = useTransaction(params.id);

  const {
    categories,
    payment_modes,
    subcategories,
  } = useConfigurations();

  const [selectedDirection, setSelectedDirection] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const { tenantOptions, leaseIdsByTenant } = useSelectTenants();

  const { leaseOptions, invoicesByLease } = useSelectLeases();

  const categoriesOptions = useMemo(() => categories
    .filter((item) => {
      if(selectedDirection) {
        return item.direction === 'money_in';
      } else {
        return item.direction === 'money_out';
      }
    })
    .map((item) => ({
      label: item.name,
      value: item.id,
  })), [categories, selectedDirection]);

  const modeOptions = useMemo(() => payment_modes.map((item) => ({
    label: item.name,
    value: item.id,
  })), [payment_modes]);

  const subcategoriesOptions = useMemo(() => subcategories
  .filter((item) => item.category === selectedCategoryId)
  .map((item) => ({
    label: item.name,
    value: item.id,
  })), [subcategories, selectedCategoryId]);

  const initValues = useMemo(() => {
    const obj = {
      direction: true,
      reference: '',
      category_id: '',
      sub_category_id: '',
      transaction_date: new Date(),
      invoice_date_due: new Date(),
      invoices: [],
      amount: 1,
      tenant_id: '',
      lease_id: '',
      supplier: '',
      mode_id: '',
      description: '',
      documents: [],
    };

    if(transaction) {
      if(transaction.direction) {
        obj.direction = transaction.direction === 'money_in';
      }

      if(transaction.reference) {
        obj.reference = transaction.reference;
      }

      if(transaction.invoices?.length) {
        obj.invoices = transaction.invoices;
      }

      if(transaction.category_id) {
        obj.category_id = transaction.category_id;
      }


      if(transaction.sub_category_id) {
        obj.sub_category_id = transaction.sub_category_id;
      }

      if(transaction.transaction_date) {
        obj.transaction_date = new Date(transaction.transaction_date);
      }

      if(transaction.invoice_date_due) {
        obj.invoice_date_due = new Date(transaction.invoice_date_due);
      }

      if(transaction.amount) {
        obj.amount = transaction.amount;
      }

      if(transaction.tenant_id) {
        obj.tenant_id = transaction.tenant_id;
      }

      if(transaction.supplier) {
        obj.supplier = transaction.supplier;
      }

      if(transaction.mode_id) {
        obj.mode_id = transaction.mode_id;
      }

      if(transaction.description) {
        obj.description = transaction.description;
      }

      if(transaction.documents) {
        obj.documents = transaction.documents;
      }
    }

    return obj;
  }, [transaction]);

  const schema = Yup.object().shape({
    direction: Yup.bool(),
    reference: Yup.string()
      .required(),
    category_id: Yup.string()
      .required(),
    sub_category_id: Yup.string()
      .when(['direction', 'category_id'], {
        is: (direction, category_id) => direction && categories
          .find((category_money_in) => category_money_in.id === category_id)?.code === 'invoice',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      })
      .when(['direction', 'category_id'], {
        is: (direction, category_id) => !direction && categories
          .find((category_money_out) => category_money_out.id === category_id)?.code === 'expense',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    transaction_date: Yup.date()
      .required(),
    invoice_date_due: Yup.date()
      .required(),
    amount: Yup.number()
      .min(1)
      .positive()
      .required(),
    tenant_id: Yup.string()
      .when('direction', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    lease_id: Yup.string(),
    invoices: Yup.array()
      .when(['direction', 'lease_id'], {
        is: (direction, lease_id) => direction && !!lease_id,
        then: Yup.array()
          .min(1)
          .required(),
        otherwise: Yup.array(),
      }),
    supplier: Yup.string()
      .when('direction', {
        is: true,
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
    mode_id: Yup.string(),
    description: Yup.string(),
    documents: Yup.array().of(
      Yup.mixed()
        .imageTypes(['png', 'jpg', 'jpeg', 'pdf'])
        .fileMaxSize()
    ),
  });

  const toTransactionsPage = useCallback((id) => {
    history.push(typeof id !== 'object' ? `${TRANSACTIONS_ROUTE}/${id}` : TRANSACTIONS_ROUTE);
  }, []);

  const handleSubmit = useCallback((values, actions) => {
    const action = params.id ? updateTransaction : createTransaction;

    const dataRequest = params.id
      ? {
        id: transaction.id,
        ...values,
      }
      : values;

    action(dataRequest, {...actions, onSuccess: toTransactionsPage});
  }, [toTransactionsPage, params, transaction]);

  const onChangeDirection = useCallback((val) => {
    setSelectedDirection(val);
    setSelectedCategoryId('');
  }, []);

  const onChangeCategory = useCallback((val) => {
    setSelectedCategoryId(val);
  }, []);

  const showSubcategories = useMemo(() => {
    return !!categories.find((item) => item.id === selectedCategoryId)?.subcategory;
  }, [categories, selectedCategoryId]);

  return (
    <PrivateLayout>

      <div className={styles.transactionCreate}>

        <div className={styles.heading}>

          <Button
            onClick={toTransactionsPage}
            variant="goBack"
            isIcon
            svg={<LeftIcon />}
          />

          <div
            className={styles.title}
          >

            {params.id ? translate('Edit Transaction') : translate('Create Transaction')}

          </div>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >

          {({ isSubmitting, values, setFieldValue }) => {
            const leasesByTenant = leaseOptions.length > 0 ? (leaseIdsByTenant[values.tenant_id] || [])
              .map((leaseId) => leaseOptions.find((leaseOption) => leaseOption.value === leaseId)) : [];

            const invoicesOptions = (invoicesByLease[values.lease_id] || []).map((invoice) => ({
              label: invoice?.name,
              value: invoice?.id,
            })) ;

            const onChangeTenant = () => {
              setFieldValue('lease_id', '');
              setFieldValue('invoices', []);
            };

            const onChangeLease = () => {
              setFieldValue('invoices', []);
            };

            const onChangeInvoice = (selectInvoiceValue) => {
              const amount = invoicesByLease[values.lease_id].filter((invoice) => selectInvoiceValue.includes(invoice.id))
                .reduce((acc, item) => {
                  return acc + Number(item.amount);
                }, 0);

              setFieldValue('amount', amount);
            };

            return (
              <Form>

                <InputCheckbox
                  id="direction"
                  name="direction"
                  className={cn(
                    styles.money,
                    {[styles.moneyIn]: values.direction},
                  )}
                  title={values.direction ? translate('Money In') : translate('Money Out')}
                  variant="switch"
                  value={true}
                  onChange={onChangeDirection}
                />

                <Label>

                  {translate('Reference')}

                </Label>

                <InputText
                  id="reference"
                  name="reference"
                />

                <Label>

                  {translate('Select Category')}

                </Label>

                <InputSelect
                  key="category_id"
                  id="category_id"
                  name="category_id"
                  options={categoriesOptions}
                  onChange={onChangeCategory}
                />

                {showSubcategories && (
                  <>

                    <Label>

                      {translate('Select Subcategory')}

                    </Label>

                    <InputSelect
                      id="sub_category_id"
                      name="sub_category_id"
                      options={subcategoriesOptions}
                    />

                  </>
                )}

                <Label>

                  {translate('Transaction date')}

                </Label>

                <InputDate
                  id="transaction_date"
                  name="transaction_date"
                />

                <Label>

                  {translate('Due date')}

                </Label>

                <InputDate
                  id="invoice_date_due"
                  name="invoice_date_due"
                />

                {values.direction
                  ? (
                    <>

                      <Label>

                        {translate('Tenant')}

                      </Label>

                      <InputSelect
                        id="tenant_id"
                        name="tenant_id"
                        options={tenantOptions}
                        onChange={onChangeTenant}
                      />

                      {values.tenant_id && (
                        <>

                          <Label>

                            {translate('Lease')}

                          </Label>

                          <InputSelect
                            id="lease_id"
                            name="lease_id"
                            options={leasesByTenant}
                            onChange={onChangeLease}
                          />

                          {values.lease_id && (
                            <>

                              <Label>

                                {translate('Invoices')}

                              </Label>

                              <InputSelect
                                id="invoices"
                                name="invoices"
                                options={invoicesOptions}
                                onChange={onChangeInvoice}
                                isMulti
                              />

                            </>
                          )}

                        </>
                      )}

                    </>
                  )
                  : (
                    <>

                      <Label>

                        {translate('Payer')}

                      </Label>

                      <InputText
                        id="supplier"
                        name="supplier"
                      />

                    </>
                  )}

                <Label>

                  {translate('Amount')}

                </Label>

                <InputText
                  id="amount"
                  name="amount"
                  type="number"
                  step={1}
                />

                {!params.id && !(values.direction && categories
                  .find((category_money_in) => category_money_in.id === values.category_id)?.code === 'invoice') && (
                  <>

                    <Label>

                    {translate('Payment Mode')}

                    </Label>

                    <InputSelect
                      id="mode_id"
                      name="mode_id"
                      options={modeOptions}
                    />

                  </>
                )}

                <Label>

                  {translate('Description')}

                </Label>

                <InputText
                  id="description"
                  name="description"
                  type="textarea"
                />

                <Label>

                  {translate('Attachments')}

                </Label>

                <InputFile
                  id="documents"
                  name="documents"
                  variant="plus"
                  multiple
                />

                <div className={styles.footer}>

                  <Button
                    color="primary"
                    onClick={toTransactionsPage}
                    className={styles.button}
                  >

                    {translate('Cancel')}

                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                    className={styles.button}
                  >

                    {params.id ? translate('Submit'): translate('Create')}

                  </Button>

                </div>

              </Form>
            );
          }}

        </Formik>

      </div>

    </PrivateLayout>
  );
}
