import { AUTH, TENANTS } from '../../constants/type';

export const defaultState = {
	ids: [],
	filter: {
		type: '',
		status: '',
		search: '',
	},
	page: 1,
	count: 0,
	limit: 0,
	select: [],
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TENANTS.GET_LIST.REQUEST:
			return {
				...state,
			};

		case TENANTS.GET_LIST.SUCCESS:
			return {
				...state,
				/*ids: payload.requestData?.refresh ? payload.result : [...new Set([
					...state.ids,
					...payload.result,
				])],*/
				ids: payload.result,
				count: Number(payload.headers['x-pagination-count']),
				limit: Number(payload.headers['x-pagination-pagesize']),
			};

		/*case TENANTS.CREATE_TENANT.SUCCESS:
			return {
				...state,
				ids: [...new Set([
					...state.ids,
					payload.result,
				])],
			};*/

		case TENANTS.GET_TENANTS_OPTIONS.SUCCESS:
			return {
				...state,
				select: payload.map(({ id, full_name, leases }) => ({
					id: id,
					name: full_name,
					leases,
				})),
			};

		case TENANTS.CLEAR_SELECT.base:
			return {
				...state,
				select: [],
			};

		case TENANTS.CHANGE_PAGE.base:
			return {
				...state,
				page: payload.page,
			};

		case TENANTS.CHANGE_FILTER.base:
			return {
				...state,
				filter: {
					...state.filter,
					[payload.type]: payload.value,
				},
				page: 1,
			};

		case AUTH.SIGN_OUT.SUCCESS:
		case AUTH.GET_PROFILE.FAILURE: {
			return defaultState;
		}

		default:
			return state;
	}
};
