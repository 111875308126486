import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { entitiesTransactionsSelector } from '../services/selectors/entities';
import createLoadingSelector from '../services/selectors/loading';
import { TRANSACTIONS } from '../constants/type';
import { transactionIdSelector } from '../services/selectors/transactions';
import { getTransaction } from '../services/actions/transactions';

const selector = createSelector(
	entitiesTransactionsSelector,
	transactionIdSelector,
	createLoadingSelector(TRANSACTIONS.GET_TRANSACTION.base),
	(transactions, transactionId, loader) => {
		const transaction = transactions[transactionId];

		return {
			transaction: transaction ? {
				...transaction,
			} : null,
			loader,
		};
	},
);

const useTransaction = (transactionId, refresh) => {
	const newSelector = useCallback((state) => selector(state, transactionId), [transactionId]);

	const { transaction, loader } = useSelector(newSelector);

	useEffect(() => {
		if (!transaction && transactionId /*&& typeof transactionId === 'number' && !isNaN(transactionId)*/) {
			getTransaction(transactionId);
		}
	}, [transaction, transactionId]);

	useEffect(() => {
		if (refresh && transactionId /*&& typeof transactionId === 'number' && !isNaN(transactionId)*/) {
			getTransaction(transactionId);
		}
	}, [refresh, transactionId]);

	return {
		transaction,
		loader,
	};
};

export default useTransaction;