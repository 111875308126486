import React from 'react';
import cn from 'classnames';
import { SettingsSvg } from '../../../../assets/icons';
import WidgetWrapper from '../../../../layouts/WidgetWrapper';
import { PROPERTIES_ROUTE } from '../../../../constants/routes';
import TableSimple from '../../../../components/TableSimple';
import useUnits from '../../../../hooks/useUnits';
import useAuth from '../../../../hooks/useAuth';
import translate from '../../../../utils/translate';
import { MobileUnitRow, UnitRow } from '../../Units';
import st from './styles.module.scss';

export default function Properties() {
  const { profile } = useAuth();

  const { ids } = useUnits();

  return (
    <WidgetWrapper
      className={cn(st.widget, { [st.landlord]: profile.is_landlord })}
      title={translate('Properties')}
      externalLink={PROPERTIES_ROUTE}
    >

      <TableSimple
        className={st.table}
        settings={profile.is_landlord}
      >

        <thead>

        <tr>
          <th>{translate('Property')}</th>
          <th>{translate('Type')}</th>
          <th>{translate('Status')}</th>
          <th>{translate('Due Date')}</th>
          <th>{profile.is_tenant ? translate('Landlord'): translate('Tenants')}</th>

          {profile.is_landlord && (
            <th>
              <div className={st.buttonDrop}>
                <SettingsSvg />
              </div>
            </th>
          )}

        </tr>

        </thead>

        <tbody>

        {ids.length > 0 && ids.map((id) => (
          <UnitRow
            key={id}
            unitId={id}
          />
        ))}

        </tbody>

      </TableSimple>

      <div className={st.list}>

        {ids.length > 0 && ids.map((id) => (
          <MobileUnitRow
            key={id}
            unitId={id}
            bordered
          />
        ))}

      </div>

    </WidgetWrapper>
  );
}
