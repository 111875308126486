export const API_SIGN_IN_URL                                = 'token/';
export const API_REFRESH_TOKEN_URL                          = 'token/refresh/';

export const API_SIGN_UP_URL                                = 'accounts/register/';
export const API_SIGN_OUT_URL                               = 'accounts/logout/';
export const API_CONFIRM_EMAIL_URL                          = 'accounts/verify-registration/';
export const API_FORGOT_URL                                 = 'accounts/send-reset-password-link/';
export const API_RESEND_EMAIL_URL                           = 'accounts/send-email-confirmation/';
export const API_SET_PASSWORD_URL                           = 'accounts/reset_password/';
export const API_CHANGE_EMAIL_URL                           = 'accounts/change-email/';
export const API_CONFIRM_CHANGE_EMAIL_URL                   = 'accounts/confirm-change-email/';
export const API_SET_INVITE_PASSWORD_URL                    = 'tenants/signup-invite/';

export const API_CHANGE_PASSWORD_URL                        = 'accounts/change-password/';
export const API_PROFILE_URL                                = 'accounts/profile/';
export const API_PAYMENT_METHODS_URL                        = 'profile/payment_methods/';
export const API_BANKS_URL                                  = 'banks/';


export const API_USERS_URL                                  = 'users/';
export const API_TENANTS_URL                                = 'tenants/';
export const API_UNITS_URL                                  = 'units/';
export const API_PROPERTIES_URL                             = 'properties/';
export const API_PROPERTIES_IMAGES_URL                      = 'properties/images/';
// export const API_PROPERTIES_UNITS_STMT_REPORT               = 'properties/units/statement-report';
export const API_PROPERTIES_UNITS_STMT_REPORT               = 'properties/units/statement-report-sections';
export const API_TRANSACTIONS_URL                           = 'transactions/';
export const API_LEASES_URL                                 = 'leases/';
export const API_WIDGETS_URL                                = 'widgets/';
export const API_LANDLORDS_URL                              = 'landlords/';
export const API_INVOICES_URL                               = 'invoices/';
export const API_IPAYAFRICA_URL                             = 'ipayafrica/';
export const API_FLUTTERWAVE_URL                            = 'flutterwave/';
export const API_MPESA_URL                                  = 'mpesa/';

export const API_CONFIGURATION_AMENITIES_URL                = 'configurations/amenities/';
export const API_CONFIGURATION_CATEGORIES_URL               = 'configurations/categories/';
export const API_CONFIGURATION_SUBCATEGORIES_URL            = 'configurations/subcategories/';
export const API_CONFIGURATION_FEATURES_URL                 = 'configurations/features/';
export const API_CONFIGURATION_PAYMENT_MODES_URL            = 'configurations/payment-modes/';
export const API_CONFIGURATION_CONSTANTS_URL                = 'configurations/constants/';

export const API_PUBLIC_TEXTS_URL                           = 'landing/texts-new/';
export const API_PUBLIC_CONTACT_URL                         = 'landing/messages/';
export const API_PUBLIC_SUBSCRIBE_URL                       = 'landing/subscribers/';
export const API_PUBLIC_REQUEST_DEMO_URL                    = 'landing/demo/';
