import React from 'react';
import CardHeader from '../../../../components/Card/CardHeader';
import CardTitle from '../../../../components/Card/CardTitle';
import CardBody from '../../../../components/Card/CardBody';
import Tenant from '../../../../components/Tenant';
import Card from '../../../../components/Card';
import useLandlord from '../../../../hooks/useLandlord';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function Landlord({ landlordId }) {
  const { landlord } = useLandlord(landlordId);

  if(!landlord) {
    return null;
  }

  return (
    <Card className={styles.card}>

      <CardHeader className={styles.cardHeader}>

        <CardTitle className={styles.title}>

          {translate('Landlord')}

        </CardTitle>

      </CardHeader>

      <CardBody>

        <Tenant
          className={styles.tenant}
          title={([landlord.first_name, landlord.last_name]).join(' ')}
          src={landlord.avatar}
          content={landlord.email}
        />

      </CardBody>

    </Card>
  );
}
