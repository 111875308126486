import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { CellSvg } from '../../../../assets/icons';
import UncontrolledDropdown from '../../../../components/DropDown/UncontrolledDropdown';
import DropdownToggle from '../../../../components/DropDown/DropdownToggle';
import DropdownMenu from '../../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../../components/DropDown/DropDownItem';
import TableSimple from '../../../../components/TableSimple';
import CardBody from '../../../../components/Card/CardBody';
import Button from '../../../../components/Button';
import Status from '../../../../components/Status';
import Card from '../../../../components/Card';
import { TRANSACTION_CREATE_ROUTE, TRANSACTIONS_ROUTE } from '../../../../constants/routes';
import useTransactionsByTenant from '../../../../hooks/useTransactionsByTenant';
import useConfigurations from '../../../../hooks/useConfigurations';
import useTransaction from '../../../../hooks/useTransaction';
import translate from '../../../../utils/translate';
import history from '../../../../utils/history';
import styles from '../styles.module.scss';
import useWidgetByTenant from '../../../../hooks/useWidgetByTenant';
import useTenant from '../../../../hooks/useTenant';
import MobileRow from '../../../../components/MobileRow';

const TransactionRow = ({ transactionId, categories, status_transaction }) => {
	const { transaction } = useTransaction(transactionId);

	const onEdit = () => {
		history.push(`${TRANSACTION_CREATE_ROUTE}/${transactionId}`);
	}

	return (
		<tr
			key={transactionId}
		>
			<td>

				<Status
					status={transaction.status}
				>

					{status_transaction.find((status) => status.id === transaction.status)?.name}

				</Status>

			</td>
			<td>{categories[transaction.category_id]}</td>
			<td>{moment(transaction.date).format('DD MMMM, YYYY')}</td>
			<td>{`${transaction.amount}KSh`}</td>
			<td>

				<UncontrolledDropdown>

					<DropdownToggle className={styles.buttonDrop}>

						<Button
							isIcon
							className={styles.buttonDrop}
						>

							<CellSvg />

						</Button>

					</DropdownToggle>

					<DropdownMenu
						position="right"
					>

						<DropDownItem
							toggle
							onClick={onEdit}
						>

							{translate('Edit')}

						</DropDownItem>

					</DropdownMenu>

				</UncontrolledDropdown>

			</td>
		</tr>
	);
};

const TransactionRowMobile = ({ transactionId, categories, status_transaction }) => {
	const { transaction } = useTransaction(transactionId);

	const { tenant } = useTenant(transaction.tenant_id);

	const handleEdit = useCallback(() => {
		history.push(`${TRANSACTION_CREATE_ROUTE}/${transactionId}`);
	}, [transactionId]);

	let title = tenant?.first_name ? `${tenant?.first_name} ${tenant?.last_name}` : tenant?.email;

	if(transaction.direction === 'money_out') {
		title = transaction.supplier;
	}

	return (
		<MobileRow
			src={transaction.direction === 'money_out' ? null : tenant?.avatar}
			status={transaction.status}
			statusName={status_transaction.find((status) => status.id === transaction.status)?.name}
			title={title}
			content={transaction.invoice_id && transaction.address}
			link={!transaction.invoice_id ? `${TRANSACTIONS_ROUTE}/${transactionId}` : null}
			bordered
			buttons={[
				{
					title: translate('Edit'),
					toggle: true,
					onClick: handleEdit,
				}
			]}
			list={[
				{
					title: translate('Type'),
					value: categories[transaction.category_id],
				},
				{
					title: translate('Due Date'),
					value: moment(transaction.date).format('DD MMMM, YYYY'),
				},
				{
					title: translate('Amount'),
					value: `${transaction.amount}KSh`,
				},
			]}
		/>
	)
}

const Accounting = () => {
	const { categories, status_transaction } = useConfigurations();

	const { tenantId } = useParams();

	const { ids } = useTransactionsByTenant(Number(tenantId));

	const { widgetsByTenant } = useWidgetByTenant(Number(tenantId), true);

	const categoriesObject  = useMemo(() => categories.reduce((acc, category) => {
		acc[category.id] = category.name;
		return acc;
	}, {}), [categories]);

	return (
		<>

			<div className={styles.wrapTitle}>

				<h2 className={styles.title}>

					{translate('Accounting')}

				</h2>

			</div>

			<Card className={styles.card}>

				<CardBody className={styles.accounting}>

					{widgetsByTenant.length> 0 && widgetsByTenant.map((widget) => (
						<div
							key={widget.code}
							className={styles.accountingItem}
						>

							<h4>{widget.name}</h4>

							<p>{widget.amount}KSh</p>

						</div>
					))}

				</CardBody>

			</Card>

			<TableSimple
				className={styles.table}
			>

				<thead>

				<tr>
					<th>{translate('Status')}</th>
					<th>{translate('Type')}</th>
					<th>{translate('Due Date')}</th>
					<th>{translate('Amount')}</th>
					<th/>
				</tr>

				</thead>

				<tbody>

				{ids?.length > 0 && ids.map((id) => (
					<TransactionRow
						key={id}
						transactionId={id}
						categories={categoriesObject}
						status_transaction={status_transaction}
					/>
				))}

				</tbody>

			</TableSimple>

			<div className={styles.list}>

				{ids?.length > 0 && ids.map((id) => (
					<TransactionRowMobile
						key={id}
						transactionId={id}
						categories={categoriesObject}
						status_transaction={status_transaction}
					/>
				))}

			</div>

		</>
	);
};

export default Accounting;