import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getWidgetMain } from '../services/actions/widgets';
import { widgetMainSelector } from '../services/selectors/widgets';

const selector = createSelector(
	widgetMainSelector,
	(widgets) => {

		return {
			widgets,
		};
	},
);

const useWidgetMain = () => {
	const data = useSelector(selector);

	useEffect(() => {
		getWidgetMain();
	}, [])

	return {
		...data,
	};
};

export default useWidgetMain;