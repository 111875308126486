import { createRequestTypes } from '../utils/actionHelpers';

export const AUTH = createRequestTypes('AUTH', [
	/* AUTH */
	'SIGN_IN',
	'SIGN_UP',
	'SIGN_OUT',
	'CONFIRM_EMAIL',
	'FORGOT',
	'RESEND_EMAIL',
	'SET_PASSWORD',
	'SIGN_OUT',
	'CHANGE_PASSWORD',
	'CHANGE_EMAIL',
	'CONFIRM_CHANGE_EMAIL',
	'UPDATE_PROFILE',
	'GET_PROFILE',
	'REMOVE_PROFILE',
	'SET_INVITE_PASSWORD',
	'GET_PAYMENT_METHODS',
	'GET_PAYMENT_METHOD',
	'GET_BANKS',
	'CREATE_PAYMENT_METHOD',
	'UPDATE_PAYMENT_METHOD',
	'DELETE_PAYMENT_METHOD',
	'REFRESH_TOKEN',
]);

export const TENANTS = createRequestTypes('TENANTS', [
	/* TENANTS */
	'GET_LIST',
	'GET_TENANT',
	'CREATE_TENANT',
	'UPDATE_TENANT',
	'ADD_TENANT_DOCUMENT',
	'REMOVE_TENANT_DOCUMENT',
	'SEND_INVITE',
	'CHANGE_FILTER',
	'CHANGE_PAGE',
	'GET_TENANTS_OPTIONS',
	'CLEAR_SELECT',
]);

export const INVOICES = createRequestTypes('INVOICES', [
	/* INVOICES */
	'GET_INVOICE',
	'GET_INVOICES_OPTIONS',
	'CLEAR_SELECT',
	'PAY_INVOICES',
	'GET_AVAILABLE_PAYMENT_METHODS'
]);

export const UNITS = createRequestTypes('UNITS', [
	/* UNITS */
	'GET_LIST',
	'GET_UNIT',
	'CREATE_UNIT',
	'UPDATE_UNIT',
	'CHANGE_FILTER',
	'CHANGE_PAGE',
	'GET_UNITS_OPTIONS',
	'CLEAR_SELECT',
	'GET_STATEMENT_REPORT',
]);

export const PROPERTIES = createRequestTypes('PROPERTIES', [
	/* PROPERTIES */
	'GET_LIST',
	'GET_PROPERTY',
	'CREATE_PROPERTY',
	'UPDATE_PROPERTY',
	'MOVE_IN',
	'ADD_PROPERTY_IMAGE',
	'REMOVE_PROPERTY_IMAGE',
	'CHANGE_FILTER',
]);

export const TRANSACTIONS = createRequestTypes('TRANSACTIONS', [
	/* TRANSACTIONS */
	'GET_LIST',
	'GET_TRANSACTION',
	'CREATE_TRANSACTION',
	'UPDATE_TRANSACTION',
	'GET_TRANSACTIONS_BY_TENANT',
	'GET_TRANSACTIONS_BY_UNIT',
	'CHANGE_FILTER',
	'CHANGE_PAGE',
]);

export const LEASES = createRequestTypes('LEASES', [
	/* LEASES */
	'GET_LIST',
	'GET_LEASE',
	'GET_LEASES_BY_TENANT',
	'GET_LEASES_BY_PROPERTY',
	'GET_LEASES_BY_UNIT',
	'CREATE_LEASE',
	'UPDATE_LEASE',
	'GET_LEASES_OPTIONS',
	'CLEAR_SELECT',
]);

export const CONFIGURATIONS = createRequestTypes('CONFIGURATIONS', [
	/* CONFIGURATIONS */
	'GET_AMENITIES',
	'GET_CATEGORIES',
	'GET_SUBCATEGORIES',
	'GET_CATEGORIES_MONEY_IN',
	'GET_CATEGORIES_MONEY_OUT',
	'GET_FEATURES',
	'GET_PAYMENT_DIRECTIONS',
	'GET_PAYMENT_MODES',
	'GET_CONSTANTS',
]);

export const PUBLIC = createRequestTypes('PUBLIC', [
	/* PUBLIC */
	'GET_TEXTS',
	'SEND_CONTACT',
	'SEND_SUBSCRIBE',
	'SEND_DEMO_REQUEST',
]);

export const WIDGETS = createRequestTypes('WIDGETS', [
	/* WIDGETS */
	'GET_MAIN',
	'GET_BY_TENANT',
]);

export const LANDLORDS = createRequestTypes('LANDLORDS', [
	/* LANDLORDS */
	'GET_LIST',
	'GET_LANDLORD',
]);
