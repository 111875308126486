import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ReactComponent as LeftIcon } from '../../../../../assets/icons/angle-left.svg';
import { SECURITY_ROUTE } from '../../../../../constants/routes';
import PrivateLayout from '../../../../../layouts/PrivateLayout';
import { InputText } from '../../../../../components/Inputs/InputText';
import Button from '../../../../../components/Button';
import Label from '../../../../../components/Label';
import { changeEmail } from '../../../../../services/actions/auth';
import translate from '../../../../../utils/translate';
import history from '../../../../../utils/history';
import styles from '../../styles.module.scss';
import st_change from './styles.module.scss';
import ModalCode from './ModalCode';
import useModal from '../../../../../hooks/useModal';

const ChangeEmail = () => {
  const [modalCode, openModalCode, closeModalCode] = useModal(false);

  const initValues = {
    email: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
  });

  const handleSubmit = useCallback((values, actions) => {
    changeEmail(values, { ...actions, onSuccess: openModalCode })
  }, [openModalCode]);

  const toSecurityPage = useCallback(() => {
    history.push(SECURITY_ROUTE);
  }, []);

  return (
    <PrivateLayout>

      <ModalCode
        isOpen={modalCode}
        onClose={closeModalCode}
      />

      <div className={st_change.container}>

        <div className={st_change.heading}>

          <Button
            variant="goBack"
            onClick={toSecurityPage}
            isIcon
            svg={<LeftIcon />}
          />

          <h2 className={styles.title}>

            {translate('Change Email')}

          </h2>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >

          <Form>

            <Label>
              {translate('New Email')}
            </Label>

            <InputText
              id='email'
              name='email'
              type='email'
              autoComplete="off"
            />

            <div className={st_change.btns}>
              <Button
                color="secondary"
                variant="shadow"
                onClick={toSecurityPage}
              >

                {translate('Cancel')}

              </Button>

              <Button
                color="primary"
                variant="contained"
                type="submit"
              >

                {translate('Change Email')}

              </Button>

            </div>

          </Form>

        </Formik>

      </div>

    </PrivateLayout>
  );
};

export default ChangeEmail;