import React, { useCallback, Fragment } from 'react';
import cn from 'classnames';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort.svg';
import { InputSelectUC } from '../Inputs/InputSelect';
import { InputSwitchUC } from '../Inputs/InputSwitch';
import InputSearch from '../Inputs/InputSearch';
import ModalFilters from './ModalFilters';
import Button from '../Button';
import Label from '../Label';
import useModal from '../../hooks/useModal';
import translate from '../../utils/translate';
import styles from './styles.module.scss';

const Filter = ({ filterInputs, searchInput, onChange }) => {
	const [modalFilters, openModalFilters, closeModalFilters] = useModal(false);

	const [modalSearch, openModalSearch, closeModalSearch] = useModal(false);

	const handleChange = useCallback((type) => (e) => {
		if(type === 'search') {
			onChange(e.target.value, type);
		} else {
			onChange(e?.value || '', type);
		}
	}, [onChange]);

	return (
		<div className={styles.filter}>

			<div
				className={cn(
					styles.wrap,
					styles.mobile,
				)}
			>

				<ModalFilters
					isOpen={modalSearch}
					onClose={closeModalSearch}
					title={translate('Search')}
				>

					<InputSearch
						id={searchInput.name}
						name={searchInput.name}
						placeholder={searchInput.label}
						onChange={handleChange('search')}
						value={searchInput.value}
					/>

				</ModalFilters>

				<ModalFilters
					isOpen={modalFilters}
					onClose={closeModalFilters}
					title={translate('Filters')}
				>

					{filterInputs.map((filter) => {
						switch(filter.type) {
							case 'select':
								return (
									<Fragment key={filter.name}>

										<Label>

											{filter.label}

										</Label>

										<InputSelectUC
											onChange={handleChange(filter.name)}
											options={filter.options}
											value={filter.value}
											isClearable
										/>

									</Fragment>
								);

							case 'switch':
								return (
									<Fragment key={filter.name}>

										<Label>

											{filter.label}

										</Label>

										<InputSwitchUC
											key={filter.name}
											className={styles.select}
											tabs={filter.tabs}
											onChange={handleChange(filter.name)}
											value={filter.value}
										/>

									</Fragment>
								);

							default:
								return null;
						}
					})}

				</ModalFilters>

				<Button
					className={styles.button}
					onClick={openModalSearch}
					svg={<SearchIcon />}
				/>

				<Button
					className={styles.button}
					onClick={openModalFilters}
					svg={<SortIcon />}
				/>

			</div>

			<div
				className={cn(
					styles.wrap,
					styles.desktop,
				)}
			>

				<div className={styles.filters}>

					{filterInputs.map((filter) => {
						switch(filter.type) {
							case 'select':
								return (
									<InputSelectUC
										key={filter.name}
										className={styles.select}
										onChange={handleChange(filter.name)}
										options={filter.options}
										value={filter.value}
										isClearable
										placeholder={filter.label}
									/>
								);

							case 'switch':
								return (
									<InputSwitchUC
										key={filter.name}
										className={styles.select}
										tabs={filter.tabs}
										onChange={handleChange(filter.name)}
										value={filter.value}
									/>
								);

							default:
								return null;
						}
					})}

				</div>

				<InputSearch
					id={searchInput.name}
					name={searchInput.name}
					placeholder={searchInput.label}
					onChange={handleChange('search')}
					value={searchInput.value}
				/>

			</div>

		</div>
	)
};

export default Filter;
