import React, { useMemo } from 'react';
import PublicLayout from '../../../layouts/PublicLayout';
import FormStart from './FormStart';
import Companies from './Companies';
import Reviews from './Reviews';
import VideoBlock from './VideoBlock';
import Benefits from './Benefits';
import Hero from './Hero';
import Devices from './Devices';
import Views from './Views';
import usePublic from '../../../hooks/usePublic';

const HomePage = () => {
	const {
		HOME_TOP_TITLE, HOME_TOP_DESCRIPTION, HOME_TOP_IMAGE_URL, HOME_HOW_IT_WORK_TITLE,
		HOME_HOW_IT_WORK_VIDEO_URL, HOME_FORMSTART_TITLE, ...rest
	} = usePublic();

	const benefits = useMemo(() => {
		const array = [];

		Array(3).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`HOME_BENEFITS_${id}_ICON`]
				|| rest[`HOME_BENEFITS_${id}_TITLE`]
				|| rest[`HOME_BENEFITS_${id}_DESCRIPTION`]
				|| rest[`HOME_BENEFITS_${id}_COLOR`]
			) {
				array.push({
					id,
					icon: rest[`HOME_BENEFITS_${id}_ICON`].join(''),
					title: rest[`HOME_BENEFITS_${id}_TITLE`],
					description: rest[`HOME_BENEFITS_${id}_DESCRIPTION`],
					color: rest[`HOME_BENEFITS_${id}_COLOR`],
				});
			}
		});

		return array;
	}, [rest]);

	const devices = useMemo(() => {
		const array = [];

		Array(3).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`HOME_DEVICES_${id}_TITLE`]
				|| rest[`HOME_DEVICES_${id}_DESCRIPTION`]
				|| rest[`HOME_DEVICES_${id}_IMAGE_URL`]
			) {
				array.push({
					id,
					device: rest[`HOME_DEVICES_${id}_TITLE`],
					title: rest[`HOME_DEVICES_${id}_DESCRIPTION`],
					image: rest[`HOME_DEVICES_${id}_IMAGE_URL`],
					order: rest[`HOME_DEVICES_${id}_POSITION`],
				});
			}
		});

		return array.sort((a, b) => a.order - b.order);
	}, [rest]);

	const views = useMemo(() => {
		const array = [];

		Array(3).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`HOME_HOW_IT_WORK_${id}_ICON`]
				|| rest[`HOME_HOW_IT_WORK_${id}_TITLE`]
				|| rest[`HOME_HOW_IT_WORK_${id}_DESCRIPTION`]
				|| rest[`HOME_HOW_IT_WORK_${id}_IMAGE_URL`]
				|| rest[`HOME_HOW_IT_WORK_${id}_COLOR`]
				|| rest[`HOME_HOW_IT_WORK_${id}_POSITION`]
			) {
				array.push({
					id,
					icon: rest[`HOME_HOW_IT_WORK_${id}_ICON`].join(''),
					title: rest[`HOME_HOW_IT_WORK_${id}_TITLE`],
					description: rest[`HOME_HOW_IT_WORK_${id}_DESCRIPTION`],
					image: rest[`HOME_HOW_IT_WORK_${id}_IMAGE_URL`],
					color: rest[`HOME_HOW_IT_WORK_${id}_COLOR`],
					order: rest[`HOME_HOW_IT_WORK_${id}_POSITION`],
				});
			}
		});

		return array.sort((a, b) => a.order - b.order);
	}, [rest]);

	const reviews = useMemo(() => {
		const array = [];

		Array(3).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`HOME_REVIEWS_${id}_IMAGE_URL`]
				|| rest[`HOME_REVIEWS_${id}_TITLE`]
				|| rest[`HOME_REVIEWS_${id}_DESCRIPTION`]
				|| rest[`HOME_REVIEWS_${id}_COMMENT`]
				|| rest[`HOME_REVIEWS_${id}_POSITION`]
			) {
				array.push({
					id,
					icon: rest[`HOME_REVIEWS_${id}_IMAGE_URL`],
					title: rest[`HOME_REVIEWS_${id}_TITLE`],
					description: rest[`HOME_REVIEWS_${id}_COMMENT`],
					position: rest[`HOME_REVIEWS_${id}_DESCRIPTION`],
					order: rest[`HOME_REVIEWS_${id}_POSITION`],
				});
			}
		});

		return array.sort((a, b) => a.order - b.order);
	}, [rest]);

	const companies = useMemo(() => {
		const array = [];

		Array(5).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(rest[`HOME_COMPANIES_${id}_ICON`]) {
				array.push({
					id,
					icon: rest[`HOME_COMPANIES_${id}_ICON`].join(''),
				});
			}
		});

		return array;
	}, [rest]);

	return (
		<PublicLayout
			withFooter
		>

			<Hero
				title={HOME_TOP_TITLE}
				description={HOME_TOP_DESCRIPTION}
				image={HOME_TOP_IMAGE_URL}
			/>

			<Benefits
				benefits={benefits}
			/>

			<Devices
				devices={devices}
			/>

			<VideoBlock
				title={HOME_HOW_IT_WORK_TITLE}
				video={HOME_HOW_IT_WORK_VIDEO_URL}
			/>

			<Views
				views={views}
			/>

			<Reviews
				reviews={reviews}
			/>

			<Companies
				companies={companies}
			/>

			<FormStart
				title={HOME_FORMSTART_TITLE}
			/>

		</PublicLayout>
	);
};

export default HomePage;