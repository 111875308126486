import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Device = ({ image, className, device, title }) => {
	return (
		<div
			className={cn(
				styles.device,
				className,
			)}
		>

			<p className={styles.type}>

				{device}

			</p>

			<h3 className={styles.title}>{title}</h3>

			<figure className={styles.image}>

				<img src={image} alt="device" />

			</figure>

		</div>
	);
};

Device.defaultProps = {
	className: '',
}

Device.propTypes = {
	className: PropTypes.string,
	device: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

export default Device;