import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { UNITS } from '../constants/type';
import createLoadingSelector from '../services/selectors/loading';
import { unitIdsSelector, unitsSelector } from '../services/selectors/units';

const selector = createSelector(
	unitIdsSelector,
	createLoadingSelector(UNITS.GET_LIST.base),
	unitsSelector,
	(ids, loader, { page, limit, count }) => {

		return {
			ids,
			loader,
			page,
			limit,
			count,
		};
	},
);

const useUnits = () => {
	const data = useSelector(selector);

	return {
		...data,
	};
};

export default useUnits;