import React, { useCallback } from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import { InputText } from '../../../../components/Inputs/InputText';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import useAuth from '../../../../hooks/useAuth';
import translate from '../../../../utils/translate';
import styles from '../../styles.module.scss';

const ForgotForm = ({ setEmail }) => {
	const {
		onForgot,
	} = useAuth();

	const initValues = {
		email: '',
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.email()
			.required(),
	});

	const handleSubmit = useCallback((values, action) => {
		onForgot(values, {...action, setEmail});
	}, [onForgot, setEmail]);

	return (
		<>

			<h2
				className={cn(
					'h2',
					styles.title
				)}
			>

				{translate('Recover password')}

			</h2>

			<p className={styles.description}>

				{translate('Have an account?')}

				&nbsp;

				<Link to={SIGN_IN_ROUTE}>

					{translate('Sign in')}

				</Link>

			</p>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
			>

				{({ isSubmitting }) => (
					<Form>

						<Label>

							{translate('Email')}

						</Label>

						<InputText
							id="email"
							name="email"
							type="email"
							autoComplete="username email"
						/>

						<Button
							color="primary"
							variant="contained"
							type="submit"
							size="big"
							loading={isSubmitting}
						>

							{translate('Recover')}

						</Button>

					</Form>
				)}

			</Formik>

		</>
	);
};

export default ForgotForm;