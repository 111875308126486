import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { FilePlusSvg, LargePlusSvg } from '../../../assets/icons';
import { InputNotice } from '../InputNotice';
import Button from '../../Button';
import Card from '../../Card';
import CardBody from '../../Card/CardBody';
import formatBytes from '../../../utils/formatBytes';
import translate from '../../../utils/translate';
import styles from './styles.module.scss';

const InputFile = ({ id, className, name, accept, heading, description, variant, multiple}) => {
	const ref = useRef(null);

	const [field, meta, helpers] = useField(name);

	const { error, touched } = meta;

	const handleChange = (e) => {
		const file = multiple
			? [
				...field.value,
				...e.currentTarget.files,
			]
			: e.currentTarget.files[0];

		if (file) {
			helpers.setTouched(true);

			helpers.setValue(file);

			field.onBlur('');
		}

		ref.current.value = null;
	};

	const handleClear = () => {
		helpers.setValue('');
	};

	const onRemove = (index) => helpers.setValue(field.value.filter((_, i) => index !== i));

	return (
		<>

			<label
				className={cn(
					styles.inputFile,
					{ [styles.error]: error && touched },
					className,
				)}
			>

				<input
					id={id}
					ref={ref}
					name={name}
					type="file"
					onBlur={field.onBlur}
					onChange={handleChange}
					accept={accept}
					multiple={multiple}
				/>

				{variant === 'default' && (
					<h3 className={styles.heading}>

						<FilePlusSvg />

						{heading}

					</h3>
				)}

				{variant === 'plus' && (
					<figure className={styles.svg}>

						<LargePlusSvg />

					</figure>
				)}

				<p className={styles.description}>

					{description}

				</p>

			</label>

			{error && touched && (
				<InputNotice
					name={name}
				/>
			)}

			{field.value && (multiple ? field.value.map((file, index) => (
				<Card
					key={index}
					className={styles.item}
				>

					<CardBody className={styles.wrap}>

						<div className={styles.content}>

							<h3>

								{file.id ? file.image.split('/').pop() : file.name}

							</h3>

							<p>

								{file.size && formatBytes(file.size)}

							</p>

						</div>

						<Button
							color="danger"
							isIcon
							onClick={() => onRemove(index)}
						>

							<TrashIcon />

						</Button>

					</CardBody>

				</Card>
			)) : (
				<Card className={styles.item}>

					<CardBody className={styles.wrap}>

						<div className={styles.content}>

							<h3>

								{typeof field.value === 'string' ? field.value.split('/').pop() : field.value.name}

							</h3>


							<p>

								{typeof field.value === 'object' && field.value.size && formatBytes(field.value.size)}

							</p>

						</div>

						<Button
							color="danger"
							isIcon
							onClick={handleClear}
						>

							<TrashIcon />

						</Button>

					</CardBody>

				</Card>
			))}

		</>
	);
};

InputFile.defaultProps = {
	className: '',
	description: translate('Please upload a copy of your valid identification in PNG, JPEG or PDF format, no larger than 3mb size'),
	accept: '.png, .jpg, .jpeg, .pdf',
	variant: 'default',
	heading: 'Upload photo',
	multiple: false,
};

InputFile.propTypes = {
	className: PropTypes.string,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string,
	accept: PropTypes.string,
	variant: PropTypes.oneOf([
		'default',
		'plus',
	]),
	heading: PropTypes.string,
	multiple: PropTypes.bool,
};

export default InputFile;