import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Benefit = ({ className, color, icon, title, description }) => {

	return (
		<div
			className={cn(
				styles.benefit,
				{[styles[color]]: color},
				className,
			)}
		>

			<figure
				className={styles.icon}
				dangerouslySetInnerHTML={{__html: icon}}
			/>

			<h3 className={styles.title}>

				{title}

			</h3>

			<p className={styles.description}>

				{description}

			</p>

		</div>
	);
};

Benefit.propTypes = {
	className: '',
	color: 'default',
};

Benefit.propTypes = {
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'default',
		'primary',
		'secondary',
		'warning',
		'danger',
		'info',
		'success',
		'light'
	]),
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
}

export default Benefit;