import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Modal';
import ModalForm from './ModalForm';

const ModalTermination = ({ isOpen, onClose }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>

			<ModalForm
				onClose={onClose}
			/>

		</Modal>
	);
};

ModalTermination.defaultProps = {
	isOpen: false,
}

ModalTermination.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
}

export default ModalTermination;