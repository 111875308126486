import React from 'react';
import PropTypes from 'prop-types';
import Benefit from './Benefit';
import styles from './styles.module.scss';

const Benefits = ({ benefits }) => {
	return (
		<section className={styles.benefits}>

			{benefits.map(({ id, icon, color, title, description }) => (
				<Benefit
					key={id}
					icon={icon}
					color={color}
					title={title}
					description={description}
				/>
			))}

		</section>
	);
};

Benefits.defaultProps = {
	benefits: [],
};

Benefits.propTypes = {
	benefits: PropTypes.array,
};

export default Benefits;