import { useCallback, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { entitiesUnitsSelector } from '../services/selectors/entities';
import createLoadingSelector  from '../services/selectors/loading';
import { unitIdSelector } from '../services/selectors/units';
import { getUnit } from '../services/actions/units';
import { UNITS } from '../constants/type';

const selector = createSelector(
	entitiesUnitsSelector,
	unitIdSelector,
	createLoadingSelector(UNITS.GET_UNIT.base),
	(units, unitId, loader) => {
		const unit = units[unitId];

		return {
			unit: unit ? {
				...unit,
			} : null,
			loader,
		};
	},
);

const useUnit = (unitId, refresh) => {
	const newSelector = useCallback((state) => selector(state, unitId), [unitId]);

	const { unit, loader } = useSelector(newSelector);

	useEffect(() => {
		if (!unit && typeof unitId === 'number' && !isNaN(unitId)) {
			getUnit(unitId);
		}
	}, [unit, unitId]);

	useEffect(() => {
		if (refresh && typeof unitId === 'number' && !isNaN(unitId)) {
			getUnit(unitId);
		}
	}, [refresh, unitId]);

	return {
		unit,
		loader,
	};
};

export default useUnit;