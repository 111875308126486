import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const CardTitle = ({ tag: Tag, className, children }) => {
	return (
		<Tag
			className={cn(
				styles.cardTitle,
				className,
			)}
		>

			{children}

		</Tag>
	);
};

CardTitle.defaultProps = {
	tag: 'div',
	className: '',
	children: '',
};

CardTitle.propTypes = {
	tag: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	className: PropTypes.string,
	children: PropTypes.node,
};

export default CardTitle;