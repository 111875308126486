import React, { useMemo } from 'react';
import Filter from '../../../../components/Filter';
import { changeTransactionsFilter } from '../../../../services/actions/transactions';
import useConfigurations from '../../../../hooks/useConfigurations';
import translate from '../../../../utils/translate';

export default function TransactionFilter({ filter: { direction, category, status, mode, search } }) {
  const { payment_direction: payment_directions = [], categories, payment_modes, status_transaction } = useConfigurations();

  const directionOptions = useMemo(() => [
    {
      label: 'All',
      value: '',
    },
    ...payment_directions.map((item) => ({
      label: item.name,
      value: item.id,
    })),
  ], [payment_directions]);

  const categoryOptions = useMemo(() => categories
    .filter((category) => !direction || category.direction === direction)
    .map((item) => ({
      label: item.name,
      value: item.id,
    })), [direction, categories]);

  const statusOptions = useMemo(() => status_transaction.map((item) => ({
    label: item.name,
    value: item.id,
  })), [status_transaction]);

  const modeOptions = useMemo(() => payment_modes.map((item) => ({
    label: item.name,
    value: item.id,
  })), [payment_modes]);

  const filterInputs = useMemo(() => ([
    {
      type: 'switch',
      name: 'direction',
      label: translate('Direction'),
      value: direction,
      tabs: directionOptions,
    },
    {
      type: 'select',
      name: 'category',
      label: translate('Category'),
      value: category,
      options: categoryOptions,
    },
    {
      type: 'select',
      name: 'status',
      label: translate('Status'),
      value: status,
      options: statusOptions,
    },
    {
      type: 'select',
      name: 'mode',
      label: translate('Payment Mode'),
      value: mode,
      options: modeOptions,
    },
  ]), [direction, directionOptions, category, categoryOptions, status, mode, modeOptions, statusOptions]);

  const searchInput = useMemo(() => ({
    name: 'search',
    label: translate('Search'),
    value: search
  }), [search]);

  return (
    <Filter
      filterInputs={filterInputs}
      searchInput={searchInput}
      onChange={changeTransactionsFilter}
    />
  );
}
