import React from 'react';
import FormStart from '../HomePage/FormStart';
import PublicLayout from '../../../layouts/PublicLayout';

import styles from '../Terms/styles.module.scss';
import usePublic from '../../../hooks/usePublic';

const Privacy = () => {
	const {
		HOME_FORMSTART_TITLE
	} = usePublic();

	return (
		<PublicLayout
			withFooter
		>

			<section className={styles.terms}>

				<h1 className={styles.terms_title}>
					Koddi Privacy Policy
				</h1>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Data protection
					</h2>
					<h3 className={styles.terms_subtitle_2}>
						Personal Data
					</h3>

					<ul className={styles.terms_list_alphabetic}>
						<li>
							Both parties recognize that they will have access to Personal Data and undertake and warrant to each other that they shall comply with the requirements of the Data Protection Act, 2019 with respect to any Personal Data supplied to it by the other Party and/or Customers for the purposes of the Solution.
						</li>
						<li>
							Each party shall only collect, process, use, store and/or disclose Personal Data acquired for the purposes of the Solution as permitted by the Governing Law and strictly only for the purposes of fulfilling its obligations in providing the Bank Services for the Solution.
						</li>
						<li>
							Each Party shall always be responsible for ensuring that the Customer Data, in whatever form, is stored securely and hereby agrees to implement security measures to maintain the confidentiality, integrity, and availability of the Customer Data; and protect it from accidental or unlawful destruction, alteration and unauthorized disclosure, unlawful processing or use, fraudulent misuse, or loss or destruction while in the Party’s custody.
						</li>
						<li>
							Each Party shall maintain, review and update when necessary its data protection and security policies with respect to the processing of Customer Data and its processes on identifying vulnerabilities in its computer networks, as well as  the procedure for prevention, mitigation and correction of security incidents that may lead to security breaches. Each Party shall inform the other Party prior to the implementation of the charges and will provide the other Party with a copy of the policies and procedures as soon as practicable prior to implementation.
						</li>
						<li>
							Each Party agrees that its personnel, agents, representatives or any other person under its control or authority, shall have access to Customer Data only as legitimate purpose and proportionality would require, and will store and/or process the Customer Data in accordance with the Data Protection Act, 2019. Each Party shall not disclose or allow access to any Customer Data other than to a person placed by the Party under the same obligations as set out in this Agreement.
						</li>
						<li>
							In case of any judicial order, governmental action, or legal obligation requiring any Party to disclose Personal Data of a Data Subject, the Party shall immediately notify the other. Each Party shall support and cooperate in the intervention of the Party in addressing the judicial order, governmental action, or legal obligation, or minimizing the scope of the disclosure.
						</li>
						<li>
							Each Party shall obtain the consent of Customers to collect and share their Customer Data  with the other Party, for purposes of the Solution, and each Party shall co-operate with the other Party with respect to any requests made by Customers in accordance with the provisions of the Data Protection Act, 2019.
						</li>
					</ul>

					<h3 className={styles.terms_subtitle_2}>
						Personal Data Breach Management and Reporting
					</h3>

					<ul className={styles.terms_list_alphabetic}>
						<li>
							If any Party suspects or becomes aware of any security breach involving Customer Data within its network, operating systems, software applications, data storage systems, media channels or other office procedures, the Party shall notify the other Party in writing within 24 hours from the occurrence of discovery of the breach and shall fully cooperate with the other Party, to investigate and remediate the breach, cooperate with any relevant regulatory authorities or law enforcement official, and assist with any required notification to Customers and/or the Office of the Data Protection Commissioner.
						</li>
						<li>
							Each Party shall defend, indemnify, and hold the other Party, its affiliates, and their respective officers, directors, stockholders, employees, and agents harmless from and against any and all claims, suits, causes of action, liability, loss, costs, and damages, including attorney’s fees and costs of litigation, in connection with or as a result of any third party claim arising from  the personal data breach of any Party.
						</li>
						<li>
							Where the personal data breach is material and substantial, and such will cause the other Party irreparable injury for which it would have no adequate remedy at law and for which there is an urgent and permanent necessity to prevent serious damage, the other Party shall be entitled to immediately seek an injunctive relief prohibiting any violation of this Agreement in addition to any other rights and remedies available to it.
						</li>
						<li>
							Immediately following the Party’s notification to the other of a Personal Data breach, the Parties shall coordinate with each other to investigate the said breach. Each Party agrees to fully cooperate with the other and provide a root cause analysis report within reasonable time as may be provided under the Data Protection Act, 2019 or the Office of the Data Protection Commissioner.
						</li>
						<li>
							Each Party shall immediately remedy any personal data breach and prevent any further personal data breach at the Party’s sole expense in accordance with the Data Protection Act, 2019. The Party accountable for the personal data breach shall also reimburse the other Party for actual costs incurred by the latter in responding to, and mitigating damages resulting from the personal data breach.
						</li>
						<li>
							Each Party agrees that it shall not inform any third party of any personal data breach affecting the other Party without first notifying the other Party and the other Party agreeing  to the disclosure. The Parties shall work together in drafting the contents of any such notice prior to submission to the relevant authorities.
						</li>
						<li>
							In the event of any personal data breach, the Party shall also put in place measures and safeguards to prevent a recurrence of any such personal data breach.
						</li>
					</ul>

				</section>

				<section>

					<h2 className={styles.terms_subtitle}>
						Confidentiality
					</h2>

					<ul className={styles.terms_list_alphabetic}>
						<li>
							The Client may/will throughout the term of this Agreement have access to confidential information of its tenants. Each party agrees that, as between the parties, each party’s or its Tenant’s confidential information is confidential and proprietary to that party or its Tenant.
						</li>
						<li>
							During the term of this Agreement and for a period of two (2) years after the expiration or earlier termination thereof, recipient will:
							<ul className={styles.terms_list_roman}>
								<li>
									Take such precautions (but not less that reasonable precautions) to protect the confidentiality of dicloser’s confidential information that the recipient takes to protect its own confidential information; and
								</li>
								<li>
									Not disclose any of discloser’s confidential information in any manner whatsoever to any third party without the prior written consent of an officer of disclosure other than those of its employees, agents, contractors and advisors who have a need to know such information to enable recipient to perform its respective obligations and are bound by the same confidentiality obligations.
								</li>
							</ul>
						</li>
						<li>
							Recipient will be responsible for any breach of these confidentiality obligations by any party to whom it discloses discloser’s confidential information, recipient will notify disclosure of such breach and recipient and disclosure may act directly against the contractor. Recipient will not make any use of discloser’s confidential information, in whole or in part for any other purpose than the performance of its obligations under this Agreement.
						</li>
						<li>
							Koddi will put in place adequate safeguards and will take all necessary measures to guarantee the security of the information systems, hard disks, back-up systems containing the Client data. Internal procedures will be put in place at Koddi to avoid that any non authorized person can intervene in the data.
						</li>
					</ul>

				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Disclosure
					</h2>
					<p className={styles.terms_text}>
						The Client hereby agrees and consents to the disclosure and release by Koddi of any information in the possession of Koddi relating to the Client, the particulars of the Transactions or any designated account relating to the Transactions for the purpose of investigating any claim or dispute arising out of this Agreement or in connection with the Transactions under the provision of the Services which consent shall survive both the suspension of rights hereunder and the termination of this Agreement.
					</p>
				</section>


			</section>

			<FormStart
				title={HOME_FORMSTART_TITLE}
			/>

		</PublicLayout>
	);
};

export default Privacy;
