import { createSelector } from 'reselect';
import _get from 'lodash/get';

const createLoadingSelector = (action) => createSelector(
  (state) => state.loading,
  (loading) => _get(loading, action, {
    loading: false,
    loaded: false,
    error: false,
  }),
);

export default createLoadingSelector;