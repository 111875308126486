import React from 'react';
import cn from 'classnames';
import { Card } from '../../../../components/Widgets';
import useWidgetMain from '../../../../hooks/useWidgetMain';
import useAuth from '../../../../hooks/useAuth';
import st from './styles.module.scss';

export default function InfoBlocks() {
  const { widgets } = useWidgetMain();

  const { profile } = useAuth();

  return (
    <div
      className={st.infoBlocks}
    >

      {widgets.map((item) => (
        <Card
          className={cn(
            st.card,
            {
              [st.cardTenat]: profile.is_tenant,
            },
          )}
          key={item.code}
          title={item.name}
          price={`${item.amount}KSh`}
          isTenant={profile.is_tenant}
        />
      ))}

    </div>
  );
}
