import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { TENANTS } from '../constants/type';
import { tenantIdsSelector, tenantsSelector } from '../services/selectors/tenants';
import createLoadingSelector from '../services/selectors/loading';

const selector = createSelector(
	tenantIdsSelector,
	createLoadingSelector(TENANTS.GET_LIST.base),
	tenantsSelector,
	(ids, loader, { count, page, limit }) => {

		return {
			ids,
			loader,
			page,
			count,
			limit,
		};
	},
);

const useTenants = () => {
	const data = useSelector(selector);

	return {
		...data,
	};
};

export default useTenants;