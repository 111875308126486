import { AUTH, INVOICES } from '../../constants/type';

export const defaultState = {
	ids: [],
	select: [],
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case INVOICES.GET_INVOICES_OPTIONS.SUCCESS:
			return {
				...state,
				select:  Object.keys(payload).map((key) => ({
					id: Number(key),
					name: payload[key],
				})),
			};

		case INVOICES.CLEAR_SELECT.base:
			return {
				...state,
				select: [],
			};

		case AUTH.SIGN_OUT.SUCCESS:
		case AUTH.GET_PROFILE.FAILURE: {
			return defaultState;
		}

		default:
			return state;
	}
};
