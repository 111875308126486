import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../../../assets/icons/angle-left.svg';
import PrivateLayout from '../../../../layouts/PrivateLayout';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import { TRANSACTIONS_ROUTE } from '../../../../constants/routes';
import { payInvoicesFlutterwave } from '../../../../services/actions/invoices';
import useSelectInvoices from '../../../../hooks/useSelectInvoices';
import translate from '../../../../utils/translate';
import history from '../../../../utils/history';
import styles from './../styles.module.scss';


const TransactionPayInvoiceLandlord = () => {
  const { id } = useParams();
  const idFormatted = useMemo(() => {
    if(!id) return;
    const lastCharIndex = id.indexOf("-") < 0 ? id.length : id.indexOf("-");
    return +id.substring(1, lastCharIndex);
  }, []);

  const { invoicesOptions: invoices } = useSelectInvoices();

  const invoicesOptions = useMemo(() => invoices.map(invoice => ({
      value: invoice.label.id,
      label: invoice.label.label,
    })),
    [invoices]);

  const initValues = {
    invoices: [idFormatted] || [],
  };

  const schema = Yup.object().shape({
    invoices: Yup.array()
      .required(),
  });

  const handleSubmit = useCallback((values, actions) => {
    const onSuccess = (data) => {
      window.open(data.url);
      history.push(TRANSACTIONS_ROUTE);
    };

    payInvoicesFlutterwave(values, { ...actions, onSuccess });
  }, []);

  return (
    <PrivateLayout>

      <div className={styles.transactionPayInvoice}>

        <div className={styles.heading}>

          <Button
            tag={Link}
            variant="goBack"
            to={TRANSACTIONS_ROUTE}
            isIcon
            svg={<LeftIcon />}
          />

          <div className={styles.title}>

            {translate('Transaction')}

          </div>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >

          <Form>

            <Label>

              {translate('Select Invoice')}

            </Label>

            <InputSelect
              id="invoices"
              name="invoices"
              options={invoicesOptions}
              isMulti
            />

            <div className={styles.footer}>

              <Button
                color="secondary"
                variant="shadow"
                className={styles.button}
              >

                {translate('Cancel')}

              </Button>

              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={styles.button}
              >

                {translate('Confirm')}

              </Button>

            </div>

          </Form>

        </Formik>

      </div>

    </PrivateLayout>
  )
};

export default TransactionPayInvoiceLandlord;
