import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';

const MenuItem = ({ className, to, children }) => {

	return (
		<NavLink
			className={cn(
				styles.menuItem,
				className,
			)}
			activeClassName={styles.active}
			to={to}
		>

			{children}

		</NavLink>
	);
};

MenuItem.defaultProps = {
	className: '',
	children: '',
};

MenuItem.propTypes = {
	className: PropTypes.string,
	to: PropTypes.string.isRequired,
	children: PropTypes.node,
};

export default MenuItem;