import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import userIcon from '../../../../../assets/images/user-t.svg';
import Avatar from '../../../../../components/Avatar';
import styles from './styles.module.scss';

const Message = ({ className, message: { id, content, datetime, user } }) => {
	const currentUserId = 1;

	return (
		<div
			className={cn(
				styles.message,
				{[styles.my]: user === currentUserId },
				{[styles.interlocutor]: user !== currentUserId },
				className,
			)}
		>

			<Avatar
				className={styles.avatar}
				src={userIcon}
				size="40"
			/>

			<div className={styles.wrap}>

				<p>

					{content}

				</p>

				<time>

					{moment(datetime * 1000).format('LT')}

				</time>

			</div>

		</div>
	);
};

Message.defaultProps = {
	className: '',
};

Message.propTypes = {
	className: PropTypes.string,
	message: PropTypes.shape({
		id: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]).isRequired,
		content: PropTypes.string.isRequired,
		datetime: PropTypes.number.isRequired,
		user: PropTypes.number.isRequired,
	}).isRequired
};

export default Message;