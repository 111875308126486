import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
	banksSelector,
	isAuthorizedSelector,
	paymentMethodsSelector,
	profileSelector
} from '../services/selectors/auth';
import createLoadingSelector from '../services/selectors/loading';
import { AUTH } from '../constants/type';
import {
	changePassword, confirmEmail, forgot, getProfile, removeProfile, resendEmail, resetPassword, updateProfile, signIn,
	signOut, signUp,
} from '../services/actions/auth';

const selector = createSelector(
	isAuthorizedSelector,
	profileSelector,
	paymentMethodsSelector,
	banksSelector,
	createLoadingSelector(AUTH.GET_PROFILE.base),
	(isAuthorized, profile, paymentMethods, banks, loader) => ({
		isAuthorized,
		profile,
		paymentMethods,
		banks,
		loader,
	}),
);

const useAuth = () => {
	const data = useSelector(selector);

	const onSignIn = signIn;
	const onSignUp = signUp;
	const onConfirmEmail = confirmEmail;
	const onForgot = forgot;
	const onResendEmail = resendEmail;
	const onResetPassword = resetPassword;
	const onSignOut = signOut;
	const onUpdateProfile = updateProfile;
	const onRemoveProfile = removeProfile;
	const onChangePassword = changePassword;
	const onGetProfile = getProfile;

	return {
		...data,
		onSignIn,
		onSignUp,
		onConfirmEmail,
		onForgot,
		onResendEmail,
		onResetPassword,
		onSignOut,
		onUpdateProfile,
		onRemoveProfile,
		onChangePassword,
		onGetProfile,
	};
};

export default useAuth;