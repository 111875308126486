import { createSelector } from 'reselect';

export const propertiesSelector = (state) => {
	return state.properties;
};

export const propertyIdSelector = (_, propertyId) => propertyId;

export const propertyIdsSelector = createSelector(
	propertiesSelector,
	(properties) => properties.ids,
);

export const propertiesSelectSelector = createSelector(
	propertiesSelector,
	(properties) => properties.select,
);
