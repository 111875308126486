import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import styles from './styles.module.scss';

const InputSearch = forwardRef(({ id, name, className, ...rest }, ref) => {
	return (
		<div
			className={cn(
				styles.inputSearch,
				className,
			)}
		>

			<SearchIcon />

			<input
				type="search"
				{...rest}
				ref={ref}
				id={id || name}
				name={name}
			/>

		</div>
	);
});

InputSearch.defaultProps = {
	id: '',
	className: '',
};

InputSearch.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default InputSearch;