import React, { useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../../assets/icons/angle-left.svg';
import PrivateLayout from '../../../layouts/PrivateLayout';
import Button from '../../../components/Button';
import GeneralInfo from './GeneralInfo';
import Amenities from './Amenities';
import Units from './Units';
import { PROPERTIES_ROUTE } from '../../../constants/routes';
import { createProperty, updateProperty } from '../../../services/actions/properties';
import useConfigurations from '../../../hooks/useConfigurations';
import useProperty from '../../../hooks/useProperty';
import translate from '../../../utils/translate';
import history from '../../../utils/history';
import styles from './styles.module.scss';

export default function PropertyCreate() {
  const { features } = useConfigurations();

  const params = useParams();

  const { property } = useProperty(Number(params.id));

  const defaultQuantity = useMemo(() => features.reduce((acc, feature) => {
    acc[feature.id] = {
      quantity: 1,
      id: '',
    };
    return acc;
  }, {}), [features]);

  const defaultQuantitySchema = useMemo(() => features.reduce((acc, feature) => {
    acc[feature.id] = Yup.object().shape({
      quantity: Yup.number().min(0).max(100),
      // id: Yup.number(),
    });
    return acc;
  }, {}), [features]);

  let initValues = {
    name: '',
    address: '',
    city: '',
    state: '',
    unit_type: false,
    amenities: [],
    cover: '',
    type: '',
    gallery: [],

    multi: [
      {
        name: '',
        type: '',
        rent: 0,
        deposit: 0,
        size: 0,
        features: {
          ...defaultQuantity,
        },
      },
    ],

    single: [
      {
        name: '',
        type: '',
        rent: 0,
        deposit: 0,
        size: 0,
        features: {
          ...defaultQuantity,
        },
      }
    ]
  };

  if(params.id && property) {
    if(property.name) {
      initValues.name = property.name;
    }

    if(property.address) {
      initValues.address = property.address;
    }

    if(property.city) {
      initValues.city = property.city;
    }

    if(property.state) {
      initValues.state = property.state;
    }

    if(property.amenities) {
      initValues.amenities = property.amenities;
    }

    if(property.type) {
      initValues.type = property.type;
    }

    initValues.unit_type = property.unit_type === 'multi';

    initValues[property.unit_type] = property.units.map((unit) => ({
      id: unit.id,
      name: unit.name,
      type: unit.type,
      rent: unit.rent,
      deposit: unit.deposit,
      size: unit.size,
      features: unit.features.reduce((acc, feature) => {
        /*{id: 1, quantity: 2, unit: 1, feature: 1}*/
        acc[feature.feature] = {
          quantity: feature.quantity,
          id: feature.id,
        };
        return acc;
      }, {}),
    }));

    if(property.cover) {
      initValues.cover = property.cover;
    }

    initValues.gallery = property.images;
  }

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(),
    address: Yup.string()
      .required(),
    city: Yup.string()
      .required(),
    state: Yup.string()
      .required(),
    type: Yup.string()
      .required(),
    unit_type: Yup.bool(),
    amenities: Yup.array()
      .required(),
    cover: Yup.mixed()
      .imageTypes()
      .fileMaxSize(),
    gallery: Yup.array().of(
      Yup.mixed()
        .imageTypes()
        .fileMaxSize()
    ),

    multi: Yup.array().when('unit_type', {
      is: true,
      then: Yup.array().of(Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        rent: Yup.number()
          .min(0)
          .required(),
        deposit: Yup.number()
          .min(0)
          .required(),
        size: Yup.number()
          .min(0)
          .required(),
        features: Yup.object().shape({
          ...defaultQuantitySchema,
        }),
      })),
      otherwise: Yup.array().strip(),
    }),

    single: Yup.array().when('unit_type', {
      is: true,
      then: Yup.array().strip(),
      otherwise: Yup.array().of(Yup.object().shape({
        name: Yup.string(),
        type: Yup.string().required(),
        rent: Yup.number()
          .min(0)
          .required(),
        deposit: Yup.number()
          .min(0)
          .required(),
        size: Yup.number()
          .min(0)
          .required(),
        features: Yup.object().shape({
          ...defaultQuantitySchema,
        }),
      })),
    }),
  });

  const toPropertiesPage = useCallback((id) => {
    history.push(typeof id !== 'object' ? `${PROPERTIES_ROUTE}/${id}` : PROPERTIES_ROUTE);
  }, []);

  const handleSubmit = useCallback((values, actions) => {
    const action = params.id
      ? updateProperty
      : createProperty;

    const data  = params.id
      ? {
        id: property.id,
        images: property.images,
        ...values,
      }
      : values;

    action(data, { ...actions, onSuccess: toPropertiesPage })
  }, [toPropertiesPage, params, property]);

  return (
    <PrivateLayout>

      <div className={styles.propertyCreate}>

        <div className={styles.heading}>

          <Button
            variant="goBack"
            onClick={toPropertiesPage}
            isIcon
            svg={<LeftIcon />}
          />

          <div className={styles.title}>

            {translate(params.id ? 'Edit Property' : 'Create new property')}

          </div>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >

          {({ isSubmitting }) => (
            <Form>

              <GeneralInfo />

              <Units />

              <Amenities />

              <div className={styles.footer}>

                <Button
                  color="primary"
                  onClick={toPropertiesPage}
                  className={styles.button}
                >

                  {translate('Cancel')}

                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  type="submit"
                  className={styles.button}
                >

                  {params.id ? translate('Save') : translate('Create')}

                </Button>

              </div>

            </Form>
          )}

        </Formik>

      </div>

    </PrivateLayout>
  );
};
