import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { useField } from 'formik';
import 'react-phone-input-2/lib/style.css';
import { InputNotice } from '../InputNotice';
import styles from './styles.module.scss';
import cn from 'classnames';

const DEFAULT_COUNTRY = 'ke';

const InputPhone = ({ id, name, onChange, disabled, country }) => {
	const [field = {}, meta = {}, helpers] = useField(name);

	const { error, touched } = meta;

	const handleChange = (phone) => {
		const setValue = () => {
			// helpers.setTouched(true);

			helpers.setValue(phone);
		};

		if (onChange) {
			onChange(phone, setValue);
		} else {
			setValue();
		}
	};

	const handleBlur = () => {
		helpers.setTouched(true);
	};

	return (
		<>

			<PhoneInput
				containerClass={cn(styles.phone, error && touched && styles.error)}
				value={field.value}
				onChange={handleChange}
				disabled={disabled}
				country={country}
				inputProps={{
					id: id || name,
					name,
					onBlur: handleBlur,
				}}
			/>

			{error && touched && (
				<InputNotice
					name={name}
				/>
			)}

		</>
	)
};

InputPhone.defaultProps = {
	onChange: null,
	disabled: false,
	country: DEFAULT_COUNTRY,
}

InputPhone.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	country: PropTypes.string,
};

export default InputPhone;

/*export const InputPhoneUC = () => {

	return (
		<>

			<PhoneInput

			/>

		</>
	)
};*/
