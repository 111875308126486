import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import Button from '../../../../../components/Button';
import { Link } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../../../../../constants/routes';
import translate from '../../../../../utils/translate';

const View = ({ className, image, title, description }) => {
	return (
		<div
			className={cn(
				styles.view,
				className
			)}
		>

			<div className={styles.wrap}>

				<figure className={styles.image}>

					<img src={image} alt="pic" />

				</figure>

			</div>

			<div className={styles.wrap}>

				<h3 className={styles.title}>

					{title}

				</h3>

				<p className={styles.description}>

					{description}

				</p>

				<Button
					tag={Link}
					to={SIGN_IN_ROUTE}
					color="secondary"
					variant="shadow"
					size="big"
					className={styles.button}
				>

					{translate('Get Started')}

				</Button>

			</div>

		</div>
	);
};

export default View;