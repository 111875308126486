import React from 'react';
import { InputText } from '../../../../components/Inputs/InputText';
import CardHeader from '../../../../components/Card/CardHeader';
import CardTitle from '../../../../components/Card/CardTitle';
import CardBody from '../../../../components/Card/CardBody';
import Label from '../../../../components/Label';
import Card from '../../../../components/Card';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function GeneralInfo({ isEdit }) {
  return (
    <Card className={styles.generalInfo}>

      <CardHeader>

        <CardTitle>

          {translate('General Info')}

        </CardTitle>

      </CardHeader>

      <CardBody>

        {!isEdit && (
          <>

            <Label>

              {translate('Email')}

            </Label>

            <InputText
              id="email"
              type="email"
              name="email"
              autoComplete="username email"
            />

          </>
        )}

        <Label>

          {translate('Address')}

        </Label>

        <InputText
          id="address"
          name="address"
        />

        <Label>

          {translate('City')}

        </Label>

        <InputText
          id="city"
          name="city"
        />


      </CardBody>

    </Card>
  );
}
