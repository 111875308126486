import { createSelector } from 'reselect';

export const widgetsSelector = (state) => state.widgets;

export const widgetMainSelector = createSelector(
	widgetsSelector,
	(widgets) => widgets.main,
);

export const widgetByTenantSelector = createSelector(
	widgetsSelector,
	(widgets) => widgets.byTenant,
);