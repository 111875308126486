import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import st from './styles.module.scss';

const Steps = ({ steps, active }) => {
  return (
    <div className={st.steps}>

      {steps.map((item, i) => (
        <div
          key={`${item}-${i}`}
          className={cx(st.step, { [st.active]: active - 1 === i }, { [st.prev]: active > i })}
        >

          {item}

        </div>
      ))}

    </div>
  );
};

export default React.memo(Steps);

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  active: PropTypes.number.isRequired,
}