import React from 'react';
import { InputText } from '../../../../components/Inputs/InputText';
import InputImage from '../../../../components/Inputs/InputImage';
import CardHeader from '../../../../components/Card/CardHeader';
import InputPhone from '../../../../components/Inputs/InputPhone';
import CardTitle from '../../../../components/Card/CardTitle';
import InputDate from '../../../../components/Inputs/InputDate';
import Label from '../../../../components/Label';
import Card from '../../../../components/Card';
import translate from '../../../../utils/translate';
import st from './styles.module.scss';
import CardBody from '../../../../components/Card/CardBody';

export default function PersonalInfo() {
  return (
    <Card className={st.personalInfo}>

      <CardHeader>

        <CardTitle>

          {translate('Personal Info')}

        </CardTitle>

      </CardHeader>

      <CardBody>

        <InputImage
          id="avatar"
          name="avatar"
        />

        <Label>

          {translate('First Name')}

        </Label>

        <InputText
          id="first_name"
          name="first_name"
        />

        <Label>

          {translate('Last Name')}

        </Label>

        <InputText
          id="last_name"
          name="last_name"
        />

        <Label>

          {translate('Phone')}

        </Label>

        <InputPhone
          id="phone"
          name="phone"
        />

        <Label>

          {translate('Date of Birth')}

        </Label>

        <InputDate
          id="birthday"
          name="birthday"
        />

      </CardBody>

    </Card>
  );
}
