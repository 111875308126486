import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import AuthLayout from '../../../layouts/AuthLayout';
import Loader from '../../../components/Loader';
import { SIGN_UP_ROUTE } from '../../../constants/routes';
import useAuth from '../../../hooks/useAuth';
import history from '../../../utils/history';

const ConfirmEmailPage = () => {
	const { search } = useLocation();

	const { onConfirmEmail } = useAuth();

	useEffect(() => {
		const urlParams = new URLSearchParams(search);

		const params = {
			user_id: urlParams.get('user_id'),
			timestamp: urlParams.get('timestamp'),
			signature: urlParams.get('signature'),
		};

		if (!(params.user_id && params.timestamp && params.signature)) {
			history.replace(SIGN_UP_ROUTE);
		} else {
			onConfirmEmail(params);
		}
	}, [search, onConfirmEmail]);

	return (
		<AuthLayout>

			<Loader />

		</AuthLayout>
	);
};

export default ConfirmEmailPage;