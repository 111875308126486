import moment from 'moment';
import { createAction } from 'redux-actions';
import schemas from '../schemas';
import { API_TENANTS_URL } from '../../constants/api';
import { TENANTS } from '../../constants/type';
import defaultErrorHandler from '../../utils/errorHandlers';
import requestWrapper from '../../utils/requestWrapper';
import store from '../../utils/store';

export const getTenants = async ({
	params = {},
	actions = {},
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: TENANTS.GET_LIST,
		url: API_TENANTS_URL,
		method: 'get',
		normalizeSchema: schemas.TENANTS_ARRAY,
		addHeadersToResponse: true,
		options: {
			params,
		},
		onSuccess,
	});
};

export const getTenant = async (id, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = () => {
	};

	await requestWrapper({
		action: TENANTS.GET_TENANT,
		url: `${API_TENANTS_URL}${id}/`,
		method: 'get',
		normalizeSchema: schemas.TENANTS,
		onSuccess,
		onFailure,
	});
};

export const clearSelectTenant = () => {
	store.dispatch(createAction(TENANTS.CLEAR_SELECT.base)());
};

export const getSelectTenants = async ({
	params = {},
	actions = {},
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: TENANTS.GET_TENANTS_OPTIONS,
		url: `${API_TENANTS_URL}listing/params/`,
		method: 'get',
		options: {
			params,
		},
		onSuccess,
	});
};

const getRequestData = (data) => {
	const requestData = new FormData();

	requestData.append('address', data.address);
	requestData.append('city', data.city);
	requestData.append('first_name', data.first_name);
	requestData.append('last_name', data.last_name);
	requestData.append('phone', data.phone ? `+${data.phone}` : data.phone);
	requestData.append('birthday', moment(data.datetime).format('YYYY-MM-DD'));
	requestData.append('identity_type', data.identity_type);
	requestData.append('identity_number', data.identity_number);
	requestData.append('identity_verify', data.identity_verify);

	return requestData;
}

export const createTenant = async (data, actions) => {
	const func = async (index, id, identity_id) => {

		if(index < data.documents.length) {
			const requestData = new FormData();

			requestData.append('file', data.documents[index]);
			requestData.append('tenant', id);

			await requestWrapper({
				action: TENANTS.ADD_TENANT_DOCUMENT,
				url: `${API_TENANTS_URL}${id}/identity-documents/${identity_id}/files/`,
				method: 'post',
				normalizeSchema: schemas.TENANTS,
				addDataToResponse: true,
				requestData,
				onSuccess: () => func(index + 1, id, identity_id),
				onFailure,
			});
		} else if (actions?.onSuccess) {
			await getTenant(id);

			actions.onSuccess(id);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	const requestData = getRequestData(data);

	requestData.append('email', data.email);
	requestData.append('avatar', data.avatar);


	await requestWrapper({
		action: TENANTS.CREATE_TENANT,
		url: API_TENANTS_URL,
		method: 'post',
		normalizeSchema: schemas.TENANTS,
		requestData,
		onSuccess: (response) => func(0, response.id, response.identity_id),
		onFailure,
	});
};

export const updateTenant = async (data, actions) => {
	const filesIds = data.documents.filter((file) => file.id).map((file) => file.id);

	const list = [
		...data.documents.filter((filesIds) => !filesIds.id),
		...data.identity_files.filter((file) => !filesIds.includes(file.id)),
	];

	const func = async (index) => {
		if(index < list.length) {
			const requestData = new FormData();
			requestData.append('tenant', data.id);

			if(list[index].id) {
				requestData.append('file', list[index].id);
				requestData.append('identity_id', data.identity_id);

				await requestWrapper({
					action: TENANTS.REMOVE_TENANT_DOCUMENT,
					url: `${API_TENANTS_URL}${data.id}/identity-documents/${data.identity_id}/files/${list[index].id}/`,
					method: 'delete',
					normalizeSchema: schemas.TENANTS,
					addDataToResponse: true,
					requestData,
					onSuccess: () => func(index + 1),
				});
			} else {
				requestData.append('file', list[index]);

				await requestWrapper({
					action: TENANTS.ADD_TENANT_DOCUMENT,
					url: `${API_TENANTS_URL}${data.id}/identity-documents/${data.identity_id}/files/`,
					method: 'post',
					normalizeSchema: schemas.TENANTS,
					addDataToResponse: true,
					requestData,
					onSuccess: () => func(index + 1),
				});
			}
		} else if (actions?.onSuccess) {
			await getTenant(data.id);

			actions.onSuccess(data.id);
		}
	};

	const requestData = getRequestData(data);

	/*requestData.append('email', data.email);*/

	if(typeof data.avatar === 'object') {
		requestData.append('avatar', data.avatar);
	}

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: TENANTS.UPDATE_TENANT,
		url: `${API_TENANTS_URL}${data.id}/`,
		method: 'patch',
		normalizeSchema: schemas.TENANTS,
		requestData,
		onSuccess: () => func(0),
		onFailure,
	});
};

export const sendInviteToTenant = async (data, actions) => {
	const requestData = new FormData();

	requestData.append('email', data.email);
	requestData.append('notes', data.notes);
	requestData.append('file', data.file);

	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: TENANTS.SEND_INVITE,
		url: `${API_TENANTS_URL}send_invite/`,
		method: 'post',
		normalizeSchema: schemas.TENANTS,
		requestData,
		onSuccess,
		onFailure,
	});
};

export const changeTenantsPage = (page) => {
	store.dispatch(createAction(TENANTS.CHANGE_PAGE.base)({
		page
	}));
}

export const changeTenantsFilter = (value, type) => {
	store.dispatch(createAction(TENANTS.CHANGE_FILTER.base)({
		value,
		type
	}));
};