import React, { useMemo } from 'react';
import PublicLayout from '../../../layouts/PublicLayout';
import usePublic from '../../../hooks/usePublic';
import FormStart from '../HomePage/FormStart';
import Benefits from './Benefits';
import Hero from './Hero';

const FeaturesPage = () => {
	const {
		FEATURES_TOP_TITLE, FEATURES_TOP_VIDEO_URL, FEATURES_BENEFITS_TITLE, FEATURES_FORMSTART_TITLE, ...rest
	} = usePublic();

	const benefits = useMemo(() => {
		const array = [];

		Array(6).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`FEATURES_BENEFITS_${id}_ICON`]
				|| rest[`FEATURES_BENEFITS_${id}_TITLE`]
				|| rest[`FEATURES_BENEFITS_${id}_DESCRIPTION`]
			) {
				array.push({
					id,
					icon: rest[`FEATURES_BENEFITS_${id}_ICON`].join(''),
					title: rest[`FEATURES_BENEFITS_${id}_TITLE`],
					description: rest[`FEATURES_BENEFITS_${id}_DESCRIPTION`],
				});
			}
		});

		return array;
	}, [rest]);

	return (
		<PublicLayout
			withFooter
		>

			<Hero
				title={FEATURES_TOP_TITLE}
				videoUrl={FEATURES_TOP_VIDEO_URL}
			/>

			<Benefits
				title={FEATURES_BENEFITS_TITLE}
				benefits={benefits}
			/>

			<FormStart
				title={FEATURES_FORMSTART_TITLE}
			/>

		</PublicLayout>
	);
};

export default FeaturesPage;