import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { CellSvg, ExternalLinkSvg } from '../../../../assets/icons';
import { TRANSACTION_CREATE_ROUTE, TRANSACTIONS_ROUTE } from '../../../../constants/routes';
import UncontrolledDropdown from '../../../../components/DropDown/UncontrolledDropdown';
import DropdownToggle from '../../../../components/DropDown/DropdownToggle';
import DropdownMenu from '../../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../../components/DropDown/DropDownItem';
import TableSimple from '../../../../components/TableSimple';
import CardHeader from '../../../../components/Card/CardHeader';
import CardTitle from '../../../../components/Card/CardTitle';
import CardBody from '../../../../components/Card/CardBody';
import Status from '../../../../components/Status';
import Tenant from '../../../../components/Tenant';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import useTransactionsByUnit from '../../../../hooks/useTransactionsByUnit';
import useConfigurations from '../../../../hooks/useConfigurations';
import useTransaction from '../../../../hooks/useTransaction';
import useTenant from '../../../../hooks/useTenant';
import translate from '../../../../utils/translate';
import history from '../../../../utils/history';
import st from './styles.module.scss';
import MobileRow from '../../../../components/MobileRow';

const TransactionRow = ({ transactionId, status_transaction }) => {
  const { transaction } = useTransaction(transactionId);

  const { tenant } = useTenant(transaction.tenant_id);

  const onEdit = useCallback(() => {
    history.push(`${TRANSACTION_CREATE_ROUTE}/${transactionId}`);
  }, [transactionId]);

  let title = tenant?.first_name ? `${tenant?.first_name} ${tenant?.last_name}` : tenant?.email;

  if(transaction.direction === 'money_out') {
    title = transaction.supplier;
  }

  return (
    <tr className={st.transaction}>
      <td>

        <Tenant
          title={title}
          src={transaction.direction === 'money_out' ? null : tenant?.avatar}
          content={transaction.invoice_id && transaction.address}
          link={!transaction.invoice_id ? `${TRANSACTIONS_ROUTE}/${transactionId}` : null}
          // content="visa *347"
        />

      </td>
      <td>

        <Status
          status={transaction.status}
        >

          {status_transaction.find((status) => status.id === transaction.status)?.name}

        </Status>

      </td>
      <td>{transaction.amount}KSh</td>
      <td>

        <UncontrolledDropdown>

          <DropdownToggle className={st.buttonDrop}>

            <Button
              isIcon
              className={st.buttonDrop}
            >

              <CellSvg />

            </Button>

          </DropdownToggle>

          <DropdownMenu
            position="right"
          >

            <DropDownItem
              toggle
              onClick={onEdit}
            >

              {translate('Edit')}

            </DropDownItem>

          </DropdownMenu>

        </UncontrolledDropdown>

      </td>
    </tr>
  );
};

const TransactionRowMobile = ({ transactionId, status_transaction }) => {
  const { transaction } = useTransaction(transactionId);

  const { tenant } = useTenant(transaction.tenant_id);

  const handleEdit = useCallback(() => {
    history.push(`${TRANSACTION_CREATE_ROUTE}/${transactionId}`);
  }, [transactionId]);

  let title = tenant?.first_name ? `${tenant?.first_name} ${tenant?.last_name}` : tenant?.email;

  if(transaction.direction === 'money_out') {
    title = transaction.supplier;
  }

  return (
    <MobileRow
      src={transaction.direction === 'money_out' ? null : tenant?.avatar}
      status={transaction.status}
      statusName={status_transaction.find((status) => status.id === transaction.status)?.name}
      title={title}
      content={transaction.invoice_id && transaction.address}
      link={!transaction.invoice_id ? `${TRANSACTIONS_ROUTE}/${transactionId}` : null}
      bordered
      buttons={[
        {
          title: translate('Edit'),
          toggle: true,
          onClick: handleEdit,
        }
      ]}
      list={[
        {
          title: translate('Amount'),
          value: `${transaction.amount}KSh`,
        },
        ]}
      />
  )
};

export default function PaymentHistory() {
  const { unit_id } = useParams();

  const { status_transaction } = useConfigurations();

  const { ids } = useTransactionsByUnit(Number(unit_id));

  return (
    <Card className={st.card}>

      <CardHeader>

        <CardTitle className={st.title}>

          {translate('Payment history')}

          <Link
            to={TRANSACTIONS_ROUTE}
            className={st.link}
          >

            <ExternalLinkSvg />

          </Link>

        </CardTitle>

      </CardHeader>

      <CardBody className={st.body}>

        <TableSimple
          className={st.table}
        >

          <thead>

          <tr>
            <th>{translate('Tenant')}</th>
            <th>{translate('Status')}</th>
            <th>{translate('Amount')}</th>
            <th/>
          </tr>

          </thead>

          <tbody>

          {ids?.length > 0 && ids.map((transactionId) => (
            <TransactionRow
              key={transactionId}
              transactionId={transactionId}
              status_transaction={status_transaction}
            />
          ))}

          </tbody>

        </TableSimple>

      </CardBody>

      <div>

        {ids?.length > 0 && ids.map((transactionId) => (
          <TransactionRowMobile
            key={transactionId}
            transactionId={transactionId}
            status_transaction={status_transaction}
          />
        ))}

      </div>

    </Card>
  );
}
