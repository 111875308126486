import { API_TENANTS_URL, API_WIDGETS_URL } from '../../constants/api';
import { WIDGETS } from '../../constants/type';
import requestWrapper from '../../utils/requestWrapper';

export const getWidgetMain = async () => {
	await requestWrapper({
		action: WIDGETS.GET_MAIN,
		url: API_WIDGETS_URL,
		method: 'get',
	});
};

export const getWidgetByTenant = async (tenantId) => {
	await requestWrapper({
		action: WIDGETS.GET_BY_TENANT,
		url: `${API_TENANTS_URL}${tenantId}/${API_WIDGETS_URL}`,
		requestData: {
			tenantId
		},
		addDataToResponse: true,
		method: 'get',
	});
};