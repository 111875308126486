import React from 'react';
import { useParams } from 'react-router';
import useLeasesByTenant from '../../../../hooks/useLeasesByTenant';
import { CellSvg } from '../../../../assets/icons';
import Card from '../../../../components/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import Status from '../../../../components/Status';
import DropdownToggle from '../../../../components/DropDown/DropdownToggle';
import Button from '../../../../components/Button';
import DropdownMenu from '../../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../../components/DropDown/DropDownItem';
import UncontrolledDropdown from '../../../../components/DropDown/UncontrolledDropdown';
import translate from '../../../../utils/translate';
import history from '../../../../utils/history';
import styles from '../styles.module.scss';
import Lease from '../../../../components/Lease';
import CardBody from '../../../../components/Card/CardBody';
import useLease from '../../../../hooks/useLease';

const LeaseRow = ({ leaseId }) => {
	const { lease } = useLease(leaseId);

	const onView = () => {
		history.push(`${history.location.pathname}/${leaseId}`);
	};

	return (
		<Card className={styles.card}>

			<CardHeader
				className={styles.leasesCardHeader}
			>

				<div className={styles.leasesHeader}>

					<h3>{translate('Lease')} #{leaseId}</h3>

					<Status
						status={lease?.status}
					/>

				</div>


				<UncontrolledDropdown>

					<DropdownToggle className={styles.buttonDrop}>

						<Button
							isIcon
							className={styles.buttonDrop}
						>

							<CellSvg />

						</Button>

					</DropdownToggle>

					<DropdownMenu
						position="right"
					>

						<DropDownItem
							toggle
							onClick={onView}
						>

							{translate('View')}

						</DropDownItem>

					</DropdownMenu>

				</UncontrolledDropdown>

			</CardHeader>

			<CardBody>

				<Lease
					leaseId={leaseId}
					noTitle
				/>

			</CardBody>

		</Card>
	);
};

const Leases = () => {
	const { tenantId } = useParams();

	const { ids } = useLeasesByTenant(Number(tenantId));

	return (
		<>

			<div className={styles.wrapTitle}>

				<h2 className={styles.title}>

					{translate('Leases')}

				</h2>

			</div>

			{ids?.length > 0 && ids.map((id) => (
				<LeaseRow
					key={id}
					leaseId={id}
				/>
			))}

		</>
	);
};

export default Leases;