import React from 'react';
import FormStart from '../HomePage/FormStart';
import PublicLayout from '../../../layouts/PublicLayout';
import usePublic from '../../../hooks/usePublic';
import styles from './styles.module.scss';

const Terms = () => {
	const {
		HOME_FORMSTART_TITLE
	} = usePublic();

	return (
		<PublicLayout
			withFooter
		>

			<section className={styles.terms}>

				<h1 className={styles.terms_title}>
					Terms and Conditions
				</h1>

				<h3 className={styles.terms_title_additional}>
					Please read these terms and conditions carefully before using Our Service.
				</h3>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Interpretation and Definitions
					</h2>
					<h3 className={styles.terms_subtitle_2}>
						Interpretation
					</h3>
					<p className={styles.terms_text}>
						The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
					</p>
					<h3 className={styles.terms_subtitle_2}>
						Definitions
					</h3>
					<p className={styles.terms_text}>
						For the purposes of these Terms and Conditions:
					</p>
					<ul className={styles.terms_list}>
						<li>
							<strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
						</li>
						<li>
							<strong>Application</strong> refers to Koddi, accessible from www.koddi.co.ke
						</li>
						<li>
							<strong>Authorized users</strong> the Client is responsible for authorized users’ use of the Service and their compliance with these Terms.
						</li>
						<li>
							<strong>Country</strong> refers to: Kenya.
						</li>
						<li>
							<strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Koddi, Unipen Building, Argwings Kodhek Road, Nairobi, Kenya.
						</li>
						<li>
							<strong>Device</strong> means any device that can access the Service such as a computer, a cell phone or a digital tablet.
						</li>
						<li>
							<strong>Service</strong> refers to the Application.
						</li>
						<li>
							<strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
						</li>
						<li>
							<strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
						</li>
						<li>
							<strong>Subscription Fees</strong> refers to the monthly amount of money pay by the client to use the Service.
						</li>
						<li>
							<strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the  Service, as applicable.
						</li>
					</ul>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Acknowledgment
					</h2>
					<p className={styles.terms_text}>
						Concept Spaces Limited is a company duly incorporated under the laws of the Republic of Kenya under which the Application “Koddi” is operating.
					</p>
					<p className={styles.terms_text}>
						These Terms of Service govern access to and use of the Services made available by Koddi. By using our Services, you agree to be bound by these Terms. If you use our Services on behalf of an entity or organization, you are binding that organization to these Terms, and you represent that you have the authority to do so.
					</p>
					<p className={styles.terms_text}>
						During the subscription Term, Clients may access and use, and may allow authorized users to access and use the Services for its business purposes in accordance with these Terms.
					</p>
					<p className={styles.terms_text}>
						Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Undertakings of Koddi
					</h2>
					<p className={styles.terms_text}>
						Koddi agrees and undertakes that it shall:
					</p>
					<ul className={styles.terms_list_alphabetic}>
						<li>
							use its best endeavors to provide to the Client the Services for which the Client enrolls and pays the applicable fees; and
						</li>
						<li>
							provide training on the use and operation of the software for the Services to Client’s personnel on such terms as the parties shall agree.
						</li>
					</ul>
					<p className={styles.terms_text}>
						Koddi makes no representations or warranties of any kind with respect to the systems operated by Koddi or any software provided, or any part thereof, expressed or implied, and shall not be liable to the Client for any loss or damage howsoever caused and regardless of the form of loss or damage which may be suffered or incurred by the Client or any third party in connection with this Agreement including any loss of profit in consequence of a breakdown in providing the Services or part thereof.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Payments
					</h2>
					<p className={styles.terms_text}>
						All transactions between Koddi’s users shall be made in either Kenyan Shillings (KES) or United States Dollar (USD) via Koddi’s software.
					</p>
					<p className={styles.terms_text}>
						All transactions made via the Application will be done through third party payment gateways including Safaricom’s Mpesa The payment gateways charge a fee for each transaction. These transaction costs will be borne by the Landlord, Tenant or both parties.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Subscription payments
					</h2>
					<p className={styles.terms_text}>
						Payment for subscription must be made to Koddi in Kenyan Shilling (KES) paid either by credit or debit card, or via mobile money.
					</p>
					<p className={styles.terms_text}>
						The Client shall be liable to make payments due to Koddi arising from this Agreement in accordance with the provisions herein:
					</p>
					<ul className={styles.terms_list_alphabetic}>
						<li>
							In consideration of using Koddi’s Services, the Client shall pay Koddi the applicable Subscription Fees (the “Subscription Fees”) and other charges at such time as may from time to time be stipulated by Koddi as set forth in the Application Form or as otherwise provided by Koddi in accordance with the terms of this Agreement.
						</li>
						<li>
							Unless otherwise specified herein, the Services are for a one-year period and renewable automatically thereafter for successive one-year periods. Any renewal of Koddi’s Services is subject to Koddi’s then-current terms and conditions, including, but not limited to, payment of all applicable fees at the time of renewal.
						</li>
						<li>
							Koddi may revise its applicable fees and other charges from time to time and will notify the Client in writing of the date (“Effective Date”) when the new fees or charges become payable. The Client shall confirm in writing to Koddi its acceptance or non-acceptance of the new fees or charges before the Effective Date, the Client shall be deemed to have accepted the new fees and charges and shall be bound to pay the new fees and charges from the Effective Date. If the Client notifies Koddi in writing before the Effective Date that it does not accept the new fees or charges, this Agreement shall be deemed terminated on the Effective Date but without prejudice to the antecedent rights or liabilities of the parties hereto.
						</li>
						<li>
							Prompt payment of Koddi’s fees and other charges shall be the essence of this Agreement.
						</li>
						<li>
							All fees and charges paid by the Client under this Agreement shall not be refundable in the event of termination of this Agreement howsoever caused.
						</li>
						<li>
							If the monthly payment is not received by Koddi’s on the third day of the following month, the access to the Client’s account will be disconnected until the payment is fully made.
						</li>
						<li>
							In case of disconnection to the account for non-payment, the account will be retained by Koddi for a period of six (6) months from the date of disconnection.
						</li>
					</ul>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Links to Other Websites
					</h2>
					<p className={styles.terms_text}>
						Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
					</p>
					<p className={styles.terms_text}>
						The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
					</p>
					<p className={styles.terms_text}>
						We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						User Restrictions
					</h2>
					<p className={styles.terms_text}>
						You may not:
					</p>
					<ul className={styles.terms_list_alphabetic}>
						<li>
							modify, disassemble, decompile or reverse engineer the Application;
						</li>
						<li>
							rent, lease, loan, resell, sublicense, distribute or otherwise transfer the Application to any third party or use the Application to provide time sharing or similar services for any third party;
						</li>
						<li>
							make any copies of the Application;
						</li>
						<li>
							remove, circumvent, disable, damage or otherwise interfere with security-related features of the Application, features that prevent or restrict use or copying of any content accessible through the Application, or features that enforce limitations on use of the Application; or
						</li>
						<li>
							delete the copyright and other proprietary rights notices on the Application.
						</li>
					</ul>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Force majeure
					</h2>
					<ul className={styles.terms_list_alphabetic}>
						<li>
							Neither party shall be in breach of this Agreement if and in the event there is any total failure of performance of its duties and obligations under this Agreement occasioned by any act of God, fire, act of Government, war, civil disturbance, order of public authority, natural catastrophe or any other disaster.
						</li>
						<li>
							The operation of this Agreement shall accordingly be suspended during that period of the total or partial failure by one or both of the parties hereto to perform any of their obligations hereunder.
						</li>
						<li>
							If and when such event ceases to exist, the party, which had been under such disability, shall inform the other party in writing of the cessation of such event or occurrence and suspension of this Agreement shall be thereupon lifted.
						</li>
						<li>
							If however the Agreement shall remain suspended for a period of ninety (90) days consequent upon force majeure, it shall automatically stand absolutely terminated as against all the parties hereto.
						</li>
					</ul>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Termination
					</h2>
					<p className={styles.terms_text}>
						This Agreement shall commence from the Effective Date and shall continue for a period of twelve (12) months unless any Party terminates the Agreement in accordance with this Agreement.
					</p>
					<p className={styles.terms_text}>
						Upon expiry of the Initial Term, this Agreement shall automatically renew for successive one (1) year periods until terminated in accordance with this Agreement.
					</p>
					<p className={styles.terms_text}>
						Party to this Agreement may elect to terminate this Agreement by giving one (1) month’s prior notice in writing to the other party of its intention to do so.
					</p>
					<p className={styles.terms_text}>
						Koddi shall have the right to terminate this Agreement anytime in writing to the Client if any of the following events of default occur:
					</p>
					<ul className={styles.terms_list_dash}>
						<li>
							In the sole opinion of Koddi, the Client has breached any of the terms and conditions of this Agreement;
						</li>
						<li>
							If the Client enters into liquidation, receivership, judicial management or otherwise compounds with its creditors or takes or suffers any similar action or occurrence in any jurisdiction;
						</li>
						<li>
							If the Client becomes insolvent or stops payment or ceases or threatens to cease to carry on its business or any part of its business;
						</li>
						<li>
							If the Client is deceased or its partnership dissolved;
						</li>
						<li>
							If the Client or any of its shareholders, partners, proprietors, employees or tenants is suspected by Koddi to be involved in any fraudulent or unlawful activity whether or not relating to the Client’s business.
						</li>
					</ul>
					<p className={styles.terms_text}>
						In the event of termination of this Agreement for whatever reason, Koddi will delete the entire Client’s file immediately.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Limitation of Liability
					</h2>
					<p className={styles.terms_text}>
						To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						"AS IS" and "AS AVAILABLE" Disclaimer
					</h2>
					<p className={styles.terms_text}>
						The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
					</p>
					<p className={styles.terms_text}>
						Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Governing Law
					</h2>
					<p className={styles.terms_text}>
						The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Disputes Resolution
					</h2>
					<p className={styles.terms_text}>
						If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
					</p>
					<p className={styles.terms_text}>
						Moreover, Koddi shall not be involved in any dispute or claim that may arise between the Client and its tenants, unless the said dispute or claim relates specifically to the use of the Services in which case the same shall be subject to the relevant terms and conditions governing the use of the Services prevailing from time to time and the dispute or claim was notified to Koddi within fourteen (14) days of occurrence.
					</p>
					<p className={styles.terms_text}>
						The Client agrees that where multiple or excess payments have been effected by or on behalf of the Tenant in respect of any Transaction, whether through the Services or through any other existing system of payment, the Client shall refund or reimburse such Tenant for such excess payments made and shall keep Koddi harmless from and indemnified against any claim related to such excess payments.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Severability and Waiver
					</h2>
					<h3 className={styles.terms_subtitle_2}>
						Severability
					</h3>
					<p className={styles.terms_text}>
						If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
					</p>
					<h3 className={styles.terms_subtitle_2}>
						Waiver
					</h3>
					<p className={styles.terms_text}>
						Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Privacy Policy
					</h2>
					<p className={styles.terms_text}>
						Koddi has a Privacy Policy that you should refer to in order to fully understand how we use and collect information. You consent to the collection, use, sharing and transfer of your personally identifiable information as outlined at <a href={"https://www.koddi.co.ke/privacy/"}>https://www.koddi.co.ke/privacy/</a>.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Assignment
					</h2>
					<p className={styles.terms_text}>
						This Agreement and any rights and licenses granted hereunder, may not be transferred or assigned by You. Koddi may at any time, for any reason and without restriction, transfer or assign this Agreement and the obligations contained in this Agreement to a third party. You hereby acknowledge and agree that if another company acquires Koddi or substantially all of our assets (by sale, merger, or otherwise), that transaction may include a sale or transfer of your Personal Information as defined by our Privacy Policy and you agree to such transfer without further action or confirmation.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Translation Interpretation
					</h2>
					<p className={styles.terms_text}>
						These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Changes to These Terms and Conditions
					</h2>
					<p className={styles.terms_text}>
						We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
					</p>
					<p className={styles.terms_text}>
						By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
					</p>
				</section>

				<section className={styles.terms_section_wrapper}>
					<h2 className={styles.terms_subtitle}>
						Contact Us
					</h2>
					<p className={styles.terms_text}>
						If you have any questions about these Terms and Conditions, You can contact us:
					</p>
					<ul className={styles.terms_list}>
						<li>
							By email: info@koddi.co.ke
						</li>
						<li>
							By visiting this page on our website: <a href={"https://www.koddi.co.ke/contacts"}>www.koddi.co.ke/contacts</a>
						</li>
						<li>
							By phone number: (254) 733-509345
						</li>
					</ul>
				</section>

			</section>



			<FormStart
				title={HOME_FORMSTART_TITLE}
			/>

		</PublicLayout>
	);
};

export default Terms;
