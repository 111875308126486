import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { TRANSACTIONS } from '../constants/type';
import { transactionIdsSelector, transactionsSelector } from '../services/selectors/transactions';
import createLoadingSelector from '../services/selectors/loading';

const selector = createSelector(
	transactionIdsSelector,
	createLoadingSelector(TRANSACTIONS.GET_LIST.base),
	transactionsSelector,
	(ids, loader, { page, limit, count }) => {

		return {
			ids,
			loader,
			page,
			limit,
			count,
		};
	},
);

const useTransactions = () => {
	const data = useSelector(selector);

	return {
		...data,
	};
};

export default useTransactions;