import * as Yup from 'yup';
import formatBytes from './formatBytes';

Yup.addMethod(Yup.mixed, 'imageTypes', function (types = ['png', 'jpg', 'jpeg'], message = 'unsupported file format') {
  return this.test(function (value) {
    const { path, createError } = this;

    if (typeof value !== 'object' || value.id) return true;

    return types.some((type) => value.type.includes(type)) || createError({ path, message });
  });
});

Yup.addMethod(Yup.mixed, 'fileMaxSize', function (size = 1048576 * 3, message = 'max file size') {
  return this.test(function (value) {
    const { path, createError } = this;

    if (typeof value !== 'object' || value.id) return true;

    const errorMessage = `${message} ${formatBytes(size)}`;

    return value.size <= size || createError({ path, message: errorMessage });
  });
});


Object.defineProperty(Array.prototype, 'chunk', {
  value: function(chunkSize) {
    const R = [];
    for (let i = 0; i < this.length; i += chunkSize)
      R.push(this.slice(i, i + chunkSize));
    return R;
  }
});