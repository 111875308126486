import schemas from '../schemas';
import { API_LANDLORDS_URL } from '../../constants/api';
import { LANDLORDS } from '../../constants/type';
import defaultErrorHandler from '../../utils/errorHandlers';
import requestWrapper from '../../utils/requestWrapper';

export const getLandlords = async () => {
	await requestWrapper({
		action: LANDLORDS.GET_LIST,
		url: API_LANDLORDS_URL,
		method: 'get',
		normalizeSchema: schemas.LANDLORDS_ARRAY,
	});
};

export const getLandlord = async (id, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LANDLORDS.GET_LANDLORD,
		url: `${API_LANDLORDS_URL}${id}/`,
		method: 'get',
		normalizeSchema: schemas.LANDLORDS,
		onSuccess,
		onFailure,
	});
};