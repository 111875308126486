import moment from 'moment';
import { createAction } from 'redux-actions';
import schemas from '../schemas';
import { API_LEASES_URL, API_PROPERTIES_URL, API_TENANTS_URL, API_UNITS_URL } from '../../constants/api';
import { LEASES } from '../../constants/type';
import defaultErrorHandler from '../../utils/errorHandlers';
import requestWrapper from '../../utils/requestWrapper';
import store from '../../utils/store';

import { getUnit } from './units';
import { getTenant } from './tenants';
import { getProperty } from './properties';

export const getLeases = async () => {
	await requestWrapper({
		action: LEASES.GET_LIST,
		url: API_LEASES_URL,
		method: 'get',
		normalizeSchema: schemas.LEASES_ARRAY,
	});
};

export const getLease = async (id, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LEASES.GET_LEASE,
		url: `${API_LEASES_URL}${id}/`,
		method: 'get',
		normalizeSchema: schemas.LEASES,
		onSuccess,
		onFailure,
	});
};

export const clearSelectLease = () => {
	store.dispatch(createAction(LEASES.CLEAR_SELECT.base)());
};

export const getSelectLeases = async ({
  params = {},
	actions = {},
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: LEASES.GET_LEASES_OPTIONS,
		url: `${API_LEASES_URL}listing/invoices/`,
		method: 'get',
		options: {
			params,
		},
		onSuccess,
	});
};

export const getLeasesByTenant = async (id, actions) => {
	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LEASES.GET_LEASES_BY_TENANT,
		url: `${API_TENANTS_URL}${id}/${API_LEASES_URL}`,
		method: 'get',
		normalizeSchema: schemas.LEASES_ARRAY,
		requestData: { tenant: id },
		addDataToResponse: true,
		onFailure,
	});
};

export const getLeasesByProperty = async (id, actions) => {
	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LEASES.GET_LEASES_BY_PROPERTY,
		url: `${API_PROPERTIES_URL}${id}/${API_LEASES_URL}`,
		method: 'get',
		normalizeSchema: schemas.LEASES_ARRAY,
		requestData: { property: id },
		addDataToResponse: true,
		onFailure,
	});
};

export const getLeasesByUnit = async (id, actions) => {
	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LEASES.GET_LEASES_BY_UNIT,
		url: `${API_UNITS_URL}${id}/${API_LEASES_URL}`,
		method: 'get',
		normalizeSchema: schemas.LEASES_ARRAY,
		requestData: { unit: id },
		addDataToResponse: true,
		onFailure,
	});
};

const getRequestDataLease = (data) => {
	const requestData = new FormData();

	requestData.append('unit_id', data.unit);

	requestData.append('tenant_id', data.tenant);

	requestData.append('co_tenants',  JSON.stringify(data.cotenants));

	requestData.append('amount', data.amount);
	requestData.append('frequency', data.frequency);
	requestData.append('date_start', moment(data.date_start).format('YYYY-MM-DD'));
	requestData.append('date_end', moment(data.date_end).format('YYYY-MM-DD'));
	requestData.append('deposit', data.deposit);

	requestData.append('agreement', data.agreement);

	if(typeof data.document === 'object') {
		requestData.append('document', data.document);
	}

	return requestData;
}

export const createLease = async (data, actions) => {
	const onSuccess = async (response) => {
		if (actions?.onSuccess) {
			await getUnit(response.unit_id);
			await getTenant(response.tenant_id);
			await getProperty(response.property_id);

			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LEASES.GET_LEASE,
		url: API_LEASES_URL,
		method: 'post',
		normalizeSchema: schemas.LEASES,
		requestData: getRequestDataLease(data),
		onSuccess,
		onFailure,
	});
};

export const updateLease = async (data, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LEASES.GET_LEASE,
		url: `${API_LEASES_URL}${data.id}/`,
		method: 'patch',
		normalizeSchema: schemas.LEASES,
		requestData: getRequestDataLease(data),
		onSuccess,
		onFailure,
	});
};

export const terminateLease = async (data, actions) => {
	const requestData = new FormData();

	requestData.append('date', moment(data.date).format('YYYY-MM-DD'));
	requestData.append('reason', data.reason);
	requestData.append('file', data.document);

	const onSuccess = async () => {
		await getUnit(data.unitId);

		await getLease(data.leaseId);

		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: LEASES.GET_LEASE,
		url: `${API_UNITS_URL}${data.unitId}/${API_LEASES_URL}${data.leaseId}/terminate/`,
		method: 'post',
		normalizeSchema: schemas.LEASES,
		requestData,
		onSuccess,
		onFailure,
	});
};