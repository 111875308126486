import React from 'react';
import PerfectScroll from 'react-perfect-scrollbar';
import styles from './styles.module.scss';

const Companies = ({ companies }) => {
	return (
		<section
			className={styles.companies}
		>

			<PerfectScroll
				options={{
					suppressScrollY: true,
				}}
				className={styles.wrap}
			>

				{companies.map(({ id, icon }) => (
					<div
						key={id}
						className={styles.company}
						dangerouslySetInnerHTML={{__html: icon}}
					/>
				))}

			</PerfectScroll>

		</section>
	);
};

export default Companies;