import { PUBLIC } from '../../constants/type';

export const defaultState = {};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  if (type === PUBLIC.GET_TEXTS.SUCCESS) {
    const objectConstants = Object.entries(payload).reduce((acc, [id, item]) => {

      if(id !== 'headers') {
        const arrItem = Object.entries(item)[0];

        acc[arrItem[0]] = arrItem[1];
        // acc[item.key] = item.val;
      }
      return acc;
    }, {});

    return {
      ...state,
      ...objectConstants,
    };
  }

  return state;
};
