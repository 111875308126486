import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CHANGE_EMAIL_ROUTE, CHANGE_PASSWORD_ROUTE, RECOVER_PASSWORD_ROUTE } from '../../../../constants/routes';
import PrivateLayout from '../../../../layouts/PrivateLayout';
import Sidebar from '../../../../components/Sidebar';
import Button from '../../../../components/Button';
import links from '../links';
import useAuth from '../../../../hooks/useAuth';
import translate from '../../../../utils/translate';
import styles from '../styles.module.scss';
import styles_security from './styles.module.scss';

const Security = () => {
	const { profile } = useAuth();

	return (
		<PrivateLayout>

			<div className={styles.profile_container}>

				<Sidebar
					className={styles.sidebar}
					links={links(profile.is_landlord)}
				/>

				<div className={styles_security.container}>

					<h2 className={cn(styles.title, styles_security.title)}>

						{translate('Security')}

					</h2>

					<div className={styles_security.content}>

						<div className={styles_security.row}>

							<div className={styles_security.description}>

								<h4>
									{translate('Change Email')}
								</h4>

								<p>
									{translate('Your personal cloud office for all your rental information. In this news')}
								</p>

							</div>

							<div className={styles_security.btns}>

								<Button
									tag={Link}
									color="secondary"
									variant="shadow"
									to={CHANGE_EMAIL_ROUTE}
								>

									{translate('Change Email')}

								</Button>

							</div>
						</div>

						<div className={styles_security.row}>

							<div className={styles_security.description}>

								<h4>
									{translate('Change & recover password')}
								</h4>

								<p>
									{translate('Your personal cloud office for all your rental information. In this news')}
								</p>

							</div>

							<div className={styles_security.btns}>

								<Button
									tag={Link}
									color="secondary"
									variant="shadow"
									to={CHANGE_PASSWORD_ROUTE}
								>

									{translate('Change Password')}

								</Button>

								<Button
									tag={Link}
									color="primary"
									to={RECOVER_PASSWORD_ROUTE}
								>

									{translate('Recover Password')}

								</Button>

							</div>

						</div>

					</div>

				</div>

			</div>

		</PrivateLayout>
	);
};

export default Security;