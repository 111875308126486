import React from 'react';
import PrivateLayout from '../../../layouts/PrivateLayout';
import Tenants from './Tenants';
import styles from './styles.module.scss';

import user from '../../../assets/images/user-t.svg';
import Dialog from './Dialog';

const mock = [
	{
		id: '1',
		title: 'Cody Fisher',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 1,
		avatar: user,
	},
	{
		id: '2',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '3',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '4',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '5',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '6',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '7',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '8',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '9',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
	{
		id: '10',
		title: 'Brooklyn Simmons',
		content: 'Move in your tenant to the property and start collecting rent...',
		time: 1608306750,
		count: 0,
		avatar: user,
	},
];

const Messages = () => {
	return (
		<PrivateLayout>

			<section className={styles.messages}>

				<Tenants
					users={mock}
				/>

				<Dialog />

			</section>

		</PrivateLayout>
	);
};


export default Messages;