import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import Status from '../Status';
import Tenant from '../Tenant';
import useConfigurations from '../../hooks/useConfigurations';
import useTenant from '../../hooks/useTenant';
import useLease from '../../hooks/useLease';
import translate from '../../utils/translate';
import styles from './styles.module.scss';

const Lease = ({ className, leaseId, noTitle }) => {
  const { lease } = useLease(leaseId);

  const { tenant } = useTenant(lease?.tenant_id);

  const { frequencies } = useConfigurations();

  if(!tenant) return null;

  return (
    <div
      className={cn(
        styles.lease,
        className,
      )}
    >

      {!noTitle && (
        <div className={styles.heading}>

          {`Lease #${lease.id}`}

          <Status
            status={lease.status}
            className={styles.status}
          />

        </div>
      )}

      <table className={styles.desktop}>

        <tbody>

        <tr>

          <td>

            <h4>{translate('Tenant')}</h4>

            <Tenant
              src={tenant.avatar}
              title={[tenant.first_name, tenant.last_name].join(' ')}
            />

          </td>

          <td>

            <h4>{translate('Start Date')}</h4>

            <p>{moment(lease.date_start).format('DD MMMM, YYYY')}</p>

          </td>

          <td>

            <h4>{translate('End Date')}</h4>

            <p>{moment(lease.date_end).format('DD MMMM, YYYY')}</p>

          </td>

        </tr>

        <tr>

          <td>

            <h4>{translate('Annual Rental Amount')}</h4>

            <p>{lease.amount}KSh</p>

          </td>

          <td>

            <h4>{translate('Deposite')}</h4>

            <p>{lease.deposit}KSh</p>

          </td>

          <td>

            <h4>{translate('Rent Frequency')}</h4>

            <p>{frequencies.find((frequency) => frequency.id === lease.frequency)?.name}</p>

          </td>

        </tr>

        </tbody>

      </table>

      <table className={styles.mobile}>

        <tbody>

        <tr>

          <td>

            <h4>{translate('Tenant')}</h4>

            <Tenant
              src={tenant.avatar}
              title={[tenant.first_name, tenant.last_name].join(' ')}
            />

          </td>

          <td>

            <h4>{translate('Start Date')}</h4>

            <p>{moment(lease.date_start).format('DD MMMM, YYYY')}</p>

          </td>

        </tr>

        <tr>

          <td>

            <h4>{translate('End Date')}</h4>

            <p>{moment(lease.date_end).format('DD MMMM, YYYY')}</p>

          </td>

          <td>

            <h4>{translate('Annual Rental Amount')}</h4>

            <p>{lease.amount}KSh</p>

          </td>

        </tr>

        <tr>

          <td>

            <h4>{translate('Deposite')}</h4>

            <p>{lease.deposit}KSh</p>

          </td>

          <td>

            <h4>{translate('Rent Frequency')}</h4>

            <p>{frequencies.find((frequency) => frequency.id === lease.frequency)?.name}</p>

          </td>

        </tr>

        </tbody>

      </table>

    </div>
  );
};

Lease.defaultProps = {
  noTitle: false,
};

Lease.propTypes = {
  leaseId: PropTypes.number.isRequired,
  noTitle: PropTypes.bool,
};

export default React.memo(Lease);
