import { useCallback, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { entitiesPropertiesSelector } from '../services/selectors/entities';
import createLoadingSelector  from '../services/selectors/loading';
import { PROPERTIES } from '../constants/type';
import { propertyIdSelector } from '../services/selectors/properties';
import { getProperty } from '../services/actions/properties';

const selector = createSelector(
	entitiesPropertiesSelector,
	propertyIdSelector,
	createLoadingSelector(PROPERTIES.GET_PROPERTY.base),
	(properties, propertyId, loader) => {
		const property = properties[propertyId];

		return {
			property: property ? {
				...property,
			} : null,
			loader,
		};
	},
);

const useProperty = (propertyId, refresh) => {
	const newSelector = useCallback((state) => selector(state, propertyId), [propertyId]);

	const { property, loader } = useSelector(newSelector);

	useEffect(() => {
		if (!property && typeof propertyId === 'number' && !isNaN(propertyId)) {
			getProperty(propertyId);
		}
	}, [property, propertyId]);

	useEffect(() => {
		if (refresh && typeof propertyId === 'number' && !isNaN(propertyId)) {
			getProperty(propertyId);
		}
	}, [refresh, propertyId]);

	return {
		property,
		loader,
	};
};

export default useProperty;