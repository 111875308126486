import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DropDown from '../index';

const UncontrolledDropdown = ({
  defaultOpen, onToggle, children, ...rest
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggle = (e) => {
    setIsOpen((state) => !state);

    if (onToggle) {
      onToggle(e, isOpen);
    }
  };

  return (
    <DropDown
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >

      {children}

    </DropDown>
  );
};

UncontrolledDropdown.defaultProps = {
  defaultOpen: false,
  onToggle: null,
  children: '',
};

UncontrolledDropdown.propTypes = {
  defaultOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  children: PropTypes.node,
};

export default UncontrolledDropdown;
