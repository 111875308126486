import { Form, Formik } from 'formik';
import st_pay_met from '../styles.module.scss';
import Label from '../../../../../../components/Label';
import translate from '../../../../../../utils/translate';
import { InputText } from '../../../../../../components/Inputs/InputText';
import Button from '../../../../../../components/Button';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import history from '../../../../../../utils/history';
import { PAYMENT_INFO_ROUTE, PROPERTIES_ROUTE } from '../../../../../../constants/routes';
import { createPaymentMethod, updatePaymentMethod } from '../../../../../../services/actions/auth';
import { PAYMENT_TYPES } from '../../index';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const SECRET_KEY_PLACEHOLDER = '***************';

const LipaNaMPESAForm = (props) => {
  const {
    id,
    paymentMethod,
  } = props;

  const initValues = useMemo(() => {
    const paymentData = paymentMethod?.data;
    if(paymentData) {
      return {
        shortcode: paymentData.shortcode,
        till_number: paymentData.till_number || '',
        consumer_key: '',
        consumer_secret: '',
        pass_key: '',
      };
    }

    return {
      shortcode: '',
      till_number: '',
      consumer_key: '',
      consumer_secret: '',
      pass_key: '',
    };
  }, [paymentMethod]);

  const schema = Yup.object().shape({
    shortcode: Yup.string()
      .max(10, translate('Shortcode must be at most 10 characters'))
      .required(translate('This field is required')),
    till_number: Yup.string().max(10, translate('Till Number must be at most 10 characters')),
    consumer_key: Yup.string()
      .when('shortcode', {
        is: () => !!id,
        then: Yup.string(),
        otherwise: Yup.string().required(translate('This field is required')),
      }),
    consumer_secret: Yup.string()
      .when('shortcode', {
        is: () => !!id,
        then: Yup.string(),
        otherwise: Yup.string().required(translate('This field is required')),
      }),
    pass_key: Yup.string()
      .when('shortcode', {
        is: () => !!id,
        then: Yup.string(),
        otherwise: Yup.string().required(translate('This field is required')),
      }),
  });

  const toSecurityPage = useCallback(() => {
    history.push(PAYMENT_INFO_ROUTE);
  }, []);

  const handleSubmit = useCallback((values, actions) => {
    const action = id ? updatePaymentMethod : createPaymentMethod;

    let data = {
      shortcode: values.shortcode,
    }
    if(values.till_number) {
      data.till_number = values.till_number;
    }
    if(id) {
      if(values.consumer_key) {
        data.consumer_key = values.consumer_key;
      }
      if(values.consumer_secret) {
        data.consumer_secret = values.consumer_secret;
      }
      if(values.pass_key) {
        data.pass_key = values.pass_key;
      }
    } else {
      data = {
        ...data,
        consumer_key: values.consumer_key,
        consumer_secret: values.consumer_secret,
        pass_key: values.pass_key,
      }
    }

    const onFailure = () => {
      if(id) {
        toast.error(translate('Error while updating payment method'));
      } else {
        toast.error(translate('Error while creating payment method'));
      }
    }

    action({
      id,
      payment_type: PAYMENT_TYPES.LIPA_NA_MPESA,
      data,
    }, {...actions, onSuccess: toSecurityPage, onFailure });
  }, [toSecurityPage, id]);

  return (
    <Formik
      initialValues={initValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      {({ isSubmitting, values }) => (
        <Form className={st_pay_met.form}>

          <Label>

            {translate('Shortcode *')}

          </Label>

          <InputText
            id="shortcode"
            name="shortcode"
          />

          <Label>

            {translate('Till Number (optional)')}

          </Label>

          <InputText
            id="till_number"
            name="till_number"
          />

          <Label>

            {translate('Consumer API Key *')}

          </Label>

          <InputText
            id="consumer_key"
            name="consumer_key"
            placeholder={id ? SECRET_KEY_PLACEHOLDER : ''}
          />

          <Label>

            {translate('Consumer API Secret *')}

          </Label>

          <InputText
            id="consumer_secret"
            name="consumer_secret"
            placeholder={id ? SECRET_KEY_PLACEHOLDER : ''}
          />

          <Label>

            {translate('M-Pesa Online Transactions passkey *')}

          </Label>

          <InputText
            id="pass_key"
            name="pass_key"
            placeholder={id ? SECRET_KEY_PLACEHOLDER : ''}
          />

          <div className={st_pay_met.btns}>

            <Button
              tag={Link}
              to={PAYMENT_INFO_ROUTE}
              color="primary"
            >
              {translate('Cancel')}

            </Button>

            <Button
              color="primary"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >

              {translate('Save')}

            </Button>

          </div>

        </Form>
      )}

    </Formik>
  )
}

LipaNaMPESAForm.propTypes = {
  id: PropTypes.string,
  paymentMethod: PropTypes.object,
};

export default LipaNaMPESAForm;
