import { reportStyles } from '../reportStyles';
import { formatPriceStr } from '../../../../../../utils/formatHelper';
import React from 'react';

const ReportSummaryRow = ({ title, totalAmount, currency }) => {
  return (
    <div style={reportStyles.footer}>

      <div style={reportStyles.footerMain}>

        <div style={reportStyles.footerTitle}>

          {title}

        </div>

        <div style={reportStyles.footerPrice}>

          {currency}&nbsp;

          <span style={reportStyles.footerPriceNumbers}>

            {formatPriceStr(totalAmount)}

          </span>

        </div>

      </div>

    </div>
  )
}

export default ReportSummaryRow;
