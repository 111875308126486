import React, { useState } from 'react';
import AuthLayout from '../../../layouts/AuthLayout';
import ForgotSending from './ForgotSending';
import ForgotForm from './ForgotForm';

const ForgotPage = () => {
	const [email, setEmail] = useState('');

	return (
		<AuthLayout>

			{ email
				? (
			    <ForgotSending
			      email={email}
			    />
			  )
			  : (
			    <ForgotForm
				    setEmail={setEmail}
			    />
			  )
			}

		</AuthLayout>
	);
};

export default ForgotPage;