import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Tooltip = ({ title }) => {
	return (
		<span className="tooltip" title={title}>
			i
		</span>
	);
};

Tooltip.defaultProps = {
	title: '',
};

Tooltip.propTypes = {
	title: PropTypes.string,
};

export default Tooltip;