import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DropdownContext from '../../../contexts/DropdownContext';
import styles from './styles.module.scss';

const DropdownMenu = ({
  tag: Tag,
  className,
  children,
  position,
  width,
}) => {
  const dropdownContext = useContext(DropdownContext);

  return (
    <Tag
      className={cn(
        styles.dropdownMenu,
        styles[position],
        styles[width],
        { [styles.active]: dropdownContext.isOpen },
        className,
      )}
      role="menu"
      tabIndex="-1"
    >

      {children}

    </Tag>
  );
};

DropdownMenu.defaultProps = {
  tag: 'div',
  className: '',
  children: '',
  position: 'center',
  width: 'fixed',
};

DropdownMenu.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  position: PropTypes.oneOf([
    'center',
    'right',
  ]),
  width: PropTypes.oneOf([
    'fixed',
    'auto',
  ]),
};

export default DropdownMenu;
