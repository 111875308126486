import { PAYMENT_INFO_ROUTE, PAYMENT_SETTINGS_ROUTE, PROFILE_ROUTE, SECURITY_ROUTE } from '../../../constants/routes';

const links = (landlord) => {
	if(landlord) {
		return [
			{
				title: 'General Info',
				location: PROFILE_ROUTE,
				exact: true,
			},
			{
				title: 'Security',
				location: SECURITY_ROUTE,
			},
			{
				title: 'Payment Info',
				location: PAYMENT_INFO_ROUTE,
			},
		];
	}

	return [
		{
			title: 'General Info',
			location: PROFILE_ROUTE,
			exact: true,
		},
		{
			title: 'Security',
			location: SECURITY_ROUTE,
		},
		{
			title: 'Payment Settings',
			location: PAYMENT_SETTINGS_ROUTE,
		},
	];
};

export default links;