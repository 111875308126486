import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InvoiceSvg, PlusSvg } from '../../../assets/icons';
import { TRANSACTION_CREATE_ROUTE, PROPERTIES_CREATE_ROUTE } from '../../../constants/routes';
import PrivateLayout from '../../../layouts/PrivateLayout';
import Button from '../../../components/Button';
import useAuth from '../../../hooks/useAuth';
import { getTransactions } from '../../../services/actions/transactions';
import { getUnits } from '../../../services/actions/units';
import translate from '../../../utils/translate';
import InfoBlocks from './InfoBlocks';
import Properties from './Properties';
import Payments from './Payments';
import styles from './styles.module.scss';

const Overview = () => {
  const { profile } = useAuth();

  useEffect(() => {
    getUnits({});
    getTransactions({});
  }, []);

  return (
    <PrivateLayout>

      <div className={styles.content}>

        <div className={styles.wrapper}>

          <div className={styles.greeting}>

            {translate('Welcome, ')}

            <span className={styles.username}>

              {profile.first_name}

            </span>

          </div>

          {!profile.blocked && (
            <div className={styles.controls}>

              <Button
                tag={Link}
                to={TRANSACTION_CREATE_ROUTE}
                color="secondary"
                variant="shadow"
                svg={<InvoiceSvg />}
                className={styles.control}
              >

                {translate('Create Transaction')}

              </Button>

              <Button
                tag={Link}
                to={PROPERTIES_CREATE_ROUTE}
                color="primary"
                variant="contained"
                svg={<PlusSvg />}
                className={styles.control}
              >

                {translate('Create Property')}

              </Button>

              <Button
                tag={Link}
                to={TRANSACTION_CREATE_ROUTE}
                color="secondary"
                variant="shadow"
                svg={<InvoiceSvg />}
                size="big"
                className={styles.controlMobile}
              />

              <Button
                tag={Link}
                to={PROPERTIES_CREATE_ROUTE}
                color="primary"
                variant="contained"
                svg={<PlusSvg />}
                size="big"
                className={styles.controlMobile}
              />

            </div>
          )}

        </div>

        {profile.blocked && (
          <div className={styles.titleBlocked}>

            {translate('Your account is blocked, please pay your KODDI commission')}

          </div>
        )}

        <InfoBlocks />

      </div>

      {!profile.blocked && (
        <div className={styles.flexContent}>

          <Properties />

          <Payments />

        </div>
      )}

    </PrivateLayout>
  );
}

export default Overview;
