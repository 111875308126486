import React, { useMemo } from 'react';
import InputCheckbox from '../../../../components/Inputs/InputCheckbox';
import CardHeader from '../../../../components/Card/CardHeader';
import CardTitle from '../../../../components/Card/CardTitle';
import InputFile from '../../../../components/Inputs/InputFile';
import CardBody from '../../../../components/Card/CardBody';
import Label from '../../../../components/Label';
import Card from '../../../../components/Card';
import useConfigurations from '../../../../hooks/useConfigurations';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function Amenities() {
  const { amenities } = useConfigurations();

  const amenitiesLis = useMemo(() => amenities.map((amenity) => ({
    label: amenity.name,
    value: amenity.id,
  })), [amenities]);

  return (
    <Card className={styles.amenities}>

      <CardHeader>

        <CardTitle>

          {translate('Amenities')}

        </CardTitle>

      </CardHeader>

      <CardBody>

        <div className={styles.wrap}>

          {amenitiesLis.map((item) => (
            <InputCheckbox
              id={`amenities-${item.value}`}
              key={item.value}
              title={translate(item.label)}
              name="amenities"
              multiValue
              variant="tag"
              value={item.value}
            />
          ))}

        </div>

        <Label>

          {translate('Cover Photo')}

        </Label>

        <InputFile
          id="cover"
          name="cover"
          description="The file size is not more than 3 mb JPEG, PNG"
        />

        <Label>

          {translate('Gallery')}

        </Label>

        <InputFile
          id="gallery"
          name="gallery"
          description="The file size is not more than 3 mb JPEG, PNG"
          multiple
        />

      </CardBody>

    </Card>
  );
}
