import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../../../assets/icons/angle-left.svg';
import PrivateLayout from '../../../../layouts/PrivateLayout';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import { TRANSACTIONS_ROUTE } from '../../../../constants/routes';
import {
  getAvailablePaymentMethods,
  payInvoicesFlutterwave,
  payInvoicesLipaNaMPESA
} from '../../../../services/actions/invoices';
import useSelectInvoices from '../../../../hooks/useSelectInvoices';
import translate from '../../../../utils/translate';
import history from '../../../../utils/history';
import styles from './../styles.module.scss';
import { PAYMENT_METHODS_OPTIONS, PAYMENT_TYPES } from '../../Profile/PaymentInfo';
import InputPhone from '../../../../components/Inputs/InputPhone';
import TransactionResultPage from '../TransactionResultPage';

const TransactionPayInvoiceTenant = () => {
  const { id } = useParams();
  const idFormatted = useMemo(() => {
    if(!id) return;
    const lastCharIndex = id.indexOf("-") < 0 ? id.length : id.indexOf("-");
    return +id.substring(1, lastCharIndex);
  }, []);

  const { invoicesOptions: invoices } = useSelectInvoices();

  const [selectedInvoices, setSelectedInvoices] = useState(() => {
    return [idFormatted] || [];
  });

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodsLoaded, setPaymentMethodsLoaded] = useState(false);

  const [selectedLandlordId, setSelectedLandlordId] = useState(null);

  const [transactionResultType, setTransactionResultType] = useState('');

  const filteredInvoices = useMemo(() => {
    if(!selectedLandlordId || selectedInvoices.length === 0) return invoices;
    return invoices.filter(invoice => invoice.label.landlord === selectedLandlordId);
  }, [invoices, selectedInvoices, selectedLandlordId]);

  const invoicesOptions = useMemo(() => filteredInvoices.map(invoice => ({
      value: invoice.label.id,
      label: invoice.label.label,
    })),
    [filteredInvoices]);

  const paymentMethodsOptions = useMemo(() => paymentMethods.map(method => ({
      value: method.payment_type,
      label: PAYMENT_METHODS_OPTIONS.find(option => option.type === method.payment_type).title,
    })),
    [paymentMethods]);

  const [initValues, setInitValues] = useState(() => ({
    invoices: [idFormatted] || [],
    payment_method: null,
    phone: '',
  }));

  const schema = Yup.object().shape({
    invoices: Yup.array()
      .required(),
    payment_method: Yup.string()
      .required(),
    phone: Yup.string()
      .when('payment_method', {
        is: (payment_method) => payment_method === PAYMENT_TYPES.LIPA_NA_MPESA,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
  });

  const handleSubmit = useCallback((values, actions) => {
    const onSuccessFlutterwave = (data) => {
      window.open(data.url);
      history.push(TRANSACTIONS_ROUTE);
    };

    const onSuccessLipaNaMPESA = () => {
      setTransactionResultType('success');
    }

    const onFailureLipaNaMPESA = () => {
      setTransactionResultType('failure');
    }

    if(values.payment_method === PAYMENT_TYPES.OTHER) {
      const requestData = {
        invoices: values.invoices,
      }
      payInvoicesFlutterwave(requestData, {
        ...actions,
        onSuccess: onSuccessFlutterwave
      });
    } else {
      const requestData = {
        invoices: values.invoices,
        phone_number: values.phone,
      }
      payInvoicesLipaNaMPESA(requestData, {
        ...actions,
        onSuccess: onSuccessLipaNaMPESA,
        onFailure: onFailureLipaNaMPESA,
      });
    }

  }, []);

  const onChangeInvoices = useCallback((invoicesVal, setFieldValue) => {
    setSelectedInvoices(invoicesVal);

    if(invoicesVal.length === 0) {
      setSelectedLandlordId(null);
      setPaymentMethods([]);
      setPaymentMethodsLoaded(false);
      if(setFieldValue) {
        setFieldValue('payment_method', '');
      } else {
        setInitValues((prev) => {
          return {...prev, payment_method: ''};
        });
      }
      return;
    }

    const selectedInvoiceId = invoicesVal[0];
    const selectedInvoice = invoices.find((invoice) => invoice.label.id === selectedInvoiceId);
    if(!selectedInvoice) return;
    const landlordId = selectedInvoice.label.landlord;
    if(landlordId === selectedLandlordId) return;
    setSelectedLandlordId(landlordId);

    const onSuccess = (data) => {
      const paymentMethods = Object.values(data);
      if(paymentMethods && paymentMethods.length > 0) {
        const sortedPaymentMethods = paymentMethods.sort((p1, p2) => (Number(p2.primary)) - Number(p1.primary));
        setPaymentMethods(sortedPaymentMethods);
        const selectedPaymentMethod = sortedPaymentMethods && sortedPaymentMethods.length > 0 ? sortedPaymentMethods[0].payment_type : null;
        if(setFieldValue) {
          setFieldValue('payment_method', selectedPaymentMethod);
        } else {
          setInitValues((prev) => {
            return {...prev, payment_method: selectedPaymentMethod};
          });
        }
      } else {
        setPaymentMethods([]);
        if(setFieldValue) {
          setFieldValue('payment_method', '');
        } else {
          setInitValues((prev) => {
            return {...prev, payment_method: ''};
          });
        }
      }
      setPaymentMethodsLoaded(true);
    };

    setPaymentMethodsLoaded(false);
    getAvailablePaymentMethods({
      invoices: invoicesVal,
    }, { onSuccess });
  }, [invoices, selectedLandlordId]);

  const [firstInit, setFirstInit] = useState(false);
  useEffect(() => {
    if(!id) return;
    if(!firstInit && selectedInvoices && selectedInvoices.length > 0 && invoices && invoices.length > 0) {
      setFirstInit(true);
      onChangeInvoices(selectedInvoices);
    }
  }, [invoices, selectedInvoices, id, firstInit]);

  if(transactionResultType) {
    return <TransactionResultPage type={transactionResultType} />
  }

  return (
    <PrivateLayout>

      <div className={styles.transactionPayInvoice}>

        <div className={styles.heading}>

          <Button
            tag={Link}
            variant="goBack"
            to={TRANSACTIONS_ROUTE}
            isIcon
            svg={<LeftIcon />}
          />

          <div className={styles.title}>

            {translate('Transaction')}

          </div>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >

          {({ values, setFieldValue }) => (

            <Form>

              <Label>

                {translate('Select Invoice')}

              </Label>

              <InputSelect
                id="invoices"
                name="invoices"
                options={invoicesOptions}
                isMulti
                onChange={(val) => {
                  onChangeInvoices(val, setFieldValue);
                }}
              />

              {
                values.invoices  &&
                values.invoices.length > 0 &&
                paymentMethodsLoaded &&
                paymentMethodsOptions &&
                paymentMethodsOptions.length > 0 && (
                  <>

                    <Label>

                      {translate('Select Payment method*')}

                    </Label>

                    <InputSelect
                      id="payment_method"
                      name="payment_method"
                      options={paymentMethodsOptions}
                    />

                  </>
                )
              }

              {
                values.invoices  &&
                values.invoices.length > 0 &&
                paymentMethodsLoaded &&
                paymentMethodsOptions &&
                paymentMethods.length === 0 && (

                  <h3 className={styles.infoLabel}>

                    {translate(`There is no available payment methods. 
										Please, contact your Landlord to get payment information`)}

                  </h3>

                )
              }

              {

                values.invoices  &&
                values.invoices.length > 0 &&
                paymentMethodsOptions &&
                paymentMethodsOptions.length > 0 &&
                values.payment_method === PAYMENT_TYPES.LIPA_NA_MPESA && (

                  <>

                    <Label>

                      {translate('Phone')}

                    </Label>

                    <InputPhone
                      id="phone"
                      name="phone"
                    />

                  </>

                )

              }

              <div className={styles.footer}>

                <Button
                  tag={Link}
                  color="secondary"
                  variant="shadow"
                  className={styles.button}
                  to={TRANSACTIONS_ROUTE}
                >

                  {translate('Cancel')}

                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={styles.button}
                  disabled={
                    !values.payment_method ||
                    (values.payment_method === PAYMENT_TYPES.LIPA_NA_MPESA && !values.phone)
                  }
                >

                  {translate('Confirm')}

                </Button>

              </div>

            </Form>

          )}

        </Formik>

      </div>

    </PrivateLayout>
  )
};

export default TransactionPayInvoiceTenant;
