import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import PublicLayout from '../../../layouts/PublicLayout';
import { InputText } from '../../../components/Inputs/InputText';
import Button from '../../../components/Button';
import Label from '../../../components/Label';
import { sendContact } from '../../../services/actions/public';
import usePublic from '../../../hooks/usePublic';
import translate from '../../../utils/translate';
import styles from './styles.module.scss';

const ContactsPage = () => {
	const {
		CONTACTS_CONTACTUS_TITLE, CONTACTS_CONTACTUS_DESCRIPTION, CONTACTS_CONTACTS_TITLE, CONTACTS_ADDRESS_TITLE,
		CONTACTS_ADDRESS_VIDEO_URL, CONTACTS_ADDITIONAL_INFO_TITLE, ...rest
	} = usePublic();

	const contacts = useMemo(() => {
		const array = [];

		Array(3).fill(0).forEach((_, index) => {
			const id = index + 1;

			if (
				rest[`CONTACTS_CONTACTS_${id}_ICON`]
				|| rest[`CONTACTS_CONTACTS_${id}_TITLE`]
				|| rest[`CONTACTS_ADDRESS_${id}_DESCRIPTION`]
			) {
				array.push({
					id,
					icon: rest[`CONTACTS_CONTACTS_${id}_ICON`]?.join(''),
					title: rest[`CONTACTS_CONTACTS_${id}_TITLE`],
					url: rest[`CONTACTS_CONTACTS_${id}_DESCRIPTION`],
				});
			}
		});

		return array;
	}, [rest]);

	const addresses = useMemo(() => {
		const array = [];

		Array(1).fill(0).forEach((_, index) => {
			const id = index + 1;

			if (
				rest[`CONTACTS_ADDRESS_${id}_ICON`]
				|| rest[`CONTACTS_ADDRESS_${id}_TITLE`]
				|| rest[`CONTACTS_ADDRESS_${id}_LINK_URL`]
			) {
				array.push({
					id,
					icon: rest[`CONTACTS_ADDRESS_${id}_ICON`]?.join(''),
					title: rest[`CONTACTS_ADDRESS_${id}_TITLE`],
					url: rest[`CONTACTS_ADDRESS_${id}_LINK_URL`],
				});
			}
		});

		return array;
	}, [rest]);

	const initValues = {
		email: '',
		subject: '',
		message: '',
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.email()
			.required(),
		subject: Yup.string()
			.required(),
		message: Yup.string()
			.required(),
	});

	const handleSubmit = useCallback((values, actions) => {
		const onSuccess = () => {
			actions.resetForm();

			toast.success(translate('Contact sended'))
		};

		sendContact(values, { ...actions, onSuccess });
	}, []);

	return (
		<PublicLayout
			withFooter
		>

			<section className={styles.contacts}>

				<div className={styles.wrap}>

					<h2 className={styles.title}>

						{CONTACTS_CONTACTUS_TITLE}

					</h2>

					<p className={styles.description}>

						{CONTACTS_CONTACTUS_DESCRIPTION}

					</p>

					<Formik
						initialValues={initValues}
						validationSchema={schema}
						onSubmit={handleSubmit}
					>

						{({ isSubmitting }) => (
							<Form>

								<Label>

									{translate('Email')}

								</Label>

								<InputText
									id="email"
									name="email"
									type="email"
									autoComplete="username email"
								/>

								<Label>

									{translate('Subject')}

								</Label>

								<InputText
									id="subject"
									name="subject"
								/>

								<Label>

									{translate('Message')}

								</Label>

								<InputText
									id="message"
									name="message"
									type="textarea"
								/>

								<Button
									className={styles.button}
									color="primary"
									variant="contained"
									size="big"
									type="submit"
									loading={isSubmitting}
								>

									{translate('Send message')}

								</Button>

							</Form>
						)}

					</Formik>

				</div>

				<div className={styles.wrap}>

					<h3 className={styles.titleInfo}>

						{CONTACTS_ADDITIONAL_INFO_TITLE}

					</h3>

					<div className={styles.address}>

						<h4>

							{CONTACTS_CONTACTS_TITLE}

						</h4>

						{contacts.map(({ id, title, icon, url }) => (
							<a
								key={id}
								href={url}
								target="_blank"
								rel="noopener noreferrer"
							>

								<span dangerouslySetInnerHTML={{ __html: icon || '' }} />

								{title}

							</a>
						))}

					</div>

					<div className={styles.address}>

						<h4>

							{CONTACTS_ADDRESS_TITLE}

						</h4>

						{addresses.map(({ id, icon, title, url }) => (
							<a
								key={id}
								href={url}
								target="_blank"
								rel="noopener noreferrer"
							>

								<span dangerouslySetInnerHTML={{ __html: icon || '' }} />

								{title}

							</a>
						))}

					</div>

					<div className={styles.map}>

						<iframe
							src={CONTACTS_ADDRESS_VIDEO_URL}
							style={{
								border: '0',
								width: '100%',
								height: '275px',
							}}
							title="map" />

					</div>

				</div>

			</section>

		</PublicLayout>
	);
};

export default ContactsPage;