import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useField } from 'formik';
import Button from '../../Button';
import { InputNotice } from '../InputNotice';
import styles from './styles.module.scss';


const InputSwitch = ({
  name, tabs, className, onChange,
}) => {
  const [field, meta, helpers] = useField(name);

  const { error, touched } = meta;

  const handleChange = (tab) => {
    const value = tab ? tab.value : null;

    helpers.setValue(value);

    helpers.setTouched(true);

    if (onChange) {
      onChange(value, name);
    }
  };

  return (
    <>

      <div
        className={cn(
          styles.inputSwitch,
          className,
        )}
      >

        {tabs.map((tab) => (
          <Button
            key={tab.value}
            className={cn(
              styles.tab,
              { [styles.active]: tab.value === field.value },
            )}
            onClick={() => handleChange(tab)}
          >

            {tab.label}

          </Button>
        ))}

      </div>

      { error && touched && (
        <InputNotice
          name={name}
        />
      )}

    </>
  );
};

InputSwitch.defaultProps = {
  className: '',
  onChange: null,
};

InputSwitch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func,
};

export default React.memo(InputSwitch);


export const InputSwitchUC = ({
 tabs, className, value, onChange,
}) => (
  <div
    className={cn(
      styles.inputSwitch,
      className
    )}
  >

    {tabs.map((tab) => (
      <Button
        key={tab.value}
        className={cn(
          styles.tab,
          { [styles.active]: tab.value === value }
        )}
        onClick={() => onChange(tab)}
      >

        {tab.label}

      </Button>
    ))}

  </div>
);

InputSwitchUC.defaultProps = {
  className: '',
};

InputSwitchUC.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};
