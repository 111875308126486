import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

const SocialItem = ({ className, to, children }) => {

	return (
		<li
			className={cn(
				styles.socialItem,
				className,
			)}
		>

			<a
				href={to}
				target="_blank"
			>

				{children}

			</a>

		</li>
	)
};

SocialItem.defaultProps = {
	className: '',
	children: '',
};

SocialItem.propTypes = {
	className: PropTypes.string,
	to: PropTypes.string.isRequired,
	children: PropTypes.node,
};

export default SocialItem;
