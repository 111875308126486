import PrivateLayout from '../../../../layouts/PrivateLayout';
import styles from '../styles.module.scss';
import PropTypes from 'prop-types';
import translate from '../../../../utils/translate';
import { Link } from 'react-router-dom';
import { HOME_ROUTE, TRANSACTIONS_ROUTE } from '../../../../constants/routes';
import Button from '../../../../components/Button';
import React, { useEffect } from 'react';
import { CheckSuccessSvg, ErrorSvg } from '../../../../assets/icons';
import history from '../../../../utils/history';

const REDIRECT_TIMEOUT = 20000;

const TransactionResultPage = ({ type }) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push(TRANSACTIONS_ROUTE);
    }, REDIRECT_TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  return (
    <PrivateLayout>

      <div className={styles.transactionResultPage}>

        {

          type === 'success' ?
            <CheckSuccessSvg /> :
            <ErrorSvg />

        }

        <h3 className={styles.title}>

          {

            type === 'success' ?
              // translate('Your payment has been successfully completed') :
              translate('Your payment is waiting for confirmation') :
              translate('An error occurred while making a payment')

          }

        </h3>

        <div className={styles.infoLabel}>

          {translate('You will be redirected in 20 seconds')}

        </div>

        <Button
          tag={Link}
          color="secondary"
          variant="shadow"
          className={styles.button}
          to={TRANSACTIONS_ROUTE}
        >

          {translate('Cancel')}

        </Button>

      </div>

    </PrivateLayout>
  )
}

TransactionResultPage.propTypes = {
  type: PropTypes.oneOf([
    'success',
    'failure',
  ]).isRequired,
};

export default TransactionResultPage;
