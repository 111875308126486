import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Socials = ({ className, children }) => {

	return (
		<ul
			className={cn(
				styles.socials,
				className,
			)}
		>

			{children}

		</ul>
	);
};

Socials.defaultProps = {
	className: '',
	children: '',
}

Socials.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
}

export default Socials;