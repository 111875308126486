import React from 'react';
import PropTypes from 'prop-types';
import user from '../../../../../assets/images/user-t.svg';
import ModalContent from '../../../../../components/Modal/ModalContent';
import ModalBody from '../../../../../components/Modal/ModalBody';
import ModalHeader from '../../../../../components/Modal/ModalHeader';
import ModalTitle from '../../../../../components/Modal/ModalTitle';
import ModalClose from '../../../../../components/Modal/ModalClose';
import Modal from '../../../../../components/Modal';
import Avatar from '../../../../../components/Avatar';
import translate from '../../../../../utils/translate';
import styles from './styles.module.scss';

const mock = [
	{
		id: 1,
		avatar: user,
		name: 'Arlene McCoy',
	},
	{
		id: 2,
		avatar: user,
		name: 'Robert Fox',
	},
	{
		id: 3,
		avatar: user,
		name: 'Esther Howard',
	},
	{
		id: 4,
		avatar: user,
		name: 'Jerome Bell',
	},
	{
		id: 5,
		avatar: user,
		name: 'Floyd Miles',
	},
	{
		id: 6,
		avatar: user,
		name: 'Ralph Edwards',
	},
	{
		id: 7,
		avatar: user,
		name: 'Kathryn Murphy',
	},
	{
		id: 8,
		avatar: user,
		name: 'Brooklyn Simmons',
	},
]

const ModalUser = ({ isOpen, onClose }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>

			<ModalContent>

				<ModalHeader>

					<ModalTitle>

						{translate('Select Tenant')}

					</ModalTitle>

					<ModalClose onClose={onClose} />

				</ModalHeader>

				<ModalBody>

					<ul className={styles.modalUser}>

						{mock.map(({ id, name, avatar }) => (
							<li
								key={id}
								className={styles.item}
							>

								<Avatar
									className={styles.avatar}
									src={avatar}
									size="40"
								/>

								{name}

							</li>
						))}

					</ul>

				</ModalBody>

			</ModalContent>

		</Modal>
	);
};

ModalUser.defaultProps = {
	isOpen: false,
}

ModalUser.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
}

export default ModalUser;