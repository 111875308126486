import merge from 'lodash/merge';
import { combineReducers } from 'redux';
import { AUTH } from '../../constants/type';

const createReducer = (state = {}, action, name) => {
  if([AUTH.SIGN_OUT.SUCCESS, AUTH.GET_PROFILE.FAILURE].includes(action.type)) {
    return {};
  }

  if (action?.payload?.entities?.[name]) {
    return merge({}, state, action.payload.entities[name]);
  }

  return state;
};

const tenants = (state = {}, action) => createReducer(state, action, 'tenants');
const properties = (state = {}, action) => createReducer(state, action, 'properties');
const transactions = (state = {}, action) => createReducer(state, action, 'transactions');
const leases = (state = {}, action) => createReducer(state, action, 'leases');
const units = (state = {}, action) => createReducer(state, action, 'units');
const landlords = (state = {}, action) => createReducer(state, action, 'landlords');
const invoices = (state = {}, action) => createReducer(state, action, 'invoices');

const entities = combineReducers({
  units,
  tenants,
  properties,
  transactions,
  leases,
  invoices,
  landlords,
});

export default entities;
