import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import useAuth from '../../../hooks/useAuth';
import SetPassword from '../SetPassword';
import translate from '../../../utils/translate';

const ResetPasswordPage = () => {
	const { search } = useLocation();

	const params = useMemo(() => {
		const urlParams = new URLSearchParams(search);

		return {
			user_id: urlParams.get('user_id'),
			timestamp: urlParams.get('timestamp'),
			signature: urlParams.get('signature'),
		};
	}, [search]);

	const {
		onResetPassword,
	} = useAuth();

	const handleSubmit = useCallback(({ password }, action) => {
		onResetPassword({
			...params,
			password,
		}, {...action});
	}, [onResetPassword, params]);

	return (
		<SetPassword
			title={translate('Reset password')}
			description={translate('Remember the password?')}
			buttonTitle={translate('Reset password')}
			onSubmit={handleSubmit}
		/>
	);
};

export default ResetPasswordPage;
