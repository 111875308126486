import React, { useMemo } from 'react';
import Filter from '../../../../components/Filter';
import { changeTenantsFilter } from '../../../../services/actions/tenants';
import useConfigurations from '../../../../hooks/useConfigurations';
import translate from '../../../../utils/translate';

export default function TenantsFilter({ filter: { type, status, search } }) {
  const { type_tenants, status_tenant } = useConfigurations();

  const typeOptions = useMemo(() => type_tenants.map((item) => ({
    label: item.name,
    value: item.id,
  })), [type_tenants]);

  const statusOptions = useMemo(() => status_tenant.map((item) => ({
    label: item.name,
    value: item.id,
  })), [status_tenant]);

  const filterInputs = useMemo(() => ([
    {
      type: 'select',
      name: 'type',
      label: translate('Type'),
      value: type,
      options: typeOptions,
    },
    {
      type: 'select',
      name: 'status',
      label: translate('Status'),
      value: status,
      options: statusOptions,
    },
  ]), [type, typeOptions, status, statusOptions]);

  const searchInput = useMemo(() => ({
    name: 'search',
    label: translate('Search'),
    value: search
  }), [search]);

  return (
    <Filter
      filterInputs={filterInputs}
      searchInput={searchInput}
      onChange={changeTenantsFilter}
    />
  );
}
