import PropTypes from 'prop-types';
import React from 'react';

const DropdownContext = React.createContext({});

DropdownContext.defaultProps = {
  disabled: false,
};

DropdownContext.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default DropdownContext;
