import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { InputSelect } from '../InputSelect';
import { LocationPoint } from '../../../assets/icons';
import { components } from "react-select";
import styles from './styles.module.scss';

const IndicatorSeparator = () => {
  return <span/>;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <span className={styles.locationIcon}>
        <LocationPoint />
      </span>
    </components.DropdownIndicator>
  );
};

export const InputLocation = ({id, name, className, onChange, disabled, ...rest}) => {

  const {
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300
  });

  const onHandleChange = useCallback((val) => {
    if(onChange) {
      onChange(val);
    }
    if(val) {
      setValue(val);
    }
  }, [setValue, onChange]);

  const suggestionOptions = useMemo(() => {
    return data.map(suggestion => {
      const {
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      const val = secondary_text ? `${main_text} ${secondary_text}` : main_text;

      return {
        value: val,
        label: val,
      };
    });
  }, [data]);

  return (
    <InputSelect
      id={id}
      name={name}
      disabled={disabled}
      className={className}
      options={suggestionOptions}
      onChange={onHandleChange}
      allowUserInput={true}
      placeholder={""}
      components={{IndicatorSeparator, DropdownIndicator}}
      {...rest}
    />
  );
}

InputLocation.defaultProps = {
  className: '',
  id: '',
  onChange: null,
  disabled: false,
};

InputLocation.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
