import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import defaultImage from '../../assets/icons/avatar.svg';
import styles from './styles.module.scss';

const Avatar = ({
  src, className, variant, size, ...rest
}) => (
  <figure
    className={cn(
      className,
      styles.avatar,
      { [styles[variant]]: variant, [styles[size]]: size },
    )}
    {...rest}
  >

    <img src={src || defaultImage} alt="avatar" />

  </figure>
);

Avatar.defaultProps = {
  src: '',
  className: '',
  variant: '',
  size: '',
};

Avatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    '',
    'rounded',
    'circle',
  ]),
  size: PropTypes.oneOf([
    '32',
    '40',
    '48',
    '72',
    '82',
  ]),
};

export default Avatar;
