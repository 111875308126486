import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import { CloneSvg, RemoveSvg } from '../../../../assets/icons';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import { InputText } from '../../../../components/Inputs/InputText';
import InputQuantity from '../../../../components/Inputs/InputQuantity';
import PropertyType from '../../../../components/PropertyType';
import CardHeader from '../../../../components/Card/CardHeader';
import CardTitle from '../../../../components/Card/CardTitle';
import CardBody from '../../../../components/Card/CardBody';
import Label from '../../../../components/Label';
import Card from '../../../../components/Card';
import Button from '../../../../components/Button';
import useConfigurations from '../../../../hooks/useConfigurations';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function Units() {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const {
    features, unit_types_single, unit_types_multi, type_properties, unit_types_commercial,
    unit_types_residential, ...rest
  } = useConfigurations();

  const unitTypesCommercialArray = useMemo(() => unit_types_commercial
    .map((item) => item.id), [unit_types_commercial]);

  const unitTypesResidentialArray = useMemo(() => unit_types_residential
    .map((item) => item.id), [unit_types_residential]);


  const filteredUnitOptions = useCallback((array) => array
    .filter((item) => {
      if(values.type === 'commercial') {
        return unitTypesCommercialArray.includes(item.id);
      } else if (values.type === 'residential') {
        return unitTypesResidentialArray.includes(item.id);
      }

      return true;
    })
    .map((unitType) => ({
      label: unitType.name,
      value: unitType.id,
    })), [values, unitTypesCommercialArray, unitTypesResidentialArray])

  const unitTypeSingleOptions = useMemo(() => filteredUnitOptions(unit_types_single), [unit_types_single, filteredUnitOptions]);

  const unitTypeMultiOptions = useMemo(() => filteredUnitOptions(unit_types_multi), [unit_types_multi, filteredUnitOptions]);

  const typePropertiesOptions = useMemo(() => type_properties.map((unitType) => ({
    label: unitType.name,
    value: unitType.id,
  })), [type_properties]);

  const onChange = (newValue) => () => {
    setFieldTouched('unit_type', true);

    setFieldValue('unit_type', newValue);
  };

  const inputsQuantity = useMemo(() => features.map((feature) => (
    <InputQuantity
      key={feature.id}
      id={`single.0.features.${feature.id}.quantity`}
      name={`single.0.features.${feature.id}.quantity`}
      title={translate(feature.name)}
    />
  )), [features]);

  const inputsQuantityMulti = useCallback((index) => features.map((feature) => (
    <InputQuantity
      key={feature.id}
      id={`multi.${index}.features.${feature.id}.quantity`}
      name={`multi.${index}.features.${feature.id}.quantity`}
      title={translate(feature.name)}
    />
  )), [features]);

  const handleChangeType = useCallback((value) => {
    const checkValue = (valueCheck, fieldName) => {
      if(
        !value
        || (value === 'commercial' && !unitTypesCommercialArray.includes(valueCheck))
        || (value === 'residential' && !unitTypesResidentialArray.includes(valueCheck))
      ) {
        setFieldValue(fieldName, '');
      }
    }

    if(values.unit_type) {
      values.multi.forEach((item, index) => {
        checkValue(item.type, `multi.${index}.type`);

        if(value === 'commercial') {
          features.forEach((feature) => {
            setFieldValue(`multi.${index}.features.${feature.id}.quantity`, 0);
          });
        }
      })
    } else {
      checkValue(values.single[0].type, 'single.0.type');

      if(value === 'commercial') {
        features.forEach((feature) => {
          setFieldValue(`single.0.features.${feature.id}.quantity`, 0);
        });
      }
    }
  }, [setFieldValue, values, features]);

  return (
    <Card className={styles.units}>

      <CardHeader>

        <CardTitle>

          {translate('Choose a property type')}

        </CardTitle>

      </CardHeader>

      <CardBody>

        <Label>

          {translate('Category')}

        </Label>

        <InputSelect
          id={`type`}
          name={`type`}
          options={typePropertiesOptions}
          onChange={handleChangeType}
        />

        <div className={styles.properties}>

          <PropertyType
            title={translate('Single unit type')}
            active={!values.unit_type}
            onClick={onChange(false)}
          />

          <PropertyType
            title={translate('Multi unit type')}
            active={values.unit_type}
            onClick={onChange(true)}
            isMulti
          />

        </div>

        {values.unit_type ? (
          <FieldArray
            name="multi"
          >

            {({ form, remove, push }) => (
              <>

                {form.values.multi.map((value, i) => (
                  <div
                    key={i}
                    className={cn(styles.unit)}
                  >

                    <div className={styles.unitHeading}>

                      {translate('Unit #')}{i + 1}

                      <div className={styles.controls}>

                        <Button
                          color="primary"
                          className={styles.control}
                          onClick={() => push(form.values.multi[i])}
                          svg={(
                            <CloneSvg />
                          )}
                        >

                          {translate('Clone')}

                        </Button>

                        {i > 0 && (
                          <Button
                            color="danger"
                            className={styles.control}
                            onClick={() => remove(i)}
                            svg={(
                              <RemoveSvg />
                            )}
                          >

                            {translate('Remove')}

                          </Button>
                        )}

                      </div>

                    </div>

                    <Label>

                      {translate('Unit Name')}

                    </Label>

                    <InputText
                      id={`multi.${i}.name`}
                      name={`multi.${i}.name`}
                    />

                    <Label>

                      {translate('Unit Type')}

                    </Label>

                    <InputSelect
                      id={`multi.${i}.type`}
                      name={`multi.${i}.type`}
                      options={unitTypeMultiOptions}
                    />

                    <Label>

                      {translate('Rent')}

                    </Label>

                    <InputText
                      type="number"
                      id={`multi.${i}.rent`}
                      name={`multi.${i}.rent`}
                    />

                    <Label>

                      {translate('Deposit')}

                    </Label>

                    <InputText
                      type="number"
                      id={`multi.${i}.deposit`}
                      name={`multi.${i}.deposit`}
                    />

                    <Label>

                      {translate('Property size')}

                    </Label>

                    <InputText
                      type="number"
                      id={`multi.${i}.size`}
                      name={`multi.${i}.size`}
                    />

                    {values.type !== 'commercial' && inputsQuantityMulti(i)}

                  </div>
                ))}

                <div className={styles.controls}>

                  <Button
                    color="primary"
                    className={styles.control}
                    onClick={() => {
                      const newUnitInitialObj = form.initialValues.multi[0];
                      const { id, ...newUnitDataObj } = newUnitInitialObj;

                      return push(newUnitDataObj)
                    }}
                  >

                    {translate('+ Add unit')}

                  </Button>

                </div>

              </>
            )}

          </FieldArray>
        ) : (
          <FieldArray
            name="single"
          >

            <>

              {/*<Label>

                {translate('Unit Name')}

              </Label>

              <InputText
                id={`single.0.name`}
                name={`single.0.name`}
              />*/}

              <Label>

                {translate('Unit Type')}

              </Label>

              <InputSelect
                id={`single.0.type`}
                name={`single.0.type`}
                options={unitTypeSingleOptions}
              />

              <Label>

                {translate('Rent')}

              </Label>

              <InputText
                type="number"
                id="single.0.rent"
                name="single.0.rent"
              />

              <Label>

                {translate('Deposit')}

              </Label>

              <InputText
                type="number"
                id="single.0.deposit"
                name="single.0.deposit"
              />

              <Label>

                {translate('Property size')}

              </Label>

              <InputText
                type="number"
                id="single.0.size"
                name="single.0.size"
              />

              {values.type !== 'commercial' && inputsQuantity}

            </>

          </FieldArray>
        )}

      </CardBody>

    </Card>
  );
};
