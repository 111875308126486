import React, { useEffect, useState } from 'react';
import {
  getConfigurationAmenities,
  getConfigurationCategories,
  getConfigurationConstants,
  getConfigurationFeatures,
  getConfigurationPaymentModes,
  getConfigurationSubcategories
} from '../../services/actions/configurations';
import Loader from '../../components/Loader';
import useAuth from '../../hooks/useAuth';
import SuperuserRouter from './SuperuserRouter';
import LandlordRouter from './LandlordRouter';
import TenantRouter from './TenantRouter';
import { getBanks, getPaymentMethods } from '../../services/actions/auth';

const PrivateRouter = () => {
  const [firstRender, setFirstRender] = useState(true);

  const { loader, profile, paymentMethods, banks } = useAuth();

  useEffect(() => {
    setFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getConfigurationAmenities();
    getConfigurationCategories();
    getConfigurationSubcategories();
    getConfigurationFeatures();
    getConfigurationPaymentModes();
    getConfigurationConstants();
  }, []);

  useEffect(() => {
    if(!paymentMethods) {
      getPaymentMethods();
    }
  }, [paymentMethods]);

  useEffect(() => {
    if(!banks) {
      getBanks();
    }
  }, [banks]);

  if (firstRender || loader.loading) {
    return <Loader />;
  }

  return (
    <>

      {profile.is_landlord && (
        <LandlordRouter />
      )}

      {profile.is_tenant && (
        <TenantRouter />
      )}

      {!profile.is_landlord && !profile.is_tenant && profile.is_superuser && (
        <SuperuserRouter />
      )}

    </>
  );
}

export default PrivateRouter;
