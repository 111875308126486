import { createSelector } from 'reselect';

export const unitsSelector = (state) => state.units;

export const unitIdSelector = (_, unitId) => unitId;

export const unitIdsSelector = createSelector(
	unitsSelector,
	(units) => units.ids,
);

export const unitsFilterSelector = createSelector(
	unitsSelector,
	(units) => units.filter,
);

export const unitsSelectSelector = createSelector(
	unitsSelector,
	(units) => units.select,
);