import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';
import Button from '../../../../../components/Button';
import { Link } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../../../../../constants/routes';
import translate from '../../../../../utils/translate';

const View = ({ className, icon, color, title, description, image }) => {
	return (
		<div
			className={cn(
				styles.view,
				className,
			)}
		>

			<div className={styles.wrap}>

				<figure
					className={cn(
						styles.icon,
						{[styles[color]]: color},
					)}
					dangerouslySetInnerHTML={{__html: icon}}
				/>

				<h3 className={styles.title}>

					{title}

				</h3>

				<p className={styles.description}>

					{description}

				</p>

				<Button
					tag={Link}
					color="secondary"
					variant="shadow"
					to={SIGN_IN_ROUTE}
					size="big"
					className={styles.button}
				>

					{translate('Get Started')}

				</Button>

			</div>

			<div className={styles.wrap}>

				<img src={image} alt="preview" />

			</div>

		</div>
	);
};

View.defaultProps = {
	className: '',
	color: 'default',
	icon: '',
	image: '',
};

View.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	color: PropTypes.string,
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	image: PropTypes.string,
}

export default View;