import React, { useMemo } from 'react';
import PublicLayout from '../../../layouts/PublicLayout';
import FormStart from '../HomePage/FormStart';
import Counters from './Counters';
import Views from './Views';
import Hero from './Hero';
import usePublic from '../../../hooks/usePublic';

const SolutionPage = () => {
	const {
		SOLUTION_TOP_TITLE, SOLUTION_TOP_IMAGE_URL, SOLUTION_FORMSTART_TITLE, ...rest
	} = usePublic();

	const counters = useMemo(() => {
		const array = [];

		Array(4).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`SOLUTION_COUNTERS_${id}_TITLE`]
				|| rest[`SOLUTION_COUNTERS_${id}_DESCRIPTION`]
			) {
				array.push({
					id,
					title: rest[`SOLUTION_COUNTERS_${id}_TITLE`],
					description: rest[`SOLUTION_COUNTERS_${id}_DESCRIPTION`],
				});
			}
		});

		return array;
	}, [rest]);

	const views = useMemo(() => {
		const array = [];

		Array(3).fill(0).forEach((_, index) => {
			const id = index + 1;

			if(
				rest[`SOLUTION_FEATURES_${id}_TITLE`]
				|| rest[`SOLUTION_FEATURES_${id}_DESCRIPTION`]
				|| rest[`SOLUTION_FEATURES_${id}_IMAGE_URL`]
			) {
				array.push({
					id,
					title: rest[`SOLUTION_FEATURES_${id}_TITLE`],
					description: rest[`SOLUTION_FEATURES_${id}_DESCRIPTION`],
					image: rest[`SOLUTION_FEATURES_${id}_IMAGE_URL`],
				});
			}
		});

		return array;
	}, [rest]);

	return (
		<PublicLayout
			withFooter
		>

			<Hero
				title={SOLUTION_TOP_TITLE}
				image={SOLUTION_TOP_IMAGE_URL}
			/>

			<Counters
				counters={counters}
			/>

			<Views
				views={views}
			/>

			<FormStart
				title={SOLUTION_FORMSTART_TITLE}
			/>

		</PublicLayout>
	);
};

export default SolutionPage;