import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ModalContent from '../../../../../../components/Modal/ModalContent';
import ModalHeader from '../../../../../../components/Modal/ModalHeader';
import ModalFooter from '../../../../../../components/Modal/ModalFooter';
import ModalBody from '../../../../../../components/Modal/ModalBody';
import Button from '../../../../../../components/Button';
import Modal from '../../../../../../components/Modal';
import { SECURITY_ROUTE } from '../../../../../../constants/routes';
import { confirmChangeEmail } from '../../../../../../services/actions/auth';
import translate from '../../../../../../utils/translate';
import history from '../../../../../../utils/history';

const ModalCode = ({ isOpen, onClose }) => {
	const initValues = {
		code: '',
	};

	const schema = Yup.object().shape({
		code: Yup.string()
			.required(),
	});

	const handleSubmit = (values, actions) => {
		const onSuccess = () => {
			history.push(SECURITY_ROUTE);
		}

		confirmChangeEmail(values, {...actions, onSuccess});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>

			<ModalContent>

				<Formik
					initialValues={initValues}
					validationSchema={schema}
					onSubmit={handleSubmit}
				>

					{({ isSubmitting }) => (
						<Form>

							<ModalHeader>


							</ModalHeader>

							<ModalBody>


							</ModalBody>

							<ModalFooter>

								<Button
									color="secondary"
									variant="shadow"
									onClick={onClose}
								>

									{translate('Cancel')}

								</Button>

								<Button
									color="primary"
									variant="contained"
									type="submit"
									loading={isSubmitting}
								>

									{translate('Confirm')}

								</Button>

							</ModalFooter>

						</Form>
					)}

				</Formik>

			</ModalContent>

		</Modal>
	);
};

export default ModalCode;