import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ReactComponent as LeftIcon } from '../../../../../assets/icons/angle-left.svg';
import { SECURITY_ROUTE } from '../../../../../constants/routes';
import { API_FORGOT_URL } from '../../../../../constants/api';
import { AUTH } from '../../../../../constants/type';
import PrivateLayout from '../../../../../layouts/PrivateLayout';
import Button from '../../../../../components/Button';
import Label from '../../../../../components/Label';
import { InputText } from '../../../../../components/Inputs/InputText';
import requestWrapper from '../../../../../utils/requestWrapper';
import translate from '../../../../../utils/translate';
import styles from '../../styles.module.scss';
import st_change from './styles.module.scss';

const RecoverPassword = () => {
  const history = useHistory();
  const [recoverPassReqSeccess, setRecoverPassReqSeccess] = useState(false);
  const [email, setEmail] = useState('');

  const initValues = {
    email: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
  });

  const toSecurityPage = useCallback(() => {
    history.push(SECURITY_ROUTE);
  }, [history]);

  const forgotPasswordHandler = useCallback(async (requestData) => {
    const onSuccess = () => {
      setEmail(requestData.email)
      setRecoverPassReqSeccess(true);
    };

    const onFailure = (data) => {
      setEmail('')
      setRecoverPassReqSeccess(false);

      if (data.non_field_errors) {
        history.push(SECURITY_ROUTE);
      }
    };

    await requestWrapper({
      action: AUTH.FORGOT,
      url: API_FORGOT_URL,
      method: 'post',
      requestData,
      onSuccess,
      onFailure,
    });
  }, [setEmail, setRecoverPassReqSeccess, history]);

  const handleSubmit = useCallback((values) => {
    forgotPasswordHandler(values);
  }, [forgotPasswordHandler]);


  return (
    <PrivateLayout>

      <div className={st_change.container}>

        <div className={st_change.heading}>

          <Button
            variant="goBack"
            onClick={toSecurityPage}
            isIcon
            svg={<LeftIcon />}
          />

          <h2 className={styles.title}>

            {translate('Recover Password')}

          </h2>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Label>
                {translate('Email')}
              </Label>

              <InputText
                id='email'
                name='email'
                type='email'
              />

              {
                recoverPassReqSeccess ?

                  <p className={cn(
                    st_change.description,
                    st_change.success,
                  )}>

                    {translate('An e-mail with a confirmation link was send to the mailbox:')}
                    &nbsp;

                    <span>
                      {email}
                    </span>

                  </p>
                  :
                  ''
              }

              <div className={st_change.btns}>
                <Button
                  color="secondary"
                  variant="shadow"
                  onClick={toSecurityPage}
                >

                  {translate('Cancel')}

                </Button>

                {
                  recoverPassReqSeccess ?

                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                    >

                      {translate('Resend email')}

                    </Button>
                    :

                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                    >

                      {translate('Recover Password')}

                    </Button>
                }
              </div>
            </Form>
          )}
        </Formik>

      </div>

    </PrivateLayout>
  );
};

export default RecoverPassword;