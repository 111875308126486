import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as AngleLeftIcon } from '../../assets/icons/angle-left.svg';
import { ReactComponent as AngleRightIcon } from '../../assets/icons/angle-right.svg';
import styles from './styles.module.scss';

const NavigationButton = forwardRef(({ className, direction, children, ...rest}, ref) => {

	return (
		<button
			className={cn(
				styles.navigationButton,
				className,
			)}
			ref={ref}
			{...rest}
		>

			{direction === 'next' && <AngleLeftIcon />}
			{direction === 'prev' && <AngleRightIcon />}

		</button>
	);
});

NavigationButton.defaultProps = {
	className: '',
	direction: 'next',
	children: '',
};

NavigationButton.propTypes = {
	className: PropTypes.string,
	direction: PropTypes.oneOf([
		'next',
		'prev',
	]),
	children: PropTypes.node,
};

export default NavigationButton;