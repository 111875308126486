import React from 'react';
import styles from './styles.module.scss';
import Message from './Message';
import TextareaMessage from './TextareaMessage';

const mock = [
	{
		id: 1,
		content: 'Aliquam porta nisl dolor, molestie pellentesque elit molestie in.',
		datetime: 1608306750,
		user: 1,
	},
	{
		id: 2,
		content: 'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi.',
		datetime: 1608306750,
		user: 1,
	},
	{
		id: 3,
		content: 'Aliquam porta nisl dolor, molestie pellentesque elit molestie in',
		datetime: 1608306750,
		user: 2,
	},
	{
		id: 4,
		content: 'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi.',
		datetime: 1608306750,
		user: 2,
	},
	{
		id: 5,
		content: 'Aliquam porta nisl dolor, molestie pellentesque elit molestie in. Morbi metus neque, elementum ullamcorper hendrerit eget, tincidunt et nisi.',
		datetime: 1608306750,
		user: 1,
	}
]

const Dialog = () => {
	return (
		<div className={styles.dialog}>

			<div className={styles.header}>

				<h2>

					Cody Fisher

				</h2>

			</div>

			<div className={styles.content}>

				{mock.sort((a, b) => b.id - a.id).map((message) => (
					<Message
						key={message.id}
						message={message}
					/>
				))}

			</div>

			<div className={styles.footer}>

				<TextareaMessage />

			</div>

		</div>
	);
};

export default Dialog;