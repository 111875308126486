import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { LANDLORDS } from '../constants/type';
import { entitiesLandlordsSelector } from '../services/selectors/entities';
import { landlordIdSelector } from '../services/selectors/landlords';
import createLoadingSelector from '../services/selectors/loading';
import { getLandlord } from '../services/actions/landlords';

const selector = createSelector(
	entitiesLandlordsSelector,
	landlordIdSelector,
	createLoadingSelector(LANDLORDS.GET_LANDLORD.base),
	(landlords, landlordId, loader) => {
		const landlord = landlords[landlordId];

		return {
			landlord: landlord ? {
				...landlord,
			} : null,
			loader,
		};
	},
);

const useLandlord = (landlordId, refresh) => {
	const newSelector = useCallback((state) => selector(state, landlordId), [landlordId]);

	const { landlord, loader } = useSelector(newSelector);

	useEffect(() => {
		if (!landlord && typeof landlordId === 'number' && !isNaN(landlordId)) {
			getLandlord(landlordId);
		}
	}, [landlord, landlordId]);

	useEffect(() => {
		if (refresh && typeof landlordId === 'number' && !isNaN(landlordId)) {
			getLandlord(landlordId);
		}
	}, [refresh, landlordId]);

	return {
		landlord,
		loader,
	};
};

export default useLandlord;