import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useField } from 'formik';
import { InputNotice, InputNoticeUC } from '../InputNotice';
import styles from './styles.module.scss';

export const InputText = forwardRef(({
	placeholder, name, className, prepend, append, type, disabled, onChange, ...rest
}, ref) => {
	const [field = {}, meta = {}, helpers] = useField(name);

	const { error, touched } = meta;

	const handleChange = (e) => {
		if (type === 'number') {
			const { value } = e.target;

			helpers.setValue(value.replace(/[^\d.]/ig, ''));
		} else {
			const setValue = () => (field.onChange(e));

			if (onChange) {
				onChange(e, setValue);
			} else {
				setValue();
			}
		}
	};

	const Tag = type === 'textarea' ? type : 'input';

	return (
		<>

			<div
				className={cn(
					styles.input,
					{[styles.error]: error && touched},
					className,
				)}
			>

				{prepend && (
					<div className={styles.prepend}>

						{prepend}

					</div>
				)}

				<Tag
					ref={ref}
					type={type}
					placeholder={placeholder}
					disabled={disabled}
					{...field}
					onChange={handleChange}
					{...rest}
				/>

				{ append && (
					<div className={styles.append}>

						{append}

					</div>
				)}

			</div>

			{error && touched && (
				<InputNotice
					name={name}
				/>
			)}

		</>
	);
});

InputText.defaultProps = {
	placeholder: '',
	className: '',
	type: 'text',
	prepend: '',
	append: '',
	disabled: false,
	onChange: null,
};

InputText.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string,
	prepend: PropTypes.node,
	append: PropTypes.node,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

export const InputTextUC = forwardRef(({ value, name, placeholder, error, className, prepend, append, type, disabled, onChange, ...rest }, ref) => {

	const Tag = type === 'textarea' ? type : 'input';

	return (
		<>

			<div
				className={cn(
					styles.input,
					{[styles.error]: error},
					className,
				)}
			>

				{prepend}

				<Tag
					ref={ref}
					name={name}
					type={type}
					placeholder={placeholder}
					onChange={onChange}
					disabled={disabled}
					value={value}
					{...rest}
				/>

				{append}

			</div>

			{ error && (
				<InputNoticeUC
					error={error}
				/>
			)}

		</>
	);
});

InputTextUC.defaultProps = {
	placeholder: '',
	name: '',
	error: '',
	className: '',
	type: 'text',
	prepend: null,
	append: null,
	disabled: false,
	value: '',
};

InputTextUC.propTypes = {
	error: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string,
	prepend: PropTypes.node,
	append: PropTypes.node,
	disabled: PropTypes.bool,
	value: PropTypes.string,
};