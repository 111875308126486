import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';
import Loader from '../components/Loader';

const Routes = () => {
  const [firstRender, setFirstRender] = useState(true);

  const { isAuthorized } = useAuth();

  useEffect(() => {
    setFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (firstRender) {
    return <Loader />;
  }

  return isAuthorized ? <PrivateRouter /> : <PublicRouter />;
};

export default Routes;
