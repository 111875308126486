import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import CounterItem from './CounterItem';

const Counters = ({ counters }) => {
	return (
		<section
			className={styles.counters}
		>

			{counters.map(({ id, title, description }) => (
				<CounterItem
					key={id}
					title={title}
					description={description}
				/>
			))}

		</section>
	);
};

Counters.defaultProps = {
	counters: [],
};

Counters.propTypes = {
	counters: PropTypes.array,
};

export default Counters;