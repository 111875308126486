import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { BellSvg, CommentsSvg } from '../../assets/icons';
import {
  HOME_ROUTE, MESSAGES_ROUTE, PROFILE_ROUTE
} from '../../constants/routes';
import { signOut } from '../../services/actions/auth';
import UncontrolledDropdown from '../DropDown/UncontrolledDropdown';
import DropdownToggle from '../DropDown/DropdownToggle';
import DropdownMenu from '../DropDown/DropdownMenu';
import DropDownItem from '../DropDown/DropDownItem';
import MenuButton from '../Header/Menu/MenuButton';
import Navigation from '../Navigation';
import Avatar from '../Avatar';
import useModal from '../../hooks/useModal';
import useAuth from '../../hooks/useAuth';
import translate from '../../utils/translate';
import history from '../../utils/history';
import styles from './styles.module.scss';

const PrivateHeader = () => {
  const { profile } = useAuth();

  const { is_landlord, is_tenant, blocked } = profile;

  const tabs = useMemo(() => {

    if(is_tenant) {
      return [
        {
          location: '/',
          title: 'Overview',
          exact: true,
        },
        {
          location: '/properties',
          title: 'Properties',
        },
        {
          location: '/transactions',
          title: 'Transactions',
        },
        {
          location: '/messages',
          title: 'Messages',
        },
      ]
    }

    if(is_landlord) {
      if(blocked) {
        return [
          {
            location: '/',
            title: 'Overview',
            exact: true,
          },
        ]
      }

      return [
        {
          location: '/',
          title: 'Overview',
          exact: true,
        },
        {
          location: '/properties',
          title: 'Properties',
        },
        {
          location: '/tenants',
          title: 'Tenants',
        },
        {
          location: null,
          title: 'Accounting',
          exact: false,
          subTabs: [
            {
              location: '/transactions',
              title: 'Transactions',
            },
            {
              location: null,
              title: 'Reports',
              subTabs: [
                {
                  location: '/accounting/report/income-statement',
                  title: 'Income statement',
                },
              ]
            }
          ]
        },
        {
          location: '/messages',
          title: 'Messages',
        },
      ]
    }

    return [];
  }, [is_landlord, is_tenant, blocked]);

  const [menu, , , toggleMenu] = useModal(false);

  const toProfile = useCallback(() => history.push(PROFILE_ROUTE), []);
  const toMessages = useCallback(() => history.push(MESSAGES_ROUTE), []);

  return (
    <>
      <header className={styles.header}>

        <MenuButton
          isOpen={menu}
          onToggle={toggleMenu}
          className={styles.menuButton}
        />

        <Link
          to={HOME_ROUTE}
          className={styles.logo}
        >

          {process.env.REACT_APP_WEBSITE_NAME}

        </Link>

        <div className={styles.right}>

          <CommentsSvg
            className={styles.svg}
            onClick={toMessages}
          />

          <BellSvg
            className={styles.svg}
          />

          <UncontrolledDropdown>

            <DropdownToggle>

              <Avatar
                src={profile.avatar}
                variant="circle"
                size="40"
              />

            </DropdownToggle>

            <DropdownMenu
              position="right"
            >

              <DropDownItem
                onClick={toProfile}
                toggle
              >

                {translate('Profile')}

              </DropDownItem>

              <DropDownItem
                onClick={signOut}
                toggle
              >

                {translate('Sign out')}

              </DropDownItem>

            </DropdownMenu>

          </UncontrolledDropdown>

        </div>

      </header>

      <Navigation
        isOpen={menu}
        tabs={tabs}
      />

    </>
  );
}

export default React.memo(PrivateHeader);
