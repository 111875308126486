import React from 'react';
import useModal from '../../hooks/useModal';
import Menu from './Menu';
import MenuItem from './Menu/MenuItem';
import translate from '../../utils/translate';
import {
	CONTACTS_ROUTE,
	FEATURES_ROUTE,
	HOME_ROUTE,
	REQUEST_DEMO_ROUTE,
	SIGN_IN_ROUTE,
	SOLUTION_ROUTE
} from '../../constants/routes';
import MenuButton from './Menu/MenuButton';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import Button from '../Button';

const Header = () => {
	const [menu, , , toggleMenu] = useModal(false);

	return (
		<>

			<header className={styles.header}>

				<Link
					to={HOME_ROUTE}
					className={styles.logo}
				>

					{process.env.REACT_APP_WEBSITE_NAME}

				</Link>

				<Menu isOpen={menu}>

					<MenuItem to={FEATURES_ROUTE}>

						{translate('Features')}

					</MenuItem>

					<MenuItem to={SOLUTION_ROUTE}>

						{translate('Solution')}

					</MenuItem>

					{/* <MenuItem to={PRICING_ROUTE}>

						{translate('Pricing')}

					</MenuItem> */}

					<MenuItem to={CONTACTS_ROUTE}>

						{translate('Contacts')}

					</MenuItem>

					<MenuItem
						className={styles.dHide}
						to={REQUEST_DEMO_ROUTE}>

						{translate('Request Demo')}

					</MenuItem>

					<MenuItem
						className={styles.dHide}
						to={SIGN_IN_ROUTE}
					>

						{translate('Sign In')}

					</MenuItem>

					{/*<MenuItem
						className={styles.dHide}
						to={SIGN_UP_ROUTE}
					>

						{translate('Sign Up')}

					</MenuItem>*/}

				</Menu>

				<div className={styles.auth}>

					<Button
						tag={Link}
						to={SIGN_IN_ROUTE}
						color="primary"
						size="big"
					>

						{translate('Sign In')}

					</Button>

					<Button
						tag={Link}
						to={REQUEST_DEMO_ROUTE}
						color="primary"
						variant="contained"
						className={styles.requestDemoButton}
					>

						{translate('Request Demo')}

					</Button>

					{/*<Button
						tag={Link}
						to={SIGN_UP_ROUTE}
						variant="outlined"
						color="primary"
						size="big"
					>

						{translate('Sign Up')}

					</Button>*/}

				</div>

				<MenuButton
					isOpen={menu}
					onToggle={toggleMenu}
					className={styles.menuButton}
				/>

			</header>

			<div
				className={styles.space}
			/>

		</>
	);
};

export default Header;
