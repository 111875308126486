import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { tenantsSelectSelector } from '../services/selectors/tenants';
import { clearSelectTenant, getSelectTenants } from '../services/actions/tenants';

const selector = createSelector(
	tenantsSelectSelector,
	(select) => {
		const tenantOptions = select.map((option) => ({
			label: option.name,
			value: option.id,
		}));

		const leaseIdsByTenant = select.reduce((acc, item) => {
			acc[item.id] = item.leases;

			return acc
		} , {});

		return {
			tenantOptions,
			leaseIdsByTenant,
		};
	},
);

const useSelectTenants = (params = {}) => {
	const data = useSelector(selector);

	useEffect(() => {
		clearSelectTenant();
		getSelectTenants({
			params,
		});
	}, []);

	return {
		...data,
	};
};

export default useSelectTenants;