import React, { forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import styles from './styles.module.scss';

const InputCheckbox = forwardRef(({ id, name, title, className, value, noLabelClick, multiValue, variant, disabled, onChange, ...rest }, ref) => {
	const [field = {}, meta = {}, helpers] = useField(name);

	const { error, touched } = meta;

	const checked = multiValue
		? field.value.includes(value)
		: field.value === value;

	const handleChange = () => {
		let newValue;

		if (multiValue) {
			const formatValue = multiValue ? [...field.value, value] : [value];

			newValue = checked ? field.value.filter((item) => item !== value) : formatValue;
		} else {
			newValue = checked ? false : value;
		}

		helpers.setTouched(true);
		helpers.setValue(newValue);

		if (onChange) {
			onChange(newValue, name);
		}
	};

	return (
		<div
			className={cn(
				styles.inputCheckbox,
				{ [styles[variant]]: variant },
				{ [styles.checked]: checked },
				{ [styles.disabled]: disabled },
				{ [styles.error]: error && touched },
				className
			)}
		>

			<label htmlFor={noLabelClick ? '' : id}>

				{title}

			</label>

			<input
				ref={ref}
				id={id}
				type="checkbox"
				name={name}
				checked={checked}
				onBlur={field.onBlur}
				onChange={handleChange}
				value={value}
				disabled={disabled}
				{...rest}
			/>

		</div>
	);
});

InputCheckbox.defaultProps = {
	id: '',
	title: '',
	className: '',
	onChange: null,
	value: false,
	multiValue: false,
	disabled: false,
	noLabelClick: false,
	variant: 'default',
};

InputCheckbox.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
	]),
	onChange: PropTypes.func,
	multiValue: PropTypes.bool,
	disabled: PropTypes.bool,
	noLabelClick: PropTypes.bool,
	variant: PropTypes.oneOf([
		'default',
		'tag',
		'switch',
	])
};

export default InputCheckbox;
