import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import styles from './styles.module.scss';

const ModalClose = ({ className, onClose }) => {
	return (
		<button
			type="button"
			className={cn(
				styles.modalClose,
				className,
			)}
			onClick={onClose}
		>

			<CloseIcon

			/>

		</button>
	);
};

ModalClose.defaultProps = {
	className: '',
}

ModalClose.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func.isRequired,
}

export default ModalClose;