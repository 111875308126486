import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';
import { InputText } from '../../../../../../components/Inputs/InputText';
import ModalContent from '../../../../../../components/Modal/ModalContent';
import ModalHeader from '../../../../../../components/Modal/ModalHeader';
import ModalFooter from '../../../../../../components/Modal/ModalFooter';
import ModalTitle from '../../../../../../components/Modal/ModalTitle';
import ModalClose from '../../../../../../components/Modal/ModalClose';
import InputFile from '../../../../../../components/Inputs/InputFile';
import ModalBody from '../../../../../../components/Modal/ModalBody';
import InputDate from '../../../../../../components/Inputs/InputDate';
import Button from '../../../../../../components/Button';
import Label from '../../../../../../components/Label';
import { terminateLease } from '../../../../../../services/actions/leases';
import useLease from '../../../../../../hooks/useLease';
import translate from '../../../../../../utils/translate';
import st from '../../../../MoveIn/Agreements/styles.module.scss';


const ModalForm = ({ onClose }) => {
	const { leaseId } = useParams();

	const { lease } = useLease(Number(leaseId));

	const initValues = {
		date: new Date(),
		reason: '',
		document: '',
	};

	const schema = Yup.object().shape({
		date: Yup.date()
			.required(),
		reason: Yup.string()
			.required(),
		document: Yup.mixed()
			.imageTypes(['png', 'jpg', 'jpeg', 'pdf'])
			.fileMaxSize(),

	});

	const handleSubmit = useCallback((values, actions) => {
		terminateLease({
			leaseId: lease.id,
			unitId: lease.unit_id,
			...values,
		}, {...actions, onSuccess: onClose });
	}, [lease, onClose]);

	return (
		<ModalContent>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
			>

				<Form>

					<ModalHeader>

						<ModalTitle>

							{translate('Lease Termination')}

						</ModalTitle>

						<ModalClose onClose={onClose} />

					</ModalHeader>

					<ModalBody>

						<Label>

							{translate('Select Date')}

						</Label>

						<InputDate
							id="date"
							name="date"
							minDate={new Date(lease.date_start)}
							maxDate={new Date(lease.date_end)}
						/>

						<Label>

							{translate('Reason')}

						</Label>

						<InputText
							id="reason"
							name="reason"
							type="textarea"
						/>

						<div className={st.upload}>

							{translate('or upload a ready lease agreement')}

						</div>

						<InputFile
							id="document"
							name="document"
							heading={translate('Upload agreement')}
							description={translate('The file size is not more than 3mb.')}
						/>

					</ModalBody>

					<ModalFooter>

						<Button
							color="primary"
							onClick={onClose}
						>

							{translate('Cancel')}

						</Button>

						<Button
							color="primary"
							variant="contained"
							type="submit"
						>

							{translate('Confirm')}

						</Button>

					</ModalFooter>

				</Form>

			</Formik>

		</ModalContent>
	);
};

ModalForm.propTypes = {
	onClose: PropTypes.func.isRequired,
}

export default ModalForm;