import { createSelector } from 'reselect';

export const invoicesSelector = (state) => state.invoices;

export const invoiceIdSelector = (_, invoiceId) => invoiceId;

export const invoicesSelectSelector = createSelector(
	invoicesSelector,
  (invoices) => invoices.select,
);
