import React, { useCallback, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import translate from '../../../../../utils/translate';
import { InputSwitchUC } from '../../../../../components/Inputs/InputSwitch';
import Label from '../../../../../components/Label';
import cn from 'classnames';
import Button from '../../../../../components/Button';
import { CloseSvg, MinusSvg, PlusSvg } from '../../../../../assets/icons';
import InputDate from '../../../../../components/Inputs/InputDate';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const PERIODS = {
  day: 'Day',
  month: 'Month',
  year: 'Year',
}

const InputRange = ({ nameStart = '', nameEnd = '' }) => {

  const [ selectedPeriod, setSelectedPeriod ] = useState(PERIODS.day);
  const [ showRange, setShowRange ] = useState(false);

  const [ start, setStart ] = useState(null);
  const [ end, setEnd ] = useState(null);

  const [startField, startMeta, startHelpers] = useField(nameStart);
  const [endField, endMeta, endHelpers] = useField(nameEnd);

  const periodOptions = useMemo(() => Object.values(PERIODS).map(item => ({
    value: item,
    label: translate(item),
  })), []);

  const dateFormat = useMemo(() => {
    switch (selectedPeriod) {
      case PERIODS.day:
        return 'dd/MM/yyyy';
      case PERIODS.month:
        return 'MM/yyyy';
      case PERIODS.year:
        return 'yyyy';
      default:
        return 'dd/MM/yyyy';
    }
  }, [selectedPeriod]);

  const onChangeStart = (value) => {
    setStart(value);
  }

  const onChangeEnd = (value) => {
    let newValue = value;

    if(selectedPeriod === PERIODS.month) {
      newValue = new Date(value);
      newValue.setMonth(value.getMonth() + 1);
      newValue.setDate(value.getDate() - 1);
    }

    if(selectedPeriod === PERIODS.year) {
      newValue = new Date(value);
      newValue.setFullYear(value.getFullYear() + 1);
      newValue.setDate(value.getDate() - 1);
    }

    setEnd(newValue);
    endHelpers.setValue(newValue);
  }

  const onDeleteRange = useCallback(() => {
    setShowRange(false);
    endHelpers.setValue(null);
    setEnd(null);
  }, [endHelpers]);

  const onChangePeriod = useCallback((option) => {
    setSelectedPeriod(option.value);
    startHelpers.setValue(null);
    endHelpers.setValue(null);
  }, [startHelpers, endHelpers]);

  return (
    <>
      <p className={styles.label}>

        {translate('Choose period')} {selectedPeriod}

      </p>

      <InputSwitchUC
        tabs={periodOptions}
        value={selectedPeriod}
        onChange={onChangePeriod}
        className={styles.periodSwitcher}
      />

      <div className={styles.datesWrapper}>

        <Label htmlFor={nameStart}>

          {showRange ? translate('Start Date') : translate('Date')}

        </Label>

        <span />

        <div className={cn(styles.endRangeWrapper, !showRange && styles.hidden)}>

          {
            showRange && (

              <Label htmlFor={nameEnd}>

                {translate('End Date')}

              </Label>

            )
          }

          <Button
            onClick={onDeleteRange}
            className={styles.rangeButton}
          >

            <>

              <CloseSvg />

              {translate('Delete Range')}

            </>

          </Button>

        </div>

        <InputDate
          id={nameStart}
          name={nameStart}
          className={styles.inputDate}
          onChange={onChangeStart}
          maxDate={end}
          showMonthYearPicker={selectedPeriod === PERIODS.month}
          showYearPicker={selectedPeriod === PERIODS.year}
          dateFormat={dateFormat}
          selectsStart
          startDate={startField.value}
          endDate={endField.value}
        />

        { showRange ? (<div className={styles.minusIcon}><MinusSvg /></div>) : <span /> }

        { showRange ? (

          <InputDate
            id={nameEnd}
            name={nameEnd}
            className={cn(styles.inputDate, styles.endDate)}
            onChange={onChangeEnd}
            minDate={start}
            showMonthYearPicker={selectedPeriod === PERIODS.month}
            showYearPicker={selectedPeriod === PERIODS.year}
            dateFormat={dateFormat}
            selectsEnd
            startDate={startField.value}
            endDate={endField.value}
          />

        ) : (

          <Button
            onClick={() => setShowRange(true)}
            className={styles.rangeButton}
          >

            <>

              <PlusSvg />

              {translate('Add Range')}

            </>

          </Button>

        )}

      </div>
    </>
  )
}

export default InputRange;

InputRange.propTypes = {
  nameStart: PropTypes.string.isRequired,
  nameEnd: PropTypes.string.isRequired,
}
