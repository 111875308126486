import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as CommentIcon } from '../../assets/icons/comments.svg';
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg';
import styles from './styles.module.scss';
import { MESSAGES_ROUTE } from '../../constants/routes';
import NavigationItem from './NavigationItem';

const Navigation = ({ isOpen, tabs, className }) => (
  <div
    className={cn(
      styles.navigation,
      {[styles.open]: isOpen},
      className
    )}
  >

    <div className={styles.navList}>

      {tabs.map((tab) => (

        <NavigationItem key={tab.location} tab={tab} />

      ))}

    </div>

    <div className={styles.icons}>

      <Link
        to={MESSAGES_ROUTE}
        className={styles.icon}
      >

        <CommentIcon />

      </Link>

      <Link
        to="/"
        className={styles.icon}
      >

        <BellIcon />

      </Link>

    </div>

  </div>
);

Navigation.defaultProps = {
  className: '',
  isOpen: false,
};

Navigation.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    location: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
};

export default React.memo(Navigation);
