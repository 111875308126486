import React from 'react';
import PrivateLayout from '../../../layouts/PrivateLayout/index';
import Button from '../../../components/Button';
import st from './styles.module.scss';
import translate from '../../../utils/translate';
import TableSimple from '../../../components/TableSimple';
import Status from '../../../components/Status';

const TenantUpload = () => (
  <PrivateLayout>

    <div className={st.content}>

      <div className={st.wrapper}>

        {translate('Upload complete')}

      </div>

      <TableSimple>

        <thead>

        <tr>
          <th>{translate('Name')}</th>
          <th>{translate('Email')}</th>
          <th>{translate('Date of Birth')}</th>
          <th>{translate('Company')}</th>
          <th>{translate('Phone')}</th>
          <th>{translate('Status')}</th>
        </tr>

        </thead>

        <tbody>
        <tr>
          <td>Vitalii Herasumenko</td>
          <td>akumuliation@gmail.com</td>
          <td>04 May, 2020</td>
          <td>Company Inc</td>
          <td>380979847984</td>
          <td>
            <Status
              status={'active'}
            />
          </td>
        </tr>
        </tbody>

      </TableSimple>

      <div className={st.footer}>

        <Button
          color="primary"
          variant="contained"
        >

          {translate('Complete')}

        </Button>

      </div>

    </div>

  </PrivateLayout>
);

export default TenantUpload;
