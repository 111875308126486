import { createSelector } from 'reselect';

export const leasesSelector = (state) => state.leases;

export const leaseIdSelector = (_, leaseId) => leaseId;

export const leaseIdsSelector = createSelector(
	leasesSelector,
	(leases) => leases.ids,
);

export const leasesSelectSelector = createSelector(
	leasesSelector,
	(leases) => leases.select,
);

export const leaseIdsByTenantSelector = createSelector(
	leasesSelector,
	(leases) => leases.leasesByTenant,
);

export const leaseIdsByPropertySelector = createSelector(
	leasesSelector,
	(leases) => leases.leasesByProperty,
);

export const leaseIdsByUnitSelector = createSelector(
	leasesSelector,
	(leases) => leases.leasesByUnit,
);
