import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Label = ({ className, htmlFor, children }) => {

	return (
		<label
			htmlFor={htmlFor}
			className={cn(
				styles.label,
				className,
			)}
		>

			{children}

		</label>
	);
};

Label.defaultProps = {
	className: '',
	htmlFor: '',
	children: '',
};

Label.propTypes = {
	className: PropTypes.string,
	htmlFor: PropTypes.string,
	children: PropTypes.node,
};

export default Label;