import { CONFIGURATIONS } from '../../constants/type';

export const defaultState = {
  amenities: [],
  categories: [],
  subcategories: [],
  categories_money_in: [],
  categories_money_out: [],
  features: [],
  payment_directions: [],
  payment_modes: [],
  frequencies: [],
  types_property_unit: [],
  status_unit: [],
  type_tenants: [],
  status_tenant: [],
  unit_types_single: [],
  unit_types_multi: [],
  document_types: [],
  currencies: [],
  status_transaction: [],
  type_properties: [],
  unit_types_residential: [],
  unit_types_commercial: [],
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  if (type === CONFIGURATIONS.GET_AMENITIES.SUCCESS) {
    return {
      ...state,
      amenities: [...new Set([
        ...state.amenities,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_CATEGORIES.SUCCESS) {
    return {
      ...state,
      categories: [...new Set([
        ...state.categories,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_CATEGORIES_MONEY_IN.SUCCESS) {
    return {
      ...state,
      categories_money_in: [...new Set([
        ...state.categories_money_in,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_CATEGORIES_MONEY_OUT.SUCCESS) {
    return {
      ...state,
      categories_money_out: [...new Set([
        ...state.categories_money_out,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_FEATURES.SUCCESS) {
    return {
      ...state,
      features: [...new Set([
        ...state.features,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_PAYMENT_DIRECTIONS.SUCCESS) {
    return {
      ...state,
      payment_directions: [...new Set([
        ...state.payment_directions,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_PAYMENT_MODES.SUCCESS) {
    return {
      ...state,
      payment_modes: [...new Set([
        ...state.payment_modes,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_SUBCATEGORIES.SUCCESS) {
    return {
      ...state,
      subcategories: [...new Set([
        ...state.subcategories,
        ...payload,
      ])],
    };
  }

  if (type === CONFIGURATIONS.GET_CONSTANTS.SUCCESS) {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
};
