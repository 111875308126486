import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/icons/linkedin.svg';
import {
	CONTACTS_ROUTE,
	FEATURES_ROUTE,
	HOME_ROUTE,
	PRIVACY_ROUTE,
	SIGN_IN_ROUTE,
	SOLUTION_ROUTE, TERMS_ROUTE
} from '../../constants/routes';
import translate from '../../utils/translate';
import FooterMenu from './FooterMenu';
import MenuItem from './FooterMenu/MenuItem';
import Socials from './Socials';
import SocialItem from './Socials/SocialItem';
import styles from './styles.module.scss';

const Footer = () => {
	const date = new Date();

	return (
		<footer className={styles.footer}>

			<div className={styles.wrap}>

				<div className={styles.row}>

					<Link
						to={HOME_ROUTE}
						className={styles.logo}
					>

						{process.env.REACT_APP_WEBSITE_NAME}

					</Link>

					<Socials>

						<SocialItem
							to="https://www.facebook.com/getkoddi/"
						>

							<FacebookIcon
								fill=""
							/>

						</SocialItem>

						<SocialItem
							to="https://www.linkedin.com/company/getkoddi"
						>

							<LinkedinIcon
								fill=""
							/>

						</SocialItem>

						<SocialItem
							to="https://twitter.com/getkoddi"
						>

							<TwitterIcon
								fill=""
							/>

						</SocialItem>

					</Socials>

				</div>

				<div
					className={cn(
						styles.row,
						styles.menus
					)}
				>

					<FooterMenu
						className={styles.menu}
					>

						<MenuItem
							to={FEATURES_ROUTE}
						>

							{translate('Features')}

						</MenuItem>

						<MenuItem
							to={SOLUTION_ROUTE}
						>

							{translate('Solution')}

						</MenuItem>

						{/* <MenuItem
							to={PRICING_ROUTE}
						>

							{translate('Pricing')}

						</MenuItem> */}

					</FooterMenu>

					<FooterMenu
						className={styles.menu}
					>

						<MenuItem
							to={CONTACTS_ROUTE}
						>

							{translate('Contacts')}

						</MenuItem>

						 <MenuItem
							to={SIGN_IN_ROUTE}
						>

							{translate('Sign In')}

						</MenuItem>

						{/* <MenuItem
							to={SIGN_UP_ROUTE}
						>

							{translate('Sign Up')}

						</MenuItem> */}

					</FooterMenu>

				</div>

			</div>

			<div
				className={cn(
					styles.wrap,
					styles.wrapCP,
				)}
			>

				<div className={styles.row}>

					<p className={styles.copyright}>

						Copyright {date.getFullYear()}

					</p>

				</div>

				<div
					className={cn(
						styles.row,
						styles.menus
					)}
				>

					<FooterMenu
						className={styles.menu}
					>

						<MenuItem
							to={TERMS_ROUTE}
							light
						>

							{translate('Terms')}

						</MenuItem>

					</FooterMenu>

					<FooterMenu
						className={styles.menu}
					>

						<MenuItem
							to={PRIVACY_ROUTE}
							light
						>

							{translate('Privacy')}

						</MenuItem>

					</FooterMenu>

				</div>

			</div>

		</footer>
	);
};

export default Footer;
