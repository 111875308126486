import React, { useEffect, useMemo } from 'react';
import Filter from '../../../../components/Filter';
import { changeUnitsFilter } from '../../../../services/actions/units';
import useConfigurations from '../../../../hooks/useConfigurations';
import translate from '../../../../utils/translate';

export default function UnitsFilter({ filter: { property_unit_type, property_type, unit_type, status, search } }) {
  const { types_property_unit, type_properties, status_unit, unit_types_residential, unit_types_commercial } = useConfigurations();

  const typeOptions = useMemo(() => types_property_unit.map((item) => ({
    label: item.name,
    value: item.id,
  })), [types_property_unit]);

  const propertyTypeOptions = useMemo(() => type_properties.map((item) => ({
    label: item.name,
    value: item.id,
  })), [type_properties]);

  const unitTypeOptions = useMemo(() => {
    let array = [...unit_types_residential, ...unit_types_commercial];

    if(property_type === 'residential') {
      array = unit_types_residential;
    } else if(property_type === 'commercial') {
      array = unit_types_commercial;
    }

    return array.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [unit_types_residential, unit_types_commercial, property_type]);

  const statusOptions = useMemo(() => status_unit.map((item) => ({
    label: item.name,
    value: item.id,
  })), [status_unit]);

  const filterInputs = useMemo(() => ([
    {
      type: 'select',
      name: 'property_unit_type',
      label: translate('Type'),
      value: property_unit_type,
      options: typeOptions,
    },
    {
      type: 'select',
      name: 'property_type',
      label: translate('Property Type'),
      value: property_type,
      options: propertyTypeOptions,
    },
    {
      type: 'select',
      name: 'unit_type',
      label: translate('Unit Type'),
      value: unit_type,
      options: unitTypeOptions,
    },
    {
      type: 'select',
      name: 'status',
      label: translate('Status'),
      value: status,
      options: statusOptions,
    },
  ]), [property_unit_type, typeOptions, status, statusOptions, unit_type, unitTypeOptions, property_type, propertyTypeOptions]);

  const searchInput = useMemo(() => ({
    name: 'search',
    label: translate('Search'),
    value: search
  }), [search]);

  useEffect(() => {
    if(property_type) {
      const array = (property_type === 'residential' ? unit_types_residential : unit_types_commercial)
        .map((item) => item.id);

      if(!array.includes(unit_type)) {
        changeUnitsFilter('', 'unit_type')
      }
    }
  }, [property_type, unit_type]);

  return (
    <Filter
      filterInputs={filterInputs}
      searchInput={searchInput}
      onChange={changeUnitsFilter}
    />
  );
}
