import { AUTH, LEASES } from '../../constants/type';

export const defaultState = {
	ids: [],
	select: [],
	leasesByTenant: {},
	leasesByProperty: {},
	leasesByUnit: {},
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case LEASES.GET_LIST.SUCCESS:
			return {
				...state,
				ids: [...new Set([
					...state.ids,
					...payload.result,
				])],
			};

		case LEASES.CREATE_LEASE.SUCCESS:
			return {
				...state,
				ids: [...new Set([
					...state.ids,
					payload.result,
				])],
			};

		case LEASES.GET_LEASES_OPTIONS.SUCCESS:
			return {
				...state,
			  select: payload,
				/*Object.keys(payload).map((key) => ({
			    id: Number(key),
			    name: payload[key],
			  })),*/
			};

		case LEASES.CLEAR_SELECT.base:
			return {
			  ...state,
			  select: [],
			};


		case LEASES.GET_LEASES_BY_TENANT.SUCCESS:
			return {
				...state,
				leasesByTenant: {
					...state.leasesByTenant,
					[payload.requestData.tenant]: payload.result,
				},
			};

		case LEASES.GET_LEASES_BY_PROPERTY.SUCCESS:
			return {
				...state,
				leasesByProperty: {
					...state.leasesByProperty,
					[payload.requestData.property]: payload.result,
				},
			};

		case LEASES.GET_LEASES_BY_UNIT.SUCCESS:
			return {
				...state,
				leasesByUnit: {
					...state.leasesByUnit,
					[payload.requestData.unit]: payload.result,
				},
			};

		case AUTH.SIGN_OUT.SUCCESS:
		case AUTH.GET_PROFILE.FAILURE: {
			return defaultState;
		}

		default:
			return state;
	}
};
