import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { InputText } from '../../../../components/Inputs/InputText';
import InputFile from '../../../../components/Inputs/InputFile';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import { PROPERTIES_ROUTE } from '../../../../constants/routes';
import { createLease } from '../../../../services/actions/leases';
import history from '../../../../utils/history';
import translate from '../../../../utils/translate';
import st from './styles.module.scss';

export default function Agreements({ values, setStep }) {
  const initValues = {
    agreement: values.agreement,
    document: values.document,
  };

  const schema = Yup.object().shape({
    agreement: Yup.string(),
    document: Yup.mixed()
      .imageTypes(['png', 'jpg', 'jpeg', 'pdf'])
      .fileMaxSize(),
  });

  const handlePrev = useCallback(() => setStep(3), [setStep]);

  const handleSubmit = useCallback((formValues) => {
    const onSuccess = () => {
      history.push(`${PROPERTIES_ROUTE}/${values.unit}`);
    }

    createLease({
      ...values,
      ...formValues,
    }, { onSuccess } );
  }, [values]);

  return (
    <div className={st.wrapper}>

      <div className={st.header}>

        {translate('Agreements')}

      </div>

      <Formik
        initialValues={initValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >

        {({ isSubmitting }) => (
          <Form>

            <Label>

              {translate('Create your agreement')}

            </Label>

            <InputText
              id="agreement"
              name="agreement"
              type="textarea"
              placeholder={translate('Type your text here...')}
            />

            <div className={st.upload}>

              {translate('or upload a ready lease agreement')}

            </div>

            <InputFile
              id="document"
              name="document"
              heading={translate('Upload agreement')}
              description={translate('The file size is not more than 3mb.')}
            />

            <div className={st.footer}>

              <Button
                color="primary"
                onClick={handlePrev}
              >

                {translate('Back')}

              </Button>

              <Button
                color="primary"
                variant="contained"
                loading={isSubmitting}
                type="submit"
              >

                {translate('Confirm')}

              </Button>

            </div>

          </Form>
        )}

      </Formik>

    </div>
  );
}

Agreements.propTypes = {
  values: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
};
