import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';
import Button from '../../Button';
import Card from '../../Card';
import translate from '../../../utils/translate';
import styles from './styles.module.scss';
import { TRANSACTION_PAY_INVOICE_ROUTE } from '../../../constants/routes';

export default function Balance({
  className, title, price, isTenant
}) {
  return (
    <Card
      className={cn(
        styles.card,
        className,
      )}
    >

      <CardHeader className={styles.title}>

        <h3>

          {title}

        </h3>

        {/*<div
          className={cx(
            st.percent,
            { [st.positive]: +percent > 0 },
            { [st.negative]: +percent < 0 },
          )}
        >
          {percent ? `${percent}  %` : ''}
        </div>*/}

        <Button
          tag={Link}
          to={TRANSACTION_PAY_INVOICE_ROUTE}
          color="info"
          variant="shadow"
          size="small"
          className={cn(
            styles.button,
            {
              [styles.isTenant]: true, //isTenant,
            }
          )}
        >

          {translate('Pay')}

        </Button>

      </CardHeader>

      <CardBody className={styles.price}>

        {price}

      </CardBody>

    </Card>
  );
}

Balance.defaultProps = {
  className: '',
  price: null,
  percent: '',
  isTenant: false,
};

Balance.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  price: PropTypes.string,
  percent: PropTypes.string,
  isTenant: PropTypes.bool,
};
