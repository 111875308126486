import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { transactionsFilterSelector } from '../services/selectors/transactions';

const selector = createSelector(
	transactionsFilterSelector,
	({ direction, category, status, mode, search }) => {

		return {
			direction,
			category,
			status,
			mode,
			search,
		};
	},
);

const useTransactionsFilter = () => {
	const data = useSelector(selector);

	return {
		...data,
	};
};

export default useTransactionsFilter;