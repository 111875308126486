import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { transactionIdsByUnitSelector } from '../services/selectors/transactions';
import { getTransactionsByUnit } from '../services/actions/transactions';
import { unitIdSelector } from '../services/selectors/units';

const selector = createSelector(
	transactionIdsByUnitSelector,
	unitIdSelector,
	(transactionIdsByUnit, unitId) => {
		const ids = transactionIdsByUnit[unitId];

		return {
			ids,
		};
	},
);

const useTransactionsByUnit = (unitId, refresh) => {
	const newSelector = useCallback((state) => selector(state, unitId), [unitId]);

	const { ids } = useSelector(newSelector);

	useEffect(() => {
		if (!ids && typeof unitId === 'number' && !isNaN(unitId)) {
			getTransactionsByUnit(unitId);
		}
	}, [ids, unitId]);

	useEffect(() => {
		if (refresh && typeof unitId === 'number' && !isNaN(unitId)) {
			getTransactionsByUnit(unitId);
		}
	}, [refresh, unitId]);

	return {
		ids,
	};
};

export default useTransactionsByUnit;