import { InputSelect } from '../../../../../components/Inputs/InputSelect';
import React, { useEffect, useMemo, useState } from 'react';
import translate from '../../../../../utils/translate';
import { components } from 'react-select';
import styles from '../../../../../components/Inputs/InputLocation/styles.module.scss';
import { AngleDown, LocationPoint } from '../../../../../assets/icons';
import { useField } from 'formik';
import Label from '../../../../../components/Label';
import { InputText } from '../../../../../components/Inputs/InputText';

const IndicatorSeparator = () => {
  return <span/>;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <span className={styles.locationIcon}>
        <AngleDown />
      </span>
    </components.DropdownIndicator>
  );
};

const InputRole = ({ nameForRole, nameForInput }) => {

  const [showInput, setShowInput] = useState(false);
  const [fieldRole, metaRole, helpersRole] = useField(nameForRole);
  const [fieldInput, metaInput, helpersInput] = useField(nameForInput);

  const roleOptions = useMemo(() => {
    return [
      {
        label: translate('Landlord'),
        value: 'L',
      },
      {
        label: translate('Property-Manager'),
        value: 'P',
      },
      {
        label: translate('Others'),
        value: 'O',
      }
    ]
  }, []);

  useEffect(() => {
    if(fieldRole.value === 'O') {
      setShowInput(true);
    } else {
      setShowInput(false);
      helpersInput.setValue('');
    }
  }, [fieldRole.value]);

  return (
    <>
      <InputSelect
        id={nameForRole}
        name={nameForRole}
        options={roleOptions}
        components={{ IndicatorSeparator, DropdownIndicator }}
        placeholder=""
      />

      {
        showInput && (
          <>

            <Label htmlFor={nameForInput}>

              {translate('Role title')}*

            </Label>

            <InputText
              id={nameForInput}
              name={nameForInput}
            />

          </>
        )
      }
    </>
  )
}

export default InputRole;
