import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { leaseIdsByUnitSelector } from '../services/selectors/leases';
import { getLeasesByUnit } from '../services/actions/leases';
import { unitIdSelector } from '../services/selectors/units';
import { entitiesLeasesSelector } from '../services/selectors/entities';

const selector = createSelector(
	entitiesLeasesSelector,
	leaseIdsByUnitSelector,
	unitIdSelector,
	(leases, leaseIdsByUnit, unitId) => {
		const ids = leaseIdsByUnit[unitId];

		const list = (ids || []).map((id) => leases[id]);

		return {
			ids,
			list,
		};
	},
);

const useLeasesByUnit = (unitId, refresh) => {
	const newSelector = useCallback((state) => selector(state, unitId), [unitId]);

	const { ids, list } = useSelector(newSelector);

	useEffect(() => {
		if (!ids && typeof unitId === 'number' && !isNaN(unitId)) {
			getLeasesByUnit(unitId);
		}
	}, [ids, unitId]);

	useEffect(() => {
		if (refresh && typeof unitId === 'number' && !isNaN(unitId)) {
			getLeasesByUnit(unitId);
		}
	}, [refresh, unitId]);

	return {
		ids: ids || [],
		list,
	};
};

export default useLeasesByUnit;