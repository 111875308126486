import React from 'react';
import translate from '../../../utils/translate';

const SuperuserRouter = () => (
	<div>

		{translate('Super User Not have application portal')}

	</div>
);

export default SuperuserRouter;