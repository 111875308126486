import { createSelector } from 'reselect';

const authSelector = (state) => state.auth;

export const isAuthorizedSelector = createSelector(
	authSelector,
	(auth) => auth.isAuthorized,
);

export const profileSelector = createSelector(
	authSelector,
	(auth) => auth.profile,
);

export const paymentMethodsSelector = createSelector(
	authSelector,
	(auth) => auth.paymentMethods,
);

export const banksSelector = createSelector(
	authSelector,
	(auth) => auth.banks,
);