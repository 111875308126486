import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { tenantsFilterSelector } from '../services/selectors/tenants';

const selector = createSelector(
	tenantsFilterSelector,
	({ type, status, search }) => {

		return {
			type,
			status,
			search,
		};
	},
);

const useTenantsFilter = () => {
	const data = useSelector(selector);

	return {
		...data,
	};
};

export default useTenantsFilter;