import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const MenuButton = ({ className, isOpen, onToggle }) => {
	return (
		<button
			type="button"
			className={cn(
				className,
				styles.menuButton,
				{[styles.active]: isOpen}
			)}
			onClick={onToggle}
		>

			<span/>

			<span/>

			<span/>

			<span/>

		</button>
	);
};

MenuButton.defaultProps = {
	isOpen: false,
	className: '',
};

MenuButton.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	onToggle: PropTypes.func.isRequired,
};

export default MenuButton;