import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Status = ({ className, status, children }) => (
  <div
    className={cn(
      styles.status,
      { [styles[status]]: status },
      className,
    )}
  >

    {children || status}

  </div>
);

Status.defaultProps = {
  className: '',
  children: '',
}

Status.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default React.memo(Status);
