import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

const Hero = ({ title, description, image }) => {
	return (
		<section className={styles.hero}>

			<div className={styles.wrap}>

				<h2 className={styles.title}>

					{title}

				</h2>

				<p className={styles.description}>

					{description}

				</p>

				<div className={styles.buttons}>

					<Button
						tag={Link}
						to={SIGN_IN_ROUTE}
						color="secondary"
						variant="shadow"
						size="big"
						className={styles.button}
					>

						{translate('Learn More')}

					</Button>

					<Button
						tag={Link}
						to={SIGN_IN_ROUTE}
						color="primary"
						variant="contained"
						size="big"
						className={styles.button}
					>

						{translate('Get Started')}

					</Button>


				</div>

			</div>

			<div className={styles.promo}>

				{image && (
					<figure className={styles.image}>

						<img src={image} alt="hero" />

					</figure>
				)}

			</div>

		</section>
	);
};

Hero.defaultProps = {
	title: '',
	description: '',
	image: '',
};

Hero.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
};

export default Hero;