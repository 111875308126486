import React from 'react';
import PropTypes from 'prop-types';
import rentIcon from '../../../../assets/icons/expand.svg';
import Feature from '../../../../components/Widgets/Features';
import useConfigurations from '../../../../hooks/useConfigurations';
import translate from '../../../../utils/translate';
import st from './styles.module.scss';

export default function Features({ rent, features }) {
  const { features: list } = useConfigurations();

  return (
    <div className={st.wrapper}>

      <div className={st.heading}>{translate('Apartment Features')}</div>

      <div className={st.list}>

        {features.map((feature) => {
          const featureItem = list.find((item) => item.id === feature.feature);

          return (
            <Feature
              key={feature.id}
              title={featureItem?.name}
              icon={featureItem?.image}
              quantity={feature.quantity}
            />
          )
        })}

        <Feature
          title={translate('Property size')}
          icon={rentIcon}
          quantity={`${rent} m2`}
        />

      </div>

    </div>
  );
};

Features.propTypes = {
  features: PropTypes.array,
}
