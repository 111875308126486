import React from 'react';
import PropTypes from 'prop-types';
import Benefit from './Benefit';
import styles from './styles.module.scss';

const Benefits = ({ title, benefits }) => {
	return (
		<section
			className={styles.benefits}
		>

			<h2
				className={styles.title}
			>

				{title}

			</h2>

			<div className={styles.wrap}>

				{benefits.map(({ id, icon, title, description }) => (
					<Benefit
						key={id}
						icon={icon}
						title={title}
						description={description}
					/>
				))}

			</div>

		</section>
	);
};

Benefits.defaultProps = {
	title: '',
	benefits: [],
}

Benefits.propTypes = {
	title: PropTypes.string,
	benefits: PropTypes.array,
}

export default Benefits;