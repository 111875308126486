import { AUTH, WIDGETS } from '../../constants/type';

export const defaultState = {
	main: [],
	byTenant: {},
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case WIDGETS.GET_MAIN.SUCCESS:
			return {
				...state,
				main: payload,
			};

		case WIDGETS.GET_BY_TENANT.SUCCESS:
			const { requestData, ...rest } = payload;

			return {
				...state,
				byTenant: {
					...state.byTenant,
					[requestData.tenantId]: Object.keys(rest).map((key) => rest[key]),
				}
			};

		case AUTH.SIGN_OUT.SUCCESS:
		case AUTH.GET_PROFILE.FAILURE: {
			return defaultState;
		}

		default:
			return state;
	}
};