import React from 'react';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';
import { InputText } from '../../../../components/Inputs/InputText';
import InputPhone from '../../../../components/Inputs/InputPhone';
import InputDate from '../../../../components/Inputs/InputDate';
import Avatar from '../../../../components/Avatar';
import Status from '../../../../components/Status';
import Loader from '../../../../components/Loader';
import Label from '../../../../components/Label';
import useConfigurations from '../../../../hooks/useConfigurations';
import useTenant from '../../../../hooks/useTenant';
import translate from '../../../../utils/translate';
import styles from '../styles.module.scss';

const Detail = () => {
	const { tenantId } = useParams();

	const { document_types, status_tenant } = useConfigurations();

	const { tenant } = useTenant(Number(tenantId));

	if(!tenant) {
		return (<Loader />);
	}

	const initValues = {
		birthday: new Date(tenant.birthday),
		phone: tenant.phone || '',
		address: tenant.address || '',
		city: tenant.city || '',
	};

	return (
		<>

			<div className={styles.wrapTitle}>

				<h2 className={styles.title}>

					{translate('General Info')}

				</h2>

			</div>

			<div className={styles.personalInfo}>

				<Avatar
					src={tenant?.avatar}
					variant="circle"
					size="72"
				/>

				<div className={styles.personalInfoWrap}>

					<h3>{[tenant?.first_name, tenant?.last_name].join(' ')}</h3>

					<p>{tenant?.email}</p>

				</div>

			</div>

			<Formik
				initialValues={initValues}
				onSubmit={() => {}}
			>

				<Form>

					<Label>

						{translate('Date of Birth')}

					</Label>

					<InputDate
						id="birthday"
						name="birthday"
						disabled
					/>

					<Label>

						{translate('Phone')}

					</Label>

					<InputPhone
						id="phone"
						name="phone"
						disabled
					/>

					<Label>

						{translate('Address')}

					</Label>

					<InputText
						id="address"
						name="address"
						disabled
					/>

					<Label>

						{translate('City')}

					</Label>

					<InputText
						id="city"
						name="city"
						disabled
					/>

				</Form>

			</Formik>

			<div className={styles.verification}>

				<h3>{translate('Verification')}</h3>

				<div className={styles.verificationWrap}>

					<div className={styles.verificationItem}>

						<h4>{translate('Document')}</h4>

						<p>{document_types.find((document) => document.id === tenant.identity_type)?.name}</p>

					</div>

					<div className={styles.verificationItem}>

						<h4>{translate('Status')}</h4>

						<Status
							status={tenant.status}
						>

							{status_tenant.find((status) => status.id === tenant.status)?.name}

						</Status>

					</div>

					<div className={styles.verificationItem}>

						<h4>{translate('Date')}</h4>

						<p>{moment(tenant.birthday).format('DD MMMM, YYYY')}</p>

					</div>

				</div>

			</div>

		</>
	);
};

export default Detail;