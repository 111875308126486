import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ExternalLinkSvg } from '../../assets/icons';
import st from './styles.module.scss';

export default function WidgetWrapper({
  children, title, className, externalLink
}) {
  return (
    <div className={cx(st.wrapper, className)}>

      <div className={st.header}>

        {title}

        {externalLink && (
          <Link to={externalLink}>

            <ExternalLinkSvg className={st.svg} />

          </Link>
        )}

      </div>

      {children}

    </div>
  );
}

WidgetWrapper.defaultProps = {
  title: '',
  className: '',
  buttons: null,
};

WidgetWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  externalLink: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.func,
  })),
};
