import React, { useCallback, useState } from 'react';
import PrivateLayout from '../../../../layouts/PrivateLayout';
import styles from './styles.module.scss';
import translate from '../../../../utils/translate';
import { ReactComponent as LeftIcon } from '../../../../assets/icons/angle-left.svg';
import Button from '../../../../components/Button';
import history from '../../../../utils/history';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputRange from './InputRange';
import InputProperty from './InputProperty';
import moment from 'moment';
import { getStatementReport } from '../../../../services/actions/units';
import ReportPage from './ReportPage';
import useAuth from '../../../../hooks/useAuth';


export default function ReportIncomeStmt() {

  const [ report, setReport ] = useState(null);
  const { profile } = useAuth();

  const initValues = {
    start_date: '',
    end_date: null,
    units: [],
  };

  const schema = Yup.object().shape({
    start_date: Yup.date()
      .required(),
    end_date: Yup.date().nullable(),
    units: Yup.array(),
  });

  const handleSubmit = useCallback((values) => {
    const date_after = moment(values.start_date).format('YYYY-MM-DD');
    const date_before = values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null;
    const unit_id_in = values.units && values.units.length > 0 ? values.units.join(',') : '';

    getStatementReport({
      params: {
        date_after,
        date_before,
        unit_id_in,
      },
      actions: {
        onSuccess: (response) => {
          const sections = Array.from(Object.values(response)).sort((s1, s2) => s1.order - s2.order);
          setReport({
            sections,
            startDate: values.start_date,
            endDate: values.end_date,
          });
        }
      }
    })
  }, []);

  const onReportBackClick = useCallback(() => {
    setReport(null);
  }, []);

  return (
    <PrivateLayout>
      {

        report ? (

          <ReportPage
            report={report}
            profile={profile}
            onBackClick={onReportBackClick}
          />

        ) : (

          <div className={styles.incomeStmtCreate}>

            <div className={styles.heading}>

              <Button
                onClick={history.goBack}
                variant="goBack"
                isIcon
                svg={<LeftIcon />}
              />

              <h3 className={styles.title}>

                {translate('Income Statement Report')}

              </h3>

            </div>

            <div className={styles.formWrapper}>

              <h4 className={styles.subtitle}>

                {translate('General Info')}

              </h4>

              <Formik
                initialValues={initValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (

                  <Form>

                    <InputRange
                      nameStart={"start_date"}
                      nameEnd={"end_date"}
                    />

                    <InputProperty
                      name="units"
                      className={styles.inputProperty}
                    />

                    <div className={styles.formButtons}>

                      <Button
                        onClick={history.goBack}
                        className={styles.cancelButton}
                      >

                        {translate('Cancel')}

                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={isSubmitting}
                        size="big"
                      >

                        {translate('Get a report')}

                      </Button>

                    </div>

                  </Form>

                )}

              </Formik>

            </div>

          </div>

        )

      }

    </PrivateLayout>
  )
}
