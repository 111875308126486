import React from 'react';
import PropTypes from 'prop-types';
import PrivateHeader from '../../components/PrivateHeader';
import st from './styles.module.scss';

const PrivateLayout = ({ children }) => (
  <>

    <PrivateHeader />

    <div className={st.content}>

      {children}

    </div>

  </>
);

PrivateLayout.defaultProps = {
  children: '',
};

PrivateLayout.propTypes = {
  children: PropTypes.node,
};

export default PrivateLayout;
