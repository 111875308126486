import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as More } from '../../../assets/icons/more.svg';
import DropdownContext from '../../../contexts/DropdownContext';
import Button from '../../Button';

const DropdownToggle = ({
  className, disabled, onClick, children,
}) => {
  const dropdownContext = useContext(DropdownContext);

  const handleClick = (e) => {
    if (disabled || dropdownContext.disabled) {
      e.preventDefault();
    } else {
      if (onClick) {
        onClick(e);
      }

      dropdownContext.toggle(e);
    }
  };

  return (
    <div
      role="presentation"
      className={cn(
        className,
      )}
      onClick={handleClick}
    >

      {children}

    </div>
  );
};

DropdownToggle.defaultProps = {
  className: '',
  disabled: false,
  onClick: null,
  children: (
    <Button
      isIcon
      size="big"
    >

      <More
        fill=""
        width="6"
        height="22"
      />

    </Button>
  ),
};

DropdownToggle.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default DropdownToggle;
