import React, { useMemo, useRef } from 'react';
import styles from './styles.module.scss';
import Button from '../../../../../components/Button';
import { ReactComponent as LeftIcon } from '../../../../../assets/icons/angle-left.svg';
import translate from '../../../../../utils/translate';
import { useReactToPrint } from 'react-to-print';
import Pdf from 'react-to-pdf';
import ReportSection from './ReportSection';
import { reportStyles } from './reportStyles';
import moment from 'moment/moment';
import jsPDF from 'jspdf';
import { fontBlack, fontMedium, fontRegular, fontSemiBold } from '../pdf-utils/pdfFonts';
import PropTypes from 'prop-types';
import { formatPhone, formatPriceStr } from '../../../../../utils/formatHelper';
import ReportSummaryRow from './ReportSummaryRow';

const KENYA_CURRENCY = 'Kes';

export default function ReportPage({ report, onBackClick, profile }) {

  const reportRef = useRef();
  const onPrint = useReactToPrint({
    content: () => reportRef.current,
  });

  const startDate = useMemo(() => {
    return report.startDate ? moment(report.startDate).format('DD/MM/YYYY') : '';
  }, [report]);

  const endDate = useMemo(() => {
    return report.endDate ? moment(report.endDate).format('DD/MM/YYYY') : '';
  }, [report]);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });

    let pWidth = doc.internal.pageSize.width;
    let srcWidth = reportRef.current.scrollWidth;
    let scale = pWidth / srcWidth;

    // Adding the fonts.

    doc.addFileToVFS('Poppins-Regular-normal.ttf', fontRegular);
    doc.addFont('Poppins-Regular-normal.ttf', 'Poppins', 'normal');

    // doc.addFileToVFS('Poppins-Medium-normal.ttf', fontMedium);
    // doc.addFont('Poppins-Medium-normal.ttf', 'Poppins', 'normal', 500);
    //
    // doc.addFileToVFS('Poppins-SemiBold-normal.ttf', fontSemiBold);
    // doc.addFont('Poppins-SemiBold-normal.ttf', 'Poppins', 'normal', 600);
    //
    // doc.addFileToVFS('Poppins-Black-normal.ttf', fontBlack);
    // doc.addFont('Poppins-Black-normal.ttf', 'Poppins', 'normal', 900);

    doc.setFont('Poppins', 'normal', 400);

    doc.html(reportRef.current, {
      html2canvas: {
        scale: scale,
      },
      autoPaging: 'slice',
      async callback(doc) {
        await doc.save('document');
      },
    });
  };

  return (
    <div className={styles.incomeStmt}>

        <div className={styles.heading}>

            <Button
              onClick={onBackClick}
              variant="goBack"
              isIcon
              svg={<LeftIcon />}
            />

            <h3 className={styles.title}>

              {translate('Income Statement Report')}

            </h3>

            {/*<Pdf targetRef={reportRef} filename="example.pdf" scale={1}>*/}

            {/*  {({ toPdf }) => (*/}

            {/*    <Button*/}
            {/*      color="secondary"*/}
            {/*      variant="shadow"*/}
            {/*      onClick={toPdf}*/}
            {/*      size="small"*/}
            {/*    >*/}

            {/*      {translate('PDF')}*/}

            {/*    </Button>*/}

            {/*  )}*/}

            {/*</Pdf>*/}

            <Button
              color="secondary"
              variant="shadow"
              onClick={handleGeneratePdf}
              size="small"
            >

              {translate('PDF')}

            </Button>

            <Button
              color="secondary"
              variant="shadow"
              onClick={onPrint}
              size="small"
            >

              {translate('Print')}

            </Button>

          </div>

        <div className={styles.reportWrapper}>

          <div style={reportStyles.wrapper} ref={reportRef} id="reportIncomeStmt">

            <div style={reportStyles.heading}>

              <div style={reportStyles.landlordDetails}>

                <h4 style={reportStyles.landlordName}>

                  {profile.first_name || ''} {profile.last_name || ''}

                </h4>

                <div style={reportStyles.companyName}>

                  {profile.company || ''}

                </div>

                <div style={reportStyles.landlordInfo}>

                  {formatPhone(profile.phone) || ''}

                </div>

                <div style={reportStyles.landlordInfo}>

                  {profile.email || ''}

                </div>

                <div style={reportStyles.landlordInfo}>

                  {profile.address || ''}

                </div>

              </div>

              <div style={reportStyles.info}>

                <h5 style={reportStyles.infoLabel}>

                  {translate('Income Statement: ')}

                </h5>

                <div style={reportStyles.infoDates}>

                <span>

                  {startDate}

                </span>

                  {
                    !!endDate && (
                      <span>

                      &nbsp;-&nbsp;{endDate}

                    </span>
                    )
                  }

                </div>

              </div>

            </div>

            <div style={reportStyles.content}>

              {
                report?.sections?.map((section) => {

                  return section?.detailed ? (

                    <ReportSection
                      key={section.section}
                      title={translate(section.section)}
                      list={[...section.income_data, ...section.outcome_data]}
                      totalLabel={translate(section.total_label) || translate(`Total ${section.section}`)}
                      totalAmount={section.net_operating_income}
                      currency={KENYA_CURRENCY}
                    />

                  ) : (

                    <ReportSummaryRow
                      key={section.section}
                      title={translate(section.section)}
                      totalAmount={section.net_operating_income}
                      currency={KENYA_CURRENCY}
                    />

                  )

                })
              }

              <div style={reportStyles.footerCaption}>

                <div style={reportStyles.footerGeneratedBy} >

                  {translate('Generated by')}

                </div>

                <div style={reportStyles.footerLogo}>

                  {process.env.REACT_APP_WEBSITE_NAME}

                </div>

              </div>

              {/*<ReportSection*/}
              {/*  title={translate('Operating Expenses')}*/}
              {/*  list={report.outcome_data}*/}
              {/*  totalLabel={translate('Total Operating Expenses')}*/}
              {/*  totalAmount={report.outcome_total_amount}*/}
              {/*  currency={KENYA_CURRENCY}*/}
              {/*/>*/}

            </div>

          </div>

        </div>


      </div>
  )
}

ReportPage.defaultProps = {
  report: {},
  onBackClick: () => {},
}

const reportData = PropTypes.arrayOf(PropTypes.shape({
  category: PropTypes.string,
  amount: PropTypes.string,
}))

ReportPage.propTypes = {
  report: PropTypes.shape({
    income_data: reportData,
    outcome_data: reportData,
    income_total_amount: PropTypes.string,
    outcome_total_amount: PropTypes.string,
    net_operating_income: PropTypes.string,
  }).isRequired,
  onBackClick: PropTypes.func.isRequired,
}
