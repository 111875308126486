import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import st from './styles.module.scss';
import Button from '../Button';

const Pane = ({ className, children, }) => (
  <div className={className}>

    {children}

  </div>
);

const Tabs = ({
  className, children, onChange, activeTabIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState(activeTabIndex);

  const tabs = Children.toArray(children);

  const handleClickTab = (index, data) => () => {
    if (index !== activeIndex) {
      if (onChange) onChange(index, data);

      setActiveIndex(index);
    }
  };

  return (
    <div className={cx(st.tabs, className)}>

      <div className={cx(st.wrapper)}>

        {tabs.map(({ props: { name, label } }, i) => (
          <Button
            className={cx(st.tab, { [st.active]: i === activeIndex })}
            key={i}
            onClick={handleClickTab(i, { name, label })}
          >

            {label}

          </Button>
        ))}

      </div>

      <div className="tabs__content">

        {tabs[activeIndex]}

      </div>

    </div>
  );
};

Pane.propTypes = {
  children: PropTypes.node.isRequired,
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  activeTabIndex: PropTypes.number,
};

Tabs.defaultProps = {
  className: null,
  onChange: null,
  activeTabIndex: 0,
};

Tabs.Pane = Pane;

export default Tabs;
