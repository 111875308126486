import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon  } from '../../../assets/icons/calendar.svg';
import styles from './styles.module.scss';

const InputDate = ({ className, name, placeholder, dateFormat, onChange, id, ...rest }) => {
	const [field, meta, helpers] = useField(name);

	const handleChange = (date) => {
		helpers.setValue(date);

		if (onChange) {
			onChange(date, name);
		}
	};

	const handleBlur = () => {
		helpers.setTouched(true);
	};

	return (
		<>

		<div
			className={cn(
				styles.inputDate,
				{ [styles.error]: meta.error && meta.touched },
				className,
			)}
		>

			<DatePicker
				id={id}
				showYearDropdown
				showPopperArrow={false}
				name={name}
				placeholderText={placeholder}
				dateFormat={dateFormat}
				onChange={handleChange}
				onBlur={handleBlur}
				selected={field.value}
				autoComplete="off"
				{...rest}
			/>

			<figure className={styles.icon}>

				<CalendarIcon />

			</figure>

		</div>

		</>
	);
};

InputDate.defaultProps = {
	className: '',
	placeholder: '',
	dateFormat: 'MM/dd/yyyy',
	onChange: null,
};

InputDate.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	dateFormat: PropTypes.string,
	onChange: PropTypes.func,
};

export default InputDate;
