import React from 'react';
import moment from 'moment';
import WidgetWrapper from '../../../../layouts/WidgetWrapper';
// import { CreditCardSvg } from '../../../../assets/icons';
import { TRANSACTIONS_ROUTE } from '../../../../constants/routes';
import TableSimple from '../../../../components/TableSimple';
import Tenant from '../../../../components/Tenant';
import useTransactions from '../../../../hooks/useTransactions';
import useTransaction from '../../../../hooks/useTransaction';
import useTenant from '../../../../hooks/useTenant';
import translate from '../../../../utils/translate';
import st from './styles.module.scss';

const TransactionRow = ({ id }) => {
  const { transaction } = useTransaction(id);

  const { tenant } = useTenant(transaction?.tenant_id);

  let title = tenant?.first_name ? `${tenant?.first_name} ${tenant?.last_name}` : tenant?.email;

  if(transaction.direction === 'money_out') {
    title = transaction.supplier;
  }

  return (
    <tr>

      <td>

        <Tenant
          title={title}
          src={transaction.direction === 'money_out' ? null : tenant?.avatar}
          content={transaction.invoice_id && transaction.address}
          // svg={<CreditCardSvg />}
          link={!transaction.invoice_id ? `${TRANSACTIONS_ROUTE}/${id}` : null}
        />

      </td>

      <td>

        <h3 className={st.price}>

          {`${transaction.amount}KSh`}

        </h3>

        <p className={st.data}>

          {moment(transaction.date).format('DD MMMM, YYYY')}

        </p>

      </td>

    </tr>
  );
};

export default function Payments() {
  const { ids } = useTransactions();

  return (
    <WidgetWrapper
      className={st.widget}
      title={translate('Payment History')}
      externalLink={TRANSACTIONS_ROUTE}
    >

      <TableSimple
        className={st.table}
      >

        <tbody>

        {ids.length > 0 && ids.map((id) =>(
          <TransactionRow
            key={id}
            id={id}
          />
        ))}

        </tbody>

      </TableSimple>


    </WidgetWrapper>
  );
}
