export const reportStyles = {
  wrapper: {
    width: '829px',
    background: '#FFFFFF',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    pageBreakInside: 'avoid',
  },
  heading: {
    width: '100%',
    background: '#FAFAFA',
    borderRadius: '6px',
    padding: '37px 45px 30px',
    pageBreakInside: 'avoid',
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoText: {
    fontStyle: 'italic',
    fontFamily: 'Poppins',
    fontWeight: '900',
    font: 'Poppins normal 900',
    fontSize: '33px',
    lineHeight: '33px',
    textTransform: 'uppercase',
    color: '#1B2B4B',
    marginBottom: '34px',
  },
  landlordDetails: {

  },
  landlordName: {
    fontFamily: 'Poppins',
    font: 'Poppins normal 500',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#1B2B4B',
  },
  companyName: {
    fontFamily: 'Poppins',
    font: 'Poppins normal 400',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#828282',
    marginBottom: '11px',
  },
  landlordInfo: {
    fontFamily: 'Poppins',
    font: 'Poppins normal 500',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#5b667d',
    marginBottom: '4px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  infoLabel: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    font: 'Poppins normal 500',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: '#1B2B4B',
    marginBottom: '2px',
  },
  infoDates: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontStyle: 'normal',
    font: 'Poppins normal 500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: '#828282',
  },
  content: {

  },
  footer: {
    margin: '0 35px',
    pageBreakInside: 'avoid',
    marginTop: '30px',
  },
  footerMain: {
    background: '#FAFAFA',
    borderRadius: '6px',
    padding: '20px 19px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerTitle: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    font: 'Poppins normal 500',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: 'normal',
    color: '#1B2B4B',
  },
  footerPrice: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    font: 'Poppins normal 500',
    fontSize: '26px',
    lineHeight: '32px',
    letterSpacing: 'normal',
    color: '#1B2B4B',
  },
  footerPriceNumbers: {
    display: 'inline-block',
    borderBottom: '2px #1B2B4B solid'
  },
  footerCaption: {
    height: '48px',
    margin: '66px 35px 24px 35px',
    display: 'flex',
    alignItems: 'center',
  },
  footerGeneratedBy: {
    fontFamily: 'Poppins',
    font: 'Poppins normal 400',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#5b667d',
    marginBottom: '4px',
    marginLeft: '19px',
  },
  footerLogo: {
    fontStyle: 'italic',
    fontFamily: 'Poppins',
    fontWeight: '900',
    font: 'Poppins normal 900',
    fontSize: '15px',
    textTransform: 'uppercase',
    color: '#1B2B4B',
    marginLeft: '10px',
    marginTop: '-3px',
  }
}

export const reportSectionStyles = {
  wrapper: {
    padding: '30px 45px',
    // margin: '30px 0',
    pageBreakInside: 'avoid',
    marginBottom: '-30px',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    font: 'Poppins normal 600',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: '#1B2B4B',
  },
  currency: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    font: 'Poppins normal 500',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: 'normal',
    color: '#1B2B4B',
  },
  elementWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #EFEFEF',
    marginBottom: '10px',
    paddingBottom: '7px',
    pageBreakInside: 'avoid',
  },
  elementTitle: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    font: 'Poppins normal 500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: '#828282',
  },
  elementPrice: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    font: 'Poppins normal 500',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: '#1B2B4B',
  },
  totalWrapper: {
    borderBottom: '2px solid #1B2B4B',
    pageBreakInside: 'avoid',
    marginTop: '14px',
  },
  totalLabel: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    font: 'Poppins normal 500',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: 'normal',
    color: '#1B2B4B',
  }
}
