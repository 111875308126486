import React, { useEffect } from 'react';
import PrivateLayout from '../../../layouts/PrivateLayout';
import { getTransactions } from '../../../services/actions/transactions';
import { getUnits } from '../../../services/actions/units';
import useAuth from '../../../hooks/useAuth';
import translate from '../../../utils/translate';
import InfoBlocks from '../Overview/InfoBlocks';
import Properties from '../Overview/Properties';
import Payments from '../Overview/Payments';
import styles from './styles.module.scss';

const OverviewTenant = () => {
  const { profile } = useAuth();

  useEffect(() => {
    getUnits({});
    getTransactions({});
  }, []);

  return (
    <PrivateLayout>

      <div className={styles.overviewTenant}>

        <div className={styles.header}>

          {translate('Welcome, ')}

          <span>

            {profile.first_name}

          </span>

        </div>

        <div className={styles.wrap}>

          <div>

            <InfoBlocks />

            <Properties />

          </div>

          <Payments />

        </div>

      </div>

    </PrivateLayout>
  );
}

export default OverviewTenant;
