import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Benefit = ({ className, icon, title, description }) => {
	return (
		<div
			className={cn(
				styles.benefit,
				className
			)}
		>

			<figure className={styles.icon} dangerouslySetInnerHTML={{__html: icon}}/>

			<h3 className={styles.title}>

				{title}

			</h3>

			<p
				className={styles.description}
			>

				{description}

			</p>

		</div>
	);
};

Benefit.defaultProps = {
	className: '',
	icon: '',
	title: '',
	description: '',
};

Benefit.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	title: PropTypes.string,
	description: PropTypes.string,
};

export default Benefit;