import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import Loader from '../../../components/Loader';
import { signInPayment } from '../../../services/actions/auth';

const PaymentPage = () => {
	const { search } = useLocation();

	useEffect(() => {
		const urlParams = new URLSearchParams(search);

		const params = {
			status: urlParams.get('status'),
			tx_ref: urlParams.get('tx_ref'),
			transaction_id: urlParams.get('transaction_id'),
		};

		signInPayment(params);
	}, [search]);

	return (
		<>

			<Loader />

		</>
	);
};

export default PaymentPage;
