import { applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import history from '../utils/history';

const middleware = () => {
	const middlewares = [
		thunk,
		routerMiddleware(history),
	];

	/* eslint-disable */
	const devTools = global.window && global.window.__REDUX_DEVTOOLS_EXTENSION__
		? global.window.__REDUX_DEVTOOLS_EXTENSION__()
		: (devTools) => devTools;
	/* eslint-enable */

	if (global.window) {
		middlewares.push(logger);
	}

	return compose(
		applyMiddleware(...middlewares),
		devTools,
	);
};

export default middleware;
