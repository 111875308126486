import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import { InputText } from '../../../../components/Inputs/InputText';
import InputDate from '../../../../components/Inputs/InputDate';
import HalfBlock from '../../../../components/Inputs/HalfBlock';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import translate from '../../../../utils/translate';
import st from './styles.module.scss';
import useConfigurations from '../../../../hooks/useConfigurations';
import useUnit from '../../../../hooks/useUnit';

export default function LeaseDetail({ values, setValues, setStep }) {
  const { unit } = useUnit(values.unit);

  const { frequencies } = useConfigurations();

  const frequencyOptions = useMemo(() => frequencies.map((frequency) => ({
    label: frequency.name,
    value: frequency.id,
  })), [frequencies]);

  const initValues = {
    amount: values.amount || unit?.rent || '',
    frequency: values.frequency,
    date_start: values.date_start,
    date_end: values.date_end,
    deposit: values.deposit || unit?.deposit || '',
  };

  const schema = Yup.object().shape({
    amount: Yup.number()
      .min(0)
      .positive()
      .required(),
    frequency: Yup.string()
      .required(),
    date_start: Yup.date()
      .required(),
    date_end: Yup.date()
      .required(),
    deposit: Yup.number()
      .min(0)
      // .positive()
      .required(),
  });

  const handleSubmit = useCallback((formValues) => {
    setValues((val) => ({
      ...val,
      ...formValues,
    }));

    setStep(4);
  }, [setStep, setValues]);

  const handlePrev = useCallback(() => setStep(2), [setStep]);

  return (
    <div className={st.wrapper}>

      <div className={st.header}>

        {translate('Lease Detail')}

      </div>

      <Formik
        initialValues={initValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize
      >

        {({ values }) => {
          return (
            <Form>

              <Label>

                {translate('Amount')}

              </Label>

              <InputText
                id="amount"
                name="amount"
                type="number"
                step={1}
              />

              <Label>

                {translate('Lease Frequency')}

              </Label>

              <InputSelect
                id="frequency"
                name="frequency"
                options={frequencyOptions}
              />

              <HalfBlock>

                <div>

                  <Label>

                    {translate('Start Date')}

                  </Label>

                  <InputDate
                    id="date_start"
                    name="date_start"
                    selectsStart
                    startDate={values.date_start}
                    endDate={values.date_end}
                  />

                </div>

                <div>

                  <Label>

                    {translate('End Date')}

                  </Label>

                  <InputDate
                    id="date_end"
                    name="date_end"
                    selectsEnd
                    startDate={values.date_start}
                    endDate={values.date_end}
                  />

                </div>

              </HalfBlock>

              <Label>

                {translate('Deposit')}

              </Label>

              <InputText
                id="deposit"
                name="deposit"
                type="number"
                step={1}
              />

              <div className={st.footer}>

                <Button
                  color="primary"
                  onClick={handlePrev}
                >

                  {translate('Back')}

                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >

                  {translate('Next Step')}

                </Button>

              </div>

            </Form>
          );
        }}

      </Formik>

    </div>
  );
};

LeaseDetail.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
};
