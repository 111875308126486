const KENYA_CODE = '+254';

export const formatPhone = (phone) => {
  if(!phone && typeof phone !== 'string') return phone;

  if(phone.startsWith(KENYA_CODE)) {
    const match = phone.match(/(\+254)(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    } else {
      return phone;
    }
  } else {
    return phone;
  }
}

export const formatPriceStr = (price) => {
  if(!price && typeof price !== 'string') return price;

  const parts = price.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
