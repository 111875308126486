import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { createAction } from 'redux-actions';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import Routes from '../../routes';
import { AUTH } from '../../constants/type';
import { getProfile } from '../../services/actions/auth';
import store from '../../utils/store';
import history from '../../utils/history';
import './styles.module.scss';

function App() {
	useEffect(() => {
		const fetchData = async () => {
			if (!!localStorage.getItem('auth') || !!sessionStorage.getItem('auth')) {
				store.dispatch(createAction(AUTH.SIGN_IN.base)());

				await getProfile();
			}
		};

		fetchData();
	}, []);

	return (
		<Provider store={store} key="provider">

			<ConnectedRouter history={history}>

				<ToastContainer />

				<Routes />

			</ConnectedRouter>

		</Provider>
	);
}

export default App;
