import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit.svg';
import PrivateLayout from '../../../../../layouts/PrivateLayout';
import { InputSelect } from '../../../../../components/Inputs/InputSelect';
// import { InputText } from '../../../../../components/Inputs/InputText';
import Sidebar from '../../../../../components/Sidebar';
import Button from '../../../../../components/Button';
import Label from '../../../../../components/Label';
import TypePayment from './TypePayment';
import useConfigurations from '../../../../../hooks/useConfigurations';
import { updatePaymentSettings } from '../../../../../services/actions/auth';
import useModal from '../../../../../hooks/useModal';
import useAuth from '../../../../../hooks/useAuth';
import translate from '../../../../../utils/translate';
import styles from '../../styles.module.scss';
import st_set from './styles.module.scss';

import links from '../../links';

const PaymentSettings = () => {
  const { profile } = useAuth();

  const { currencies } = useConfigurations();

  const [readonly, setPositive, setNegative] = useModal(true);

  const initValues = {
    auto_payment: profile.auto_payment,
    // company: profile.company || '',
    // company_details: '',
    currency: 2,
  };

  const schema = Yup.object().shape({
    auto_payment: Yup.bool()
      .required(),
    /*company: Yup.string()
      .required(),
    company_details: Yup.string(),*/
    currency: Yup.string()
      .required(),
  });

  const handleSubmit = useCallback((values, actions) => {
    updatePaymentSettings(values, { ...actions, onSuccess: setPositive })
  }, [setPositive]);

  const currencyOptions = useMemo(() => currencies.map((currency) => ({
    label: `${currency.name} (${currency.iso})`,
    value: currency.id,
  })), [currencies]);

  return (
    <PrivateLayout>

      <div className={styles.profile_container}>

        <Sidebar
          className={styles.sidebar}
          links={links(profile.is_landlord)}
        />

        <div className={styles.container}>

          <div className={st_set.heading}>

            <h2 className={styles.title}>

              {translate('Payment Settings')}

            </h2>

            {readonly && (
              <Button
                color="secondary"
                variant="shadow"
                svg={<EditIcon />}
                onClick={setNegative}
                className={styles.edit}
              >

                {translate('Edit')}

              </Button>
            )}

          </div>

          <Formik
            initialValues={initValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
            enableReinitialize
          >

            {({ isSubmitting }) => (
              <Form className={st_set.form}>

                <TypePayment
                  id="auto_payment_true"
                  name="auto_payment"
                  title={translate('Automatic Payment')}
                  description={translate('When a new invoice is issued. We will attempt to collect payment automatically from the payment method on file')}
                  value={true}
                />

                <TypePayment
                  id="auto_payment_false"
                  name="auto_payment"
                  title={translate('Manual Payment')}
                  description={translate('When a new invoice is issued, we will not attempt to collect payment automatically.')}
                  value={false}
                />

                {/*<Label>

                 {translate('Company')}

                 </Label>

                 <InputText
                 id='company'
                 name='company'
                 autoComplete="off"
                 placeholder="Company"
                 />

                 <Label>

                 {translate('Company Details')}

                 </Label>

                 <InputText
                 id='company_details'
                 name='company_details'
                 type='textarea'
                 autoComplete="off"
                 placeholder="Company Details"
                 />*/}

                <Label>

                  {translate('Currency')}

                </Label>

                <InputSelect
                  id="currency"
                  name="currency"
                  options={currencyOptions}
                  className={st_set.select}
                  disabled
                />

                {!readonly && (
                  <div className={st_set.btns}>

                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >

                      {translate('Save')}

                    </Button>

                  </div>
                )}

              </Form>
            )}

          </Formik>

        </div>

      </div>

    </PrivateLayout>
  );
};

export default PaymentSettings;