import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as AngleLeftIcon } from '../../../assets/icons/angle-left.svg';
import { ReactComponent as AngleRightIcon } from '../../../assets/icons/angle-right.svg';
import styles from './styles.module.scss';

const PaginationItem = ({ item, current, onClick }) => {
  const content = useMemo(() => {
    switch(item) {
      case 'LEFT_PAGE':
        return <AngleLeftIcon />;
      case 'RIGHT_PAGE':
        return <AngleRightIcon />;
      default:
        return item;
    }
  }, [item]);

  return (
    <button
      type="button"
      className={cn(
        styles.navigationItem,
        { [styles.active]: item === current },
        { [styles.isControl]: ['LEFT_PAGE', 'RIGHT_PAGE'].includes(item) },
        { [styles.left]: 'LEFT_PAGE' === item },
        { [styles.right]: 'RIGHT_PAGE' === item },
      )}
      onClick={onClick}
    >

      {content}

    </button>
  );
};

PaginationItem.defaultProps = {
  isControl: false,
  active: false,
  children: '',
  onClick: null,
};

PaginationItem.propTypes = {
  isControl: PropTypes.bool,
  active: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default PaginationItem;
