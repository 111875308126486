import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.svg';
import Button from '../../../../components/Button';
import { sendSubscribe } from '../../../../services/actions/public';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

const FormStart = ({ title }) => {
	const initValues = {
		email: '',
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.email()
			.required(),
	});

	const handleSubmit = useCallback((values, actions) => {
		const onSuccess = () => {
			actions.resetForm();

			toast.success(translate('Your request has been submitted'))
		};

		sendSubscribe(values, { ...actions, onSuccess });
	}, []);

	return (
		<section
			className={styles.formStart}
		>

			<h2
				className={cn(
					styles.title,
				)}
			>

				{title}

			</h2>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
			>

				<Form
					className={styles.form}
				>

					<div
						className={styles.input}
					>

						<Field
							type="email"
							name="email"
							placeholder={translate('Enter your email here')}
						/>

						<Button
							className={styles.button}
							isIcon
							type="submit"
						>

							<ArrowRightIcon
								fill=""
							/>

						</Button>

					</div>

				</Form>

			</Formik>

		</section>
	);
};

FormStart.defaultProps = {
	title: '',
};

FormStart.propTypes = {
	title: PropTypes.string,
};

export default FormStart;