import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '../../Button';
import { useField } from 'formik';
import styles from './styles.module.scss';

const InputQuantity = ({ className, name, title, step }) => {
	const [field = {}, meta = {}, helpers] = useField(name);

	const { error, touched } = meta;

	const onChange = useCallback((type) => () => {
		const newValue = type ? (field.value + step) : (field.value - step);

		helpers.setTouched(true);

		helpers.setValue(newValue);

		field.onBlur('');
	}, [field, helpers, step]);

	return (
		<div
			className={cn(
				styles.inputQuantity,
				{[styles.error]: error && touched },
				className,
			)}
		>

			{title}

			<div className={styles.controls}>

				<Button
					className={styles.control}
					color="primary"
					variant="outlined"
					isIcon
					onClick={onChange(false)}
				>

					-

				</Button>

				<div
					className={styles.input}
				>

					{field.value}

				</div>

				<Button
					className={styles.control}
					color="primary"
					variant="outlined"
					isIcon
					onClick={onChange(true)}
				>

					+

				</Button>

			</div>

		</div>
	);
};

InputQuantity.defaultProps = {
	className: '',
	step: 1,
};

InputQuantity.propTypes = {
	className: PropTypes.string,
	step: PropTypes.number,
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};


export default InputQuantity;