import React, { useCallback } from 'react';
import Button from '../../../../components/Button';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Label from '../../../../components/Label';
import { InputText } from '../../../../components/Inputs/InputText';
import InputFile from '../../../../components/Inputs/InputFile';
import { sendInviteToTenant } from '../../../../services/actions/tenants'
import translate from '../../../../utils/translate';
import style from './styles.module.scss';

const SendInviteForm = ({ onClose }) => {
    const initValues = {
        email: '',
        notes: '',
        file: '',
    };

    const schema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required(),
        notes: Yup.string(),
        file: Yup.mixed()
            .fileMaxSize(),
    });

    const handleSubmit = useCallback((values, actions) => {
        sendInviteToTenant(values, { ...actions, onSuccess: onClose })
    }, [onClose]);

    return (
        <Formik
            initialValues={initValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form>
                    <Label>
                        {translate('Email')}
                    </Label>

                    <InputText
                        id='email'
                        name='email'
                        type='email'
                    />

                    <Label>
                        {translate('Invitation text')}
                    </Label>

                    <InputText
                        id='notes'
                        name='notes'
                        type='textarea'
                        className={style.text}
                    />

                    <Label
                        className={style.upload_logo}
                    >
                        {translate('or upload file')}
                    </Label>

                    <InputFile
                        id='file'
                        name='file'
                        description='The file size is not more than 3 mb.'
                    />

                    <div
                        className={style.btn_block}
                    >
                        <Button
                            color="primary"
                            onClick={onClose}
                        >

                            {translate('Cancel')}

                        </Button>

                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                        >

                            {translate('Confirm')}

                        </Button>
                    </div>
                </Form>
            )}

        </Formik>
    )
}

export default SendInviteForm
