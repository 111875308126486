import React from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../../../constants/routes';
import AuthLayout from '../../../layouts/AuthLayout';
import { InputText } from '../../../components/Inputs/InputText';
import Button from '../../../components/Button';
import Label from '../../../components/Label';
import translate from '../../../utils/translate';
import styles from '../styles.module.scss';

const SetPassword = ({ title, description, buttonTitle, onSubmit }) => {
	const initValues = {
		password: '',
		'confirm-password': '',
	};

	const schema = Yup.object().shape({
		password: Yup.string()
			.min(8)
			.max(24)
			.required(),
		'confirm-password': Yup.string()
			.oneOf([Yup.ref('password')], translate('Both passwords are not matching'))
			.required(),
	});

	return (
		<AuthLayout>

			<h2
				className={cn(
					'h2',
					styles.title
				)}
			>

				{title}

			</h2>

			<p className={styles.description}>

				{description}

				&nbsp;

				<Link to={SIGN_IN_ROUTE}>

					{translate('Sign in')}

				</Link>

			</p>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={onSubmit}
			>

				{({ isSubmitting }) => (
					<Form>

						<Label>

							{translate('Password')}

						</Label>

						<InputText
							id="password"
							name="password"
							type="password"
							autoComplete="new-password"
						/>

						<Label>

							{translate('Confirm password')}

						</Label>

						<InputText
							id="confirm-password"
							name="confirm-password"
							type="password"
							autoComplete="new-password"
						/>

						<Button
							color="primary"
							variant="contained"
							type="submit"
							loading={isSubmitting}
							style={{
								width: '100%',
							}}
							size="big"
						>

							{buttonTitle}

						</Button>

					</Form>
				)}

			</Formik>

		</AuthLayout>
	);
};

export default SetPassword;