import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { unitsFilterSelector } from '../services/selectors/units';

const selector = createSelector(
	unitsFilterSelector,
	({ property_unit_type, property_type, unit_type, status, search }) => {

		return {
			property_unit_type,
			property_type,
			unit_type,
			status,
			search,
		};
	},
);

const useUnitsFilter = () => {
	const data = useSelector(selector);

	return {
		...data,
	};
};

export default useUnitsFilter;