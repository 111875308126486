import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ReactComponent as RightIcon } from '../../assets/icons/angle-right.svg';
import styles from './styles.module.scss'

const Sidebar = ({ className, links }) => {
	return (
		<aside
			className={cn(
				styles.sidebar,
				className,
			)}
		>

			<ul className={styles.list}>

				{links.map((link) => (
					<li
						key={link.location}
						className={cn(styles.link)}
					>

						<NavLink
							to={link.location}
							exact={link.exact}
							activeClassName={styles.active}
						>

							{link.title}

							<RightIcon />

						</NavLink>

					</li>
				))}

			</ul>

		</aside>
	);
};

Sidebar.defaultProps = {
	className: '',
};

Sidebar.propTypes = {
	className: PropTypes.string,
	links: PropTypes.array.isRequired,
};

export default Sidebar;