import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const CounterItem = ({ className, title, description }) => {
	return (
		<div
			className={cn(
				styles.counter,
				className,
			)}
		>

			<h3 className={styles.title}>

				{title}

			</h3>

			<p className={styles.description}>

				{description}

			</p>

		</div>
	);
};

CounterItem.defaultProps = {
	className: '',
};

CounterItem.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

export default CounterItem;