import React from 'react';
import { reportSectionStyles as styles } from '../reportStyles';
import translate from '../../../../../../utils/translate';
import { formatPriceStr } from '../../../../../../utils/formatHelper';


const ReportSection = ({ title, list, totalLabel, totalAmount, currency }) => {
  return (

    <div style={styles.wrapper}>

      <div style={styles.heading}>

        <h4 style={styles.title}>

          {title}

        </h4>

        <h4 style={styles.currency}>

          {currency}

        </h4>

      </div>



      {
        list.map(el => (

          <div style={styles.elementWrapper} key={el.category}>

            <div style={styles.elementTitle}>

              {el.category || translate('Others')}

            </div>

            <div style={styles.elementPrice}>

              {formatPriceStr(el.amount)}

            </div>

          </div>

        ))
      }

      <div style={{ ...styles.elementWrapper, ...styles.totalWrapper }}>

        <div style={styles.totalLabel}>

          {totalLabel}

        </div>

        <div style={styles.totalLabel}>

          {formatPriceStr(totalAmount)}

        </div>

      </div>

    </div>

  );
};

export default ReportSection;
