import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import {
	TRANSACTION_CREATE_ROUTE, PROPERTIES_CREATE_ROUTE, HOME_ROUTE, PROPERTIES_ROUTE, TENANTS_ROUTE, TRANSACTIONS_ROUTE,
	TENANTS_CREATE_ROUTE, TENANTS_IMPORT_ROUTE, TENANTS_UPLOAD_ROUTE, MESSAGES_ROUTE, PROFILE_ROUTE, SECURITY_ROUTE,
	CHANGE_EMAIL_ROUTE, CHANGE_PASSWORD_ROUTE, RECOVER_PASSWORD_ROUTE, PAYMENT_INFO_ROUTE, // PAYMENT_SETTINGS_ROUTE,
	ADD_PAYMENT_METHOD_ROUTE, MOVE_IN_ROUTE, TRANSACTION_PAY_INVOICE_ROUTE, PAYMENT_ROUTE,
	ACCOUNTING_REPORT_INCOME_STMT_ROUTE,
} from '../../../constants/routes';
import TransactionPayInvoice from '../../../modules/Private/TransactionPayInvoice';
import TransactionDetail from '../../../modules/Private/TransactionDetail';
import TransactionCreate from '../../../modules/Private/TransactionCreate';
import AddPaymentMethod from '../../../modules/Private/Profile/PaymentInfo/AddPaymentMethod';
// import PaymentSettings from '../../../modules/Private/Profile/PaymentInfo/PaymentSettings';
import RecoverPassword from '../../../modules/Private/Profile/Security/RecoverPassword';
import ChangePassword from '../../../modules/Private/Profile/Security/ChangePassword';
import PropertyCreate from '../../../modules/Private/PropertyCreate';
import TenantDetail from '../../../modules/Private/TenantDetail';
import Transactions from '../../../modules/Private/Transactions';
import TenantImport from '../../../modules/Private/TenantImport';
import TenantCreate from '../../../modules/Private/TenantCreate';
import TenantUpload from '../../../modules/Private/TenantUpload';
import ChangeEmail from '../../../modules/Private/Profile/Security/ChangeEmail';
import PaymentInfo from '../../../modules/Private/Profile/PaymentInfo';
import UnitDetail from '../../../modules/Private/UnitDetail';
import Messages from '../../../modules/Private/Messages';
import Security from '../../../modules/Private/Profile/Security';
import Overview from '../../../modules/Private/Overview';
import Tenants from '../../../modules/Private/Tenants';
import Profile from '../../../modules/Private/Profile';
import MoveIn from '../../../modules/Private/MoveIn';
import Units from '../../../modules/Private/Units';
import useAuth from '../../../hooks/useAuth';
import PaymentPage from '../../../modules/Auth/PaymentPage';
import ReportIncomeStmt from '../../../modules/Private/Accounting/ReportIncomeStmt';

const LandlordRouter = () => {
	const { profile } = useAuth();

	if(!profile.blocked) {
		return (
			<Switch>

				<Route exact path={PAYMENT_ROUTE}><PaymentPage /></Route>

				<Route exact path={HOME_ROUTE}><Overview /></Route>

				{/* PROPERTIES */}
				<Route exact path={PROPERTIES_ROUTE}><Units /></Route>
				<Route path={`${PROPERTIES_CREATE_ROUTE}/:id?`}><PropertyCreate /></Route>
				<Route path={`${PROPERTIES_ROUTE}/:unit_id`}><UnitDetail /></Route>

				{/* TENANTS */}
				<Route exact path={TENANTS_ROUTE}><Tenants /></Route>
				<Route path={`${TENANTS_CREATE_ROUTE}/:id?`}><TenantCreate /></Route>
				<Route path={`${TENANTS_ROUTE}/:tenantId`}><TenantDetail /></Route>
				<Route path={TENANTS_IMPORT_ROUTE}><TenantImport /></Route>
				<Route path={TENANTS_UPLOAD_ROUTE}><TenantUpload /></Route>

				<Route path={MOVE_IN_ROUTE}><MoveIn /></Route>

				{/* ACCOUNTING */}
				<Route path={ACCOUNTING_REPORT_INCOME_STMT_ROUTE}><ReportIncomeStmt /></Route>

				{/* TRANSACTIONS */}
				<Route exact path={TRANSACTIONS_ROUTE}><Transactions /></Route>
				<Route path={`${TRANSACTION_PAY_INVOICE_ROUTE}/:id?`}><TransactionPayInvoice /></Route>
				<Route path={`${TRANSACTION_CREATE_ROUTE}/:id?`}><TransactionCreate /></Route>
				<Route path={`${TRANSACTIONS_ROUTE}/:id`}><TransactionDetail /></Route>

				{/* MESSAGES */}
				<Route path={MESSAGES_ROUTE}><Messages /></Route>

				{/* PROFILE */}
				<Route path={PROFILE_ROUTE}><Profile /></Route>
				<Route exact path={SECURITY_ROUTE}><Security /></Route>
				<Route path={CHANGE_EMAIL_ROUTE}><ChangeEmail /></Route>
				<Route path={CHANGE_PASSWORD_ROUTE}><ChangePassword /></Route>
				<Route path={RECOVER_PASSWORD_ROUTE}><RecoverPassword /></Route>
				<Route exact path={PAYMENT_INFO_ROUTE}><PaymentInfo /></Route>
				{/*<Route path={PAYMENT_SETTINGS_ROUTE}><PaymentSettings /></Route>*/}
				<Route path={`${ADD_PAYMENT_METHOD_ROUTE}/:id?`}><AddPaymentMethod /></Route>

				{/* NOT FOUND */}
				<Route path="*"><Redirect to={HOME_ROUTE} /></Route>

			</Switch>
		);
	}

	return (
		<Switch>

			<Route exact path={PAYMENT_ROUTE}><PaymentPage /></Route>

			<Route exact path={HOME_ROUTE}><Overview /></Route>

			{/* TRANSACTIONS */}
			<Route path={`${TRANSACTION_PAY_INVOICE_ROUTE}/:id?`}><TransactionPayInvoice /></Route>

			{/* PROFILE */}
			<Route path={PROFILE_ROUTE}><Profile /></Route>
			<Route exact path={SECURITY_ROUTE}><Security /></Route>
			<Route path={CHANGE_EMAIL_ROUTE}><ChangeEmail /></Route>
			<Route path={CHANGE_PASSWORD_ROUTE}><ChangePassword /></Route>
			<Route path={RECOVER_PASSWORD_ROUTE}><RecoverPassword /></Route>
			<Route exact path={PAYMENT_INFO_ROUTE}><PaymentInfo /></Route>
			{/*<Route path={PAYMENT_SETTINGS_ROUTE}><PaymentSettings /></Route>*/}
			<Route path={ADD_PAYMENT_METHOD_ROUTE}><AddPaymentMethod /></Route>

			{/* NOT FOUND */}
			<Route path="*"><Redirect to={HOME_ROUTE} /></Route>

		</Switch>
	);
};

export default LandlordRouter;
