import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getLeasesByTenant } from '../services/actions/leases';
import { tenantIdSelector } from '../services/selectors/tenants';
import { leaseIdsByTenantSelector } from '../services/selectors/leases';

const selector = createSelector(
	leaseIdsByTenantSelector,
	tenantIdSelector,
	(leaseIdsByTenant, tenantId) => {
		const ids = leaseIdsByTenant[tenantId];

		return {
			ids,
		};
	},
);

const useLeasesByTenant = (tenantId, refresh) => {
	const newSelector = useCallback((state) => selector(state, tenantId), [tenantId]);

	const { ids } = useSelector(newSelector);

	useEffect(() => {
		if (!ids && typeof tenantId === 'number' && !isNaN(tenantId)) {
			getLeasesByTenant(tenantId);
		}
	}, [ids, tenantId]);

	useEffect(() => {
		if (refresh && typeof tenantId === 'number' && !isNaN(tenantId)) {
			getLeasesByTenant(tenantId);
		}
	}, [refresh, tenantId]);

	return {
		ids,
	};
};

export default useLeasesByTenant;