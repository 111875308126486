import { createAction } from 'redux-actions';
import { API_FLUTTERWAVE_URL, API_INVOICES_URL, API_MPESA_URL } from '../../constants/api';
import { INVOICES } from '../../constants/type';
import requestWrapper from '../../utils/requestWrapper';
import store from '../../utils/store';
import defaultErrorHandler from '../../utils/errorHandlers';
import schemas from '../schemas';

export const getInvoice = async (id, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: INVOICES.GET_INVOICE,
		url: `${API_INVOICES_URL}${id}/`,
		method: 'get',
		normalizeSchema: schemas.INVOICES,
		addLoading: true,
		requestData: { id },
		onSuccess,
		onFailure,
	});
};

export const clearSelectInvoices = () => {
	store.dispatch(createAction(INVOICES.CLEAR_SELECT.base)());
};

export const getSelectInvoices = async ({
	params = {},
	actions = {},
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: INVOICES.GET_INVOICES_OPTIONS,
		url: `${API_INVOICES_URL}unpaid/`,
		method: 'get',
		options: {
			params,
		},
		onSuccess,
	});
};

export const getAvailablePaymentMethods = async (requestData, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e)
	};

	await requestWrapper({
		action: INVOICES.GET_AVAILABLE_PAYMENT_METHODS,
		url: `${API_INVOICES_URL}available-payment-methods/`,
		method: 'post',
		requestData,
		onFailure,
		onSuccess,
	});
};

export const payInvoicesFlutterwave = async (requestData, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: INVOICES.PAY_INVOICES,
		url: `${API_FLUTTERWAVE_URL}link/`,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const payInvoicesLipaNaMPESA = async (requestData, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = (e) => {
		if (actions?.onFailure) {
			actions.onFailure(e);
		} else {
			defaultErrorHandler(e, actions)
		}
	};

	await requestWrapper({
		action: INVOICES.PAY_INVOICES,
		url: `${API_MPESA_URL}process/`,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};
