import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { entitiesLeasesSelector } from '../services/selectors/entities';
import createLoadingSelector from '../services/selectors/loading';
import { LEASES } from '../constants/type';
import { leaseIdSelector } from '../services/selectors/leases';
import { getLease } from '../services/actions/leases';

const selector = createSelector(
	entitiesLeasesSelector,
	leaseIdSelector,
	createLoadingSelector(LEASES.GET_LEASE.base),
	(leases, leaseId, loader) => {
		const lease = leases[leaseId];

		return {
			lease: lease ? {
				...lease,
			} : null,
			loader,
		};
	},
);

const useLease = (leaseId, refresh) => {
	const newSelector = useCallback((state) => selector(state, leaseId), [leaseId]);

	const { lease, loader } = useSelector(newSelector);

	useEffect(() => {
		if (!lease && typeof leaseId === 'number' && !isNaN(leaseId)) {
			getLease(leaseId);
		}
	}, [lease, leaseId]);

	useEffect(() => {
		if (refresh && typeof leaseId === 'number' && !isNaN(leaseId)) {
			getLease(leaseId);
		}
	}, [refresh, leaseId]);

	return {
		lease,
		loader,
	};
};

export default useLease;