import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import PaginationItem from './PaginationItem';
import translate from '../../utils/translate';
import styles from './styles.module.scss';

const range = (from, to, step = 1) => {
  let i = from;
  const array = [];

  while (i <= to) {
    array.push(i);
    i += step;
  }

  return array;
};

const Pagination = ({ className, limit, current, total, neighbors, onChangePage }) => {
  const count = useMemo(() => Math.ceil(total / limit), [total, limit]);

  const currentItemsCount = useMemo(() => {
    if(total < limit) {
      return total;
    }

    return (total >= current * limit) ? limit : (total - (current - 1) * limit)
  }, [current, limit, total]);

  const onNext = useCallback(() => {
    onChangePage(current + 1);
  }, [onChangePage, current]);

  const onPrevious = useCallback(() => {
    onChangePage(current - 1);
  }, [onChangePage, current]);

  const arrayPagination = useMemo(() => {
    const pageNeighbors = Math.max(0, Math.min(neighbors, 2));
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (pageNeighbors * 2) + 3;

    const totalBlocks = totalNumbers + 2;

    if (count > totalBlocks) {
      const startPage = Math.max(2, current - pageNeighbors);

      const endPage = Math.min(count - 1, current + pageNeighbors);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;

      const hasRightSpill = (count - endPage) > 1;

      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill):
          return ['LEFT_PAGE', 1, ' ...', ...range(startPage - spillOffset, startPage - 1), ...pages, count];

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill):
          return [1, ...pages, ...range(endPage + 1, endPage + spillOffset), '... ', count, 'RIGHT_PAGE'];

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default:
          return ['LEFT_PAGE', 1, ' ...', ...pages, '... ', count, 'RIGHT_PAGE'];
      }
    }

    return range(1, count);
  }, [count, current, neighbors]);

  const handleChangePage = useCallback((item) => () => {
    switch((`${item}`).trim()) {
      case 'LEFT_PAGE':
        onPrevious();
        break;
      case 'RIGHT_PAGE':
        onNext();
        break;
      case '...':
        break;

      default:
        onChangePage(item);
    }
  }, [onChangePage, onNext, onPrevious]);

  return (
    <div
      className={cn(
        styles.wrapper,
        className,
      )}
    >

      <div className={styles.displaying}>
        {`${translate('Displaying')} ${currentItemsCount} ${translate('out of')} ${total}`}
      </div>

      <div className={styles.pagination}>

        {arrayPagination.map((item) => (
          <PaginationItem
            key={item}
            item={item}
            current={current}
            onClick={handleChangePage(item)}
          />
        ))}

      </div>

    </div>
  );
}

Pagination.defaultProps = {
  className: '',
  current: 1,
  total: 0,
  limit: 10,
  neighbors: 1,
};

Pagination.propTypes = {
  className: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
  limit: PropTypes.number,
  neighbors: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
};

export default React.memo(Pagination);
