import React from 'react';
import ModalContent from '../../../../components/Modal/ModalContent';
import ModalBody from '../../../../components/Modal/ModalBody';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import ModalTitle from '../../../../components/Modal/ModalTitle';
import ModalClose from '../../../../components/Modal/ModalClose';
import Modal from '../../../../components/Modal';
import translate from '../../../../utils/translate';
import SendInviteForm from '../SendInviteForm';

const ModalSendInvite = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >

      <ModalContent>

        <ModalHeader>

          <ModalTitle>

            {translate('Send Invitation')}

          </ModalTitle>

          <ModalClose onClose={onClose} />

        </ModalHeader>

        <ModalBody>

          <SendInviteForm onClose={onClose} />

        </ModalBody>

      </ModalContent>

    </Modal>
  );
};

export default ModalSendInvite
