import axios from 'axios';
import get from 'lodash/get';
import { refreshToken } from '../services/actions/auth';
import { API_PROFILE_URL } from '../constants/api';

// Set up Base Axios instance
axios.defaults.headers.post['Content-Type'] = 'application/json';

const baseAxiosInstance = axios.create();

baseAxiosInstance.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_URL}`;

const token = localStorage.getItem('auth') || sessionStorage.getItem('auth') || '';

baseAxiosInstance.defaults.headers.common.Authorization = token ? `Bearer ${token}` : token;

baseAxiosInstance.interceptors.response.use((response) => response, (e) => {
	const response = get(e, 'response', {});
	let responseError = get(e, 'response.data', {});

	if (typeof responseError === 'string' && response.status === 500) {
		responseError = {};
	}

	const tokenRefresh = localStorage.getItem('auth-refresh') || sessionStorage.getItem('auth-refresh') || '';

	if(response.status === 401 && tokenRefresh) {

		if(!e.response.config.url.includes(API_PROFILE_URL)) {
			refreshToken(tokenRefresh, !!localStorage.getItem('auth'), e.response.config);
		}
	}

	const error = {
		status: response.status || 500,
		message: responseError.Message || 'Something went wrong',
		data: responseError,
	};

	return Promise.reject(error);
});

export const setAuthHeader = ({ access, refresh }, remember, callback) => {
	baseAxiosInstance.defaults.headers.common.Authorization = `Bearer ${access}`;
	console.log(access, refresh);
	const storage = {
		localStorage,
		sessionStorage,
	};

	const typeStorage = remember ? 'localStorage' : 'sessionStorage';

	if (storage[typeStorage]) {
		storage[typeStorage].setItem('auth', access);

		if(refresh) {
			storage[typeStorage].setItem('auth-refresh', refresh);
		}
	}

	if (callback) callback();
};

export const removeAuthHeader = (callback) => {
	if (localStorage) {
		localStorage.removeItem('auth');
		localStorage.removeItem('auth-refresh');
	}

	if(sessionStorage) {
		sessionStorage.removeItem('auth');
		sessionStorage.removeItem('auth-refresh');
	}

	baseAxiosInstance.defaults.headers.common.Authorization = '';

	if (callback) callback();
};

export const baseApi = baseAxiosInstance;
