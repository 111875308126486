import React, { useCallback } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import useAuth from '../../../../hooks/useAuth';
import translate from '../../../../utils/translate';
import styles from '../../styles.module.scss';

const ForgotSending = ({ email }) => {
	const { onForgot } = useAuth();

	const handleSend = useCallback(() => {
		onForgot({ email });
	}, [onForgot, email]);

	return (
		<>

			<h2
				className={cn(
					'h2',
					styles.title
				)}
			>

				{translate('Recover password')}

			</h2>

			<p className={styles.description}>

				{translate('Have an account?')}

				&nbsp;

				<Link to={SIGN_IN_ROUTE}>

					{translate('Sign in')}

				</Link>

			</p>

			<p className={cn(
				styles.description,
				styles.success,
			)}>

				{translate('An e-mail with a confirmation link was send to the mailbox:')}

				&nbsp;

				<span>

					{email}

				</span>

			</p>

			<p
				className={cn(
					styles.description,
					styles.noEmail,
				)}
			>

				{translate('Didn\'t get the email? Check the address is correct and try again')}

			</p>

			<Button
				variant="contained"
				color="primary"
				size="big"
				onClick={handleSend}
			>

				{translate('Resend Email')}

			</Button>

		</>
	);
};

export default ForgotSending;