import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ReactComponent as LeftIcon } from '../../../../../assets/icons/angle-left.svg';
import { createPaymentMethod, updatePaymentMethod } from '../../../../../services/actions/auth';
import { PAYMENT_INFO_ROUTE } from '../../../../../constants/routes';
import PrivateLayout from '../../../../../layouts/PrivateLayout';
import { InputText } from '../../../../../components/Inputs/InputText';
import InputPhone from '../../../../../components/Inputs/InputPhone';
import Button from '../../../../../components/Button';
import Label from '../../../../../components/Label';
import useAuth from '../../../../../hooks/useAuth';
import translate from '../../../../../utils/translate';
import history from '../../../../../utils/history';
import styles from '../../styles.module.scss';
import st_pay_met from './styles.module.scss';
import { InputSelect } from '../../../../../components/Inputs/InputSelect';
import { PAYMENT_METHODS, PAYMENT_METHODS_OPTIONS, PAYMENT_TYPES } from '../index';
import LipaNaMPESAForm from './LipaNaMPESAForm';
import FlutterwaveForm from './FlutterwaveForm';

const AddPaymentMethod = () => {
  const { id } = useParams();
  const { search } = useLocation();

  const { paymentMethods } = useAuth();

  const paymentMethodOption = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    const type = urlParams.get('type');
    return PAYMENT_METHODS_OPTIONS.find((pm) => pm.type === type);
  }, [search]);

  const paymentMethod = useMemo(() => {
    return id ? paymentMethods.find((item) => item.id === Number(id)) : null;
  }, [paymentMethods, id]);

  useEffect(() => {
    if(!id && paymentMethods.length === 2 ||
      !id && !!paymentMethods.find((method) => method.type === paymentMethodOption.type)
    ) {
      history.push(PAYMENT_INFO_ROUTE);
    }
  }, [id, paymentMethods, paymentMethodOption]);


  const toSecurityPage = useCallback(() => {
    history.push(PAYMENT_INFO_ROUTE);
  }, []);

  return (
    <PrivateLayout>

      <div className={styles.container}>

        <div className={st_pay_met.heading}>

          <Button
            variant="goBack"
            onClick={toSecurityPage}
            isIcon
            svg={<LeftIcon />}
          />

          <h2 className={styles.title}>

            {translate(paymentMethodOption.title)}

          </h2>

        </div>

        {
          paymentMethodOption.type === PAYMENT_TYPES.LIPA_NA_MPESA ? (
            <LipaNaMPESAForm
              id={id}
              paymentMethod={paymentMethod}
            />
          ) : (
            <FlutterwaveForm
              id={id}
              paymentMethod={paymentMethod}
            />
          )
        }

      </div>

    </PrivateLayout>
  );
};

export default AddPaymentMethod;
