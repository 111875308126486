import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import st from './styles.module.scss';
import { InfoSvg, UserGroupSvg, UserSvg } from '../../assets/icons';

const PropertyType = ({ title, active, onClick, isMulti }) => (
  <div
    role="button"
    tabIndex={0}
    className={cx(st.wrapper, { [st.active]: active })}
    onClick={onClick}
    onKeyDown={null}
  >

    <div className={st.info}>

      <InfoSvg />

    </div>

    <div className={st.svg}>

      {isMulti ? <UserGroupSvg /> : <UserSvg />}

    </div>

    <div className={st.title}>

      {title}

    </div>

  </div>
);

PropertyType.defaultProps = {
  onClick: null,
};

PropertyType.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  isMulti: PropTypes.bool,
};

export default React.memo(PropertyType);
