import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useLocation } from 'react-router';
import { setInvitePassword } from '../../../services/actions/auth';
import AuthLayout from '../../../layouts/AuthLayout';
import { SIGN_IN_ROUTE, TERMS_ROUTE } from '../../../constants/routes';
import { InputText } from '../../../components/Inputs/InputText';
import InputCheckbox from '../../../components/Inputs/InputCheckbox';
import Button from '../../../components/Button';
import Label from '../../../components/Label';
import translate from '../../../utils/translate';
import styles from '../styles.module.scss';
import styles2 from '../SignInPage/styles.module.scss';


const InviteRegister = () => {
	const { search } = useLocation();

	const params = useMemo(() => {
		const urlParams = new URLSearchParams(search);

		return {
			invitation_token: urlParams.get('invite'),
			email: urlParams.get('email'),
		};
	}, [search]);

	const initValues = {
		email: params?.email || '',
		password: '',
		password_confirm: '',
		first_name: '',
		last_name: '',
		terms: false,
	};

	const schema = Yup.object().shape({
		email: Yup.string()
			.email(),
		password: Yup.string()
			.min(8)
			.max(24)
			.required(),
		password_confirm: Yup.string()
			.oneOf([Yup.ref('password')], translate('Both passwords are not matching'))
			.required(),
		first_name: Yup.string()
			.required(),
		last_name: Yup.string()
			.required(),
		terms: Yup.bool()
			.oneOf([true])
			.required(),
	});

	const handleSubmit = useCallback((values, actions) => {
		setInvitePassword({
			...params,
			...values,
		}, {...actions});
	}, [params]);

	return (
		<AuthLayout>

			<h2
				className={cn(
					'h2',
					styles.title
				)}
			>

				{translate('Invite')}

			</h2>

			<p className={styles.description}>

				{translate('Have an account?')}

				&nbsp;

				<Link to={SIGN_IN_ROUTE}>

					{translate('Sign in')}

				</Link>

			</p>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
			>

				{({ isSubmitting }) => (
					<Form>

						<Label>

							{translate('Email')}

						</Label>

						<InputText
							id="email"
							name="email"
							type="email"
							disabled
						/>

						<Label>

							{translate('Password')}

						</Label>

						<InputText
							id="password"
							name="password"
							type="password"
							autoComplete="new-password"
						/>

						<Label>

							{translate('Confirm password')}

						</Label>

						<InputText
							id="password_confirm"
							name="password_confirm"
							type="password"
							autoComplete="new-password"
						/>

						<Label>

							{translate('First name')}

						</Label>

						<InputText
							id="first_name"
							name="first_name"
							autoComplete="username"
						/>

						<Label>

							{translate('Last name')}

						</Label>

						<InputText
							id="last_name"
							name="last_name"
						/>

						<InputCheckbox
							id="terms"
							name="terms"
							noLabelClick
							title={(
								<div className={styles2.agree}>

									{translate('Creating an account means you’re okay with our')}

									<br />

									<Link
										to={TERMS_ROUTE}
									>

										{translate('Terms of Service,')}

									</Link>

									&nbsp;

									<Link
										to={TERMS_ROUTE}
									>

										{translate('Privacy Policy')}

									</Link>

								</div>
							)}
							value={true}
						/>

						<Button
							color="primary"
							variant="contained"
							type="submit"
							loading={isSubmitting}
							style={{
								width: '100%',
							}}
							size="big"
						>

							{translate('Confirm')}

						</Button>

					</Form>
				)}

			</Formik>

		</AuthLayout>
	);
};

export default InviteRegister;