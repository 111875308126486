import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { MOVE_IN_ROUTE } from '../../../../constants/routes';
import CardHeader from '../../../../components/Card/CardHeader';
import CardTitle from '../../../../components/Card/CardTitle';
import CardBody from '../../../../components/Card/CardBody';
import Button from '../../../../components/Button';
import Tenant from '../../../../components/Tenant';
import Card from '../../../../components/Card';
import useTenant from '../../../../hooks/useTenant';
import useLease from '../../../../hooks/useLease';
import useUnit from '../../../../hooks/useUnit';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

const TenantWrap = ({ leaseId }) => {
  const { lease } = useLease(leaseId);

  const { tenant } = useTenant(lease?.tenant_id);

  if(!tenant) {
    return null;
  }

  return (
    <Tenant
      className={styles.tenant}
      title={([tenant.first_name, tenant.last_name]).join(' ')}
      src={tenant.avatar}
      content={tenant.email}
    />
  )
}

export default function Tenants({ leaseIds }) {
  const { unit_id } = useParams();

  const { unit } = useUnit(Number(unit_id));

  return (
    <Card className={styles.card}>

      <CardHeader className={styles.cardHeader}>

        <CardTitle className={styles.title}>

          {translate('Tenants')}

        </CardTitle>

        {unit.status !== 'pending' && (
          <Button
            tag={Link}
            to={`${MOVE_IN_ROUTE}?unitId=${unit_id}`}
            color="info"
            variant="shadow"
            size="small"
          >

            {translate('Move in')}

          </Button>
        )}

      </CardHeader>

      {leaseIds.length > 0 && (
        <CardBody>

          {leaseIds.map((id) => (
            <TenantWrap
              key={id}
              leaseId={id}
            />
          ))}

        </CardBody>
      )}

    </Card>
  );
}
