import { createSelector } from 'reselect';

export const entitiesSelector = (state) => state.entities;

export const entitiesTenantsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.tenants,
);

export const entitiesUnitsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.units,
);

export const entitiesPropertiesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.properties,
);

export const entitiesTransactionsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.transactions,
);

export const entitiesLeasesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.leases,
);

export const entitiesInvoicesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.invoices,
);

export const entitiesLandlordsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.landlords,
);
