import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ModalFooter = ({ classNames, children }) => {
	return (
		<div
			className={cn(
				styles.modalFooter,
				classNames
			)}
		>

			{children}

		</div>
	);
};

ModalFooter.propTypes = {
	classNames: '',
	children: '',
};

ModalFooter.propTypes = {
	classNames: PropTypes.string,
	children: PropTypes.node,
};

export default ModalFooter;