import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useField } from 'formik';
import styles from './styles.module.scss';

const TypePayment = ({ id, name, title, onChange, description, disabled, value }) => {
	const [field, meta, helpers] = useField(name);

	const { error, touched } = meta;

	const checked = field.value === value;

	const handleChange = () => {
		helpers.setTouched(true);
		helpers.setValue(value);

		if (onChange) {
			onChange(value, name);
		}
	};

	return (
		<label
			htmlFor={id || name}
			className={cn(
				styles.typePayment,
				{
					[styles.checked]: checked,
					[styles.error]: error && touched,
				},
			)}
		>

			<h3 className={styles.title}>

				{title}

			</h3>

			<p className={styles.description}>

				{description}

			</p>

			<input
				type="radio"
				id={id || name}
				name={name}
				onBlur={field.onBlur}
				onChange={handleChange}
				checked={checked}
				disabled={disabled}
			/>

		</label>
	);
};

TypePayment.defaultProps = {
	id: '',
	onChange: null,
	disabled: false,
};

TypePayment.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.bool,
	]).isRequired,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};

export default TypePayment;