import React, { useMemo } from 'react';
import { Route, Switch, useParams } from 'react-router';
import { TENANTS_ROUTE } from '../../../constants/routes';
import PrivateLayout from '../../../layouts/PrivateLayout';
import Sidebar from '../../../components/Sidebar';
import getLinks from './getLinks';
import Detail from './Detail';
import Accounting from './Accounting';
import Leases from './Leases';
import LeaseEdit from './LeaseEdit';
import LeaseDetail from './LeaseDetail';
import styles from './styles.module.scss';

const TenantDetail = () => {
	const { tenantId } = useParams();

	const links = useMemo(() => getLinks(tenantId), [tenantId]);

	return (
		<PrivateLayout>

			<Sidebar
			  className={styles.sidebar}
			  links={links}
			/>

			<div className={styles.container}>

				<Switch>

					<Route exact path={`${TENANTS_ROUTE}/:tenantId`}><Detail/></Route>
					<Route exact path={`${TENANTS_ROUTE}/:tenantId/accounting`}><Accounting/></Route>
					<Route exact path={`${TENANTS_ROUTE}/:tenantId/leases`}><Leases/></Route>
					<Route exact path={`${TENANTS_ROUTE}/:tenantId/leases/:leaseId`}><LeaseDetail/></Route>
					<Route path={`${TENANTS_ROUTE}/:tenantId/leases/:leaseId/edit`}><LeaseEdit/></Route>

				</Switch>

			</div>

		</PrivateLayout>
	);
};

export default TenantDetail;