import _get from 'lodash/get';
import { AUTH } from '../../constants/type';

const newState = (requestState) => {
  switch (requestState) {
    case 'REQUEST': {
      return {
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case 'SUCCESS': {
      return {
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case 'FAILURE': {
      return {
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return null;
  }
};

const loadingReducer = (state = {}, action) => {
  const type = _get(action, 'type', null);
  const matches = /(.*)\/(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if([AUTH.SIGN_OUT.SUCCESS, AUTH.GET_PROFILE.FAILURE].includes(action.type)) {
    return {};
  }

  return {
    ...state,
    [requestName]: newState(requestState),
  };
};

export default loadingReducer;
