import {
  API_CONFIGURATION_AMENITIES_URL,
  API_CONFIGURATION_CATEGORIES_URL,
  API_CONFIGURATION_CONSTANTS_URL,
  API_CONFIGURATION_FEATURES_URL,
  API_CONFIGURATION_PAYMENT_MODES_URL,
  API_CONFIGURATION_SUBCATEGORIES_URL,
} from '../../constants/api';
import { CONFIGURATIONS } from '../../constants/type';
import requestWrapper from '../../utils/requestWrapper';

export const getConfigurationAmenities = async () => {
  await requestWrapper({
    action: CONFIGURATIONS.GET_AMENITIES,
    url: `${API_CONFIGURATION_AMENITIES_URL}?page_size=1000`,
    method: 'get',
  });
};

export const getConfigurationCategories = async (direction) => {
  let action = CONFIGURATIONS.GET_CATEGORIES;
  if(direction === 'money_in') {
    action = CONFIGURATIONS.GET_CATEGORIES_MONEY_IN;
  }
  if(direction === 'money_out') {
    action = CONFIGURATIONS.GET_CATEGORIES_MONEY_OUT;
  }
  await requestWrapper({
    action: action,
    url: `${API_CONFIGURATION_CATEGORIES_URL}?page_size=1000&direction=${direction || ''}`,
    method: 'get',
  });
};

export const getConfigurationSubcategories = async (categoryId) => {
  await requestWrapper({
    action: CONFIGURATIONS.GET_SUBCATEGORIES,
    url: `${API_CONFIGURATION_SUBCATEGORIES_URL}?page_size=1000`,
    method: 'get',
  });
};

export const getConfigurationFeatures = async () => {
  await requestWrapper({
    action: CONFIGURATIONS.GET_FEATURES,
    url: `${API_CONFIGURATION_FEATURES_URL}?page_size=1000`,
    method: 'get',
  });
};

export const getConfigurationPaymentModes = async () => {
  await requestWrapper({
    action: CONFIGURATIONS.GET_PAYMENT_MODES,
    url: `${API_CONFIGURATION_PAYMENT_MODES_URL}?page_size=1000`,
    method: 'get',
  });
};

export const getConfigurationConstants = async () => {
  await requestWrapper({
    action: CONFIGURATIONS.GET_CONSTANTS,
    url: API_CONFIGURATION_CONSTANTS_URL,
    method: 'get',
  });
};
