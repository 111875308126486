import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
// import styles from './styles.module.scss';

const CardHeader = ({ tag: Tag, className, styles, children }) => {
	return (
		<Tag
			className={cn(
				styles.cardHeader,
				className,
			)}
			styles={styles}
		>

			{children}

		</Tag>
	);
};

CardHeader.defaultProps = {
	tag: 'div',
	className: '',
	children: '',
	styles: {},
};

CardHeader.propTypes = {
	tag: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	className: PropTypes.string,
	children: PropTypes.node,
	styles: PropTypes.object,
};

export default CardHeader;