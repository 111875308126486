import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styles from './styles.module.scss';

const PublicLayout = ({ withFooter, children }) => {

	return (
		<>

			 <Header />

			 <main className={styles.content}>

				 <div>

					 {children}

				 </div>

				 { withFooter && (
					 <Footer />
				 )}

			 </main>

		</>
	);
};

PublicLayout.dafaultProps = {
	withFooter: false,
	children: '',
};

PublicLayout.propTypes = {
	withFooter: PropTypes.bool,
	children: PropTypes.node,
};

export default PublicLayout;
