import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import { InputText } from '../../../../components/Inputs/InputText';
import InputFile from '../../../../components/Inputs/InputFile';
import HalfBlock from '../../../../components/Inputs/HalfBlock';
import InputDate from '../../../../components/Inputs/InputDate';
import Loader from '../../../../components/Loader';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import { updateLease } from '../../../../services/actions/leases';
import useConfigurations from '../../../../hooks/useConfigurations';
import useSelectTenants from '../../../../hooks/useSelectTenants';
import useSelectUnits from '../../../../hooks/useSelectUnits';
import useLease from '../../../../hooks/useLease';
import translate from '../../../../utils/translate';
import history from '../../../../utils/history';
import styles from '../styles.module.scss';

const LeaseEdit = () => {
	const { leaseId } = useParams();

	const { lease } = useLease(Number(leaseId));

	const { tenantOptions } = useSelectTenants();

	const { unitOptions } = useSelectUnits();

	const { frequencies } = useConfigurations();

	const frequencyOptions = useMemo(() => frequencies.map((frequency) => ({
		label: frequency.name,
		value: frequency.id,
	})), [frequencies]);

	const handleSubmit = useCallback((values) => {
		updateLease({
			...values,
			id: leaseId,
		}, { onSuccess: history.goBack } );
	}, [leaseId]);

	if(!lease) {
		return (
			<Loader/>
		);
	}

	const initValues = {
		unit: lease.unit_id,
		tenant: lease.tenant_id,
		cotenants: lease.co_tenants || [],
		amount: lease.amount || 0,
		frequency: lease.frequency || '',
		date_start: new Date(lease.date_start),
		date_end: new Date(lease.date_end),
		deposit: lease.deposit,
		agreement: lease.agreement || '',
		document: lease.document || '',
	};

	const schema = Yup.object().shape({
		unit: Yup.string()
			.required(),
		tenant: Yup.string()
			.required(),
		cotenants: Yup.array()
			.required(),
		amount: Yup.number()
			.min(0)
			.positive()
			.required(),
		frequency: Yup.string()
			.required(),
		date_start: Yup.date()
			.required(),
		date_end: Yup.date()
			.required(),
		deposit: Yup.number()
			.min(0)
			// .positive()
			.required(),
		agreement: Yup.string()
			.required(),
		document: Yup.mixed()
			.imageTypes(['png', 'jpg', 'jpeg', 'pdf'])
			.fileMaxSize(),
	});

	return (
		<>

			<div className={styles.wrapTitle}>

				<h2 className={styles.title}>

					{translate('Edit Lease')}

				</h2>

			</div>

			<Formik
				initialValues={initValues}
				validationSchema={schema}
				onSubmit={handleSubmit}
				enableReinitialize
			>

				{({ values, isSubmitting }) => {
					const tenantsOptions = tenantOptions
						.filter((option) => !values.cotenants.includes(option.value));

					const cotenantsOptions = tenantOptions
						.filter((option) => option.value !== values.tenant);

					return (
						<Form>

							<Label>

								{translate('Property')}

							</Label>

							<InputSelect
								id="unit"
								name="unit"
								options={unitOptions}
							/>

							<HalfBlock>

								<div>

									<Label>

										{translate('Start Date')}

									</Label>

									<InputDate
										id="date_start"
										name="date_start"
										selectsStart
										startDate={values.date_start}
										endDate={values.date_end}
									/>

								</div>

								<div>

									<Label>

										{translate('End Date')}

									</Label>

									<InputDate
										id="date_end"
										name="date_end"
										selectsEnd
										startDate={values.date_start}
										endDate={values.date_end}
									/>

								</div>

							</HalfBlock>

							<Label>

								{translate('Tenant')}

							</Label>

							<InputSelect
								id="tenant"
								name="tenant"
								options={tenantsOptions}
							/>

							<Label>

								{translate('Cotenants')}

							</Label>

							<InputSelect
								id="cotenants"
								name="cotenants"
								options={cotenantsOptions}
								isMulti
							/>

							<Label>

								{translate('Amount')}

							</Label>

							<InputText
								id="amount"
								name="amount"
								type="number"
								step={1}
							/>

							<Label>

								{translate('Lease Frequency')}

							</Label>

							<InputSelect
								id="frequency"
								name="frequency"
								options={frequencyOptions}
							/>

							<Label>

								{translate('Deposit')}

							</Label>

							<InputText
								id="deposit"
								name="deposit"
								type="number"
								step={1}
							/>

							<Label>

								{translate('Create your agreement')}

							</Label>

							<InputText
								id="agreement"
								name="agreement"
								type="textarea"
								placeholder={translate('Type your text here...')}
							/>

							<div className={styles.upload}>

								{translate('or upload a ready lease agreement')}

							</div>

							<InputFile
								id="document"
								name="document"
								heading={translate('Upload agreement')}
								description={translate('The file size is not more than 3mb.')}
							/>

							<div className={styles.buttons}>

								<Button
									color="primary"
									onClick={history.goBack}
								>

									{translate('Cancel')}

								</Button>

								<Button
									color="primary"
									variant="contained"
									type="submit"
									loading={isSubmitting}
								>

									{translate('Confirm changes')}

								</Button>

							</div>

						</Form>
					);
				}}

			</Formik>

		</>
	);
};

export default LeaseEdit;