import React/*, { useEffect }*/ from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// import history from '../../../utils/history';
import styles from './styles.module.scss';

const Menu = ({ isOpen, children }) => {
	/*useEffect(() => {

		const unListen = history.listen((location, action) => {
			// console.log(location, action, isOpen);
		})

		return () => {
			unListen();
		}
	}, []);*/

	return (
		<menu
			className={cn(
				styles.menu,
				{[styles.active]: isOpen},
			)}
		>
			{children}
		</menu>
	);
};

Menu.defaultProps = {
	children: '',
	isOpen: false,
};

Menu.propTypes = {
	isOpen: PropTypes.bool,
	children: PropTypes.node,
}

export default Menu;