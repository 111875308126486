import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FooterMenu = ({ className, children }) => {
	return (
		<ul
			className={cn(
				className,
			)}
		>

			{children}

		</ul>
	);
};

FooterMenu.defaultProps = {
	className: '',
	children: '',
}

FooterMenu.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
};

export default FooterMenu;