import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SIGN_IN_ROUTE } from '../../../../constants/routes';
import Video from '../../../../components/Video';
import Button from '../../../../components/Button';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

const VideoBlock = ({ title, video }) => {
	return (
		<section className={styles.videoBlock}>

			<h2 className={styles.title}>

				{title}

			</h2>

			<Video
				src={video}
				className={styles.video}
			/>

			<Button
				tag={Link}
				className={styles.button}
				size="big"
				color="secondary"
				variant="shadow"
				to={SIGN_IN_ROUTE}
			>

				{translate('Get Started')}

			</Button>

		</section>
	);
};

VideoBlock.defautProps = {
	title: '',
	video: '',
};

VideoBlock.propTypes = {
	title: PropTypes.string,
	video: PropTypes.string,
};

export default VideoBlock;