import React, { Fragment, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PROPERTIES_CREATE_ROUTE, PROPERTIES_ROUTE } from '../../../constants/routes';
import PrivateLayout from '../../../layouts/PrivateLayout';
import UnitsFilter from './UnitsFilter';
import { CellSvg, ImportSvg, PlusSvg, SettingsSvg } from '../../../assets/icons';
import UncontrolledDropdown from '../../../components/DropDown/UncontrolledDropdown';
import DropdownToggle from '../../../components/DropDown/DropdownToggle';
import DropdownMenu from '../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../components/DropDown/DropDownItem';
import TableSimple from '../../../components/TableSimple';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/Button';
import Status from '../../../components/Status';
import Tenant from '../../../components/Tenant';
import Loader from '../../../components/Loader';
import { changeUnitsPage, getUnits } from '../../../services/actions/units';
import useConfigurations from '../../../hooks/useConfigurations';
import useLeasesByUnit from '../../../hooks/useLeasesByUnit';
import useUnitsFilter from '../../../hooks/useUnitsFilter';
import useLandlord from '../../../hooks/useLandlord';
import useProperty from '../../../hooks/useProperty';
import useTenant from '../../../hooks/useTenant';
import useUnits from '../../../hooks/useUnits';
import useLease from '../../../hooks/useLease';
import useAuth from '../../../hooks/useAuth';
import useUnit from '../../../hooks/useUnit';
import translate from '../../../utils/translate';
import history from '../../../utils/history';
import st from './styles.module.scss';
import MobileRow from '../../../components/MobileRow';

const LandlordName = ({ landlordId }) => {
  const { landlord } = useLandlord(landlordId);

  if(!landlord) return null;

  return (
    <>
      {landlord.first_name ? `${landlord?.first_name} ${landlord?.last_name}` : landlord.email}
    </>
  );
};

const TenantName = ({ leaseId }) => {
  const { lease } = useLease(leaseId);

  const { tenant } = useTenant(lease?.tenant_id);

  return `${tenant?.first_name} ${tenant?.last_name}`;
}

const LeaseEnd = ({ leaseId }) => {
  const { lease } = useLease(leaseId);

  return moment(lease?.date_end).format('DD MMMM, YYYY');
}

export const UnitRow = ({ unitId }) => {
  const { types_property_unit, status_unit } = useConfigurations();

  const { profile } = useAuth();

  const { unit } = useUnit(unitId);

  const { ids } = useLeasesByUnit(unitId);

  const { property } = useProperty(unit?.property);

  const handleEdit = useCallback(() => {
    history.push(`${PROPERTIES_CREATE_ROUTE}/${unit?.property}`)
  }, [unit]);

  if(!unit || !property) {
    return (
      <tr>
        <td colSpan={profile.is_landlord ? 6 : 5}>

          <Loader />

        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td>

        <Tenant
          title={`${property.name} ${unit.name}`}
          content={`${property.state}, ${property.city}, ${property.address}`}
          src={property.cover}
          isCircleLogo={false}
          link={`${PROPERTIES_ROUTE}/${unitId}`}
        />

      </td>
      <td>

        {types_property_unit.find((type) => type.id === property.unit_type)?.name}

      </td>
      <td>

        <Status
          status={unit.status}
        >

          {status_unit.find((status) => status.id === unit.status)?.name}

        </Status>

      </td>
      <td>

        {ids?.length > 0 && ids.map((leaseId) => (
          <LeaseEnd
            key={leaseId}
            leaseId={leaseId}
          />)
        ).reduce((prev, curr, idx) => [prev, <Fragment key={idx}>,<br/></Fragment>, curr])}

      </td>
      <td>

        {profile.is_landlord && ids?.length > 0 && ids.map((leaseId) => (
          <TenantName
            key={leaseId}
            leaseId={leaseId}
          />)
        ).reduce((prev, curr, idx) => [prev, <Fragment key={idx}>,<br/></Fragment>, curr])}

        {profile.is_tenant && (
          <LandlordName
            landlordId={property?.landlord}
          />
        )}

      </td>

      {profile.is_landlord && (
        <td>

          <UncontrolledDropdown
            className={st.buttonDrop}
          >

            <DropdownToggle>

              <Button
                isIcon
                className={st.buttonDrop}
              >

                <CellSvg />

              </Button>

            </DropdownToggle>

            <DropdownMenu
              position="right"
            >

              <DropDownItem
                onClick={handleEdit}
              >

                {translate('Edit')}

              </DropDownItem>

            </DropdownMenu>

          </UncontrolledDropdown>

        </td>
      )}

    </tr>
  );
};

export const MobileUnitRow = ({ unitId, bordered }) => {
  const { types_property_unit, status_unit } = useConfigurations();

  const { profile } = useAuth();

  const { unit } = useUnit(unitId);

  const { ids } = useLeasesByUnit(unitId);

  const { property } = useProperty(unit?.property);

  const handleEdit = useCallback(() => {
    history.push(`${PROPERTIES_CREATE_ROUTE}/${unit?.property}`)
  }, [unit]);

  if(!unit || !property) {
    return null;
  }

  const leaseEnd = ids?.length > 0 && ids.map((leaseId) => (
    <LeaseEnd
      key={leaseId}
      leaseId={leaseId}
    />)
  ).reduce((prev, curr, idx) => [prev, <Fragment key={idx}>,<br/></Fragment>, curr]);

  const users = profile.is_tenant
    ? (
      <LandlordName
        landlordId={property?.landlord}
      />
    )
    : ids?.length > 0 && ids.map((leaseId) => (
    <TenantName
      key={leaseId}
      leaseId={leaseId}
    />)
  ).reduce((prev, curr, idx) => [prev, <Fragment key={idx}>,<br/></Fragment>, curr]);

  return (
    <MobileRow
      src={property.cover}
      status={unit.status}
      statusName={status_unit.find((status) => status.id === unit.status)?.name}
      title={`${property.name} ${unit.name}`}
      content={`${property.state}, ${property.city}, ${property.address}`}
      link={`${PROPERTIES_ROUTE}/${unitId}`}
      bordered={bordered}
      buttons={profile.is_landlord ? [
        {
          title: translate('Edit'),
          toggle: true,
          onClick: handleEdit,
        }
      ] : undefined}
      list={[
        {
          title: translate('Type'),
          value: types_property_unit.find((type) => type.id === property.unit_type)?.name,
        },
        {
          title: translate('Lease End Date'),
          value: leaseEnd,
        },
        {
          title: profile.is_tenant ? translate('Landlord') : translate('Tenants'),
          value: users,
        },
      ]}
    />
  );
};

const Units = () => {
  const { profile } = useAuth();

  const filter = useUnitsFilter();

  const { property_unit_type, property_type, unit_type, status, search } = filter;

  const { ids, page, count, limit } = useUnits();

  const onChangePage = useCallback((newPage) => {
    changeUnitsPage(newPage);
  }, []);

  useEffect(() => {
    changeUnitsPage(1);
  }, []);

  useEffect(() => {
    getUnits({
      params: {
        page,
        property_unit_type,
        property_type,
        unit_type,
        status,
        search,
      }
    });
  }, [property_unit_type, property_type, unit_type, status, search, page]);

  return (
    <PrivateLayout>

      <div className={st.content}>

        <div className={st.wrapper}>

          {translate('Properties')}

          {profile.is_landlord && (
            <div className={st.controls}>

              <Button
                color="secondary"
                variant="shadow"
                svg={<ImportSvg />}
                className={st.control}
              >

                {translate('CSV')}

              </Button>

              <Button
                tag={Link}
                color="primary"
                variant="contained"
                svg={<PlusSvg />}
                to={PROPERTIES_CREATE_ROUTE}
                className={st.control}
              >

                {translate('Create Property')}

              </Button>

              <Button
                color="secondary"
                variant="shadow"
                svg={<ImportSvg />}
                className={st.controlMobile}
              />

              <Button
                tag={Link}
                color="primary"
                variant="contained"
                svg={<PlusSvg />}
                to={PROPERTIES_CREATE_ROUTE}
                className={st.controlMobile}
              />

            </div>
          )}

        </div>

        <UnitsFilter
          filter={filter}
        />

        <TableSimple
          className={st.table}
          settings={profile.is_landlord}
        >

          <thead>

          <tr>
            <th>{translate('Property')}</th>
            <th>{translate('Type')}</th>
            <th>{translate('Status')}</th>
            <th>{translate('Lease End Date')}</th>
            <th>{profile.is_tenant ? translate('Landlord') : translate('Tenants')}</th>

            {profile.is_landlord && (
              <th>

                <div className={st.buttonDrop}>

                  <SettingsSvg />

                </div>

              </th>
            )}

          </tr>

          </thead>

          <tbody>

          {ids.length > 0 && ids.map((unitId) => (
            <UnitRow
              key={unitId}
              unitId={unitId}
            />
          ))}

          </tbody>

        </TableSimple>

        <div>

          {ids.length > 0 && ids.map((id) => (
            <MobileUnitRow
              key={id}
              unitId={id}
            />
          ))}

        </div>

        <Pagination
          current={page}
          total={count}
          limit={limit}
          onChangePage={onChangePage}
        />

      </div>

    </PrivateLayout>
  );
};

export default Units;
