import { createAction } from 'redux-actions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { SIGN_IN_ROUTE, HOME_ROUTE, SIGN_UP_ROUTE, } from '../../constants/routes';
import {
	API_CHANGE_PASSWORD_URL,
	API_CONFIRM_EMAIL_URL,
	API_PROFILE_URL,
	API_FORGOT_URL,
	API_RESEND_EMAIL_URL,
	API_SET_PASSWORD_URL,
	API_SIGN_IN_URL,
	API_SIGN_UP_URL,
	API_SIGN_OUT_URL,
	API_SET_INVITE_PASSWORD_URL,
	API_CHANGE_EMAIL_URL,
	API_CONFIRM_CHANGE_EMAIL_URL,
	API_REFRESH_TOKEN_URL,
	API_PAYMENT_METHODS_URL,
	API_BANKS_URL, API_FLUTTERWAVE_URL
} from '../../constants/api';
import { AUTH } from '../../constants/type';
import { setAuthHeader, removeAuthHeader } from '../../utils/apiService';
import defaultErrorHandler from '../../utils/errorHandlers';
import requestWrapper from '../../utils/requestWrapper';
import translate from '../../utils/translate';
import appStore from '../../utils/store';
import history from '../../utils/history';
import { baseApi } from '../../utils/apiService';

export const getProfile = async () => {
	const onFailure = (error) => {
		if (error?.code === 'token_not_valid') {
			removeAuthHeader();
		}
	};

	await requestWrapper({
		action: AUTH.GET_PROFILE,
		url: API_PROFILE_URL,
		method: 'get',
		// onSuccess,
		onFailure,
	});
};

export const updateProfile = async (data, actions) => {
	const requestData = new FormData();

	if(typeof data.avatar === 'object') {
		requestData.append('avatar', data.avatar);
	}

	requestData.append('first_name', data.first_name);
	requestData.append('last_name', data.last_name);
	requestData.append('company', data.company);
	requestData.append('as_company', data.as_company);
	requestData.append('birthday', moment(data.birthday).format('YYYY-MM-DD'));
	requestData.append('phone', data.phone ? `+${data.phone}` : data.phone);
	requestData.append('city', data.city);
	requestData.append('address', data.address);
	requestData.append('state', data.state);

	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = async (e) => {
		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.UPDATE_PROFILE,
		url: API_PROFILE_URL,
		method: 'patch',
		requestData: requestData,
		onSuccess,
		onFailure,
	});
};

export const removeProfile = async () => {
	const onSuccess = () => {
		removeAuthHeader();
	};

	await requestWrapper({
		action: AUTH.REMOVE_PROFILE,
		url: API_PROFILE_URL,
		method: 'delete',
		onSuccess,
	});
};

export const resendEmail = async (email, actions) => {
	const onSuccess = () => {
		toast.success(translate('Email confirmation sent'))
	};

	const onFailure = () => {
		if (actions?.setSubmitting) {
			actions.setSubmitting(false);
		}
	};

	await requestWrapper({
		action: AUTH.RESEND_EMAIL,
		url: API_RESEND_EMAIL_URL,
		method: 'post',
		requestData: { email },
		onSuccess,
		onFailure,
	});
};

export const changePassword = async (requestData, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}

		toast.success(translate('Password changed'));
	};

	const onFailure = (e) => {
		if (actions?.onFailure) {
			actions.onFailure(e);
		}

		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: AUTH.CHANGE_PASSWORD,
		url: API_CHANGE_PASSWORD_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const signIn = async ({ remember, ...requestData }, actions) => {
	const onSuccess = (data) => {
		setAuthHeader(data, remember, getProfile);

		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		const { email } = requestData;

		if (e?.non_field_errors && e.non_field_errors[0] === 'Email not confirmed.') {
			resendEmail(email, {}, true);

			history.push(`${SIGN_UP_ROUTE}?email=${email}`);
		} else {
			defaultErrorHandler(e, actions);
		}
	};

	await requestWrapper({
		action: AUTH.SIGN_IN,
		url: API_SIGN_IN_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const signUp = async (requestData, actions) => {
	const onSuccess = ({ email }) => {
		if (actions?.setEmail) {
			actions.setEmail(email);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.SIGN_UP,
		url: API_SIGN_UP_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const confirmEmail = async (requestData) => {
	const onSuccess = () => {
		history.replace(SIGN_IN_ROUTE);
	};

	const onFailure = (data) => {
		if (data.non_field_errors) {
			history.replace(SIGN_IN_ROUTE);
		}
	};

	await requestWrapper({
		action: AUTH.CONFIRM_EMAIL,
		url: API_CONFIRM_EMAIL_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const forgot = async (requestData, actions) => {
	const onSuccess = () => {
		if (actions?.setEmail) {
			actions.setEmail(requestData.email);
		}
	};

	const onFailure = (data) => {
		if (data.non_field_errors) {
			history.replace(SIGN_IN_ROUTE);
		}

		if (actions?.setSubmitting) {
			actions.setSubmitting(false);
		}
	};

	await requestWrapper({
		action: AUTH.FORGOT,
		url: API_FORGOT_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const resetPassword = async (requestData, actions) => {
	const onSuccess = () => {
		history.replace(SIGN_IN_ROUTE);
	};

	const onFailure = (e) => {
		if (e.detail) {
			history.replace(SIGN_IN_ROUTE);
		}

		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.SET_PASSWORD,
		url: API_SET_PASSWORD_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const changeEmail = async (requestData, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.CHANGE_EMAIL,
		url: API_CHANGE_EMAIL_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const confirmChangeEmail = async (requestData, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.CONFIRM_CHANGE_EMAIL,
		url: API_CONFIRM_CHANGE_EMAIL_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const setInvitePassword = async (requestData, actions) => {
	const onSuccess = () => {
		history.replace(SIGN_IN_ROUTE);
	};

	const onFailure = (e) => {
		if (e.detail) {
			history.replace(SIGN_IN_ROUTE);
		}

		if (actions?.setSubmitting) {
			actions.setSubmitting(false);
		}
	};

	await requestWrapper({
		action: AUTH.SET_INVITE_PASSWORD,
		url: API_SET_INVITE_PASSWORD_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const signOut = async () => {
	const callback = () => removeAuthHeader(() => {
		appStore.dispatch(createAction(AUTH.SIGN_OUT.SUCCESS)());

		history.push({
			pathname: HOME_ROUTE,
		});
	});

	await requestWrapper({
		action: AUTH.SIGN_OUT,
		url: API_SIGN_OUT_URL,
		method: 'post',
		onSuccess: callback,
		onFailure: callback,
	});
};

export const refreshToken = async (refresh, remember, config) => {
	const onSuccess = ({ access }) => {
		setAuthHeader({
			access,
			refresh,
		}, remember, () => {
			baseApi.request({
				method: config.method,
				url: config.url,
				data: config.data
			});
		});
	}

	await requestWrapper({
		action: AUTH.REFRESH_TOKEN,
		url: API_REFRESH_TOKEN_URL,
		method: 'post',
		requestData: {
			refresh,
		},
		onSuccess,
	});
};

export const getPaymentMethods = async () => {
	await requestWrapper({
		action: AUTH.GET_PAYMENT_METHODS,
		url: API_PAYMENT_METHODS_URL,
		method: 'get',
	});
};

export const getBanks = async () => {
	await requestWrapper({
		action: AUTH.GET_BANKS,
		url: API_BANKS_URL,
		method: 'get',
	});
};

export const createPaymentMethod = async (requestData, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = async (e) => {
		defaultErrorHandler(e, actions);
		if(actions?.onFailure) {
			actions.onFailure(e);
		}
	};

	await requestWrapper({
		action: AUTH.CREATE_PAYMENT_METHOD,
		url: API_PAYMENT_METHODS_URL,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const updatePaymentMethod = async (requestData, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = async (e) => {
		defaultErrorHandler(e, actions);
		if(actions?.onFailure) {
			actions.onFailure(e);
		}
	};

	await requestWrapper({
		action: AUTH.UPDATE_PAYMENT_METHOD,
		url: `${API_PAYMENT_METHODS_URL}${requestData.id}/`,
		method: 'patch',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const deletePaymentMethod = async (requestData, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess(requestData);
		}
	};

	const onFailure = async (e) => {
		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.DELETE_PAYMENT_METHOD,
		url: `${API_PAYMENT_METHODS_URL}${requestData.id}/`,
		method: 'delete',
		onSuccess,
		onFailure,
	});
};

export const signInPayment = async (requestData, actions) => {
	const onSuccess = (data) => {
		history.replace(HOME_ROUTE);
		setAuthHeader(data, false, getProfile);

		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = (e) => {
		history.replace(SIGN_IN_ROUTE);

		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.SIGN_IN,
		url: `${API_FLUTTERWAVE_URL}callback/`,
		method: 'post',
		requestData,
		onSuccess,
		onFailure,
	});
};

export const updatePaymentSettings = async (data, actions) => {
	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess();
		}
	};

	const onFailure = async (e) => {
		defaultErrorHandler(e, actions);
	};

	await requestWrapper({
		action: AUTH.UPDATE_PROFILE,
		url: API_PROFILE_URL,
		method: 'patch',
		requestData: {
			// company: data.company,
			auto_payment: data.auto_payment,
		},
		onSuccess,
		onFailure,
	});
};
