import { createSelector } from 'reselect';

export const transactionsSelector = (state) => state.transactions;

export const transactionIdSelector = (_, transactionId) => transactionId;

export const transactionIdsSelector = createSelector(
	transactionsSelector,
	(transactions) => transactions.ids,
);

export const transactionIdsByTenantSelector = createSelector(
	transactionsSelector,
	(transactions) => transactions.transactionsByTenant,
);

export const transactionIdsByUnitSelector = createSelector(
	transactionsSelector,
	(transactions) => transactions.transactionsByUnit,
);

export const transactionsFilterSelector = createSelector(
	transactionsSelector,
	(transactions) => transactions.filter,
);
