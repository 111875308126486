import React, { useCallback } from 'react';
import SwiperCore, { Navigation, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams, Link } from 'react-router-dom';
import PrivateLayout from '../../../layouts/PrivateLayout/index';
import { PROPERTIES_CREATE_ROUTE, PROPERTIES_ROUTE } from '../../../constants/routes';
import { ReactComponent as LeftIcon } from '../../../assets/icons/angle-left.svg';
import { EditSvg } from '../../../assets/icons';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import useLeasesByUnit from '../../../hooks/useLeasesByUnit';
import useProperty from '../../../hooks/useProperty';
import useUnit from '../../../hooks/useUnit';
import useAuth from '../../../hooks/useAuth';
import translate from '../../../utils/translate';
import history from '../../../utils/history';
import PaymentHistory from './PaymentHistory';
import Amenities from './Amenities';
import Features from './Features';
import Landlord from './Landlord';
import Tenants from './Tenants';
import Lease from './Lease';
import styles from './styles.module.scss';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Scrollbar, A11y]);

export default function UnitDetail() {
  const { profile } = useAuth();

  const { unit_id } = useParams();

  const { ids } = useLeasesByUnit(Number(unit_id), true);

  const { unit, loader: { loading } } = useUnit(Number(unit_id));

  const { property } = useProperty(unit?.property);

  const toUnitsPage = useCallback(() => {
    history.push(PROPERTIES_ROUTE)
  }, []);

  return (
    <PrivateLayout>

      {(!loading && unit && property) ? (
        <div className={styles.unitDetail}>

          <div className={styles.wrapper}>

            <div className={styles.controls}>

              <Button
                onClick={toUnitsPage}
                isIcon
                variant="goBack"
                svg={<LeftIcon />}
              />

              <div>

                <div className={styles.title}>

                  {`${property.name} ${unit.name}`}

                </div>

                <div className={styles.description}>

                  {`${property.description || ''} (Rent: ${unit.rent}KSh, Deposit: ${unit.deposit}KSh)`}

                </div>

              </div>

            </div>

            {profile.is_landlord && (
              <Button
                tag={Link}
                to={`${PROPERTIES_CREATE_ROUTE}/${unit.property}`}
                color="secondary"
                variant="shadow"
                svg={<EditSvg />}
                className={styles.edit}
              >

                {translate('Edit property')}

              </Button>
            )}

          </div>

          <div className={styles.flexContent}>

            <div className={styles.leftContent}>

              <Swiper
                className={styles.slider}
                slidesPerView={1}
                loop
              >

                {property.images.map((image) => (
                  <SwiperSlide
                    key={image.id}
                    className={styles.slide}
                  >

                    <img src={image.image} alt="alt" />

                  </SwiperSlide>
                ))}

              </Swiper>

              <div className={styles.blockMobile}>

                {profile.is_landlord && (
                  <Button
                    tag={Link}
                    to={`${PROPERTIES_CREATE_ROUTE}/${unit.property}`}
                    color="secondary"
                    variant="shadow"
                    svg={<EditSvg />}
                    size="big"
                    className={styles.editMobile}
                  >

                    {translate('Edit property')}

                  </Button>
                )}

                {profile.is_landlord && (
                  <Tenants
                    leaseIds={ids}
                  />
                )}

                {profile.is_tenant && (
                  <Landlord
                    landlordId={property?.landlord}
                  />
                )}

                {ids.length > 0 && (
                  <Lease
                    leaseIds={ids}
                  />
                )}

                <PaymentHistory />

              </div>

              <Features
                rent={unit?.rent}
                features={unit.features}
              />

              <Amenities
                amenities={property.amenities}
              />

            </div>

            <div className={styles.rightContent}>

              {profile.is_landlord && (
                <Tenants
                  leaseIds={ids}
                />
              )}

              {profile.is_tenant && (
                <Landlord
                  landlordId={property?.landlord}
                />
              )}

              {ids.length > 0 && (
                <Lease
                  leaseIds={ids}
                />
              )}

              <PaymentHistory />

            </div>

          </div>

        </div>
      ) : (
        <Loader />
      )}

    </PrivateLayout>
  );
}
