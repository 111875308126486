import { useState } from 'react';

const useModal = (initState) => {
	const [modal, setModal] = useState(initState);

	const openModal = (id) => {
		const types = ['string', 'number', 'object'];

		let value = types.includes(typeof id) ? id : true;

		if (typeof value === 'object' && value.target) {
			value = true;
		}

		setModal(() => value);
	};

	const closeModal = () => setModal(() => false);

	const toggleModal = () => setModal((state) => !state);

	return [modal, openModal, closeModal, toggleModal];
};

export default useModal;
