import React, { useCallback } from 'react';
import ModalContent from '../../../../components/Modal/ModalContent';
import ModalBody from '../../../../components/Modal/ModalBody';
import Modal from '../../../../components/Modal';
import translate from '../../../../utils/translate';
import { CheckSuccessSvg } from '../../../../assets/icons';
import styles from '../styles.module.scss';
import Button from '../../../../components/Button';
import { HOME_ROUTE } from '../../../../constants/routes';
import { Link } from 'react-router-dom';

const ModalRequestDemoSubmitted = ({ isOpen, onClose }) => {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >

      <ModalContent className={styles.requestDemoModal}>

        <ModalBody>

          <div className={styles.wrapper}>

            <CheckSuccessSvg />

            <h3 className={styles.title}>

              {translate("Request Created")}

            </h3>

            <p className={styles.description}>

              {translate("Your request has been created. You will be redirected in 10 seconds")}

            </p>

            <Button
              color="secondary"
              variant="shadow"
            >
              <Link
                to={HOME_ROUTE}
                className={styles.button}
              >

                {translate("Go to Main page")}

              </Link>


            </Button>

          </div>

        </ModalBody>

      </ModalContent>

    </Modal>
  );
};

export default ModalRequestDemoSubmitted
