import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { clearSelectUnit, getSelectUnits } from '../services/actions/units';
import { unitsSelectSelector } from '../services/selectors/units';

const selector = createSelector(
	unitsSelectSelector,
	(select) => {
		const unitOptions = select.map((option) => ({
			label: option.name,
			value: option.id,
		}));

		return {
			unitOptions,
		};
	},
);

const useSelectUnits = (params = {}) => {
	const data = useSelector(selector);

	useEffect(() => {
		clearSelectUnit();
		getSelectUnits({
			params,
		});
	}, []);

	return {
		...data,
	};
};

export default useSelectUnits;