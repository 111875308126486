import React from 'react';
import PropTypes from 'prop-types';
import View from './View';
import styles from './styles.module.scss';

const Views = ({ views }) => {
	return (
		<section className={styles.views}>

			{views.map(({ id, title, description, color, icon, image }) => (
				<View
					key={id}
					title={title}
					description={description}
					color={color}
					icon={icon}
					image={image}
				/>
			))}

		</section>
	);
};

Views.defaultProps = {
	views: [],
};

Views.propTypes = {
	views: PropTypes.array,
};

export default Views;