import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Avatar from '../../../../../components/Avatar';

const Review = ({ content, profile }) => {

	return (
		<div>

			<p className={styles.content}>

				{content}

			</p>

			<div className={styles.profile}>

				<Avatar
					src={profile.avatar}
					size="72"
				/>

				<div className={styles.wrap}>

					<h3 className={styles.name}>

						{profile.name}

					</h3>

					<p className={styles.position}>

						{profile.position}

					</p>

				</div>
				
			</div>

		</div>
	);
};

Review.propTypes = {
	content: PropTypes.string.isRequired,
	profile: PropTypes.object.isRequired,
}

export default Review;