import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import entities from './entities';
import loading from './loading';
import auth from './auth';
import tenants from './tenants';
import properties from './properties';
import configurations from './configurations';
import transactions from './transactions';
import leases from './leases';
import units from './units';
import publicReducer  from './public';
import widgets  from './widgets';
import invoices  from './invoices';
import history from '../../utils/history';

const reducers = combineReducers({
	router: connectRouter(history),
	// -- start connecting reducers
	entities,
	configurations,
	public: publicReducer,
	loading,
	auth,
	units,
	tenants,
	properties,
	transactions,
	leases,
	widgets,
	invoices,
});

export default reducers;
