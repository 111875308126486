import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import translate from '../../../../utils/translate';
import st from './styles.module.scss';
import useConfigurations from '../../../../hooks/useConfigurations';

export default function Amenities({ amenities }) {
  const { amenities: amenityList } = useConfigurations();

  const getAmenity = useCallback((id) => {
    const amenity = amenityList.find((item) => item.id === id);

    return (
      <div
        key={id}
        className={st.item}
      >

        <figure className={st.image}>

          {amenity.image && (
            <img
              src={amenity.image}
              alt="icon-amenity"
            />
          )}

        </figure>

        {amenity.name}

      </div>
    )}, [amenityList])

  return (
    <div className={st.wrapper}>

      <div className={st.heading}>{translate('Amenities')}</div>

      <div className={st.content}>

        <div className={st.left}>

          {amenities
            .slice(0, Math.floor(amenities.length/2))
            .map(getAmenity)}

        </div>

        <div className={st.right}>

          {amenities
            .slice(Math.floor(amenities.length/2))
            .map(getAmenity)}

        </div>

      </div>

    </div>
  );
};

PropTypes.Amenities = {
  amenities: PropTypes.array.isRequired,
}
