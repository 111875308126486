import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { CameraSvg } from '../../../assets/icons';
import { ReactComponent as CameraIcon } from '../../../assets/icons/camera2.svg';
import { InputNotice } from '../InputNotice';
import Avatar from '../../Avatar';
import translate from '../../../utils/translate';
import styles from './styles.module.scss';

const InputImage = ({ className, id, name, accept, variant, readOnly, disabled }) => {
	const ref = useRef(null);

	const [field, meta, helpers] = useField(name);

	const { error, touched } = meta;

	const [source, setSource] = useState('');

	useEffect(() => {
		let mounted = true;

		if (typeof meta.value === 'string') {
			setSource(meta.value);
		} else if (meta.value) {
			if (/(image\/*)/.test(meta.value.type)) {
				const reader = new FileReader();

				reader.readAsDataURL(meta.value);

				reader.onloadend = () => {
					if (mounted) {
						if (!meta.error) {
							setSource(reader.result);
						} else {
							setSource('');
						}
					}
				};
			} else {
				setSource('');
			}
		}

		return () => {
			mounted = false;
		};
	}, [meta]);

	const handleChange = (e) => {
		const file = e.currentTarget.files[0];

		if (file) {
			helpers.setTouched(true);

			helpers.setValue(file);

			field.onBlur('');
		}

		ref.current.value = null;
	};

	/*const handleClear = () => {
		helpers.setValue('');
	};*/

	const content = useMemo(() => {
		if(variant === 'default') {
			return (
				<div className={styles.defaultAvatar}>

					<Avatar
						className={cn(
							styles.image,
							{ [styles.error]: error && touched },
						)}
						src={source}
						variant="circle"
						size="48"
					/>

					<p className={styles.description}>

						<CameraSvg />

						{translate('Upload Photo')}

					</p>

				</div>
			);
		}

		if(variant === 'profile') {
			return (
				<div className={styles.profileAvatar}>

					<Avatar
						className={cn(
							styles.image,
							{ [styles.error]: error && touched },
						)}
						src={source}
						variant="circle"
						size="48"
					/>

					<div className={styles.camera}>

						<CameraIcon
							width={20}
							height={20}
						/>

					</div>

				</div>
			);
		}
	}, [source, variant, error, touched]);

	return (
		<>

			<label
				className={cn(
					styles.inputImage,
					className,
				)}
			>

				<input
					id={id}
					ref={ref}
					type="file"
					name={name}
					onBlur={field.onBlur}
					onChange={handleChange}
					accept={accept}
					readOnly={readOnly}
					disabled={disabled}
				/>

				{content}

			</label>

			{error && touched && (
				<InputNotice
					name={name}
				/>
			)}

		</>
	);
};

InputImage.defaultProps = {
	className: '',
	accept: '.png, .jpg, .jpeg',
	variant: 'default',
	readOnly: false,
	disabled: false,
};

InputImage.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	accept: PropTypes.string,
	variant: PropTypes.oneOf([
		'default',
		'profile',
	]),
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default InputImage;