import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import history from '../../../../../../utils/history';
import { PAYMENT_INFO_ROUTE } from '../../../../../../constants/routes';
import { createPaymentMethod, updatePaymentMethod } from '../../../../../../services/actions/auth';
import useAuth from '../../../../../../hooks/useAuth';
import { useParams } from 'react-router';
import { Form, Formik } from 'formik';
import st_pay_met from '../styles.module.scss';
import Label from '../../../../../../components/Label';
import translate from '../../../../../../utils/translate';
import { InputSelect } from '../../../../../../components/Inputs/InputSelect';
import { InputText } from '../../../../../../components/Inputs/InputText';
import InputPhone from '../../../../../../components/Inputs/InputPhone';
import Button from '../../../../../../components/Button';
import { PAYMENT_TYPES } from '../../index';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const FlutterwaveForm = (props) => {

  const {
    id,
    paymentMethod,
  } = props;

  const { banks } = useAuth();

  const bankOptions = useMemo(() => (banks || []).map((bank) => ({
    label: bank.name,
    value: bank.id,
  })), [banks]);

  const initValues = useMemo(() => {
    const paymentData = paymentMethod?.data;
    if(paymentData) {
      return {
        payment_method: paymentData.bank_code,
        phone: paymentData.bank_code === 'MPS' ? paymentData.bank_account : '',
        bank_account: paymentData.bank_code !== 'MPS' ? paymentData.bank_account : '',
      };
    }

    return {
      payment_method: '',
      phone: '',
      bank_account: '',
    };
  }, [paymentMethod]);

  const schema = Yup.object().shape({
    payment_method: Yup.string()
      .required(),
    phone: Yup.string()
      .when('payment_method', {
        is: (payment_method) => payment_method !== 'MPS',
        then: Yup.string(),
        otherwise: Yup.string().required(),
      }),
    bank_account: Yup.string()
      .when('payment_method', {
        is: (payment_method) => payment_method !== 'MPS',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
  });

  const toSecurityPage = useCallback(() => {
    history.push(PAYMENT_INFO_ROUTE);
  }, []);

  const handleSubmit = useCallback((values, actions) => {
    const action = id ? updatePaymentMethod : createPaymentMethod;

    const onFailure = () => {
      if(id) {
        toast.error(translate('Error while updating payment method'));
      } else {
        toast.error(translate('Error while creating payment method'));
      }
    }

    action({
      id,
      payment_type: PAYMENT_TYPES.OTHER,
      data: {
        bank_code: values.payment_method,
        bank_name: banks.find((bank) => bank.id === values.payment_method)?.name,
        bank_account: values.payment_method === 'MPS' ? (values.phone ? `+${values.phone}` : '') : values.bank_account,
        _type: values.payment_method === 'MPS' ? 'mpesa' : 'bank_account',
      },
    }, {...actions, onSuccess: toSecurityPage, onFailure });
  }, [toSecurityPage, id, banks]);


  return (
    <Formik
      initialValues={initValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >

      {({ isSubmitting, values }) => (
        <Form className={st_pay_met.form}>

          <Label>

            {translate('Bank')}

          </Label>

          <InputSelect
            id="payment_method"
            name="payment_method"
            options={bankOptions}
          />


          {values.payment_method
            ? values.payment_method !== 'MPS'
              ? (
                <>

                  <Label>

                    {translate('Bank account')}

                  </Label>

                  <InputText
                    id="bank_account"
                    name="bank_account"
                  />

                </>
              )
              : (
                <>

                  <Label>

                    {translate('Phone')}

                  </Label>

                  <InputPhone
                    id="phone"
                    name="phone"
                  />

                </>
              )
            : null}

          <div className={st_pay_met.btns}>

            <Button
              tag={Link}
              to={PAYMENT_INFO_ROUTE}
              color="primary"
            >
              {translate('Cancel')}

            </Button>

            <Button
              color="primary"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >

              {translate('Save')}

            </Button>

          </div>

        </Form>
      )}

    </Formik>
  );
}

FlutterwaveForm.propTypes = {
  id: PropTypes.string,
  paymentMethod: PropTypes.object,
};

export default FlutterwaveForm;
