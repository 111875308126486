import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const MenuItem = ({ className, to, light, children }) => {

	return (
		<li
			className={cn(
				styles.menuItem,
				{[styles.light]: light},
				className
			)}
		>

			<Link
				to={to}
			>

				{children}

			</Link>

		</li>
	);
};

MenuItem.defaultProps = {
	className: '',
	light: false,
	children: '',
}

MenuItem.propTypes = {
	className: PropTypes.string,
	light: PropTypes.bool,
	to: PropTypes.string.isRequired,
	children: PropTypes.node,
};

export default MenuItem;