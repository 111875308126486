import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ReactComponent as LeftIcon } from '../../../../../assets/icons/angle-left.svg';
import { SECURITY_ROUTE } from '../../../../../constants/routes';
import PrivateLayout from '../../../../../layouts/PrivateLayout';
import { changePassword } from '../../../../../services/actions/auth'
import Button from '../../../../../components/Button';
import Label from '../../../../../components/Label';
import { InputText } from '../../../../../components/Inputs/InputText';
import translate from '../../../../../utils/translate';
import styles from '../../styles.module.scss';
import st_change from './styles.module.scss';

const ChangePassword = () => {
  const history = useHistory();

  const initValues = {
    old_password: '',
    password: '',
    password_confirm: '',
  };

  const schema = Yup.object().shape({
    old_password: Yup.string()
      .min(8)
      .max(24)
      .required(),
    password: Yup.string()
      .min(8)
      .max(24)
      .required(),
    password_confirm: Yup.string()
      .min(8)
      .max(24)
      .required(),
  });

  const onSuccessHandler = useCallback(() => {
    history.push(SECURITY_ROUTE);
  }, [history])

  const handleSubmit = useCallback((values, action) => {
    changePassword(values, { ...action, onSuccess: onSuccessHandler });
  }, [onSuccessHandler]);

  const toSecurityPage = useCallback(() => {
    history.push(SECURITY_ROUTE);
  }, [history]);

  return (
    <PrivateLayout>

      <div className={st_change.container}>

        <div className={st_change.heading}>

          <Button
            variant="goBack"
            onClick={toSecurityPage}
            isIcon
            svg={<LeftIcon />}
          />

          <h2 className={styles.title}>

            {translate('Change Password')}

          </h2>

        </div>

        <Formik
          initialValues={initValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Label>
                {translate('Old password')}
              </Label>

              <InputText
                id='old_password'
                name='old_password'
                type='password'
                autoComplete="off"
              />

              <Label>
                {translate('New password')}
              </Label>

              <InputText
                id="password"
                name="password"
                type="password"
                autoComplete="off"
              />

              <Label>
                {translate('Confirm password')}
              </Label>

              <InputText
                id="password_confirm"
                name="password_confirm"
                type="password"
                autoComplete="off"
              />

              <div className={st_change.btns}>
                <Button
                  color="secondary"
                  variant="shadow"
                  onClick={toSecurityPage}
                >

                  {translate('Cancel')}

                </Button>

                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                >

                  {translate('Change Password')}

                </Button>
              </div>
            </Form>
          )}
        </Formik>

      </div>

    </PrivateLayout>
  );
};

export default ChangePassword;