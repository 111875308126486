import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ModalHeader = ({ className, children }) => {
	return (
		<div
			className={cn(
				styles.modalHeader,
				className,
			)}
		>

			{children}

		</div>
	);
};

ModalHeader.defaultProps = {
	className: '',
	children: '',
};

ModalHeader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

export default ModalHeader;