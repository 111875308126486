export const HOME_ROUTE = '/';

/* PROFILE */
export const PROFILE_ROUTE = '/profile';
export const SECURITY_ROUTE = '/security';
export const CHANGE_EMAIL_ROUTE = '/security/change-email';
export const CHANGE_PASSWORD_ROUTE = '/security/change-password';
export const RECOVER_PASSWORD_ROUTE = '/security/recover-password';

/* PROPERTIES */
export const PROPERTIES_ROUTE = '/properties';
export const PROPERTIES_CREATE_ROUTE = '/create-property';

/* TENANTS */
export const TENANTS_ROUTE = '/tenants';
export const TENANTS_IMPORT_ROUTE = '/tenants-import';
export const TENANTS_UPLOAD_ROUTE = '/tenants-upload';
export const TENANTS_CREATE_ROUTE = '/tenants/tenants-create';

/* ACCOUNTING */
export const ACCOUNTING_REPORT_INCOME_STMT_ROUTE = '/accounting/report/income-statement';

/* TRANSACTIONS */
export const TRANSACTIONS_ROUTE = '/transactions';
export const TRANSACTION_CREATE_ROUTE = '/transactions/transaction-create';
export const TRANSACTION_PAY_INVOICE_ROUTE = '/transactions/transaction-pay-invoice';

/* MESSAGES */
export const MESSAGES_ROUTE = '/messages';

export const MOVE_IN_ROUTE = '/move-in';
export const PAYMENT_INFO_ROUTE = '/payment-info';
export const PAYMENT_SETTINGS_ROUTE = '/payment-settings';
export const ADD_PAYMENT_METHOD_ROUTE = '/payment-info/add-payment-method';

/* PUBLIC */
export const FEATURES_ROUTE = '/features';
export const SOLUTION_ROUTE = '/solution';
export const PRICING_ROUTE = '/pricing';
export const CONTACTS_ROUTE = '/contacts';
export const TERMS_ROUTE = '/terms';
export const PRIVACY_ROUTE = '/privacy';
export const REQUEST_DEMO_ROUTE = '/request-demo';

/* AUTH */
export const SIGN_IN_ROUTE = '/sign-in';
export const SIGN_UP_ROUTE = '/sign-up';
export const CONFIRM_EMAIL_ROUTE = '/confirm-email';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
// /?invite=6add4f04-68d6-40c0-bc96-a3f8993194b3"
export const INVITE_REGISTER_ROUTE = '/invite-register';
export const PAYMENT_ROUTE = '/payment';
