import {
  API_PUBLIC_TEXTS_URL,
  API_PUBLIC_CONTACT_URL,
  API_PUBLIC_SUBSCRIBE_URL,
  API_PUBLIC_REQUEST_DEMO_URL,
} from '../../constants/api';
import { PUBLIC } from '../../constants/type';
import requestWrapper from '../../utils/requestWrapper';
import defaultErrorHandler from '../../utils/errorHandlers';

export const getTexts = async () => {
  await requestWrapper({
    action: PUBLIC.GET_TEXTS,
    url: API_PUBLIC_TEXTS_URL,
    method: 'get',
  });
};

export const sendContact = async (requestData, actions) => {
  const onSuccess = (response) => {
    if (actions?.onSuccess) {
      actions.onSuccess(response);
    }
  };

  const onFailure = (e) => {
    defaultErrorHandler(e, actions);
  };

  await requestWrapper({
    action: PUBLIC.SEND_CONTACT,
    url: API_PUBLIC_CONTACT_URL,
    method: 'post',
    requestData,
    onSuccess,
    onFailure,
  });
};

export const sendSubscribe = async (requestData, actions) => {
  const onSuccess = (response) => {
    if (actions?.onSuccess) {
      actions.onSuccess(response);
    }
  };

  const onFailure = (e) => {
    defaultErrorHandler(e, actions);
  };

  await requestWrapper({
    action: PUBLIC.SEND_SUBSCRIBE,
    url: API_PUBLIC_SUBSCRIBE_URL,
    method: 'post',
    requestData,
    onSuccess,
    onFailure,
  });
};

export const sendDemoRequest = async (requestData, actions) => {
  const onSuccess = (response) => {
    if (actions?.onSuccess) {
      actions.onSuccess(response);
    }
  };

  const onFailure = (e) => {
    defaultErrorHandler(e, actions);
    if (actions?.onFailure) {
      actions.onFailure(e);
    }
  };

  await requestWrapper({
    action: PUBLIC.SEND_DEMO_REQUEST,
    url: API_PUBLIC_REQUEST_DEMO_URL,
    method: 'post',
    requestData,
    onSuccess,
    onFailure,
  });
};
