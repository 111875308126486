import React from 'react';
import ModalContent from '../../Modal/ModalContent';
import ModalHeader from '../../Modal/ModalHeader';
import ModalTitle from '../../Modal/ModalTitle';
import ModalClose from '../../Modal/ModalClose';
import ModalBody from '../../Modal/ModalBody';
import Modal from '../../Modal';

const ModalFilters = ({ isOpen, onClose, title, children }) => {

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
		>

			<ModalContent>

				<ModalHeader>

					<ModalTitle>

						{title}

					</ModalTitle>

					<ModalClose onClose={onClose} />

				</ModalHeader>

				<ModalBody>

					{children}

				</ModalBody>

			</ModalContent>

		</Modal>
	);
};

export default ModalFilters;