import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { widgetByTenantSelector } from '../services/selectors/widgets';
import { getWidgetByTenant } from '../services/actions/widgets';
import { tenantIdSelector } from '../services/selectors/tenants';

const selector = createSelector(
	widgetByTenantSelector,
	tenantIdSelector,
	(tenantWidgets, tenantId) => {
		const widgetsByTenant = tenantWidgets[tenantId];

		return {
			widgetsByTenant,
		};
	},
);

const useWidgetByTenant = (tenantId, refresh) => {
	const newSelector = useCallback((state) => selector(state, tenantId), [tenantId]);

	const { widgetsByTenant } = useSelector(newSelector);

	useEffect(() => {
		if (!widgetsByTenant && typeof tenantId === 'number' && !isNaN(tenantId)) {
			getWidgetByTenant(tenantId);
		}
	}, [widgetsByTenant, tenantId]);

	useEffect(() => {
		if (refresh && typeof tenantId === 'number' && !isNaN(tenantId)) {
			getWidgetByTenant(tenantId);
		}
	}, [refresh, tenantId]);

	return {
		widgetsByTenant: widgetsByTenant || [],
	};
};

export default useWidgetByTenant;