import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
	Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel,
} from 'react-accessible-accordion';
import { ReactComponent as AngleDown } from '../../../../assets/icons/angle-down.svg';
import styles from './styles.module.scss';

const Questions = ({ title, questions }) => {
	const half = Math.ceil(questions.length / 2);

	return (
		<section
			className={styles.questions}
		>

			<h2
				className={styles.title}
			>

				{title}

			</h2>

			<div
				className={styles.container}
			>

				<Accordion
					allowZeroExpanded
					className={cn(
						'accordion',
						styles.wrap
					)}
				>

					{questions.slice(0, half).map(({ title, description }, index) => (
						<AccordionItem
							key={index}
						>

							<AccordionItemHeading>

								<AccordionItemButton>

									{title}

									<AngleDown />

								</AccordionItemButton>

							</AccordionItemHeading>

							<AccordionItemPanel>

								<p>

									{description}

								</p>

							</AccordionItemPanel>

						</AccordionItem>
					))}

				</Accordion>

				<Accordion
					allowZeroExpanded
					className={cn(
						'accordion',
						styles.wrap
					)}
				>

					{questions.slice(half).map(({ title, description }, index) => (
						<AccordionItem
							key={index}
						>

							<AccordionItemHeading>

								<AccordionItemButton>

									{title}

									<AngleDown />

								</AccordionItemButton>

							</AccordionItemHeading>

							<AccordionItemPanel>

								<p>

									{description}

								</p>

							</AccordionItemPanel>

						</AccordionItem>
					))}

				</Accordion>


			</div>

		</section>
	);
};

Questions.defaultProps = {
	title: '',
	questions: [],
};

Questions.propTypes = {
	title: PropTypes.string,
	questions: PropTypes.array,
};

export default Questions;