import React, { useState } from 'react';
import { useLocation } from 'react-router';
import PrivateLayout from '../../../layouts/PrivateLayout';
import Steps from '../../../components/Steps';
import ChooseProperty from './ChooseProperty';
import ChooseTenant from './ChooseTenant';
import Agreements from './Agreements';
import LeaseDetail from './LeaseDetail';
import styles from './styles.module.scss';

const steps = ['Property', 'Tenants', 'Lease', 'Agreements'];

const MoveIn = () => {
	const { search } = useLocation();

	const urlParams = new URLSearchParams(search);

	const [step, setStep] = useState(1);

	const [values, setValues] = useState({
		unit: urlParams.get('unitId') ? Number(urlParams.get('unitId')) : '',
		tenant: urlParams.get('tenantId') ? Number(urlParams.get('tenantId')) : '',
		cotenants: [],
		amount: 0,
		frequency: '',
		date_start: new Date(),
		date_end: new Date(),
		deposit: 0,
		agreement: '',
		document: '',
	});

	const content = {
		1: (
			<ChooseProperty
				setStep={setStep}
				values={values}
				setValues={setValues}
			/>
		),
		2: (
			<ChooseTenant
				setStep={setStep}
				values={values}
				setValues={setValues}
			/>
		),
		3: (
			<LeaseDetail
				setStep={setStep}
				values={values}
				setValues={setValues}
			/>
		),
		4: (
			<Agreements
				setStep={setStep}
				values={values}
				setValues={setValues}
			/>
		),
	};

	return (
		<PrivateLayout>

			<div className={styles.moveIn}>

				<Steps steps={steps} active={step} />

				{content[step]}

			</div>

		</PrivateLayout>
	);
};

export default MoveIn;