import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { intersectionBy } from 'lodash';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import useSelectTenants from '../../../../hooks/useSelectTenants';
import useLeasesByUnit from '../../../../hooks/useLeasesByUnit';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function ChooseTenant({ values, setValues, setStep }) {
  const { ids } = useLeasesByUnit(values.unit);

  const { tenantOptions, leaseIdsByTenant } = useSelectTenants();

  const filteredTenantOptions = useMemo(() => tenantOptions
    .filter((tenantOption) => !intersectionBy(ids, leaseIdsByTenant[tenantOption.value]).length), [ids, tenantOptions, leaseIdsByTenant]);

  const handlePrev = useCallback(() => {
    setStep(1);
  }, [setStep]);

  const initValues = {
    tenant: values.tenant,
    cotenants: values.cotenants,
  };

  const schema = Yup.object().shape({
    tenant: Yup.string()
      .required(),
    cotenants: Yup.array(),
  });

  const handleSubmit = useCallback((formValues) => {
    setValues((val) => ({
      ...val,
      ...formValues,
    }));

    setStep(3);
  }, [setValues, setStep]);

  return (
    <div className={styles.wrapper}>

      <div className={styles.header}>

        {translate('Choose a Tenant')}

      </div>

      <Formik
        initialValues={initValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >

        {({ values }) => {
          const tenantsOptions = filteredTenantOptions
            .filter((option) => !values.cotenants.includes(option.value));

          const cotenantsOptions = filteredTenantOptions
            .filter((option) => option.value !== values.tenant);

          return (
            <Form>

              <Label>

                {translate('Tenant')}

              </Label>

              <InputSelect
                id="tenant"
                name="tenant"
                options={tenantsOptions}
              />

              <Label>

                {translate('Cotenants')}

              </Label>

              <InputSelect
                id="cotenants"
                name="cotenants"
                options={cotenantsOptions}
                isMulti
              />

              <div className={styles.footer}>

                <Button
                  color="secondary"
                  variant="shadow"
                  onClick={handlePrev}
                >

                  {translate('Back')}

                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >

                  {translate('Next Step')}

                </Button>

              </div>

            </Form>
          );
        }}

      </Formik>

    </div>
  );
};

ChooseTenant.propTypes = {
  values: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};
