import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TransactionFilter from './TransactionFilter';
import PrivateLayout from '../../../layouts/PrivateLayout/index';
import {
  TRANSACTION_CREATE_ROUTE,
  TRANSACTION_PAY_INVOICE_ROUTE,
  TRANSACTIONS_ROUTE
} from '../../../constants/routes';
import { CellSvg, ImportSvg, PlusSvg, SettingsSvg } from '../../../assets/icons';
import UncontrolledDropdown from '../../../components/DropDown/UncontrolledDropdown';
import DropdownToggle from '../../../components/DropDown/DropdownToggle';
import DropdownMenu from '../../../components/DropDown/DropdownMenu';
import DropDownItem from '../../../components/DropDown/DropDownItem';
import TableSimple from '../../../components/TableSimple';
import Pagination from '../../../components/Pagination';
import MobileRow from '../../../components/MobileRow';
import Tooltip from '../../../components/Tooltip';
import Status from '../../../components/Status';
import Button from '../../../components/Button';
import Tenant from '../../../components/Tenant';
import Loader from '../../../components/Loader';
import { changeTransactionsPage, getTransactions } from '../../../services/actions/transactions';
import useTransactionsFilter from '../../../hooks/useTransactionsFilter';
import useConfigurations from '../../../hooks/useConfigurations';
import useTransactions from '../../../hooks/useTransactions';
import useTransaction from '../../../hooks/useTransaction';
import useTenant from '../../../hooks/useTenant';
import useAuth from '../../../hooks/useAuth';
import translate from '../../../utils/translate';
import history from '../../../utils/history';
import st from './styles.module.scss';

const TransactionRow = ({ transactionId }) => {
  const { profile } = useAuth();

  const { payment_modes, categories, status_transaction } = useConfigurations();

  const { transaction } = useTransaction(transactionId);

  const { tenant } = useTenant(transaction?.tenant_id);

  const handleEdit = useCallback(() => {
    history.push(`${TRANSACTION_CREATE_ROUTE}/${transactionId}`);
  }, [transactionId]);

  const handlePay = useCallback(() => {
    history.push(`${TRANSACTION_PAY_INVOICE_ROUTE}/${transactionId}`);
  }, [transactionId]);

  const dropItems = useMemo(() => {
    let items = [];

    if(profile.is_landlord && !transaction.invoice_id && !['settled', 'confirmed'].includes(transaction.status)) {
      items = [
        ...items,
        (<DropDownItem
          key="edit"
          onClick={handleEdit}
        >

          {translate('Edit')}

        </DropDownItem>)
      ]
    }

    if(((profile.is_landlord && transaction.invoice_id && transaction.direction === 'money_out')
      || (profile.is_tenant && transaction.invoice_id && transaction.direction === 'money_in' && !transaction.automatic_payment))
      && !['settled', 'confirmed', 'in_progress'].includes(transaction.status)
    ) {
      items = [
        ...items,
        (
          <DropDownItem
            key="pay"
            onClick={handlePay}
          >

            {translate('Pay')}

          </DropDownItem>
        )
      ];
    }

    return items;
  }, [profile, transaction, handleEdit, handlePay]);

  if(!transaction) {
    return (
      <tr>

        <td colSpan={9}>

          <Loader />

        </td>

      </tr>
    );
  }

  let title = tenant?.first_name ? `${tenant?.first_name} ${tenant?.last_name}` : tenant?.email;

  if(transaction.direction === 'money_out') {
    title = transaction.supplier;
  }

  const getTransactionDate = (transaction) => {
    if(transaction.invoice_id) {
      if(transaction.parent_number) {
        return transaction.transaction_date && moment(transaction.transaction_date).format('DD MMMM, YYYY');
      } else {
        return transaction.invoice_date && moment(transaction.invoice_date).format('DD MMMM, YYYY');
      }
    } else {
      return transaction.transaction_date && moment(transaction.transaction_date).format('DD MMMM, YYYY');
    }
  };

  return (
    <tr>
      <td>

        <Tenant
          title={title}
          src={transaction.direction === 'money_out' ? null : tenant?.avatar}
          isCircleLogo={false}
          content={transaction.invoice_id && transaction.address}
          link={`${TRANSACTIONS_ROUTE}/${transactionId}`}
        />

      </td>
      <td>{transaction.reference}</td>
      <td>{categories.find((category) => category.id === transaction.category_id)?.name}</td>
      <td>

        <Status
          className="i-flex"
          status={transaction.status}
        >

          {status_transaction.find((status) => status.id === transaction.status)?.name}

        </Status>

        {transaction.status === 'confirmed' && transaction.transaction_code && (
          <Tooltip
            title={translate('It may take up to 1-2 days to settle this payment')}
          />
        )}

      </td>
      <td>
        {getTransactionDate(transaction)}
      </td>
      <td>{transaction.invoice_date_due && moment(transaction.invoice_date_due).format('DD MMMM, YYYY')}</td>
      <td>{payment_modes.find((mode) => mode.id === transaction.mode_id)?.name}</td>
      <td>{`${transaction.amount}KSh`}</td>
      <td>

        {dropItems?.length > 0 && (
          <UncontrolledDropdown>

            <DropdownToggle>

              <Button
                isIcon
                className={st.buttonDrop}
              >

                <CellSvg />

              </Button>

            </DropdownToggle>

            <DropdownMenu
              position="right"
            >

              {dropItems}

            </DropdownMenu>

          </UncontrolledDropdown>
        )}

      </td>
    </tr>
  );
};

const TransactionRowMobile = ({ transactionId }) => {
  const { profile } = useAuth();

  const { payment_modes, categories, status_transaction } = useConfigurations();

  const { transaction } = useTransaction(transactionId);

  const { tenant } = useTenant(transaction?.tenant_id);

  const handleEdit = useCallback(() => {
    history.push(`${TRANSACTION_CREATE_ROUTE}/${transactionId}`);
  }, [transactionId]);

  const handlePay = useCallback(() => {
    history.push(`${TRANSACTION_PAY_INVOICE_ROUTE}/${transactionId}`);
  }, [transactionId]);

  const dropItems = useMemo(() => {
    let items = [];

    if(profile.is_landlord && !transaction.invoice_id) {
      items.push({
        title: translate('Edit'),
        onClick: handleEdit,
      });
    }

    if(transaction.invoice_id && transaction.direction === 'money_out') {
      items.push({
        title: translate('Pay'),
        onClick: handlePay,
      });
    }

    return items;
  }, [profile, transaction, handleEdit, handlePay]);

  if(!transaction) {
    return null;
  }

  let title = tenant?.first_name ? `${tenant?.first_name} ${tenant?.last_name}` : tenant?.email;

  if(transaction.direction === 'money_out') {
    title = transaction.supplier;
  }

  const getTransactionDate = (transaction) => {
    if(transaction.invoice_id) {
      if(transaction.parent_number) {
        return transaction.transaction_date && moment(transaction.transaction_date).format('DD MMMM, YYYY');
      } else {
        return transaction.invoice_date && moment(transaction.invoice_date).format('DD MMMM, YYYY');
      }
    } else {
      return transaction.transaction_date && moment(transaction.transaction_date).format('DD MMMM, YYYY');
    }
  };

  return (
    <MobileRow
      src={transaction.direction === 'money_out' ? null : tenant?.avatar}
      status={transaction.status}
      statusName={status_transaction.find((status) => status.id === transaction.status)?.name}
      title={title}
      content={transaction.invoice_id && transaction.address}
      link={`${TRANSACTIONS_ROUTE}/${transactionId}`}
      buttons={dropItems}
      list={[
        {
          title: translate('Reference'),
          value: transaction.reference,
        },
        {
          title: translate('Category'),
          value: categories.find((category) => category.id === transaction.category_id)?.name,
        },
        {
          title: translate('Date'),
          value: getTransactionDate(transaction),
        },
        {
          title: translate('Due Date'),
          value: transaction.invoice_date_due && moment(transaction.invoice_date_due).format('DD MMMM, YYYY'),
        },
        {
          title: translate('Payment Mode'),
          value: payment_modes.find((mode) => mode.id === transaction.mode_id)?.name,
        },
        {
          title: translate('Amount'),
          value: `${transaction.amount}KSh`,
        },
      ]}
    />
  );
};

const Transactions = () => {
  const { profile } = useAuth();

  const filter = useTransactionsFilter();

  const { direction, category, status, mode, search } = filter;

  const { ids, page, count, limit } = useTransactions();

  useEffect(() => {
    changeTransactionsPage(1);
  }, []);

  const onChangePage = useCallback((newPage) => {
    changeTransactionsPage(newPage);
  }, []);

  useEffect(() => {
    getTransactions({
      params: {
        page,
        direction,
        category,
        status,
        mode,
        search
      }
    });
  }, [page, direction, category, status, mode, search]);

  return (
    <PrivateLayout>

      <div className={st.content}>

        <div className={st.wrapper}>

          {translate('Transactions')}

          {profile.is_landlord && (
            <div className={st.controls}>

              <Button
                tag={Link}
                to={'/'}
                color="secondary"
                variant="shadow"
                svg={<ImportSvg />}
                className={st.control}
              >

                {translate('CSV')}

              </Button>

              <Button
                tag={Link}
                to={TRANSACTION_CREATE_ROUTE}
                color="primary"
                variant="contained"
                svg={<PlusSvg />}
                className={st.control}
              >

                {translate('Create Transaction')}

              </Button>

              <Button
                tag={Link}
                to={'/'}
                color="secondary"
                variant="shadow"
                svg={<ImportSvg />}
                className={st.controlMobile}
              />

              <Button
                tag={Link}
                to={TRANSACTION_CREATE_ROUTE}
                color="primary"
                variant="contained"
                svg={<PlusSvg />}
                className={st.controlMobile}
              />

            </div>
          )}

        </div>

        <TransactionFilter
          filter={filter}
        />

        <TableSimple
          className={st.table}
          settings
        >

          <thead>

          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Reference')}</th>
            <th>{translate('Category')}</th>
            <th>{translate('Status')}</th>
            <th>{translate('Date')}</th>
            <th>{translate('Due Date')}</th>
            <th>{translate('Payment Mode')}</th>
            <th>{translate('Amount')}</th>
            <th>

              <div className={st.buttonDrop}>
                <SettingsSvg />
              </div>

            </th>
          </tr>

          </thead>

          <tbody>

          {ids.map((transaction) => (
            <TransactionRow
              key={transaction}
              transactionId={transaction}
            />
          ))}

          </tbody>

        </TableSimple>

        <div className={st.list}>

          {ids.map((transaction) => (
            <TransactionRowMobile
              key={transaction}
              transactionId={transaction}
            />
          ))}

        </div>

        <Pagination
          current={page}
          total={count}
          limit={limit}
          onChangePage={onChangePage}
        />

      </div>

    </PrivateLayout>
  );
}

export default Transactions;
