import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';

const Button = ({ tag: Tag, type, className, color, svg, variant, size, isIcon, children, loading, disabled, ...rest }) => {

	return (
		<Tag
			type={type}
			className={cn(
				styles.button,
				{ [styles[color]]: color },
				{ [styles[variant]]: variant },
				{ [styles[size]]: size },
				{ [styles.icon]: isIcon },
				className,
			)}
			{...rest}
			disabled={disabled || loading}
		>
			{svg && <span className={styles.svg}>{svg}</span>}
			{loading ? 'Loading...' : children}

		</Tag>
	);
};

Button.defaultProps = {
	tag: 'button',
	className: '',
	color: 'default',
	variant: '',
	size: '',
	isIcon: false,
	loading: false,
	disabled: false,
	type: 'button',
	children: '',
	svg: null,
};

Button.propTypes = {
	tag: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
    svg: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.node,
	  ]),
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'default',
		'primary',
		'secondary',
		'warning',
		'danger',
		'success',
		'info',
		'light',
	]),
	variant: PropTypes.oneOf([
		'',
		'outlined',
		'contained',
		'shadow',
		'goBack',
		'widget',
	]),
	size: PropTypes.oneOf([
		'',
		'small',
		'big',
	]),
	isIcon: PropTypes.bool,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	children: PropTypes.node,
};

export default Button;