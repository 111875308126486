import React from 'react'
import { ReactComponent as BoldIcon } from '../../../../../assets/icons/bold2.svg';
import { ReactComponent as ItalicIcon } from '../../../../../assets/icons/italic2.svg';
import { ReactComponent as ListIcon } from '../../../../../assets/icons/list2.svg';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/link2.svg';
import { ReactComponent as SentIcon } from '../../../../../assets/icons/send2.svg';
import { ReactComponent as AttachFileIcon } from '../../../../../assets/icons/attach-file.svg';
import Button from '../../../../../components/Button';
import styles from './styles.module.scss';
import translate from '../../../../../utils/translate';

const TextareaMessage = () => {
	return (
		<div className={styles.textareaMessage}>

			<textarea
				rows="2"
				placeholder={translate('Type your message...')}
			/>

			<div className={styles.wrap}>

				<div>

					<Button
						className={styles.button}
						color="primary"
						isIcon
					>

						<BoldIcon/>

					</Button>

					<Button
						className={styles.button}
						color="primary"
						isIcon
					>

						<ItalicIcon/>

					</Button>

					<Button
						className={styles.button}
						color="primary"
						isIcon
					>

						<ListIcon/>

					</Button>

					<Button
						className={styles.button}
						color="primary"
						isIcon
					>

						<LinkIcon/>

					</Button>

					<Button
						className={styles.button}
						color="primary"
						isIcon
					>

						<AttachFileIcon/>

					</Button>

				</div>

				<Button
					className={styles.button}
					color="primary"
					isIcon
				>

					<SentIcon/>

				</Button>

			</div>

		</div>
	);
};

export default TextareaMessage;