import React from 'react';
import useAuth from '../../../hooks/useAuth';
import TransactionPayInvoiceLandlord from './TransactionPayInvoiceLandlord';
import TransactionPayInvoiceTenant from './TransactionPayInvoiceTenant';

const TransactionPayInvoice = () => {
	const { profile } = useAuth();
	if(profile.is_landlord) {
		return <TransactionPayInvoiceLandlord />
	} else {
		return <TransactionPayInvoiceTenant />
	}
}

export default TransactionPayInvoice;
