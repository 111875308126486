import React from 'react';
import PropTypes from 'prop-types';
import st from './styles.module.scss';

export default function Feature({
  title, icon, quantity,
}) {
  return (
    <div className={st.wrapper}>

      <figure className={st.icon}>

        {icon && (
          <img src={icon} alt="feature" />
        )}

      </figure>

      <h3 className={st.title}>

        {title}

      </h3>

      <p className={st.description}>

        {quantity}

      </p>

    </div>
  );
}

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  quantity: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};
