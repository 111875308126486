import moment from 'moment';
import { createAction } from 'redux-actions';
import schemas from '../schemas';
import { API_TENANTS_URL, API_TRANSACTIONS_URL, API_UNITS_URL } from '../../constants/api';
import { TRANSACTIONS } from '../../constants/type';
import defaultErrorHandler from '../../utils/errorHandlers';
import requestWrapper from '../../utils/requestWrapper';
import store from '../../utils/store';

export const getTransactions = async ({
	params = {},
	actions = {},
}) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	await requestWrapper({
		action: TRANSACTIONS.GET_LIST,
		url: API_TRANSACTIONS_URL,
		method: 'get',
		normalizeSchema: schemas.TRANSACTIONS_ARRAY,
		addHeadersToResponse: true,
		options: {
			params,
		},
		onSuccess,
	});
};

export const getTransaction = async (id, actions) => {
	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response);
		}
	};

	const onFailure = () => {};

	await requestWrapper({
		action: TRANSACTIONS.GET_TRANSACTION,
		url: `${API_TRANSACTIONS_URL}${id}/`,
		method: 'get',
		normalizeSchema: schemas.TRANSACTIONS,
		onSuccess,
		onFailure,
	});
};

const getRequestData = (data) => {
	const requestData = new FormData();

	requestData.append('direction', data.direction ? 'money_in' : 'money_out');

	requestData.append('reference', data.reference);

	requestData.append('invoices', JSON.stringify(data.invoices));

	requestData.append('category_id', data.category_id);

	if(data.sub_category_id) {
		requestData.append('sub_category_id', data.sub_category_id);
	}

	requestData.append('transaction_date', moment(data.transaction_date).format('YYYY-MM-DD'));

	requestData.append('invoice_date_due', moment(data.invoice_date_due).format('YYYY-MM-DD'));

	requestData.append('amount', data.amount);

	if(data.tenant_id) {
		requestData.append('tenant_id', data.tenant_id);
	}

	requestData.append('supplier', data.supplier);

	requestData.append('lease_id', data.lease_id);

	requestData.append('mode_id', data.mode_id);

	requestData.append('description', data.description);

	requestData.append('documents', data.documents);

	return requestData;
}

export const createTransaction = async (data, actions) => {
	const requestData = getRequestData(data);

	const onSuccess = (response) => {
		if (actions?.onSuccess) {
			actions.onSuccess(response.id);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: TRANSACTIONS.CREATE_TRANSACTION,
		url: API_TRANSACTIONS_URL,
		method: 'post',
		normalizeSchema: schemas.TRANSACTIONS,
		requestData,
		onSuccess,
		onFailure,
	});
};

export const updateTransaction = async (data, actions) => {
	const requestData = getRequestData(data);

	const onSuccess = () => {
		if (actions?.onSuccess) {
			actions.onSuccess(data.id);
		}
	};

	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: TRANSACTIONS.UPDATE_TRANSACTION,
		url: `${API_TRANSACTIONS_URL}${data.id}/`,
		method: 'patch',
		normalizeSchema: schemas.TRANSACTIONS,
		requestData,
		onSuccess,
		onFailure,
	});
};

export const getTransactionsByTenant = async (id, actions) => {
	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: TRANSACTIONS.GET_TRANSACTIONS_BY_TENANT,
		url: `${API_TENANTS_URL}${id}/${API_TRANSACTIONS_URL}`,
		method: 'get',
		normalizeSchema: schemas.TRANSACTIONS_ARRAY,
		requestData: { tenant: id },
		addDataToResponse: true,
		onFailure,
	});
};

export const getTransactionsByUnit = async (id, actions) => {
	const onFailure = (e) => {
		defaultErrorHandler(e, actions)
	};

	await requestWrapper({
		action: TRANSACTIONS.GET_TRANSACTIONS_BY_UNIT,
		url: `${API_UNITS_URL}${id}/${API_TRANSACTIONS_URL}`,
		method: 'get',
		normalizeSchema: schemas.TRANSACTIONS_ARRAY,
		requestData: { unit: id },
		addDataToResponse: true,
		onFailure,
	});
};

export const changeTransactionsPage = (page) => {
	store.dispatch(createAction(TRANSACTIONS.CHANGE_PAGE.base)({
		page
	}));
};

export const changeTransactionsFilter = (value, type) => {
	store.dispatch(createAction(TRANSACTIONS.CHANGE_FILTER.base)({
		value,
		type
	}));
};
