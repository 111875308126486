import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactPlayer from 'react-player';
import styles from './styles.module.scss';

const Video = ({ src, className, ...rest }) => {
	return (
		<div
			className={cn(
				styles.video,
				className,
			)}
		>

			<ReactPlayer
				url={src}
				controls
				width="100%"
				height="400px"
				config={{
					youtube: {
						playerVars: { showinfo: 1 },
						embedOptions: {
							height: '100%',
							width: '100%',
						},
					},
				}}
				{...rest}
			/>

		</div>
	);
};

Video.defaultProps = {
	className: '',
};

Video.propTypes = {
	src: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default Video;