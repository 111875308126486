import { createSelector } from 'reselect';

export const tenantsSelector = (state) => state.tenants;

export const tenantIdSelector = (_, tenantId) => tenantId;

export const tenantIdsSelector = createSelector(
	tenantsSelector,
  (tenants) => tenants.ids,
);

export const tenantsFilterSelector = createSelector(
	tenantsSelector,
  (tenants) => tenants.filter,
);

export const tenantsSelectSelector = createSelector(
	tenantsSelector,
  (tenants) => tenants.select,
);
