import { AUTH, TRANSACTIONS } from '../../constants/type';

export const defaultState = {
	ids: [],
	transactionsByTenant: {},
	transactionsByUnit: {},
	filter: {
		direction: '',
		category: '',
		status: '',
		mode: '',
		search: '',
	},
	page: 1,
	count: 0,
	limit: 0,
};

export default (state = defaultState, action) => {
	const { type, payload } = action;

	switch (type) {
		case TRANSACTIONS.GET_LIST.SUCCESS:
			return {
				...state,
				/*ids: payload.requestData?.refresh ? payload.result : [...new Set([
				 ...state.ids,
				 ...payload.result,
				 ])],*/
				ids: payload.result,
				count: Number(payload.headers['x-pagination-count']),
				limit: Number(payload.headers['x-pagination-pagesize']),
			};

		/*case TRANSACTIONS.CREATE_TRANSACTION.SUCCESS:
			return {
				...state,
				ids: [...new Set([
					...state.ids,
					payload.result,
				])],
			};*/

		case TRANSACTIONS.GET_TRANSACTIONS_BY_TENANT.SUCCESS:
			return {
				...state,
				transactionsByTenant: {
					...state.transactionsByTenant,
					[payload.requestData.tenant]: payload.result,
				},
			};

		case TRANSACTIONS.GET_TRANSACTIONS_BY_UNIT.SUCCESS:
			return {
				...state,
				transactionsByUnit: {
					...state.transactionsByUnit,
					[payload.requestData.unit]: payload.result,
				},
			};

		case TRANSACTIONS.CHANGE_PAGE.base:
			return {
				...state,
				page: payload.page,
			};

		case TRANSACTIONS.CHANGE_FILTER.base:
			return {
				...state,
				filter: {
					...state.filter,
					[payload.type]: payload.value,
				},
				page: 1,
			};

		case AUTH.SIGN_OUT.SUCCESS:
		case AUTH.GET_PROFILE.FAILURE: {
			return defaultState;
		}

		default:
			return state;
	}
};
