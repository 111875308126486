import React, { useMemo } from 'react';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import { InputText } from '../../../../components/Inputs/InputText';
import InputCheckbox from '../../../../components/Inputs/InputCheckbox';
import CardHeader from '../../../../components/Card/CardHeader';
import InputFile from '../../../../components/Inputs/InputFile';
import CardTitle from '../../../../components/Card/CardTitle';
import CardBody from '../../../../components/Card/CardBody';
import Label from '../../../../components/Label';
import Card from '../../../../components/Card';
import useConfigurations from '../../../../hooks/useConfigurations';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function Verification() {
  const { document_types } = useConfigurations();

  const documentOptions = useMemo(() => document_types.map((document) => ({
    label: document.name,
    value: document.id,
  })), [document_types]);

  return (
    <Card className={styles.verification}>

      <CardHeader>

        <CardTitle>

          {translate('Verification')}

        </CardTitle>

      </CardHeader>

      <CardBody>


        <Label>

          {translate('Document')}

        </Label>

        <InputSelect
          id="identity_type"
          name="identity_type"
          options={documentOptions}
        />

        <Label>

          {translate('Passport/ID\'s number')}

        </Label>

        <InputText
          id="identity_number"
          name="identity_number"
        />

        <InputFile
          id="documents"
          name="documents"
          variant="plus"
          multiple
        />

        <InputCheckbox
          id="identity_verify"
          name="identity_verify"
          title={translate('I confirm ID is valid unti the expiry date and is in color')}
          value
        />

      </CardBody>

    </Card>
  );
}
