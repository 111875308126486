import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CellSvg } from '../../assets/icons';
import UncontrolledDropdown from '../DropDown/UncontrolledDropdown';
import DropdownToggle from '../DropDown/DropdownToggle';
import DropdownMenu from '../DropDown/DropdownMenu';
import DropDownItem from '../DropDown/DropDownItem';
import Status from '../Status';
import Avatar from '../Avatar';
import Button from '../Button';
import styles from './styles.module.scss';

const MobileRow = ({ src, link, title, content, status,  statusName, buttons, bordered, list, buttonBottom }) => {
	const LinkTag = link ? Link : 'div';

	const tables = useMemo(() => {
		const items = [];

		list.chunk(3).forEach((table, index) => {
			table.forEach((item) => {
				if(!items[index]?.head) {
					items[index] = {
						head: [],
						body: [],
					};
				}

				items[index].head.push(item.value);
				items[index].body.push(item.title);
			});
		});

		return items;
	}, [list]);

	return (
		<div
			className={cn(
				styles.mobileRow,
				{
					[styles.bordered]: bordered,
				}
			)}
		>

			<div className={styles.header}>

				<LinkTag
					to={link}
					className={styles.link}
				>

					<Avatar
						src={src}
						size="82"
					/>

					<div className={styles.wrap}>

						<h3 className={styles.title}>

							{title}

						</h3>

						<p className={styles.content}>

							{content}

						</p>

						{status && (
							<Status
								status={status}
							>

								{statusName || status}

							</Status>
						)}

					</div>

				</LinkTag>

				{buttons.length > 0 && (
					<UncontrolledDropdown>

						<DropdownToggle className={styles.buttonDrop}>

							<Button
								isIcon
								className={styles.buttonDrop}
							>

								<CellSvg />

							</Button>

						</DropdownToggle>

						<DropdownMenu
							position="right"
						>

							{buttons.map((button, index) => (
								<DropDownItem
									key={index}
									toggle={button.toggle}
									onClick={button.onClick}
								>

									{button.title}

								</DropDownItem>
							))}

						</DropdownMenu>

					</UncontrolledDropdown>
				)}

			</div>

			{list.length > 0 && tables.map((table, index) => (
				<table className={styles.footer} key={index}>

					<thead>

					<tr>
						{table.head.map((value, index) => (<th key={index}>{value}</th>))}
					</tr>

					</thead>

					<tbody>

					<tr>
						{table.body.map((value, index) => (<td key={index}>{value}</td>))}
					</tr>

					</tbody>

				</table>
			))}

			{buttonBottom && (
				<Button
					color="info"
					variant="shadow"
					onClick={buttonBottom.onClick}
					className={styles.buttonBottom}
				>

					{buttonBottom.title}

				</Button>
			)}

		</div>
	);
};

MobileRow.defaultProps = {
	src: '',
	bordered: false,
	link: '',
	title: '',
	content: '',
	status: '',
	statusName: '',
	buttons: [],
	list: [],
	buttonBottom: null,
};

MobileRow.propTypes = {
	src: PropTypes.string,
	bordered: PropTypes.bool,
	link: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string,
	status: PropTypes.string,
	statusName: PropTypes.string,
	buttons: PropTypes.array,
	list: PropTypes.array,
	buttonBottom: PropTypes.object,
};

export default MobileRow;