import React from 'react';
import Device from './Device';
import styles from './styles.module.scss';

const Devices = ({ devices }) => {
	return (
		<section className={styles.devices}>

			{devices.map(({ id, title, device, image }) => (
				<Device
					key={id}
					className={styles.device}
					title={title}
					device={device}
					image={image}
				/>
			))}

		</section>
	);
};

export default Devices;