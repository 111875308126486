import { normalize } from 'normalizr';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { baseApi } from './apiService';
import appStore from './store';

const getActionsState = (action) => {
	return {
		requestAction: (requestData) => appStore.dispatch(createAction(action.REQUEST)(requestData)),
		successAction: (response) => appStore.dispatch(createAction(action.SUCCESS)(response)),
		failureAction: (response) => appStore.dispatch(createAction(action.FAILURE)(response)),
	}
};

const requestWrapper = async ({
	action, url, method, requestData = null, onSuccess = () => {}, onFailure = () => {}, addDataToResponse = false,
	options = {}, normalizeSchema = null, addHeadersToResponse = false, addLoading = false,
}) => {
	let response;

	const stateActions = getActionsState(action);

	stateActions.requestAction(!addLoading ? undefined : (
		normalizeSchema
			? normalize(requestData, normalizeSchema)
			: requestData
	));

	try {
		response = await baseApi({
			url, method, data: requestData, ...options,
		});

		const { data, headers } = response;

		let clearData = {
			...data,
		};

		let normalizeData = normalizeSchema
			? normalize(data, normalizeSchema)
			: data;

		if(addDataToResponse) {
			normalizeData = {
				...normalizeData,
				requestData,
			};

			clearData = {
				...clearData,
				requestData
			};
		}

		if(addHeadersToResponse) {
			normalizeData = {
				...normalizeData,
				headers,
			};

			clearData = {
				...clearData,
				headers
			};
		}

		stateActions.successAction(normalizeData);

		onSuccess(clearData);

	} catch (e) {
		onFailure(e.data);

		stateActions.failureAction(e.data);

		// if (process.env.NODE_ENV !== 'production') {
		// 	toast.error(`
    //     URL: ${url};
    //     ERROR: ${JSON.stringify(e, null, 2)}
    //   `);
		//
		// 	console.log('==============================================');
		// 	console.log('url', url);
		// 	console.log('==============================================');
		// 	console.error('error', e);
		// 	console.log('==============================================');
		// 	console.error('error message', e.message);
		// 	console.log('==============================================');
		// }

		return {
			error: e.data,
		};
	}

	return response;
};

export default requestWrapper;
