import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Hero = ({ title, description }) => {
	return (
		<section className={styles.hero}>

			<h2 className={styles.title}>

				{title}

			</h2>

			<p className={styles.description}>

				{description}

			</p>

		</section>
	);
};

Hero.defaultProps = {
	title: '',
	description: '',
};

Hero.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
};

export default Hero;