import { useCallback, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { entitiesTenantsSelector } from '../services/selectors/entities';
import { tenantIdSelector } from '../services/selectors/tenants';
import createLoadingSelector  from '../services/selectors/loading';
import { TENANTS } from '../constants/type';
import { getTenant } from '../services/actions/tenants';

const selector = createSelector(
	entitiesTenantsSelector,
	tenantIdSelector,
	createLoadingSelector(TENANTS.GET_TENANT.base),
	(tenants, tenantId, loader) => {
		const tenant = tenants[tenantId];

		return {
			tenant: tenant ? {
				...tenant,
			} : null,
			loader,
		};
	},
);

const useTenant = (tenantId, refresh) => {
	const newSelector = useCallback((state) => selector(state, tenantId), [tenantId]);

	const { tenant, loader } = useSelector(newSelector);

	useEffect(() => {
		if (!tenant && typeof tenantId === 'number' && !isNaN(tenantId)) {
			getTenant(tenantId);
		}
	}, [tenant, tenantId]);

	useEffect(() => {
		if (refresh && typeof tenantId === 'number' && !isNaN(tenantId)) {
			getTenant(tenantId);
		}
	}, [refresh, tenantId]);

	return {
		tenant,
		loader,
	};
};

export default useTenant;