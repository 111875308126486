import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { InputSelect } from '../../../../components/Inputs/InputSelect';
import Button from '../../../../components/Button';
import { PROPERTIES_ROUTE } from '../../../../constants/routes';
import useSelectUnits from '../../../../hooks/useSelectUnits';
import translate from '../../../../utils/translate';
import styles from './styles.module.scss';

export default function ChooseProperty({ values, setValues, setStep }) {
  const { unitOptions } = useSelectUnits();

  const initValues = {
    unit: values.unit,
  };

  const schema = Yup.object().shape({
    unit: Yup.string()
      .required(),
  });

  const handleSubmit = useCallback((formValues) => {
    setValues((val) => ({
      ...val,
      ...formValues,
    }));

    setStep(2);
  }, [setValues, setStep]);

  return (
    <div className={styles.wrapper}>

      <div className={styles.header}>

        {translate('Choose a Property')}

      </div>

      <Formik
        initialValues={initValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >

        <Form>

          <InputSelect
            id="unit"
            name="unit"
            options={unitOptions}
          />

          <div className={styles.footer}>

            <Button
              tag={Link}
              to={PROPERTIES_ROUTE}
              color="primary"
            >
              {translate('Cancel')}

            </Button>

            <Button
              color="primary"
              variant="contained"
              type="submit"
            >

              {translate('Next Step')}

            </Button>

          </div>

        </Form>

      </Formik>

    </div>
  );
};

ChooseProperty.propTypes = {
  values: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
};
