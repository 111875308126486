import { schema } from 'normalizr';

const units = new schema.Entity('units');
const tenants = new schema.Entity('tenants');
const properties = new schema.Entity('properties');
const transactions = new schema.Entity('transactions');
const leases = new schema.Entity('leases');
const landlords = new schema.Entity('landlords');
const invoices = new schema.Entity('invoices');

export default {
  UNITS_ARRAY: [units],
  UNITS: units,
  TENANTS_ARRAY: [tenants],
  TENANTS: tenants,
  PROPERTIES_ARRAY: [properties],
  PROPERTIES: properties,
  TRANSACTIONS_ARRAY: [transactions],
  TRANSACTIONS: transactions,
  LEASES_ARRAY: [leases],
  LEASES: leases,
  INVOICES_ARRAY: [invoices],
  INVOICES: invoices,
  LANDLORDS_ARRAY: [landlords],
  LANDLORDS: landlords,
};
